import { AbortParams } from 'src/data/api/api-client';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

import {
    GoogleEnrichmentOption,
    GooglePlaceOption,
    createLocationData,
    transformGoogleEnrichmentFromFragment,
} from './google-enrichment.model';
import {
    createDealContactCompanyFromFragment,
    DealContactCompany,
} from 'src/app-features/contact/data/model/deal-contacts.model';

interface GetGoogleEnrichmentLocationsParams extends AbortParams {
    dealId: string;
    name: string;
}

interface GetGoogleEnrichmentDataParams extends AbortParams {
    placeId: string;
    language: string;
}

interface AutoEnrichmentParams extends AbortParams {
    dealId: string;
    companyId: string;
}
interface UpdateCompanyWithEnrichmentParams extends AbortParams {
    companyId: string;
    dealId: string;
    placeId: string;
    fields?: string[];
}

interface RemoveEnrichmentWithCompanyParams extends AbortParams {
    companyId: string;
    placeId: string;
    field?: string;
}

export interface IGoogleEnrichmentApi {
    getLocationOptions: (
        params: GetGoogleEnrichmentLocationsParams,
    ) => Promise<GooglePlaceOption[]>;
    getLocationEnrichment: (
        params: GetGoogleEnrichmentDataParams,
    ) => Promise<GoogleEnrichmentOption>;
    runAutoEnrichmentForCompany: (
        params: AutoEnrichmentParams,
    ) => Promise<DealContactCompany>;
    saveAutoEnrichmentResultForCompany: (
        params: UpdateCompanyWithEnrichmentParams,
    ) => Promise<DealContactCompany>;
    removeEnrichmentItemFromCompany: (
        params: RemoveEnrichmentWithCompanyParams,
    ) => Promise<DealContactCompany>;
}

export const createGoogleEnrichmentApi = (
    processGqlSdk: ProcessGqlSdk,
): IGoogleEnrichmentApi => {
    const getLocationOptions = async (
        params: GetGoogleEnrichmentLocationsParams,
    ): Promise<GooglePlaceOption[]> => {
        const response = await processGqlSdk.GetDealLocationData(params);
        return (response.actionFeatureGoogleEnrichmentPlaces ?? []).map(
            createLocationData,
        );
    };

    const getLocationEnrichment = async (
        params: GetGoogleEnrichmentDataParams,
    ): Promise<GoogleEnrichmentOption> => {
        const { placeId, language } = params;
        const { actionFeatureGoogleEnrichmentPlaceDetails: response } =
            await processGqlSdk.GetGoogleEnrichmentData({
                language,
                placeId,
            });

        return transformGoogleEnrichmentFromFragment(response);
    };

    const runAutoEnrichmentForCompany = async (
        params: AutoEnrichmentParams,
    ): Promise<DealContactCompany> => {
        const { dealId, companyId } = params;
        const response = await processGqlSdk.RunAutoEnrichmentForCompany(
            {
                dealId,
                companyId,
            },
            { signal: params.signal },
        );
        return createDealContactCompanyFromFragment(response.autoEnrichCompany);
    };
    const saveAutoEnrichmentResultForCompany = async (
        params: UpdateCompanyWithEnrichmentParams,
    ): Promise<DealContactCompany> => {
        const { companyId, dealId, placeId, fields, signal } = params;
        const response = await processGqlSdk.UpdateCompanyWithEnrichment(
            {
                dealId,
                companyId,
                placeId,
                fields,
            },
            {
                signal: signal,
            },
        );
        return createDealContactCompanyFromFragment(
            response.overrideCompanyInfoWithPlace,
        );
    };

    const removeEnrichmentItemFromCompany = async (
        params: RemoveEnrichmentWithCompanyParams,
    ): Promise<DealContactCompany> => {
        const { companyId, placeId, field } = params;
        const response = await processGqlSdk.RemoveEnrichmentItemFromCompany(
            {
                companyId,
                placeId,
                field,
            },
            {
                signal: params.signal,
            },
        );
        return createDealContactCompanyFromFragment(
            response.removePlaceFromCompany,
        );
    };

    return {
        getLocationEnrichment,
        getLocationOptions,
        runAutoEnrichmentForCompany,
        saveAutoEnrichmentResultForCompany,
        removeEnrichmentItemFromCompany,
    };
};
