import { AbortParams } from 'src/data/api/api-client';
import { type DeleteDealContentMutation } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    type ProcessMultipartGqlSdk,
    ProcessGqlSdk,
} from 'src/data/api/graphql/graphql-client.wrapper';
import {
    type TDealAttachment,
    mapDealAttachment,
} from '../domain/deal-attachments.model';

interface IGetAttachmentListParams {
    dealId: string;
}

interface IGetFileAttachmentDownloadUrlParams {
    contentId: string;
    inlineContent?: boolean;
}

interface IUploadDealFileAttachmentParams {
    dealId: string;
    file: File;
}

interface IUploadUrlAttachmentParams {
    dealId: string;
    url: string;
}

interface IDeleteAttachmentParams {
    dealId: string;
    contentId: string;
}

export interface IDealAttachmentsApi {
    getAttachmentList: (
        params: IGetAttachmentListParams,
        options?: Partial<AbortParams>,
    ) => Promise<TDealAttachment[]>;
    getFileAttachmentDownloadUrl: (
        params: IGetFileAttachmentDownloadUrlParams,
        options?: Partial<AbortParams>,
    ) => Promise<string>;
    uploadAttachment: (
        params: IUploadDealFileAttachmentParams | IUploadUrlAttachmentParams,
        options?: Partial<AbortParams>,
    ) => Promise<TDealAttachment | null>;
    deleteAttachment: (
        params: IDeleteAttachmentParams,
        options?: Partial<AbortParams>,
    ) => Promise<DeleteDealContentMutation>;
}

export const createDealAttachmentsApi = (
    processGqlSdk: ProcessGqlSdk,
    processGqlSdkMultipart: ProcessMultipartGqlSdk,
): IDealAttachmentsApi => {
    const getAttachmentList: IDealAttachmentsApi['getAttachmentList'] = async (
        params,
        options,
    ) => {
        const { deal } = await processGqlSdk.GetDealContentMetadata(
            params,
            options,
        );

        return deal.contents.map(mapDealAttachment);
    };

    const getFileAttachmentDownloadUrl: IDealAttachmentsApi['getFileAttachmentDownloadUrl'] =
        async (params, options) => {
            const { contentId, inlineContent } = params;
            const { getDealAttachmentDownloadUrl } =
                await processGqlSdk.GetDealContentAttachmentDownloadUrl(
                    { contentId, inline: inlineContent ?? false },
                    options,
                );

            return getDealAttachmentDownloadUrl.value;
        };

    const uploadAttachment: IDealAttachmentsApi['uploadAttachment'] = async (
        params,
        options,
    ) => {
        const { uploadDealAttachment } =
            await processGqlSdkMultipart.UploadDealAttachment(params, options);

        const uploadedAttachment = uploadDealAttachment[0];

        if (!uploadedAttachment) return null;
        return mapDealAttachment(uploadedAttachment);
    };

    const deleteAttachment: IDealAttachmentsApi['deleteAttachment'] = async (
        params,
        options,
    ) => {
        return processGqlSdk.DeleteDealContent(params, options);
    };

    return {
        getAttachmentList,
        getFileAttachmentDownloadUrl,
        uploadAttachment,
        deleteAttachment,
    };
};
