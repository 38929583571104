import { type FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { type Reminder } from 'src/domain/models/reminder/reminder.model';
import { EmptyNotificationsList } from '../shared/empty-notifications-list/empty-notifications-list.component';
import { ReminderListSection } from './reminder-list-section/reminder-list-section.component';

interface RemindersDashboardProps {
    overdueReminders: Reminder[];
    upcomingReminders: Reminder[];
    isLoading: boolean;
}

export const RemindersDashboard: FC<RemindersDashboardProps> = observer(
    ({ overdueReminders, upcomingReminders, isLoading }) => {
        const { t } = useTranslation();

        const shouldDisplayEmptyRemindersComponent =
            !overdueReminders.length && !upcomingReminders.length && !isLoading;

        return (
            <div className="flex flex-col gap-6">
                {shouldDisplayEmptyRemindersComponent ? (
                    <EmptyNotificationsList
                        title={t(
                            'notification_flyout.reminders_tab.empty_reminders.title',
                        )}
                    />
                ) : (
                    <>
                        <ReminderListSection
                            title={t(
                                'notification_flyout.reminders_tab.overdue_section_title',
                            )}
                            fetchingReminders={isLoading}
                            reminders={overdueReminders}
                        />
                        <ReminderListSection
                            title={t(
                                'notification_flyout.reminders_tab.upcoming_section_title',
                            )}
                            fetchingReminders={isLoading}
                            reminders={upcomingReminders}
                        />
                    </>
                )}
            </div>
        );
    },
);
