import { TDealAttachment } from 'src/app-features/deal-attachments/domain/deal-attachments.model';
import {
    type ContactExtractionStatus,
    type GetContactExtractionResultQuery,
    ExtractionType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { isValidUrl } from 'src/utils/url.utils';

interface CandidateSource {
    id: string;
    attachment?: TDealAttachment;
}
export interface DealContactCandidate {
    firstName: string;
    lastName: string;
    companyName: string;
    legitimateInterestText: string;
    sourceUrls: string[];
    sourceContents: CandidateSource[];
    role: string;
    extractedFromSources: ExtractionType[];
}

export interface DealCompanyCandidate {
    name: string;
    legitimateInterestText: string;
    sourceUrls: string[];
    sourceContents: CandidateSource[];
    role: string;
    extractedFromSources: ExtractionType[];
    saved?: boolean;
}

export interface DealContactCandidateForm {
    firstName: string;
    lastName: string;
    companyName: string;
    notes: string;
    extractionTypes: ExtractionType[];
    customValues?: CustomValue[];
}

export interface DealCompanyCandidateForm {
    name: string;
    notes: string;
    extractionTypes: ExtractionType[];
    customValues?: CustomValue[];
}

export enum ContactExtractionResultStatus {
    Error = 'ERROR',
    NoContacts = 'NO_CONTACTS',
    Success = 'SUCCESS',
    ArticlesSearchInProgress = 'ARTICLES_SEARCH_IN_PROGRESS',
    WebSearchInProgress = 'WEB_SEARCH_IN_PROGRESS',
    NeverRun = 'NEVER_RUN',
    Disabled = 'DISABLED',
    AllAdded = 'ALL_ADDED',
}

export type ContactExtractionResultData = {
    status: ContactExtractionStatus;
    extractionTypes: ExtractionType[];
    extractedContacts: DealContactCandidate[];
    extractedCompanies: DealCompanyCandidate[];
};

export interface ContactExtractionResult {
    projectContextResult: ContactExtractionResultData | null;
    webSearchResult: ContactExtractionResultData | null;
}

export interface ContactCandidateGroup {
    company?: DealCompanyCandidate;
    contacts: DealContactCandidate[];
}

type CustomValue = { name: string; value: string };

export const mapContactExtractionResult = (
    result: GetContactExtractionResultQuery['getContactExtractionResult'],
): ContactExtractionResult => {
    const { projectContextResult, webSearchResult } = result;

    const mappedProjectContextResult: ContactExtractionResultData | null =
        projectContextResult
            ? {
                  status: projectContextResult.status,
                  extractionTypes: [projectContextResult.extractionType],
                  extractedContacts: projectContextResult.extractedContacts.map(
                      (contact) => ({
                          ...contact,
                          sourceUrls: contact.sourceUrls.filter(isValidUrl),
                          sourceContents: getAttachmentSources(
                              contact.sourceUrls,
                          ),
                          extractedFromSources: [
                              projectContextResult.extractionType,
                          ],
                      }),
                  ),
                  extractedCompanies:
                      projectContextResult.extractedCompanies.map(
                          (company) => ({
                              ...company,
                              sourceUrls: company.sourceUrls.filter(isValidUrl),
                              sourceContents: getAttachmentSources(
                                  company.sourceUrls,
                              ),
                              extractedFromSources: [
                                  projectContextResult.extractionType,
                              ],
                          }),
                      ),
              }
            : null;

    const mappedWebSearchResult: ContactExtractionResultData | null =
        webSearchResult
            ? {
                  status: webSearchResult.status,
                  extractionTypes: [webSearchResult.extractionType],
                  extractedContacts: webSearchResult.extractedContacts.map(
                      (contact) => ({
                          ...contact,
                          sourceUrls: contact.sourceUrls.filter(isValidUrl),
                          sourceContents: getAttachmentSources(
                              contact.sourceUrls,
                          ),
                          extractedFromSources: [
                              webSearchResult.extractionType,
                          ],
                      }),
                  ),
                  extractedCompanies: webSearchResult.extractedCompanies.map(
                      (company) => ({
                          ...company,
                          sourceUrls: company.sourceUrls.filter(isValidUrl),
                          sourceContents: getAttachmentSources(
                              company.sourceUrls,
                          ),
                          extractedFromSources: [
                              webSearchResult.extractionType,
                          ],
                      }),
                  ),
              }
            : null;

    return {
        projectContextResult: mappedProjectContextResult,
        webSearchResult: mappedWebSearchResult,
    };
};

const getAttachmentSources = (sources: string[]): CandidateSource[] => {
    return sources
        .filter((source) => !isValidUrl(source)) // if not valid URL, assume it's a deal content ID
        .map((source) => ({
            id: source,
            attachment: undefined,
        }));
};
