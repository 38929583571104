import {
    type TDealAttachment,
    EDealAttachmentType,
    EDealAttachmentCreationSource,
} from './deal-attachments.model';

export const getAttachmentName = (attachment: TDealAttachment) => {
    return attachment.type === EDealAttachmentType.FILE
        ? attachment.sourceName
        : attachment.source;
};

export const filterAttachmentsForDisplay = (
    attachmentList: TDealAttachment[],
    isDealAIAttachmentsFeatureEnabled: boolean,
): TDealAttachment[] =>
    attachmentList.filter((attachment) => {
        if (attachment.type === EDealAttachmentType.TEXT) {
            return false;
        }

        if (
            attachment.creationSource ===
            EDealAttachmentCreationSource.ADDITIONAL_SOURCES_LLM
        ) {
            return isDealAIAttachmentsFeatureEnabled;
        }

        return true;
    });
