import {
    ApolloClient,
    ApolloLink,
    DefaultOptions,
    from,
    HttpLink,
    InMemoryCache,
    split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';

import { getCookie } from 'src/utils/cookie.utils';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getGraphqlUrl, getWsUrl } from './graphql-client.utils';

const authMiddleware = new ApolloLink((operation, forward) => {
    const xsrfToken = getCookie('XSRF-TOKEN');
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'X-XSRF-TOKEN': xsrfToken,
        },
    }));
    return forward(operation);
});

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

const createLink = (url: string, enableWebSocket: boolean) => {
    if (url.includes('multipart')) {
        return createUploadLink({
            uri: getGraphqlUrl(url),
            headers: {
                'Apollo-Require-Preflight': 'true',
            },
        });
    } else {
        const httpLink = new HttpLink({
            uri: getGraphqlUrl(url),
        });

        if (!enableWebSocket) {
            return httpLink;
        }

        const wsLink = new GraphQLWsLink(
            createClient({
                url: getWsUrl(url),
            }),
        );
        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            httpLink,
        );
        return splitLink;
    }
};

const cache = new InMemoryCache();
export const getGraphqlApolloClient = (url: string, enableWebSocket = false) =>
    new ApolloClient({
        link: from([
            removeTypenameFromVariables(),
            authMiddleware,
            createLink(url, enableWebSocket),
        ]),
        cache: cache,
        defaultOptions: defaultOptions,
        queryDeduplication: enableWebSocket ? true : false,
    });
