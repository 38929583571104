import { configure } from 'mobx';
import { createContext } from 'react';

import { createDeveloperApiKeysApi } from 'src/app-features/account-developer-tools/data/api/developer-api-keys.api';
import {
    AccountDeveloperToolsFeature,
    IAccountDeveloperToolsFeature,
} from 'src/app-features/account-developer-tools/domain/account-developer-tools.feature';
import { createAchievementsApi } from 'src/app-features/achievements/data/api/achievements.api';
import { AchievementsFeature } from 'src/app-features/achievements/domain/achievements.feature';
import { IAchievementsFeature } from 'src/app-features/achievements/domain/achievements.feature.interface';
import { createAchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import { createDealContactsApi } from 'src/app-features/contact/data/api/deal-contacts.api';
import { DealContactsFeature } from 'src/app-features/contact/domain/deal-contacts.feature';
import { createContactEnrichmentApi } from 'src/app-features/contact-enrichment/data/api/contact-enrichment.api';
import {
    ContactEnrichmentFeature,
    IContactEnrichmentFeature,
} from 'src/app-features/contact-enrichment/domain/contact-enrichment.feature';
import {
    ExternalLinkFeature,
    IExternalLinkFeature,
} from 'src/app-features/external-link/domain/external-link.feature';
import { createGoogleEnrichmentApi } from 'src/app-features/google-enrichment/data/google-enrichment.api';
import { IGoogleEnrichmentFeature } from 'src/app-features/google-enrichment/domain/google-enrichment.feature.interface';
import { createPipelineStatisticsApi } from 'src/app-features/pipeline-statistics/data/api/pipeline-statistics.api';
import { PipelineStatisticsFeature } from 'src/app-features/pipeline-statistics/domain/pipeline-statistics.feature';
import { IPipelineStatisticsFeature } from 'src/app-features/pipeline-statistics/domain/pipeline-statistics.feature.interface';
import {
    type IScreeningSearchConfigurationFeature,
    ScreeningSearchConfigurationFeature,
} from 'src/app-features/screening-search-configuration-page/domain/screening-search-configuration.feature';
import { createSearchesApi } from 'src/app-features/searches-configuration/api/searches.api';
import { FilterModuleConfigFeature } from 'src/app-features/searches-configuration/domain/features/filter-module-config/feature';
import { IFilterModuleConfigFeature } from 'src/app-features/searches-configuration/domain/features/filter-module-config/feature.interface';
import { SearchesConfigFeature } from 'src/app-features/searches-configuration/domain/features/search-config/feature';
import { ISearchesConfigFeature } from 'src/app-features/searches-configuration/domain/features/search-config/feature.interface';
import { FilterModuleConfigStore } from 'src/app-features/searches-configuration/domain/stores/filter-module-configuration/store';
import {
    StageObjectivesFeature,
    StageObjectivesFeatureImpl,
} from 'src/app-features/stage-objectives/domain/feature/stage-objectives.feature';
import { createAccountConfigurationApi } from 'src/data/api/account-configuration/account-configuration.api';
import { createRemindersApi } from 'src/data/api/actions/reminders/reminders.api';
import { createXRayApi } from 'src/data/api/actions/xray/xray.api';
import { createApiClient } from 'src/data/api/api-client';
import { createAuthApi } from 'src/data/api/auth/auth.api';
import { createCommentsApi } from 'src/app-features/deal-comments/data/comments.api';
import { createCustomFieldApi } from 'src/data/api/custom-fields/custom-fields.api';
import { createCustomParametersApi } from 'src/app-features/custom-parameter/data/api/custom-parameters.api';
import { createDealsApi } from 'src/data/api/deal/deal.api';
import { createDealsExportApi } from 'src/data/api/deal-export/deal-export.api';
import { createExportToCRMApi } from 'src/data/api/export-to-crm/export-to-crm.api';
import { createFolderCountersApi } from 'src/data/api/folder-counters/folder-counters.api';
import {
    ProcessGqlSdkWrapper,
    ProcessMultipartGqlSdkWrapper,
    ProjectGqlSdkWrapper,
    SearchGqlSdkWrapper,
    UserGqlSdkWrapper,
} from 'src/data/api/graphql/graphql-client.wrapper';
import { createLeadFieldsApi } from 'src/data/api/lead-fields/lead-fields.api';
import { createLeadsApi } from 'src/data/api/leads/leads.api';
import { createLookupToolApi } from 'src/data/api/lookup-tool/lookup-tool.api';
import { createNotificationsApi } from 'src/data/api/notifications/notifications.api';
import { createPipelineAPi } from 'src/data/api/pipeline/pipeline.api';
import { createSubscriptionsApi } from 'src/data/api/subscriptions/subscriptions.api';
import { createUsersApi } from 'src/data/api/user/user.api';
import { createExportService } from 'src/data/services/export/export.service';
import { createFeatureFlagsService } from 'src/data/services/feature-flags/feature-flags.service';
import { createHubSpotService } from 'src/data/services/hubspot/hubspot.service';
import { createLocalStorageService } from 'src/data/services/local-storage/local-storage.service';
import { createLocalizeService } from 'src/data/services/localize/localize.service';
import { LocationServiceImpl } from 'src/data/services/location/location.service';
import { createMixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { createPendoService } from 'src/data/services/pendo/pendo.service';
import { createSaveContactToPhoneService } from 'src/data/services/save-contact-to-phone/save-contact-to-phone.service';
import { AccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store';
import { QuickActionsStoreImpl } from 'src/data/stores/actions/quick-actions.store';
import { AssociatedCompaniesStoreImpl } from 'src/data/stores/associated-companies/associated-companies.store';
import { CommentsStoreImpl } from 'src/app-features/deal-comments/store/comments.store';
import { CustomFieldsStoreImpl } from 'src/data/stores/custom-fields/custom-fields.store';
import { CustomParametersStoreImpl } from 'src/app-features/custom-parameter/domain/custom-parameters.store';
import { DealBatchOperationsStore } from 'src/data/stores/deal-batch-operations/deal-batch-operations.store';
import { DealsStoreImpl } from 'src/data/stores/deals/deals.store';
import { ErrorsStoreImpl } from 'src/data/stores/errors/errors.store';
import { ExportStoreImpl } from 'src/data/stores/export/export.store';
import { FolderCountersStoreImpl } from 'src/data/stores/folder-counters/folder-counters.store';
import { LeadsStoreImpl } from 'src/data/stores/leads/leads.store';
import { NotificationsStoreImpl } from 'src/data/stores/notifications/notifications.store';
import { ObjectivesStoreImpl } from 'src/data/stores/objectives/objectives.store';
import { PipelineStoreImpl } from 'src/data/stores/pipeline/pipeline.store';
import { PipelineDynamicFiltersStore } from 'src/data/stores/pipeline-data/dynamic-filters/pipeline-dynamic-filters.store';
import { PipelineTableViewStore } from 'src/data/stores/pipeline-data/pipeline-table-view/pipeline-table-view.store';
import { ViewPreferencesStore } from 'src/data/stores/pipeline-data/view-preferences/view-preferences.store';
import { ProcurementTextsStoreImpl } from 'src/data/stores/procurement-texts/procurement-texts.store';
import { BaseStore } from 'src/data/stores/shared/base.store';
import { SubscriptionsStoreImpl } from 'src/data/stores/subscriptions/subscriptions.store';
import { ToasterStoreImpl } from 'src/data/stores/toaster/toaster.store';
import { UserStoreImpl } from 'src/data/stores/user/user.store';
import { IAccountConfigurationFeature } from 'src/domain/features/account-configuration/account-configuration-interface.feature';
import { AccountConfigurationFeature } from 'src/domain/features/account-configuration/account-configuration.feature';
import { IEmailTemplatesFeature } from 'src/domain/features/actions/quick-actions/email-templates/email-templates-interface.feature';
import { EmailTemplateFeature } from 'src/domain/features/actions/quick-actions/email-templates/email-templates.feature';
import { IQuickActionsFeatureInterface } from 'src/domain/features/actions/quick-actions/quick-actions-interface.feature';
import { QuickActionsFeature } from 'src/domain/features/actions/quick-actions/quick-actions.feature';
import { IRemindersFeature } from 'src/app-features/schedule-reminders/domain/reminders-interface.feature';
import { RemindersFeature } from 'src/app-features/schedule-reminders/domain/reminders.feature';
import { IXRayFeature } from 'src/domain/features/actions/quick-actions/xray/xray-interface.feature';
import { XRayFeature } from 'src/domain/features/actions/quick-actions/xray/xray.feature';
import {
    AssociatedCompaniesFeature,
    AssociatedCompaniesFeatureImpl,
} from 'src/domain/features/associated-companies/associated-companies.feature';
import {
    AssociatedSearchesFeature,
    AssociatedSearchesFeatureImpl,
} from 'src/domain/features/associated-searches/associated-searches.feature';
import {
    AuthFeature,
    AuthFeatureImpl,
} from 'src/domain/features/auth/auth.feature';
import {
    CommentsFeature,
    CommentsFeatureImpl,
} from 'src/app-features/deal-comments/domain/feature/comments.feature';
import {
    CustomFieldsFeature,
    CustomFieldsFeatureImpl,
} from 'src/domain/features/custom-fields/custom-fields.feature';
import {
    CustomParametersFeature,
    CustomParametersFeatureImpl,
} from 'src/app-features/custom-parameter/domain/custom-parameters.feature';
import {
    DashboardFeature,
    DashboardFeatureImpl,
} from 'src/domain/features/dashboard/dashboard.feature';
import {
    DealActionsFeature,
    DealActionsFeatureImpl,
} from 'src/domain/features/deal-actions/deal-actions.feature';
import {
    BulkDealEditFeature,
    IBulkDealEditFeature,
} from 'src/domain/features/deal-batch-operations/bulk-edit/bulk-deal-edit.feature';
import {
    DealBatchOperationsFeature,
    IDealBatchOperationsFeature,
} from 'src/domain/features/deal-batch-operations/deal-batch-operations.feature';
import { IContactExtractionFeature } from 'src/app-features/contact-extraction/domain/feature/contact-extraction-interface.feature';
import {
    DealExportFeature,
    DealExportFeatureImpl,
} from 'src/domain/features/deal-export/deal-export.feature';
import {
    DealMoveProcessFeature,
    DealMoveProcessFeatureImpl,
} from 'src/domain/features/deal-move-process/deal-move-process.feature';
import {
    DealPauseFeature,
    DealPauseFeatureImpl,
} from 'src/domain/features/deal-pause/deal-pause.feature';
import {
    DealViewFeature,
    DealViewFeatureImpl,
} from 'src/domain/features/deal-view/deal-view.feature';
import {
    DealsListFeature,
    DealsListFeatureImpl,
} from 'src/domain/features/deals-list/deals-list.feature';
import {
    ExportFeature,
    ExportFeatureImpl,
} from 'src/domain/features/export/export.feature';
import {
    LeadActionsFeature,
    LeadActionsFeatureImpl,
} from 'src/app-features/project-leads/domain/lead-actions/lead-actions.feature';
import {
    ILeadsListFeature,
    LeadsListFeature,
} from 'src/app-features/project-leads/domain/leads-list/leads-list.feature';
import {
    NavbarFeature,
    NavbarFeatureImpl,
} from 'src/domain/features/navbar/navbar.feature';
import { NotificationsFeature } from 'src/domain/features/notifications/notifications-interface.feature';
import { NotificationsFeatureImpl } from 'src/domain/features/notifications/notifications.feature';
import {
    PipelineFeature,
    PipelineFeatureImpl,
} from 'src/domain/features/pipeline/pipeline.feature';
import {
    IPipelineTableViewFeature,
    PipelineTableViewFeature,
} from 'src/domain/features/pipeline-data/table-view/pipeline-table-view.feature';
import {
    ProjectLookupFeature,
    ProjectLookupFeatureImpl,
} from 'src/domain/features/project-lookup/project-lookup.feature';
import {
    ToasterFeature,
    ToasterFeatureImpl,
} from 'src/domain/features/toaster/toaster.feature';
import {
    TranslationFeature,
    TranslationFeatureImpl,
} from 'src/domain/features/translation/translation.feature';
import {
    UserSettingsFeature,
    UserSettingsFeatureImpl,
} from 'src/domain/features/user-settings/user-settings.feature';
import { history } from 'src/history.utils';
import {
    DealActivityFeature,
    IDealActivityFeature,
} from 'src/app-features/deal-activity/domain/deal-activity.feature';
import { createDealActivityApi } from 'src/app-features/deal-activity/data/deal-activity.api';
import { createVpoGenerationApi } from 'src/app-features/ai-value-proposition-outreach/api/vpo-generation.api';
import {
    IVpoGenerationFeature,
    VpoGenerationFeature,
} from 'src/app-features/ai-value-proposition-outreach/domain/vpo-generation.feature';
import { createPromptBlockApi } from 'src/app-features/ai-value-proposition-outreach/api/prompt-block.api';
import {
    IPromptBlocksManagementFeature,
    PromptBlocksManagementFeature,
} from 'src/app-features/ai-value-proposition-outreach/domain/prompt-blocks-management.feature';
import { VpoGenerationStore } from 'src/app-features/ai-value-proposition-outreach/domain/vpo-generation.store';
import {
    IQrCodeFeature,
    QrCodeFeature,
} from 'src/app-features/ai-value-proposition-outreach/domain/save-contact-to-phone/qr-code.feature';
import { DealActivityStore } from 'src/app-features/deal-activity/domain/deal-activity.store';
import {
    IOmniSearchFeature,
    OmniSearchFeature,
} from 'src/app-features/omni-search/domain/omni-search.feature';
import { createOmniSearchApi } from 'src/app-features/omni-search/data/omni-search.api';
import { ContactExtractionFeature } from 'src/app-features/contact-extraction/domain/feature/contact-extraction.feature';
import { createContactExtractionApi } from 'src/app-features/contact-extraction/data/contact-extraction.api';
import { ContactExtractionStore } from 'src/app-features/contact-extraction/data/store/contact-extraction.store';
import { DealContactsStore } from 'src/app-features/contact/data/stores/deal-contacts.store';
import {
    type IDealAttachmentsFeature,
    DealAttachmentsFeature,
} from 'src/app-features/deal-attachments/domain/deal-attachments.feature';
import { createDealAttachmentsApi } from 'src/app-features/deal-attachments/data/deal-attachments.api';
import {
    LeadFieldsFeature,
    LeadFieldsFeatureImpl,
} from 'src/app-features/project-leads/domain/lead-fields/lead-fields.feature';
import {
    BringYourOwnProjectsFeature,
    IBringYourOwnProjectsFeature,
} from 'src/app-features/bring-your-own-projects/domain/feature';
import { createBringYourOwnProjectsApi } from 'src/app-features/bring-your-own-projects/domain/api';
import {
    createViewPreferencesFeature,
    IViewPreferencesFeature,
} from 'src/domain/features/pipeline-data/view-preferences/view-preferences.feature';
import {
    createPipelineDataFeatureObservable,
    IPipelineDataFeature,
} from 'src/domain/features/pipeline-data/pipeline-data.feature';
import { createContextAccessor } from 'src/data/services/context-accessor/context-acessor.service';
import {
    ContactFinderFeature,
    IContactFinderFeature,
} from 'src/app-features/company-contacts-finder/domain/contact-finder.feature';
import { createContactFinderApi } from 'src/app-features/company-contacts-finder/data/contact-finder.api';
import { createGoogleEnrichmentFeature } from 'src/app-features/google-enrichment/domain/google-enrichment.feature';
import { createDealContactsTrackingService } from 'src/app-features/contact/data/services/deal-contacts-tracking.service';
import { DealContactsSectionStore } from 'src/app-features/contact/data/stores/deal-contacts-section.store';
import { IDealContactsFeature } from 'src/app-features/contact/domain/interfaces/deal-contacts.feature.interface';
import { IDealContactsSectionFeature } from 'src/app-features/contact/domain/interfaces/deal-contacts-section.feature.interface';
import { DealContactsSectionFeature } from 'src/app-features/contact/domain/deal-contacts-section.feature';
import { DealContactsFormFeature } from 'src/app-features/contact/domain/deal-contacts-form.feature';
import { IDealContactsFormFeature } from 'src/app-features/contact/domain/interfaces/deal-contacts-form.feature.interface';
import { DealAttachmentsStore } from 'src/app-features/deal-attachments/domain/store/deal-attachments.store';
import { createLiveNotificationApi } from 'src/app-features/live-notification/data/live-notification.api';
import { LiveNotificationFeature } from 'src/app-features/live-notification/domain/live-notification.feature';
import { ILiveNotificationFeature } from 'src/app-features/live-notification/domain/live-notification.feature.interface';

configure({ isolateGlobalState: true, enforceActions: 'always' });

// here we have only interfaces
export interface AppFeatures {
    leadsListFeature: ILeadsListFeature;
    navbarFeature: NavbarFeature;
    translationFeature: TranslationFeature;
    authFeature: AuthFeature;
    exportFeature: ExportFeature;
    leadActionsFeature: LeadActionsFeature;
    dealActionsFeature: DealActionsFeature;
    stageObjectivesFeature: StageObjectivesFeature;
    dealPauseFeature: DealPauseFeature;
    commentsFeature: CommentsFeature;
    associatedCompaniesFeature: AssociatedCompaniesFeature;
    associatedSearchesFeature: AssociatedSearchesFeature;
    notificationsFeature: NotificationsFeature;
    customFieldsFeature: CustomFieldsFeature;
    customParametersFeature: CustomParametersFeature;
    toasterFeature: ToasterFeature;
    leadFieldsFeature: LeadFieldsFeature;
    projectLookupFeature: ProjectLookupFeature;
    pipelineFeature: PipelineFeature;
    pipelineDataFeature: IPipelineDataFeature;
    dealsListFeature: DealsListFeature;
    dealViewFeature: DealViewFeature;
    dealExportFeature: DealExportFeature;
    contactExtractionFeature: IContactExtractionFeature;
    dealContactsFeature: IDealContactsFeature;
    dealContactsSectionFeature: IDealContactsSectionFeature;
    dealContactsFormFeature: IDealContactsFormFeature;
    dealMoveProcessFeature: DealMoveProcessFeature;
    dealAttachmentsFeature: IDealAttachmentsFeature;
    dashboardFeature: DashboardFeature;
    userSettingsFeature: UserSettingsFeature;
    qrCodeFeature: IQrCodeFeature;
    xRayFeature: IXRayFeature;
    accountConfigurationFeature: IAccountConfigurationFeature;
    googleEnrichmentFeature: IGoogleEnrichmentFeature;
    quickActionsFeature: IQuickActionsFeatureInterface;
    remindersFeature: IRemindersFeature;
    emailTemplatesFeature: IEmailTemplatesFeature;
    viewPreferencesFeature: IViewPreferencesFeature;
    pipelineTableViewFeature: IPipelineTableViewFeature;
    accountDeveloperToolsFeature: IAccountDeveloperToolsFeature;
    dealBatchOperationsFeature: IDealBatchOperationsFeature;
    bulkDealEditFeature: IBulkDealEditFeature;
    externalLinkFeature: IExternalLinkFeature;
    searchesConfigFeature: ISearchesConfigFeature;
    filterModuleConfigFeature: IFilterModuleConfigFeature;
    contactEnrichmentFeature: IContactEnrichmentFeature;
    achievementsFeature: IAchievementsFeature;
    pipelineStatisticsFeature: IPipelineStatisticsFeature;
    dealActivityFeature: IDealActivityFeature;
    vpoGenerationFeature: IVpoGenerationFeature;
    promptBlocksManagementFeature: IPromptBlocksManagementFeature;
    omniSearchFeature: IOmniSearchFeature;
    bringYourOwnProjectsFeature: IBringYourOwnProjectsFeature;
    contactFinderFeature: IContactFinderFeature;
    liveNotificationFeature: ILiveNotificationFeature;
    screeningSearchConfigurationFeature: IScreeningSearchConfigurationFeature;
}

const lookupApiClient = createApiClient('/apps/project_lookup/api');

// ::::: DATA LAYER :::::

// api
const leadsApi = createLeadsApi(ProjectGqlSdkWrapper);
const usersApi = createUsersApi(ProcessGqlSdkWrapper, UserGqlSdkWrapper);
const folderCountersApi = createFolderCountersApi(ProjectGqlSdkWrapper);
const authApi = createAuthApi(ProcessGqlSdkWrapper, UserGqlSdkWrapper);
const commentsApi = createCommentsApi(ProcessGqlSdkWrapper);
const subscriptionsApi = createSubscriptionsApi(SearchGqlSdkWrapper);
const notificationsApi = createNotificationsApi(ProcessGqlSdkWrapper);
const customFieldsApi = createCustomFieldApi(ProjectGqlSdkWrapper);
const leadFieldsApi = createLeadFieldsApi(SearchGqlSdkWrapper);
const exportToCRMApi = createExportToCRMApi(ProcessGqlSdkWrapper);
const customParametersApi = createCustomParametersApi(ProcessGqlSdkWrapper);
const pipelineApi = createPipelineAPi(ProcessGqlSdkWrapper);
const dealsApi = createDealsApi(ProcessGqlSdkWrapper);
const dealActivityApi = createDealActivityApi(ProcessGqlSdkWrapper);
const dealAttachmentsApi = createDealAttachmentsApi(
    ProcessGqlSdkWrapper,
    ProcessMultipartGqlSdkWrapper,
);
const lookupToolApi = createLookupToolApi(lookupApiClient);
const dealsExportApi = createDealsExportApi(ProcessGqlSdkWrapper);
const AccountConfigurationApi =
    createAccountConfigurationApi(ProcessGqlSdkWrapper);
const remindersApi = createRemindersApi(ProcessGqlSdkWrapper);
const xRayApi = createXRayApi(ProcessGqlSdkWrapper);
const developerApiKeysApi = createDeveloperApiKeysApi(UserGqlSdkWrapper);
const searchesApi = createSearchesApi(
    SearchGqlSdkWrapper,
    ProjectGqlSdkWrapper,
);
const contactEnrichmentApi = createContactEnrichmentApi(ProcessGqlSdkWrapper);
const achievementsApi = createAchievementsApi(ProcessGqlSdkWrapper);
const pipelineStatisticsApi = createPipelineStatisticsApi(ProcessGqlSdkWrapper);

const googleEnrichmentApi = createGoogleEnrichmentApi(ProcessGqlSdkWrapper);
const dealContactsApi = createDealContactsApi(ProcessGqlSdkWrapper);
const contactExtractionApi = createContactExtractionApi(ProcessGqlSdkWrapper);
const vpoGenerationApi = createVpoGenerationApi(ProcessGqlSdkWrapper);
const promptBlockApi = createPromptBlockApi(ProcessGqlSdkWrapper);
const omniSearchApi = createOmniSearchApi(ProcessGqlSdkWrapper);
const byopApi = createBringYourOwnProjectsApi(ProcessMultipartGqlSdkWrapper);
const contactFinderApi = createContactFinderApi(ProcessGqlSdkWrapper);
const liveNotificationApi = createLiveNotificationApi(ProcessGqlSdkWrapper);
// reusable stores
const userStore = new UserStoreImpl();
const leadsStore = new LeadsStoreImpl();
const subscriptionsStore = new SubscriptionsStoreImpl();
const folderCountersStore = new FolderCountersStoreImpl();
const procurementTextsStore = new ProcurementTextsStoreImpl();
const associatedCompaniesStore = new AssociatedCompaniesStoreImpl();
const errorsStore = new ErrorsStoreImpl();
const toasterStore = new ToasterStoreImpl();
const customFieldsStore = new CustomFieldsStoreImpl();
const pipelineStore = new PipelineStoreImpl();
const dealsStore = new DealsStoreImpl();
const customParameterStore = new CustomParametersStoreImpl();
const contactExtractionStore = new ContactExtractionStore();
const exportStore = new ExportStoreImpl();
const commentsStore = new CommentsStoreImpl();
const notificationsStore = new NotificationsStoreImpl();
const objectivesStore = new ObjectivesStoreImpl();
const quickActionsStore = new QuickActionsStoreImpl();
const accountConfigurationStore = new AccountConfigurationStore();
const pipelinePerformanceViewPreferencesStore = new ViewPreferencesStore();
const pipelineDynamicFiltersStore = new PipelineDynamicFiltersStore();
const dealBatchOperationStore = new DealBatchOperationsStore();
const pipelineTableViewStore = new PipelineTableViewStore();
const filterModuleConfigStore = new FilterModuleConfigStore();
const dealContactsStore = new DealContactsStore();
const vpoGenerationStore = new VpoGenerationStore();
const dealActivityStore = new DealActivityStore();
const dealContactsPresentationStore = new DealContactsSectionStore();
const dealAttachmentsStore = new DealAttachmentsStore();

// services, which do something external, exports, translations using json, local storage
const contextAccessor = createContextAccessor({
    getDeal: dealsStore.getDeal,
    getPipeline: pipelineStore.getPipeline,
});
const localizeService = createLocalizeService(UserGqlSdkWrapper);
const localStorageService = createLocalStorageService();
const pendoService = createPendoService();
const mixpanelService = createMixpanelService(contextAccessor);
const exportService = createExportService();
const locationService = new LocationServiceImpl(history, 20);
const hubspotService = createHubSpotService();
const saveContactToPhoneService = createSaveContactToPhoneService();
const featureFlagsService = createFeatureFlagsService();
const achievementService = createAchievementService();
const dealContactsTrackingService = createDealContactsTrackingService(
    mixpanelService,
    dealContactsStore,
);

// ::::: FRONTEND BUSINESS LOGIC LAYER :::::
// aka view models, aka use-cases

// it does not know about how the data could be used in view
// it does not know about how data is being got
// it does not know about how any external features (like export to PDF) works
// so it does not know about any realization, only interfaces / types
// it could only use its dependencies by interfaces

const baseStore = new BaseStore(errorsStore, toasterStore);

const leadsListFeature = new LeadsListFeature(
    leadsApi,
    customFieldsApi,
    customFieldsStore,
    userStore,
    leadsStore,
    baseStore,
    mixpanelService,
);

const projectLookupFeature = new ProjectLookupFeatureImpl(
    lookupToolApi,
    leadsStore,
    mixpanelService,
    baseStore,
);

const navbarFeature = new NavbarFeatureImpl(
    folderCountersApi,
    userStore,
    folderCountersStore,
    leadsApi,
    toasterStore,
    baseStore,
    mixpanelService,
);

const translationFeature = new TranslationFeatureImpl(
    localizeService,
    localStorageService,
    userStore,
    pendoService,
    baseStore,
);

const notificationsFeature = new NotificationsFeatureImpl(
    notificationsApi,
    notificationsStore,
    baseStore,
);

const associatedSearchesFeature = new AssociatedSearchesFeatureImpl(
    subscriptionsApi,
    userStore,
    leadsStore,
    subscriptionsStore,
    searchesApi,
    toasterStore,
    baseStore,
);

const authFeature = new AuthFeatureImpl(
    authApi,
    errorsStore,
    userStore,
    localStorageService,
    usersApi,
    pendoService,
    toasterStore,
    locationService,
    hubspotService,
    pipelineStore,
    mixpanelService,
    translationFeature,
    featureFlagsService,
    notificationsFeature,
    baseStore,
    associatedSearchesFeature,
);

const leadActionsFeature = new LeadActionsFeatureImpl(
    leadsStore,
    dealsApi,
    leadsApi,
    pipelineStore,
    toasterStore,
    userStore,
    customFieldsStore,
    mixpanelService,
    baseStore,
    achievementService,
    subscriptionsStore,
);

const commentsFeature = new CommentsFeatureImpl(
    commentsApi,
    commentsStore,
    userStore,
    toasterStore,
    mixpanelService,
    baseStore,
);

const associatedCompaniesFeature = new AssociatedCompaniesFeatureImpl(
    associatedCompaniesStore,
);

const customFieldsFeature = new CustomFieldsFeatureImpl(
    customFieldsApi,
    userStore,
    customFieldsStore,
    baseStore,
);

const customParametersFeature = new CustomParametersFeatureImpl(
    customParametersApi,
    customParameterStore,
    baseStore,
);

const leadFieldsFeature = new LeadFieldsFeatureImpl(
    leadFieldsApi,
    procurementTextsStore,
    baseStore,
);

const exportFeature = new ExportFeatureImpl(
    exportToCRMApi,
    userStore,
    subscriptionsStore,
    associatedCompaniesStore,
    procurementTextsStore,
    customFieldsStore,
    exportService,
    exportStore,
    dealsStore,
    mixpanelService,
    baseStore,
);

const toasterFeature = new ToasterFeatureImpl(toasterStore);

const pipelineFeature = new PipelineFeatureImpl(
    pipelineApi,
    pipelineStore,
    userStore,
    pipelinePerformanceViewPreferencesStore,
    baseStore,
);

const pipelineDataFeature = createPipelineDataFeatureObservable(
    pipelineApi,
    pipelinePerformanceViewPreferencesStore,
    pipelineDynamicFiltersStore,
    pipelineStore,
    accountConfigurationStore,
    baseStore,
    mixpanelService,
);

const dealsListFeature = new DealsListFeatureImpl(
    dealsApi,
    pipelineStore,
    dealsStore,
    toasterStore,
    achievementService,
    baseStore,
);

const dealActionsFeature = new DealActionsFeatureImpl(
    dealsStore,
    pipelineStore,
    dealsApi,
    baseStore,
    achievementService,
);

const dealPauseFeature = new DealPauseFeatureImpl(
    dealsStore,
    dealsApi,
    mixpanelService,
    baseStore,
    achievementService,
);

const dealViewFeature = new DealViewFeatureImpl(
    dealsStore,
    leadsStore,
    pipelineStore,
    leadsApi,
    dealsApi,
    toasterStore,
    locationService,
    hubspotService,
    objectivesStore,
    userStore,
    mixpanelService,
    baseStore,
);

const dealExportFeature = new DealExportFeatureImpl(
    dealsStore,
    userStore,
    dealsExportApi,
    baseStore,
);

const dealContactsFeature = new DealContactsFeature(
    dealContactsPresentationStore,
    dealContactsStore,
    contactExtractionStore,
    dealContactsApi,
    googleEnrichmentApi,
    baseStore,
    mixpanelService,
    dealsStore,
    toasterStore,
    accountConfigurationStore,
    achievementService,
    userStore,
    dealContactsTrackingService,
);

const contactExtractionFeature = new ContactExtractionFeature(
    contactExtractionApi,
    contactExtractionStore,
    baseStore,
    userStore,
    accountConfigurationStore,
    mixpanelService,
    dealContactsStore,
    dealAttachmentsStore,
);

const dealMoveProcessFeature = new DealMoveProcessFeatureImpl(
    dealsApi,
    dealsStore,
    dealBatchOperationStore,
    pipelineStore,
    pipelinePerformanceViewPreferencesStore,
    baseStore,
);

const dashboardFeature = new DashboardFeatureImpl(
    dealsApi,
    dealsStore,
    baseStore,
);

const userSettingsFeature = new UserSettingsFeatureImpl(
    userStore,
    usersApi,
    baseStore,
);

const qrCodeFeature = new QrCodeFeature(
    dealContactsStore,
    saveContactToPhoneService,
    dealActivityStore,
);

const xRayFeature = new XRayFeature(
    mixpanelService,
    leadsStore,
    xRayApi,
    baseStore,
    accountConfigurationStore,
    achievementService,
);

const accountConfigurationFeature = new AccountConfigurationFeature(
    AccountConfigurationApi,
    accountConfigurationStore,
    baseStore,
);

const googleEnrichmentFeature = createGoogleEnrichmentFeature(
    googleEnrichmentApi,
    dealContactsStore,
    userStore,
    accountConfigurationStore,
    baseStore,
    mixpanelService,
);

const quickActionsFeature = new QuickActionsFeature(
    quickActionsStore,
    mixpanelService,
);

const remindersFeature = new RemindersFeature(
    errorsStore,
    toasterStore,
    remindersApi,
    notificationsStore,
    dealsStore,
    baseStore,
    achievementService,
);

const emailTemplatesFeature = new EmailTemplateFeature(
    dealContactsStore,
    accountConfigurationStore,
    quickActionsStore,
    dealsStore,
    userStore,
    leadsStore,
    customParameterStore,
    toasterStore,
);

const pipelinePerformanceViewPreferencesFeature = createViewPreferencesFeature(
    pipelinePerformanceViewPreferencesStore,
    userStore,
    usersApi,
    accountConfigurationStore,
    pipelineStore,
    mixpanelService,
    baseStore,
);

const pipelineTableViewFeature = new PipelineTableViewFeature(
    dealsApi,
    pipelineTableViewStore,
    pipelinePerformanceViewPreferencesStore,
    pipelineDynamicFiltersStore,
    pipelineStore,
    accountConfigurationStore,
    baseStore,
);

const accountDeveloperToolsFeature = new AccountDeveloperToolsFeature(
    developerApiKeysApi,
    baseStore,
);
const dealBatchOperationsFeature = new DealBatchOperationsFeature(
    dealBatchOperationStore,
);

const bulkDealEditFeature = new BulkDealEditFeature(
    accountConfigurationStore,
    dealBatchOperationStore,
    pipelinePerformanceViewPreferencesStore,
    dealsStore,
    dealsApi,
    customParametersApi,
    baseStore,
);

const externalLinkFeature = new ExternalLinkFeature();

const searchesConfigFeature = new SearchesConfigFeature(
    userStore,
    filterModuleConfigStore,
    subscriptionsStore,
    searchesApi,
    subscriptionsApi,
    baseStore,
);

const filterModuleConfigFeature = new FilterModuleConfigFeature(
    filterModuleConfigStore,
    userStore,
    subscriptionsStore,
    searchesApi,
    baseStore,
);

const dealAttachmentsFeature = new DealAttachmentsFeature(
    dealAttachmentsApi,
    baseStore,
    dealAttachmentsStore,
    toasterStore,
    mixpanelService,
);

const stageObjectivesFeature = new StageObjectivesFeatureImpl(
    dealsStore,
    dealContactsStore,
    pipelineStore,
    dealsApi,
    objectivesStore,
    pendoService,
    mixpanelService,
    baseStore,
    achievementService,
);

const contactEnrichmentFeature = new ContactEnrichmentFeature(
    contactEnrichmentApi,
    quickActionsStore,
    userStore,
    dealContactsStore,
);

const achievementsFeature = new AchievementsFeature(
    userStore,
    achievementsApi,
    mixpanelService,
    baseStore,
    achievementService,
    toasterStore,
    locationService,
    accountConfigurationStore,
    pipelineStore,
);

const pipelineStatisticsFeature = new PipelineStatisticsFeature(
    pipelineStatisticsApi,
    mixpanelService,
);

const dealActivityFeature = new DealActivityFeature(
    dealsStore,
    dealActivityApi,
    mixpanelService,
    toasterStore,
    dealActivityStore,
    baseStore,
    userStore,
);

const vpoGenerationFeature = new VpoGenerationFeature(
    vpoGenerationApi,
    userStore,
    vpoGenerationStore,
    accountConfigurationStore,
    toasterStore,
    dealActivityStore,
    dealsStore,
    baseStore,
);

const promptBlocksManagementFeature = new PromptBlocksManagementFeature(
    usersApi,
    promptBlockApi,
    mixpanelService,
    accountConfigurationStore,
    userStore,
    vpoGenerationStore,
    baseStore,
    dealActivityStore,
);

const omniSearchFeature = new OmniSearchFeature(
    omniSearchApi,
    mixpanelService,
    baseStore,
);

const bringYourOwnProjectsFeature = new BringYourOwnProjectsFeature(
    byopApi,
    mixpanelService,
    baseStore,
    pipelineStore,
    achievementService,
);

const contactFinderFeature = new ContactFinderFeature(
    contactFinderApi,
    dealContactsStore,
    dealContactsFeature,
    userStore,
    mixpanelService,
    baseStore,
);

const dealContactsSectionFeature = new DealContactsSectionFeature(
    dealContactsPresentationStore,
    dealContactsStore,
    mixpanelService,
);

const dealContactsFormFeature = new DealContactsFormFeature(
    dealContactsStore,
    dealContactsPresentationStore,
);

const liveNotificationFeature = new LiveNotificationFeature(
    liveNotificationApi,
    toasterStore,
    baseStore,
);

const screeningSearchConfigurationFeature =
    new ScreeningSearchConfigurationFeature(
        baseStore,
        userStore,
        folderCountersStore,
        subscriptionsStore,
        usersApi,
        subscriptionsApi,
        searchesApi,
        leadsApi,
        folderCountersApi,
        mixpanelService,
    );

const appFeatures: AppFeatures = {
    stageObjectivesFeature,
    leadsListFeature,
    navbarFeature,
    translationFeature,
    authFeature,
    exportFeature,
    leadActionsFeature,
    commentsFeature,
    associatedCompaniesFeature,
    associatedSearchesFeature,
    notificationsFeature,
    customFieldsFeature,
    customParametersFeature,
    toasterFeature,
    leadFieldsFeature,
    projectLookupFeature,
    pipelineFeature,
    pipelineDataFeature,
    dealsListFeature,
    dealViewFeature,
    dealExportFeature,
    dealActionsFeature,
    dealPauseFeature,
    contactExtractionFeature,
    dealContactsFeature,
    dealContactsSectionFeature,
    dealContactsFormFeature,
    dealMoveProcessFeature,
    dealAttachmentsFeature,
    dashboardFeature,
    userSettingsFeature,
    xRayFeature,
    qrCodeFeature,
    accountConfigurationFeature,
    googleEnrichmentFeature,
    quickActionsFeature,
    remindersFeature,
    emailTemplatesFeature,
    viewPreferencesFeature: pipelinePerformanceViewPreferencesFeature,
    pipelineTableViewFeature,
    accountDeveloperToolsFeature,
    dealBatchOperationsFeature,
    bulkDealEditFeature,
    externalLinkFeature,
    searchesConfigFeature,
    filterModuleConfigFeature,
    contactEnrichmentFeature,
    achievementsFeature,
    pipelineStatisticsFeature,
    dealActivityFeature,
    vpoGenerationFeature,
    promptBlocksManagementFeature,
    omniSearchFeature,
    bringYourOwnProjectsFeature,
    contactFinderFeature,
    liveNotificationFeature,
    screeningSearchConfigurationFeature,
};

export const FeaturesContext = createContext<AppFeatures>(appFeatures);
