import { makeAutoObservable } from 'mobx';

import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { doNothing } from 'src/utils/function.utils';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

import {
    DealActivity,
    DealActivityType,
    ActivityLogSubType,
    OutreachNextAction,
    OutreachNextActionData,
} from 'src/app-features/deal-activity/domain/deal-activity.model';
import { IDealActivityApi } from '../data/deal-activity.api';
import { getI18n } from 'react-i18next';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import {
    IDealActivityStore,
    OutreachActivationState,
} from './deal-activity.store';
import {
    OutreachActivityLaunchSource,
    OutreachActivityLogSource,
    ActivitySubTypeToOutreachMetricsTabName,
    OutreachModeMetricsType,
    filterOutreachAttempts,
} from './deal-activity.utils';
import { UserStore } from 'src/data/stores/user/user.store';
import { OutreachNextActionMetricsName } from '../presentation/outreach-log-base/next-best-actions/utils';
import { Deal } from 'src/domain/models/deal/deal.model';
import { ParsedComment } from 'src/app-features/deal-comments/domain/model/comment.model';

interface DealActivityCreatePayload {
    dealId: string;
    type: DealActivityType;
    notes?: string;
    contactId?: string;
    relevantCompanyId?: string;
    subType: ActivityLogSubType;
    customType?: string;
}

export interface DealActivityUpdatePayload {
    activityLogId: string;
    dealId: string;
    notes?: string;
    contactId?: string;
    relevantCompanyId?: string;
    customType?: string;
}

export interface DealActivityCreationMetrics {
    source?: OutreachActivityLogSource;
    oneClickNextAction?: OutreachNextAction;
}

export interface IDealActivityFeature {
    outreachNextAction: OutreachNextActionData;
    outreachActivationState: OutreachActivationState;
    setOutreachComment: (comment: ParsedComment) => void;
    createDealActivity: (
        params: DealActivityCreatePayload,
        metrics?: DealActivityCreationMetrics,
    ) => Promise<boolean>;
    deleteDealActivity: (
        dealId: string,
        activityId: string,
    ) => Promise<boolean>;
    updateDealActivity: (params: DealActivityUpdatePayload) => Promise<boolean>;
    setOutreachNextAction: (action: OutreachNextActionData) => void;
    setOutreachActivationState: (
        state: Partial<OutreachActivationState>,
    ) => void;
    resetOutreachActivationState: () => void;
    fetchDealActivities: (dealId: string) => Promise<void>;
}

export class DealActivityFeature implements IDealActivityFeature {
    outreachNextAction: OutreachNextActionData = {
        enabled: false,
        action: OutreachNextAction.none,
        checkedObjectiveIds: [],
    };

    setOutreachComment = (comment: ParsedComment) => {
        this.outreachNextAction.commentText = comment;
    };

    setOutreachNextAction = (action: OutreachNextActionData) =>
        (this.outreachNextAction = action);

    createDealActivity = async (
        params: DealActivityCreatePayload,
        metrics?: DealActivityCreationMetrics,
    ) => {
        try {
            const res = await handleRequestAsync(
                this.dealActivityApi.createDealActivity,
                { ...params },
                doNothing,
                (err) =>
                    this.baseStore.onRequestFailed('create-deal-activity', err),
            );
            if (res) {
                this.onDealActivityCreated(params.dealId, res, metrics);
            }

            return !!res;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'create-deal-activity',
                error as Error,
            );
            return false;
        }
    };

    deleteDealActivity = async (dealId: string, activityId: string) => {
        try {
            const res = await handleRequestAsync(
                this.dealActivityApi.deleteDealActivity,
                { activityLogId: activityId },
                doNothing,
                (err) =>
                    this.baseStore.onRequestFailed('delete-deal-activity', err),
            );
            if (res) {
                this.onDealActivityDeleted(dealId, activityId);
            }
            return true;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'delete-deal-activity',
                error as Error,
            );
            return false;
        }
    };

    updateDealActivity = async (params: DealActivityUpdatePayload) => {
        try {
            const res = await handleRequestAsync(
                this.dealActivityApi.updateDealActivity,
                { ...params },
                doNothing,
                (err) =>
                    this.baseStore.onRequestFailed('update-deal-activity', err),
            );
            if (res) {
                this.onDealActivityUpdated(params.dealId, res);
            }

            return !!res;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'update-deal-activity',
                error as Error,
            );
            return false;
        }
    };

    onDealActivityCreated = (
        dealId: string,
        activity: DealActivity,
        metrics?: DealActivityCreationMetrics,
    ) => {
        const deal = this.dealsStore.dealsMap.get(dealId);

        if (deal) {
            deal.activityLog = [activity, ...deal.activityLog];

            if (activity.type === DealActivityType.outreachAttempt) {
                this.trackOutreachActivityCreated(deal, activity, metrics);
            }

            const { t } = getI18n();
            this.toasterStore.showMessage({
                title: t<string>('outreach_log.toast.successfully_saved'),
                autoClose: 1500,
                type: 'success',
            });
        }
    };

    onDealActivityDeleted = (dealId: string, activityId: string) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        if (!deal) return;
        const activity = deal?.activityLog.find((a) => a.id === activityId);
        if (deal && activity) {
            deal.activityLog = deal.activityLog.filter(
                (a) => a.id !== activityId,
            );
            if (activity.type === DealActivityType.outreachAttempt) {
                this.trackOutreachActivityDeletion(deal, activity, dealId);
            }
        }
    };

    onDealActivityUpdated = (dealId: string, activity: DealActivity) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        if (!deal) return;

        if (deal) {
            const result = deal.activityLog.findIndex(
                (a) => a.id === activity.id,
            );
            if (result < 0) return;
            deal.activityLog = [
                ...deal.activityLog.slice(0, result),
                activity,
                ...deal.activityLog.slice(result + 1),
            ];
            if (activity.type === DealActivityType.outreachAttempt) {
                this.trackOutreachActivityUpdated(deal, activity, dealId);
            }
        }
    };

    onOutreachTabSwitchMetricsEvent = (
        dealId: string,
        prev: ActivityLogSubType,
        current: ActivityLogSubType,
    ) => {
        this.mixpanelService.trackEvent(
            MixpanelEventName.OutreachModalTabSwitched,
            {
                originTab: ActivitySubTypeToOutreachMetricsTabName[prev],
                destinationTab:
                    ActivitySubTypeToOutreachMetricsTabName[current],
                outreachModalSessionId: this.sessionId,
            },
            dealId,
        );
    };

    onOutreachActivateMetricsEvent = (
        dealId: string,
        launchSource: OutreachActivityLaunchSource,
    ) => {
        this.mixpanelService.trackEvent(
            MixpanelEventName.OutreachModalOpened,
            {
                launchSource,
                outreachModalSessionId: this.sessionId,
            },
            dealId,
        );
    };

    private trackOutreachActivityCreated = (
        deal: Deal,
        activity: DealActivity,
        metrics?: DealActivityCreationMetrics,
    ) => {
        this.mixpanelService.trackEvent(
            MixpanelEventName.LoggedOutreachAttempt,
            {
                outreachAttemptId: activity.id,
                outreachAttemptCount: deal.activityLog.length,
                outreachMode: this.getOutreachMode(activity.subType),
                outreachNote: activity.notes,
                resultType: activity.customType,
                contactId: activity.contactId,
                companyId: activity.relevantCompanyId,
                outreachLogSource: metrics?.source?.toString(),
                outreachModalSessionId: this.sessionId,
                oneClickNextAction: metrics?.oneClickNextAction
                    ? OutreachNextActionMetricsName[metrics?.oneClickNextAction]
                    : undefined,
            },
            deal.id,
        );
    };

    private trackOutreachActivityUpdated(
        deal: Deal,
        activity: DealActivity,
        dealId: string,
    ) {
        this.mixpanelService.trackEvent(
            MixpanelEventName.EditedOutreachAttempt,
            {
                outreachAttemptCount: deal.activityLog.length,
                outreachMode: activity.subType,
                outreachAttemptId: activity.id,
                outreachModalSessionId: this.sessionId,
            },
            dealId,
        );
    }

    private trackOutreachActivityDeletion(
        deal: Deal,
        activity: DealActivity,
        dealId: string,
    ) {
        this.mixpanelService.trackEvent(
            MixpanelEventName.DeletedOutreachAttempt,
            {
                outreachAttemptCount: deal.activityLog.length,
                outreachMode: activity.subType,
                outreachAttemptId: activity.id,
                outreachModalSessionId: this.sessionId,
            },
            dealId,
        );
    }

    get outreachActivationState() {
        return this.activityStore.outreachActivationState;
    }

    get sessionId() {
        return this.activityStore.sessionId;
    }

    getOutreachMode = (
        type: ActivityLogSubType,
    ): OutreachModeMetricsType | undefined => {
        const { dealId, contact } = this.outreachActivationState;

        if (!type || !dealId || !contact) {
            return undefined;
        }

        if (type === ActivityLogSubType.email) {
            const currentDeal = this.dealsStore.getDeal(dealId);

            const outreachAttempts = filterOutreachAttempts(
                currentDeal?.activityLog ?? [],
                contact,
            );

            const hasEmailLog =
                outreachAttempts.filter(
                    (activity) => activity.subType === ActivityLogSubType.email,
                ).length > 1; // > 1 bc this is checked AFTER the log is created. So if there is only 1 email log, it means it's the first email log

            return hasEmailLog
                ? OutreachModeMetricsType.FOLLOW_UP_MAIL
                : OutreachModeMetricsType.FIRST_MAIL;
        } else {
            return type as unknown as OutreachModeMetricsType;
        }
    };

    setOutreachActivationState = (state: Partial<OutreachActivationState>) => {
        const prev = { ...this.outreachActivationState };
        this.activityStore.setOutreachActivationState(
            state,
            this.userStore.user?.itemId ?? 0,
        );
        if (
            prev.logOutreachChosenType &&
            state.logOutreachChosenType &&
            prev.logOutreachChosenType !== state.logOutreachChosenType
        ) {
            this.onOutreachTabSwitchMetricsEvent(
                this.outreachActivationState.dealId ?? '',
                prev.logOutreachChosenType,
                state.logOutreachChosenType,
            );
        } else if (state.launchSource && state.dealId && state.contact) {
            this.onOutreachActivateMetricsEvent(
                state.dealId,
                state.launchSource,
            );
        }
    };

    get resetOutreachActivationState() {
        return this.activityStore.resetOutreachActivationState;
    }

    fetchDealActivities = async (dealId: string) => {
        try {
            const activityLog = await handleRequestAsync(
                this.dealActivityApi.getActivityLogByDeal,
                { dealId, signal: new AbortController().signal },
                doNothing,
            );
            if (activityLog !== undefined) {
                this.dealsStore.setDealActivityLog(dealId, activityLog);
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'fetch-deal-activities',
                error as Error,
            );
        }
    };

    constructor(
        private dealsStore: DealsStore,
        private dealActivityApi: IDealActivityApi,
        private mixpanelService: MixpanelService,
        private toasterStore: ToasterStore,
        private activityStore: IDealActivityStore,
        private baseStore: IBaseStore,
        private userStore: UserStore,
    ) {
        makeAutoObservable(this);
    }
}
