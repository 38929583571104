import * as Sentry from '@sentry/react';
import { User, getFullName } from 'src/domain/models/user/user.model';
import { getBrAppEnvironment } from 'src/utils/environment.utils';

export const initializeSentry = async () => {
    const environment = getBrAppEnvironment();
    const version = await fetch('/build/static/version.json')
        .then((response) => response.json())
        .then((data) => data.buildVersion)
        .catch(() =>
            console.error(
                'Failed to fetch version from /build/static/version.json',
            ),
        );

    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN_KEY,
        autoSessionTracking: false,
        environment,
        release: version,
        enabled: environment !== 'development',
    });
};

export const setCurrentLoggedSentryUser = (user: User) => {
    if (!Sentry.getClient()) {
        console.error(
            'The Sentry client is not yet initialized, the operation cannot be completed.',
        );
    }

    const { itemId, email, firstName, lastName, isImpersonated } = user;
    Sentry.setUser({
        id: itemId.toString(),
        email,
        username: getFullName(firstName, lastName),
        isImpersonated,
    });
};
