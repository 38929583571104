export enum AppRoute {
    inbox = '/inbox',
    screening = '/screening',
    searchConfiguration = '/screening/search-configuration',
    home = '/home',
    interesting = '/interesting',
    snoozed = '/snoozed',
    done = '/done',
    exported = '/exported',
    notInteresting = '/not_interesting',
    login = '/login',
    newPassword = '/new-password',
    recoverPassword = '/recover_password',
    empty = '/',
    generalSearch = '/project-lookup',
    lead = '/leads',
    pipelines = '/pipelines',
    deals = '/deals',
    markedDone = '/marked-done',
    disqualified = '/disqualified',
    settings = '/user-settings',
    accountSettings = '/user-settings/account',
    subscriptionSettings = '/user-settings/search-subscriptions',
    configureSearches = '/configure-searches',
    configureNewSearch = `/configure-new-search`,
}

export enum WebAppRoute {
    adminApp = '/apps/admin/v2',
    adminAppCompanyInfo = '/apps/admin/v2/{companyId}/company_info',
}
