import { BrIcon, IconName } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { SearchType } from 'src/app-features/searches-configuration/domain/models/configured-search';
import { BuildSearchUrl } from 'src/app-features/searches-configuration/presentation/utils/utils';
import { User } from 'src/domain/models/user/user.model';
import {
    buildMobileMenuIcons,
    mobileMenuClassNames,
    mobileMenuRoute,
    mobileMenuText,
} from 'src/presentation/modules/mobile-menu/components/mobile-menu-content/mobile-menu-content.config';
import { MobileMenuItemComponent } from 'src/presentation/modules/mobile-menu/components/mobile-menu-item/mobile-menu-item.component';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { Localized } from 'src/presentation/shared/localized/localized';

import {
    MoreWrapperStyled,
    BackButtonStyled,
    BackButtonTextStyled,
} from './more-submenu.styled';

interface MoreSubmenuComponentProps {
    onCloseMore: () => void;
    onLinkClick: () => void;
    currentRoute: string;
    user: User;
}

export const MoreSubmenuComponent: FC<MoreSubmenuComponentProps> = observer(
    ({ onCloseMore, currentRoute, onLinkClick, user }) => {
        const isDisqualifiedActive = currentRoute.startsWith(
            AppRoute.disqualified,
        );

        const disqualifiedIcon = isDisqualifiedActive
            ? buildMobileMenuIcons(true).disqualified
            : buildMobileMenuIcons().disqualified;

        const showSearch = !user.featureToggle.inboxOnly || user.isImpersonated;

        return (
            <MoreWrapperStyled>
                <BackButtonStyled onClick={onCloseMore}>
                    <BrIcon iconName={IconName.BrArrowBackIosIcon} />
                    <BackButtonTextStyled>
                        <Localized>common.back</Localized>
                    </BackButtonTextStyled>
                </BackButtonStyled>
                <MobileMenuItemComponent
                    isActive={isDisqualifiedActive}
                    className={mobileMenuClassNames.disqualified}
                    Icon={disqualifiedIcon}
                    text={mobileMenuText.disqualified}
                    link={mobileMenuRoute.disqualified}
                    onClick={onLinkClick}
                />
                {showSearch && (
                    <MobileMenuItemComponent
                        isActive={false}
                        className="configure-searches"
                        Icon={
                            <BrIcon
                                iconName={IconName.BrSearchIcon}
                                iconColor="var(--gray-80)"
                            />
                        }
                        text={'navbar.configure_searches'}
                        onClick={onLinkClick}
                        link={BuildSearchUrl(SearchType.Article)}
                    />
                )}
            </MoreWrapperStyled>
        );
    },
);
