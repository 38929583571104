import { StringMap } from 'i18next';
import { observer } from 'mobx-react';
import { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';

interface LocalizedProps {
    children:
        | string
        | ((t: TFunction<'translation', undefined>) => ReactElement);
    placeholderMap?: StringMap;
    components?: { [tagName: string]: ReactNode } & {
        [tagName: string]: ReactElement<
            any,
            string | JSXElementConstructor<any>
        >;
    };
    shouldUnescape?: boolean
}

export const Localized = observer((props: LocalizedProps) => {
    const { t, i18n } = useTranslation();

    const { children, placeholderMap, shouldUnescape } = props;

    if (typeof children === 'function') {
        return children(t);
    }

    return (
        <Trans
            i18n={i18n}
            i18nKey={children}
            values={placeholderMap}
            components={props.components}
            shouldUnescape={shouldUnescape}
        />
    );
});
