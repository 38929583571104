import { scrollToElement } from 'src/utils/element.utils';
import {
    DealContactType,
    PredefinedCustomFields,
} from '../data/model/deal-contacts.model';
import { IDealContactsStore } from '../data/stores/interfaces/deal-contacts.store.interface';
import { IDealContactsPresentationStore } from '../data/stores/interfaces/deal-contacts-presentation.store.interface';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import {
    ActionFeatureLaunchSource,
    ContactQuickActionsCategory,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { CustomParameterLinkClickName } from './deal-contacts.utils';
import { IDealContactsSectionFeature } from './interfaces/deal-contacts-section.feature.interface';
import { makeAutoObservable } from 'mobx';

export class DealContactsSectionFeature implements IDealContactsSectionFeature {
    contactsReferenceMap: Map<string, HTMLElement | null> = new Map();
    isCompaniesExpanded: boolean = false;
    isContactsExpanded: boolean = false;
    isArchivedExpanded: boolean = false;

    constructor(
        private dealContactsPresentationStore: IDealContactsPresentationStore,
        private dealContactsStore: IDealContactsStore,
        private mixpanelService: MixpanelService,
    ) {
        makeAutoObservable(this);
    }

    get createContactInProgress(): boolean {
        return this.dealContactsPresentationStore.createContactInProgress;
    }

    get requestDealContactsInProgress(): boolean {
        return this.dealContactsPresentationStore.requestDealContactsInProgress;
    }

    scrollToContactId = (id: string) => {
        const contact = this.dealContactsStore.personsMap.get(id);
        const company = this.dealContactsStore.companiesMap.get(id);
        const target = contact ?? company;
        if (!target) {
            return;
        }
        const archived = !target.isKey;
        const ref = this.contactsReferenceMap.get(id);
        if (!ref) {
            if (archived) {
                this.setArchivedExpanded(true);
            } else if (target.type === DealContactType.person) {
                this.setContactsExpanded(true);
            } else {
                this.setCompaniesExpanded(true);
            }
        }
        setTimeout(() => {
            const refElement = this.contactsReferenceMap.get(id);
            if (refElement) {
                scrollToElement(refElement, 200);
            }
        }, 0);
    };

    setContactReference = (id: string, ref: HTMLElement | null) => {
        this.contactsReferenceMap.set(id, ref);
    };

    setCompaniesExpanded = (flag: boolean) => {
        this.isCompaniesExpanded = flag;
    };

    setContactsExpanded = (flag: boolean) => {
        this.isContactsExpanded = flag;
    };

    setArchivedExpanded = (flag: boolean) => {
        this.isArchivedExpanded = flag;
    };

    clear = () => {
        this.dealContactsPresentationStore.clear();
        this.contactsReferenceMap.clear();
    };

    trackContactListExpansionAction = (
        contactType: DealContactType,
        dealId: string,
    ) => {
        const eventName =
            contactType === DealContactType.person
                ? MixpanelEventName.ExpandedContactSection
                : MixpanelEventName.ExpandedCompanySection;
        this.mixpanelService.trackEvent(eventName, undefined, dealId);
    };

    trackCustomParameterLinkClick = (
        dealId: string,
        type: DealContactType,
        parameter: string,
    ) => {
        const launchSource =
            type === DealContactType.person
                ? ActionFeatureLaunchSource.ContactCardLinkedCompanyParameter
                : ActionFeatureLaunchSource.CompanyCardLinkedContactParameter;

        const quickActionCategory =
            parameter === PredefinedCustomFields.url ||
            parameter === PredefinedCustomFields.linkedin
                ? ContactQuickActionsCategory.Research
                : ContactQuickActionsCategory.Outreach;

        const quickActionName = CustomParameterLinkClickName[parameter];

        this.mixpanelService.trackEvent(
            MixpanelEventName.ClickToUseContactCardLink,
            {
                launchSource,
                quickActionCategory,
                quickActionName,
            },
            dealId,
        );
    };
}
