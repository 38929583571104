import { DealCommentFragment } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export interface Comment {
    id: string;
    created: Date;
    updated: Date;
    text: string;
    authorId: number;
    dealId: string;
}

export const createComment = (rawComment: DealCommentFragment): Comment => {
    const { id, content, creatorBid, createdDate, updatedDate, parentId } =
        rawComment;
    return {
        id,
        created: new Date(createdDate),
        updated: new Date(updatedDate),
        authorId: creatorBid,
        text: content,
        dealId: parentId,
    };
};

// `ParsedComment` is a custom export format from the commenteditor.
// The format of a mention is defined by `<@userId>` defined by `USER_COMMENT_REGEX`
export interface ParsedComment {
    textWithMentions: string; // original output text with raw mention text, example  This is a comment for <@20>
    plainText: string; // output text with replaced mentions, example  This is a comment for @Alphar
    mentions: number[]; // the list of user ids that are mentioned
}

export const emptyParsedComment: ParsedComment = {
    textWithMentions: '',
    plainText: '',
    mentions: [],
};
