import { makeAutoObservable } from 'mobx';
import { type IDealContactsFeature } from 'src/app-features/contact/domain/interfaces/deal-contacts.feature.interface';
import { getUserFullName } from 'src/domain/models/user/user.model';
import {
    type DiscoveredContactProfile,
    type ContactDiscoveryResults,
    type SearchMetadata,
} from './discovered-contact-profile.model';
import { type IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { type IContactFinderApi } from '../data/contact-finder.api';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { type IDealContactsStore } from 'src/app-features/contact/data/stores/interfaces/deal-contacts.store.interface';
import { type CustomInput } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { PredefinedCustomFields } from 'src/app-features/contact/data/model/deal-contacts.model';
import { buildLinkedinLink } from 'src/utils/url.utils';
import { buildUniqueUserWebSessionId } from 'src/utils/web-session.utils';
import { type UserStore } from 'src/data/stores/user/user.store';
import { type MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { isNotEmpty } from 'src/utils/string.utils';

export interface FilterPayload {
    companyName: string;
    keyword?: string;
    location?: string;
    title?: string;
}

export interface IContactFinderFeature {
    addedDealContactNameSet: Set<string>;
    flyoutIsOpen: boolean;
    companyId: string | null;
    filters: FilterPayload;
    isLoading: boolean;
    currentPage: number;
    results: ContactDiscoveryResults;
    searchSessionId: string | null;
    setFlyoutIsOpen: (flag: boolean) => void;
    setFilter: (filterName: keyof FilterPayload, value?: string) => void;
    openFlyoutForCompany: (companyId: string, dealId: string) => Promise<void>;
    findAndSetContactsByFilter: (
        payload: FilterPayload,
        page: number,
        dealId: string,
    ) => Promise<void>;
    extractLinkedinProfileContactInfoAsCustomInputs: (
        contact: DiscoveredContactProfile,
    ) => Promise<CustomInput>;
    trackContactCreated: (
        dealId: string,
        customInput: CustomInput,
        contactIndex: number,
    ) => void;
}

const defaultFilters: FilterPayload = {
    companyName: '',
};

const defaultMetadataState: SearchMetadata = {
    page: 0,
    pageSize: 25,
    total: 0,
    usedCompanyName: '',
};

export class ContactFinderFeature implements IContactFinderFeature {
    flyoutIsOpen: boolean = false;
    filters: FilterPayload = { ...defaultFilters };
    companyId: string | null = null;
    isLoading: boolean = false;
    currentPage: number = 0;
    results: ContactDiscoveryResults = {
        contacts: [],
        metadata: { ...defaultMetadataState },
    };
    searchSessionId: string | null = null;

    constructor(
        private contactFinderApi: IContactFinderApi,
        private dealContactsStore: IDealContactsStore,
        private dealContactsFeature: IDealContactsFeature,
        private userStore: UserStore,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    get addedDealContactNameSet() {
        const addedDealContacts =
            this.dealContactsFeature.allDealContactPersons;

        return new Set(addedDealContacts.map(getUserFullName));
    }

    findAndSetContactsByFilter = async (
        payload: FilterPayload,
        page: number,
        dealId: string,
    ): Promise<void> => {
        this.currentPage = page;

        const { companyName, keyword, location, title } = payload;

        try {
            const results = await handleRequestAsync(
                this.contactFinderApi.findContactsForCompany,
                { companyName, keyword, location, title, page },
                (loading) => (this.isLoading = loading),
            );

            if (results) {
                this.results = results;
                this.currentPage = results.metadata.page;

                this.trackSearchWithContactFinder(dealId);
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'find-contacts-by-company-name',
                error as Error,
            );
        }
    };

    extractLinkedinProfileContactInfoAsCustomInputs = async (
        contact: DiscoveredContactProfile,
    ): Promise<CustomInput> => {
        try {
            const { linkedinUsername, hasEmail, hasPhone, title } = contact;

            /** Always add linkedin as custom input, since we already have this */
            const customInput: CustomInput = {
                customValues: [
                    {
                        name: PredefinedCustomFields.linkedin,
                        value: buildLinkedinLink(linkedinUsername),
                    },
                ],
            };

            if (isNotEmpty(title)) {
                customInput.customValues.push({
                    name: PredefinedCustomFields.position,
                    value: title,
                });
            }

            /** Fetch email and/or phone if available */
            if (hasEmail || hasPhone) {
                const contactInfo = await handleRequestAsync(
                    this.contactFinderApi.getLinkedinProfileContactInfo,
                    { linkedinUserName: linkedinUsername },
                );

                if (contactInfo) {
                    const emailList = contactInfo.workEmail;
                    const phoneList = contactInfo.phone;

                    if (emailList.length) {
                        customInput.customValues.push({
                            name: PredefinedCustomFields.mail,
                            value: emailList[0],
                        });
                    }

                    if (phoneList.length) {
                        customInput.customValues.push({
                            name: PredefinedCustomFields.telephone,
                            value: phoneList[0],
                        });
                    }
                }
            }

            return customInput;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'get-linkedin-profile-contact-info',
                error as Error,
            );
            throw error;
        }
    };

    setFlyoutIsOpen = (flag: boolean): void => {
        this.flyoutIsOpen = flag;

        if (!flag) {
            //clearing the session
            this.searchSessionId = null;
        }
    };

    setFilter = (filterName: keyof FilterPayload, value?: string) => {
        if (this.filters) {
            this.filters = {
                ...this.filters,
                [filterName]: value,
            };
        }
    };

    openFlyoutForCompany = async (
        companyId: string,
        dealId: string,
    ): Promise<void> => {
        const company = this.dealContactsStore.companiesMap.get(companyId);
        if (!company) return;

        if (this.userStore.user && !this.searchSessionId) {
            this.searchSessionId = buildUniqueUserWebSessionId(
                this.userStore.user.itemId,
                'contact_finder',
            );
        }

        this.setFlyoutIsOpen(true);

        if (this.results.metadata.usedCompanyName !== company.name) {
            this.companyId = company.id;
            this.filters = { companyName: company.name };

            await this.findAndSetContactsByFilter(
                { companyName: company.name },
                1,
                dealId,
            );
        }
    };

    trackContactCreated = (
        dealId: string,
        customInput: CustomInput,
        contactIndex: number,
    ) => {
        if (!this.searchSessionId) {
            return;
        }

        const fieldsProvidedSorted = (
            customInput.customValues.map(
                (field) => field.name,
            ) as PredefinedCustomFields[]
        ).sort((a, b) => a.localeCompare(b));

        this.mixpanelService.trackEvent(
            MixpanelEventName.CreatedContactFromContactFinder,
            {
                contactFinderSearchSession: this.searchSessionId,
                contactFinderParametersProvided: fieldsProvidedSorted,
                contactFinderContactCreationIndex: contactIndex + 1,
                contactFinderPage: this.results.metadata.page,
                contactFinderTotalResults: this.results.metadata.total,
                contactFinderFilter: JSON.stringify(this.filters),
                companyId: this.companyId,
            },
            dealId,
        );
    };

    trackSearchWithContactFinder = (dealId: string) => {
        if (!this.searchSessionId) {
            return;
        }

        this.mixpanelService.trackEvent(
            MixpanelEventName.SearchedContactFinder,
            {
                contactFinderSearchSession: this.searchSessionId,
                contactFinderFilter: JSON.stringify(this.filters),
                contactFinderPage: this.results.metadata.page,
                contactFinderTotalResults: this.results.metadata.total,
                companyId: this.companyId,
            },
            dealId,
        );
    };
}
