import { makeAutoObservable } from 'mobx';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import { Language } from 'src/domain/models/locale/locale.model';
import { getFullName } from 'src/domain/models/user/user.model';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import {
    ContactExtractionResultStatus,
    DealCompanyCandidate,
    DealContactCandidate,
} from '../../data/contact-extraction.model';
import { IContactExtractionStore } from '../../data/store/contact-extraction.interface.store';
import { doNothing } from 'src/utils/function.utils';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import {
    ActionFeatureIdentifierNamesMap,
    ActionFeaturesIdentifiers,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { type IContactExtractionFeature } from './contact-extraction-interface.feature';
import { type IContactExtractionApi } from '../../data/contact-extraction.api';
import {
    determineExtractorStatus,
    getCombinedCandidates,
    groupCandidatesByCompany,
    mapCandidateSources,
    removeSavedCandidates,
} from './contact-extraction.helpers';
import { getFeatureFlagBool } from 'src/utils/hooks/use-feature-flag';
import { FeatureFlag } from 'src/data/services/feature-flags/feature-flags.model';
import { IDealContactsStore } from 'src/app-features/contact/data/stores/interfaces/deal-contacts.store.interface';
import { IDealAttachmentsStore } from 'src/app-features/deal-attachments/domain/store/deal-attachments.store.interface';

export class ContactExtractionFeature implements IContactExtractionFeature {
    constructor(
        private contactExtractionApi: IContactExtractionApi,
        private contactExtractionStore: IContactExtractionStore,
        private baseStore: IBaseStore,
        private userStore: UserStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private mixpanelService: MixpanelService,
        private contactsStore: IDealContactsStore,
        private attachmentsStore: IDealAttachmentsStore,
    ) {
        makeAutoObservable(this);
    }

    isFlyoutOpen: IContactExtractionFeature['isFlyoutOpen'] = false;

    get isFeatureEnabled(): IContactExtractionFeature['isFeatureEnabled'] {
        return this.accountConfigurationStore.isActionFeatureEnabled(
            ActionFeaturesIdentifiers.contact_extractor,
        );
    }

    get contactExtractionResultStatus(): IContactExtractionFeature['contactExtractionResultStatus'] {
        return this.contactExtractionStore.contactExtractionResultStatus;
    }

    get contactExtractionCandidates(): IContactExtractionFeature['contactExtractionCandidates'] {
        return this.contactExtractionStore.contactExtractionCandidates;
    }

    get companyExtractionCandidates(): IContactExtractionFeature['companyExtractionCandidates'] {
        return this.contactExtractionStore.companyExtractionCandidates;
    }

    get groupedExtractionCandidates(): IContactExtractionFeature['groupedExtractionCandidates'] {
        const groups = groupCandidatesByCompany(
            this.contactExtractionCandidates,
            this.companyExtractionCandidates,
        );
        const cleanedGroups = removeSavedCandidates(
            groups,
            Array.from(this.contactsStore.personsMap.values()),
            Array.from(this.contactsStore.companiesMap.values()),
        );
        return cleanedGroups;
    }

    get selectedContactCandidateNames(): IContactExtractionFeature['selectedContactCandidateNames'] {
        return this.contactExtractionStore.selectedContactCandidateNames;
    }

    get selectedCompanyCandidateNames(): IContactExtractionFeature['selectedCompanyCandidateNames'] {
        return this.contactExtractionStore.selectedCompanyCandidateNames;
    }

    get selectedContactCandidates(): IContactExtractionFeature['selectedContactCandidates'] {
        return this.contactExtractionCandidates.filter((contact) =>
            this.selectedContactCandidateNames.has(
                getFullName(contact.firstName, contact.lastName),
            ),
        );
    }

    get selectedCompanyCandidates(): IContactExtractionFeature['selectedCompanyCandidates'] {
        return this.companyExtractionCandidates.filter((company) =>
            this.selectedCompanyCandidateNames.has(company.name),
        );
    }

    selectContactWithGroup: IContactExtractionFeature['selectContactWithGroup'] =
        (candidate, selected, group) => {
            if (selected) {
                this.contactExtractionStore.selectContactCandidate(candidate);
                if (group.company && !group.company.saved) {
                    this.contactExtractionStore.selectCompanyCandidate(
                        group.company,
                    );
                }
            } else {
                this.contactExtractionStore.deselectContact(candidate);
            }
        };

    selectCompanyWithGroup: IContactExtractionFeature['selectCompanyWithGroup'] =
        (candidate, selected, group) => {
            if (selected) {
                if (candidate) {
                    this.contactExtractionStore.selectCompanyCandidate(
                        candidate,
                    );
                }
                if (group.contacts.length > 0) {
                    group.contacts.forEach((contact) => {
                        this.contactExtractionStore.selectContactCandidate(
                            contact,
                        );
                    });
                }
            } else {
                if (candidate) {
                    this.contactExtractionStore.deselectCompany(candidate);
                } else {
                    // special case for unidentified company group: deselect all contacts when unidentified company is deselected
                    group.contacts.forEach((contact) => {
                        this.contactExtractionStore.deselectContact(contact);
                    });
                }
            }
        };

    onSelectContactCandidate: IContactExtractionFeature['onSelectContactCandidate'] =
        (candidate, selected) => {
            const contactCompany = this.companyExtractionCandidates.find(
                (company) => company.name === candidate.companyName,
            );

            if (selected) {
                this.contactExtractionStore.selectContactCandidate(candidate);

                if (contactCompany) {
                    this.contactExtractionStore.selectCompanyCandidate(
                        contactCompany,
                    );
                }
            } else {
                this.contactExtractionStore.deselectContact(candidate);

                if (contactCompany) {
                    this.contactExtractionStore.deselectCompany(contactCompany);
                }
            }
        };

    onSelectCompanyCandidate: IContactExtractionFeature['onSelectCompanyCandidate'] =
        (candidate, selected) => {
            if (selected) {
                this.contactExtractionStore.selectCompanyCandidate(candidate);
            } else {
                this.contactExtractionStore.deselectCompany(candidate);
            }
        };

    getAutoExtractionResult: IContactExtractionFeature['getAutoExtractionResult'] =
        async (dealId) => {
            const response = await handleRequestAsync(
                this.contactExtractionApi.getAutoExtractionResult,
                { dealId },
                doNothing,
                (error) => {
                    if (!error) return;

                    this.baseStore.onRequestFailed(
                        'request-auto-extract-contacts',
                        error,
                    );

                    this.setContactExtractionResultStatus(
                        ContactExtractionResultStatus.Error,
                    );
                },
                'getAutoExtractionResult',
            );

            this.setContactExtractionResult(response);
        };

    rerunAutoExtraction: IContactExtractionFeature['rerunAutoExtraction'] =
        async (dealId) => {
            this.setContactExtractionResultStatus(
                ContactExtractionResultStatus.ArticlesSearchInProgress,
            );

            await handleRequestAsync(
                this.contactExtractionApi.runAutoExtraction,
                {
                    dealId,
                    language: this.userStore.user?.language ?? Language.De,
                },
            );

            await this.getAutoExtractionResult(dealId);
        };

    setContactExtractionCandidates: IContactExtractionFeature['setContactExtractionCandidates'] =
        (candidates) => {
            this.contactExtractionStore.setContactExtractionCandidates(
                candidates,
            );
        };

    setCompanyExtractionCandidates: IContactExtractionFeature['setCompanyExtractionCandidates'] =
        (candidates) => {
            this.contactExtractionStore.setCompanyExtractionCandidates(
                candidates,
            );
        };

    openFlyout: IContactExtractionFeature['openFlyout'] = (
        dealId,
        launchSource,
    ) => {
        this.isFlyoutOpen = true;
        this.onTrackViewResults(dealId, launchSource);
    };

    onTrackViewResults: IContactExtractionFeature['onTrackViewResults'] = (
        dealId,
        launchSource,
    ) => {
        this.mixpanelService.trackEvent(
            MixpanelEventName.LaunchedQuickAction,
            {
                quickActionName:
                    ActionFeatureIdentifierNamesMap[
                        ActionFeaturesIdentifiers.contact_extractor
                    ],
                launchSource: launchSource.toString(),
            },
            dealId,
        );
    };

    closeFlyout: IContactExtractionFeature['closeFlyout'] = () => {
        this.isFlyoutOpen = false;
    };

    setContactExtractionResultStatus: IContactExtractionFeature['setContactExtractionResultStatus'] =
        (status) => {
            this.contactExtractionStore.setContactExtractionResultStatus(
                status,
            );
        };

    setContactExtractionResult: IContactExtractionFeature['setContactExtractionResult'] =
        (result) => {
            if (!result) {
                this.clear();
                return;
            }

            const { projectContextResult, webSearchResult } = result;

            const status = determineExtractorStatus(
                projectContextResult,
                webSearchResult,
            );

            const candidates = getCombinedCandidates(
                projectContextResult,
                webSearchResult,
            );

            candidates.contacts = mapCandidateSources(
                this.attachmentsStore.attachmentList,
                candidates.contacts,
            ) as DealContactCandidate[];
            candidates.companies = mapCandidateSources(
                this.attachmentsStore.attachmentList,
                candidates.companies,
            ) as DealCompanyCandidate[];

            this.setContactExtractionCandidates(candidates.contacts);
            this.setCompanyExtractionCandidates(candidates.companies);

            if (
                status === ContactExtractionResultStatus.Success &&
                !!candidates.contacts.length &&
                !getFeatureFlagBool(FeatureFlag.contactExtractionGamePadUi)
            ) {
                this.onSelectContactCandidate(candidates.contacts[0], true);
            }

            this.setContactExtractionResultStatus(status);
        };

    clear: IContactExtractionFeature['clear'] = () => {
        this.contactExtractionStore.clear();
    };
}
