import { makeAutoObservable } from 'mobx';

import { TextInputStatus } from 'src/app-features/contact/data/model/deal-contacts.model';
import { IDealContactsPresentationStore } from './interfaces/deal-contacts-presentation.store.interface';

export class DealContactsSectionStore
    implements IDealContactsPresentationStore
{
    requestDealContactsInProgress: boolean = false;

    createContactInProgress: boolean = false;

    fieldProgress: Map<string, TextInputStatus> = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    clear = () => {
        this.requestDealContactsInProgress = false;
        this.createContactInProgress = false;
        this.fieldProgress.clear();
    };
}
