import { withFeatures } from 'src/utils/component.utils';

import { MentionsDashboard } from './mentions-dashboard.component';

export const MentionsDashboardContainer = withFeatures(MentionsDashboard)(
    (f) => ({
        mentions: f.notificationsFeature.mentions,
        isLoading: f.notificationsFeature.isLoading,
        user: f.authFeature.user,
    }),
);
