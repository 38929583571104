import { type FC, useState } from 'react';
import { observer } from 'mobx-react';
import { CircleX } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Button } from '@buildingradar/ui_library/button';
import { Tooltip } from '@buildingradar/ui_library/tooltip';
import { AvatarSizes, BrAvatar } from '@buildingradar/br_component_lib';
import { FeatureFlag } from 'src/data/services/feature-flags/feature-flags.model';
import { DealOpenSource } from 'src/domain/models/deal/deal.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { type Mention } from 'src/domain/models/mention/mention.model';
import { type User, getUserFullName } from 'src/domain/models/user/user.model';
import { createDealPageLinkToComments } from 'src/presentation/modules/router/utils/route.utils';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { useUpdatableDateAgo } from 'src/utils/datetime.utils';
import { useFeatureFlagBool } from 'src/utils/hooks/use-feature-flag';
import { type NotificationsFeature } from 'src/domain/features/notifications/notifications-interface.feature';
import { NotificationCard } from '../../shared/notification-card/notification-card.component';

type TProps = {
    user: User | undefined;
    mention: Mention;
    toggleNotificationsOpened: (flag: boolean) => void;
    toggleMobileMenu: (flag: boolean) => void;
    markMentionAsRead: NotificationsFeature['markMentionAsRead'];
    dismissMention: NotificationsFeature['dismissMention'];
};

export const MentionComponent: FC<TProps> = observer(
    ({
        user,
        mention,
        toggleNotificationsOpened,
        toggleMobileMenu,
        markMentionAsRead,
        dismissMention,
    }) => {
        const [isDismissing, setIsDismissing] = useState(false);

        const { t } = useTranslation();
        const isNotificationManagementFeatureEnabled = useFeatureFlagBool(
            FeatureFlag.notificationsManagement,
        );

        const { language = Language.En, colleagues = [] } = user ?? {};
        const { creatorId, updatedDate, deal } = mention;

        const dateAgo = useUpdatableDateAgo(updatedDate, language);
        const fullWidthMenu = useMediaQuery(MediaQuery.fullWidthMenu);

        const author = colleagues.find(
            (colleague) => colleague.itemId === creatorId,
        );
        if (!author) return null;

        const authorFullName = getUserFullName(author);
        const shouldShowUnreadBadge =
            isNotificationManagementFeatureEnabled && !mention.isRead;

        const handleClick = () => {
            toggleNotificationsOpened(false);
            toggleMobileMenu(false);

            if (isNotificationManagementFeatureEnabled && !mention.isRead) {
                void markMentionAsRead(mention.commentId);
            }
        };

        const handleDismiss = async () => {
            setIsDismissing(true);
            await dismissMention(mention.commentId);
            setIsDismissing(false);
        };

        const title = `${authorFullName} ${t('navbar.notifications.mentioned_you_in_a_comment')}`;

        const actions = isNotificationManagementFeatureEnabled && (
            <>
                <Tooltip
                    content={t('common.dismiss')}
                    triggerAsChild
                    disableHoverableContent
                >
                    <Button
                        variant="ghost"
                        size="icon"
                        disabled={isDismissing}
                        onClick={handleDismiss}
                    >
                        <CircleX className="size-5 text-gray-600" />
                    </Button>
                </Tooltip>
            </>
        );

        return (
            <NotificationCard
                linkUrl={createDealPageLinkToComments(
                    deal.id,
                    DealOpenSource.NotificationsMentions,
                )}
                headerBadge={
                    <BrAvatar
                        avatarData={author}
                        size={fullWidthMenu ? AvatarSizes.m : AvatarSizes.l}
                    />
                }
                title={title}
                subTitle={deal.title}
                when={dateAgo}
                actions={actions}
                shouldShowUnreadBadge={shouldShowUnreadBadge}
                onLinkClickedHandler={handleClick}
            />
        );
    },
);
