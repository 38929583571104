import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** Date with time (isoformat) */
    DateTime: { input: any; output: any };
    Upload: { input: any; output: any };
};

export type CreateDealResultQl = {
    dealId: Scalars['String']['output'];
    ok: Scalars['Boolean']['output'];
};

export enum DealContentCreationSource {
    AdditionalSourcesLlm = 'ADDITIONAL_SOURCES_LLM',
    DealImport = 'DEAL_IMPORT',
    UserCreated = 'USER_CREATED',
}

export type DealContentMetadataQl = {
    creationSource: DealContentCreationSource;
    hasContent: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    name?: Maybe<Scalars['String']['output']>;
    publishedDate?: Maybe<Scalars['DateTime']['output']>;
    source?: Maybe<Scalars['String']['output']>;
    type: DealContentType;
};

export enum DealContentType {
    File = 'FILE',
    Text = 'TEXT',
    Url = 'URL',
}

export type DealImportStatusQl = {
    ok: Scalars['Boolean']['output'];
    uploadSize: Scalars['Int']['output'];
};

export type DebugQl = {
    request: DebugRequestQl;
};

export type DebugRequestQl = {
    tracing: DebugRequestTracingQl;
};

export type DebugRequestTracingQl = {
    traceId?: Maybe<Scalars['String']['output']>;
};

export type MultipartMutation = {
    createDealFromExternalSource: CreateDealResultQl;
    requestDealImport: DealImportStatusQl;
    uploadDealAttachment: Array<DealContentMetadataQl>;
};

export type MultipartMutationCreateDealFromExternalSourceArgs = {
    file?: InputMaybe<Scalars['Upload']['input']>;
    pipelineId: Scalars['String']['input'];
    url?: InputMaybe<Scalars['String']['input']>;
};

export type MultipartMutationRequestDealImportArgs = {
    externalSourceUpload?: InputMaybe<Scalars['Upload']['input']>;
    externalSourceUrl?: InputMaybe<Scalars['String']['input']>;
    targetPipelineId: Scalars['String']['input'];
};

export type MultipartMutationUploadDealAttachmentArgs = {
    dealId: Scalars['String']['input'];
    file?: InputMaybe<Scalars['Upload']['input']>;
    url?: InputMaybe<Scalars['String']['input']>;
};

export type MultipartQuery = {
    debug: DebugQl;
    timeoutTest: Scalars['String']['output'];
    translate: Scalars['String']['output'];
};

export type MultipartQueryTimeoutTestArgs = {
    timeoutSec?: Scalars['Int']['input'];
};

export type MultipartQueryTranslateArgs = {
    language: Scalars['String']['input'];
    text: Scalars['String']['input'];
};

export type DealContentMetadataFragment = {
    id: string;
    type: DealContentType;
    source?: string | null;
    name?: string | null;
    hasContent: boolean;
    publishedDate?: any | null;
    creationSource: DealContentCreationSource;
};

export type RequestDealImportMutationVariables = Exact<{
    targetPipelineId: Scalars['String']['input'];
    externalSourceUpload?: InputMaybe<Scalars['Upload']['input']>;
    externalSourceUrl?: InputMaybe<Scalars['String']['input']>;
}>;

export type RequestDealImportMutation = {
    requestDealImport: { ok: boolean; uploadSize: number };
};

export type UploadDealAttachmentMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    file?: InputMaybe<Scalars['Upload']['input']>;
    url?: InputMaybe<Scalars['String']['input']>;
}>;

export type UploadDealAttachmentMutation = {
    uploadDealAttachment: Array<{
        id: string;
        type: DealContentType;
        source?: string | null;
        name?: string | null;
        hasContent: boolean;
        publishedDate?: any | null;
        creationSource: DealContentCreationSource;
    }>;
};

export type CreateDealFromExternalSourceMutationVariables = Exact<{
    pipelineId: Scalars['String']['input'];
    file?: InputMaybe<Scalars['Upload']['input']>;
    url?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateDealFromExternalSourceMutation = {
    createDealFromExternalSource: { dealId: string };
};

export const DealContentMetadataFragmentDoc = gql`
    fragment DealContentMetadata on DealContentMetadataQL {
        id
        type
        source
        name
        hasContent
        publishedDate
        creationSource
    }
`;
export const RequestDealImportDocument = gql`
    mutation RequestDealImport(
        $targetPipelineId: String!
        $externalSourceUpload: Upload
        $externalSourceUrl: String
    ) {
        requestDealImport(
            targetPipelineId: $targetPipelineId
            externalSourceUrl: $externalSourceUrl
            externalSourceUpload: $externalSourceUpload
        ) {
            ok
            uploadSize
        }
    }
`;
export const UploadDealAttachmentDocument = gql`
    mutation UploadDealAttachment(
        $dealId: String!
        $file: Upload
        $url: String
    ) {
        uploadDealAttachment(dealId: $dealId, file: $file, url: $url) {
            ...DealContentMetadata
        }
    }
    ${DealContentMetadataFragmentDoc}
`;
export const CreateDealFromExternalSourceDocument = gql`
    mutation CreateDealFromExternalSource(
        $pipelineId: String!
        $file: Upload
        $url: String
    ) {
        createDealFromExternalSource(
            pipelineId: $pipelineId
            file: $file
            url: $url
        ) {
            dealId
        }
    }
`;
export type Requester<C = {}> = <R, V>(
    doc: DocumentNode,
    vars?: V,
    options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        RequestDealImport(
            variables: RequestDealImportMutationVariables,
            options?: C,
        ): Promise<RequestDealImportMutation> {
            return requester<
                RequestDealImportMutation,
                RequestDealImportMutationVariables
            >(
                RequestDealImportDocument,
                variables,
                options,
            ) as Promise<RequestDealImportMutation>;
        },
        UploadDealAttachment(
            variables: UploadDealAttachmentMutationVariables,
            options?: C,
        ): Promise<UploadDealAttachmentMutation> {
            return requester<
                UploadDealAttachmentMutation,
                UploadDealAttachmentMutationVariables
            >(
                UploadDealAttachmentDocument,
                variables,
                options,
            ) as Promise<UploadDealAttachmentMutation>;
        },
        CreateDealFromExternalSource(
            variables: CreateDealFromExternalSourceMutationVariables,
            options?: C,
        ): Promise<CreateDealFromExternalSourceMutation> {
            return requester<
                CreateDealFromExternalSourceMutation,
                CreateDealFromExternalSourceMutationVariables
            >(
                CreateDealFromExternalSourceDocument,
                variables,
                options,
            ) as Promise<CreateDealFromExternalSourceMutation>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
