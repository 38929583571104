import { observer } from 'mobx-react';
import { CSSProperties, FC, MouseEvent, PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { cn } from 'src/utils/tailwindcss.utils';

interface LinkProps {
    href: string;
    className?: string;
    style?: CSSProperties;
    target?: string;
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

export const Link: FC<PropsWithChildren<LinkProps>> = observer(
    ({ children, href, className, style, target, onClick }) => {
        return (
            <RouterLink
                className={cn(
                    'no-underline hover:no-underline focus:no-underline',
                    className,
                )}
                style={style}
                onClick={onClick}
                to={href}
                target={target}
            >
                {children}
            </RouterLink>
        );
    },
);
