import { createPath } from 'history';
import { generatePath } from 'react-router-dom';
import { SearchType } from 'src/app-features/searches-configuration/domain/models/configured-search';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';

export const BuildSearchUrl = (type: SearchType) => {
    const pathname = generatePath(AppRoute.configureNewSearch);
    const searchParams = new URLSearchParams();
    searchParams.set('type', type);

    return createPath({
        pathname,
        search: searchParams.toString(),
    });
};
