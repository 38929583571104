import { withFeatures } from 'src/utils/component.utils';
import { NotificationsTab } from './notifications-tab.component';

export const NotificationsTabContainer = withFeatures(NotificationsTab)(
    (f) => ({
        overdueReminders: f.notificationsFeature.overdueReminders,
        unreadMentions: f.notificationsFeature.unreadMentions,
        fetchRemindersAndMentions:
            f.notificationsFeature.fetchRemindersAndMentions,
    }),
);
