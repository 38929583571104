import { FC } from 'react';

import { FoldersSectionContainer } from './components/folders-section/folders-section.container';
import { OtherSectionContainer } from './components/other-section/other-section.container';
import { SubMenuContainer } from './components/sub-menu/sub-menu.container';

export const PrimaryNavbarComponent: FC = () => {
    return (
        <aside className="primary-nav-bar sticky top-0 z-navbar flex h-screen w-16 flex-none flex-col items-start justify-between overflow-hidden bg-navbar-default leading-4">
            <div className="flex w-full flex-col items-center">
                <FoldersSectionContainer />
                <SubMenuContainer />
            </div>
            <OtherSectionContainer />
        </aside>
    );
};
