import { type FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { CircleCheckBig, Pencil } from 'lucide-react';
import { Button } from '@buildingradar/ui_library/button';
import { Tooltip } from '@buildingradar/ui_library/tooltip';
import { createDealPageLink } from 'src/presentation/modules/router/utils/route.utils';
import { type ReminderFlyoutDataContext } from 'src/app-features/schedule-reminders/domain/reminders-interface.feature';
import { DealOpenSource } from 'src/domain/models/deal/deal.model';
import { type Language } from 'src/domain/models/locale/locale.model';
import { type Reminder } from 'src/domain/models/reminder/reminder.model';
import { NotificationCard } from '../../shared/notification-card/notification-card.component';
import { formatReminderDueDate } from './reminder-due-date.helper';
import { ReminderBadge } from './reminder-badge.component';

type TProps = {
    reminder: Reminder;
    language?: Language;
    toggleNotificationsOpened: (flag: boolean) => void;
    openReminderFlyout: (dataContext: ReminderFlyoutDataContext) => void;
    markAsCompleted: (reminderId: string, dealId: string) => void;
};

export const ReminderCard: FC<TProps> = observer(
    ({
        reminder,
        language,
        toggleNotificationsOpened,
        openReminderFlyout,
        markAsCompleted,
    }) => {
        const { t } = useTranslation();

        const { id, description, dueDate, target } = reminder;
        const { title, id: dealId } = target;

        const handleReminderCardClicked = (timeout = true) => {
            toggleNotificationsOpened(false);

            setTimeout(
                () =>
                    openReminderFlyout({
                        launchSource: 'Notification Flyout',
                        reminder,
                    }),
                timeout ? 500 : 0,
            );
        };

        const actions = (
            <>
                <Tooltip
                    content={t('common.edit')}
                    triggerAsChild
                    disableHoverableContent
                >
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleReminderCardClicked(false)}
                    >
                        <Pencil className="size-5 text-gray-600" />
                    </Button>
                </Tooltip>
                <Tooltip
                    content={t('next_steps.complete_action.tooltip')}
                    triggerAsChild
                    disableHoverableContent
                >
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => {
                            if (id) markAsCompleted(id, target.id);
                        }}
                    >
                        <CircleCheckBig className="size-5 text-gray-600" />
                    </Button>
                </Tooltip>
            </>
        );

        return (
            <NotificationCard
                linkUrl={createDealPageLink(
                    dealId,
                    DealOpenSource.NotificationsNextSteps,
                )}
                headerBadge={<ReminderBadge dueDate={dueDate} />}
                title={description.replaceAll('\n', ' ')}
                subTitle={title}
                when={formatReminderDueDate(dueDate, t, language)}
                actions={actions}
                onLinkClickedHandler={handleReminderCardClicked}
            />
        );
    },
);
