import { type FC, type ReactNode, type MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Tooltip } from '@buildingradar/ui_library/tooltip';
import { Link } from 'src/presentation/shared/link/link.component';

type TProps = {
    linkUrl: string;
    headerBadge?: ReactNode;
    title?: string | undefined;
    subTitle?: string | undefined;
    when?: string | undefined;
    actions?: ReactNode;
    shouldShowUnreadBadge?: boolean;
    onLinkClickedHandler?: () => void;
    onEdit?: () => void;
    onDismiss?: () => void;
};

export const NotificationCard: FC<TProps> = observer(
    ({
        linkUrl,
        headerBadge,
        title,
        subTitle,
        when,
        actions,
        shouldShowUnreadBadge,
        onLinkClickedHandler,
    }) => {
        const handleActionsContainerClick = (e: MouseEvent) => {
            // Prevent click event from propagating to the parent link.
            e.stopPropagation();
            e.preventDefault();
        };

        return (
            <Link
                className="notification-card link group flex w-full items-center gap-2 rounded-md bg-white p-4 shadow hover:bg-primary-100"
                href={linkUrl}
                onClick={onLinkClickedHandler}
            >
                <div className="flex flex-grow gap-4">
                    {!!headerBadge && (
                        <div className="relative">
                            {headerBadge}
                            {shouldShowUnreadBadge && (
                                <div className="absolute -right-1 -top-1 size-4 rounded-full border-2 border-solid border-white bg-red-500 shadow" />
                            )}
                        </div>
                    )}
                    <div className="w-0 flex-grow overflow-hidden text-base tracking-wide">
                        {!!title && (
                            <Tooltip
                                content={title}
                                triggerAsChild
                                disableHoverableContent
                            >
                                <h4 className="w-fit max-w-full truncate">
                                    {title}
                                </h4>
                            </Tooltip>
                        )}
                        {!!subTitle && (
                            <Tooltip
                                content={subTitle}
                                triggerAsChild
                                disableHoverableContent
                            >
                                <div className="w-fit max-w-full truncate font-medium text-gray-800 group-hover:text-indigo-600">
                                    {subTitle}
                                </div>
                            </Tooltip>
                        )}
                        {!!when && (
                            <Tooltip
                                content={when}
                                triggerAsChild
                                disableHoverableContent
                            >
                                <div className="w-fit max-w-full text-sm tracking-wide">
                                    {when}
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
                {!!actions && (
                    <div
                        className="flex flex-shrink-0 gap-2"
                        onClick={handleActionsContainerClick}
                    >
                        {actions}
                    </div>
                )}
            </Link>
        );
    },
);
