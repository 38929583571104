import { AbortParams } from 'src/data/api/api-client';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { languageModelToRaw } from 'src/domain/models/deal-export/deal-export.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { type ContactExtractionResult, mapContactExtractionResult } from './contact-extraction.model';

interface GetContactExtractionParams extends AbortParams {
    dealId: string;
    language: Language;
}

interface GetAutoExtractionResultParams extends AbortParams {
    dealId: string;
}

export interface IContactExtractionApi {
    getAutoExtractionResult: (
        params: GetAutoExtractionResultParams,
    ) => Promise<ContactExtractionResult>;
    runAutoExtraction: (params: GetContactExtractionParams) => Promise<boolean>;
}

export const createContactExtractionApi = (
    processGqlSdk: ProcessGqlSdk,
): IContactExtractionApi => {
    const getAutoExtractionResult: IContactExtractionApi['getAutoExtractionResult'] =
        async (params: GetAutoExtractionResultParams) => {
            const response = await processGqlSdk.GetContactExtractionResult(
                { dealId: params.dealId },
                { signal: params.signal },
            );

            return mapContactExtractionResult(
                response.getContactExtractionResult,
            );
        };

    const runAutoExtraction = async (
        params: GetContactExtractionParams,
    ): Promise<boolean> => {
        const response = await processGqlSdk.RunContactExtractionForDeal(
            {
                dealId: params.dealId,
                language: languageModelToRaw[params.language],
            },
            { signal: params.signal },
        );
        return response.runContactExtractionForDeal.ok;
    };

    return {
        getAutoExtractionResult,
        runAutoExtraction,
    };
};
