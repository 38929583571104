import { makeAutoObservable } from 'mobx';
import { computedFn } from 'mobx-utils';

import {
    ValueMilestoneFragment,
    ValueMilestoneType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { PipelineApi } from 'src/data/api/pipeline/pipeline.api';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IViewPreferencesStore } from 'src/data/stores/pipeline-data/view-preferences/view-preferences.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import { Stage } from 'src/domain/models/deal-stage/deal-stage.model';
import {
    getPipelineViewDataTypeBasedOnVisualizationMode,
    RefetchPipelineViewDataSource,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import { Pipeline } from 'src/domain/models/pipeline/pipeline.model';
import { sortNewArray } from 'src/utils/array.utils';
import { doNothing } from 'src/utils/function.utils';
import {
    Cancellable,
    emptyCancellable,
    handleRequest,
} from 'src/utils/handle-request.utils';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

export interface PipelineFeature {
    myPipeline: Pipeline | null;
    arePipelinesLoading: boolean;
    needToRequestPipelines: boolean;
    pipelines: Pipeline[];
    sortedPipelines: Pipeline[];
    currentSelectedPipeline?: Pipeline;
    valueMilestones: ValueMilestoneFragment[] | undefined;
    requestPipelines: () => Cancellable;
    setCurrentSelectedPipeline: (pipeline: Pipeline) => void;
    getPipeline: (pipelineId: string) => Pipeline | null;
    getPipelineStages: (pipelineId?: string) => {
        stageMap: Map<string, Stage> | undefined;
        stages: Stage[];
    };
    getValueMilestones: () => void;
    isValueMilestoneSet: (
        type: ValueMilestoneType,
        pipelineId?: string,
    ) => boolean;
    isValueMilestoneSetForAnyPipeline: (type: ValueMilestoneType) => boolean;
}

export class PipelineFeatureImpl implements PipelineFeature {
    get pipelines(): Pipeline[] {
        return this.pipelineStore.pipelineIds
            .map((id) => this.pipelineStore.getPipeline(id))
            .filter(isNonNullable);
    }

    get sortedPipelines(): Pipeline[] {
        return sortNewArray(this.pipelines)((a, b) =>
            a.name.localeCompare(b.name),
        );
    }

    get needToRequestPipelines(): boolean {
        return this.pipelineStore.needToRequestPipelines;
    }

    get myPipeline(): Pipeline | null {
        // return default pipeline if any
        if (this.userStore.user?.settings.defaultPipeline) {
            const pipeline = this.getPipeline(
                this.userStore.user.settings.defaultPipeline,
            );
            if (pipeline) {
                return pipeline;
            }
        }

        // return oldest pipeline
        const { pipelineIds } = this.pipelineStore;
        if (pipelineIds.length) {
            return this.getPipeline(pipelineIds[0]);
        }

        return null;
    }

    get arePipelinesLoading(): boolean {
        return this.pipelineStore.arePipelinesLoading;
    }

    get valueMilestones() {
        return this.pipelineStore.valueMilestones;
    }

    get isValueMilestoneSet() {
        return this.pipelineStore.isValueMilestoneSet;
    }

    get isValueMilestoneSetForAnyPipeline() {
        return this.pipelineStore.isValueMilestoneSetForAnyPipeline;
    }

    constructor(
        private pipelineApi: PipelineApi,
        private pipelineStore: PipelineStore,
        private userStore: UserStore,
        private performanceViewPreferencesStore: IViewPreferencesStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    getPipeline = computedFn((pipelineId: string): Pipeline | null => {
        return this.pipelineStore.getPipeline(pipelineId);
    });

    getPipelineStages = (pipelineId?: string) => {
        return this.pipelineStore.getPipelineStages(pipelineId);
    };

    requestPipelines = () => {
        if (!this.userStore.user) {
            return emptyCancellable;
        }
        return handleRequest(
            this.pipelineApi.getPipelinesData,
            {},
            (pipelines) => this.pipelineStore.setPipelines(pipelines),
            this.pipelineStore.setLoadingPipelines,
            (error) =>
                this.baseStore.onRequestFailed('request-pipelines', error),
            'request-pipelines',
        );
    };

    get currentSelectedPipeline(): Pipeline | undefined {
        return this.pipelineStore.currentSelectedPipeline;
    }

    setCurrentSelectedPipeline = (pipeline: Pipeline) => {
        /**
         * When setting the selected pipeline, we need to refetch the pipeline performance view, however
         * we need to skip this when the pipeline page loads for the first time.
         * As the main source for fetching pipeline statistics is the view preferences section,
         * when the page loads, we leave this refetch task to the view preference.
         * Only on subsequent pipeline selections, we set the app to refresh statistics.
         */
        if (this.currentSelectedPipeline) {
            this.performanceViewPreferencesStore.setRefetchPipelineViewDataState(
                {
                    active: true,
                    refetchType:
                        getPipelineViewDataTypeBasedOnVisualizationMode(
                            this.performanceViewPreferencesStore
                                .visualizationModePreference,
                        ),
                    source: RefetchPipelineViewDataSource.switchPipeline,
                },
            );
        }
        this.pipelineStore.setCurrentSelectedPipeline(pipeline);
    };

    getValueMilestones = () => {
        handleRequest(
            this.pipelineApi.getValueMilestones,
            {},
            this.pipelineStore.setValueMilestones,
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('value-milestones', error),
            'value-milestones',
        );
    };
}
