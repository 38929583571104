import { AbortParams } from 'src/data/api/api-client';
import { ProcessMultipartGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

interface RequestImportParams extends AbortParams {
    targetPipelineId: string;
    externalSourceUrl?: string;
    externalSourceUpload?: File;
}

export interface IBringYourOwnProjectsApi {
    requestDealImport: (payload: RequestImportParams) => Promise<boolean>;
    createDealFromExternalSource: (
        payload: RequestImportParams,
    ) => Promise<string>;
}

export const createBringYourOwnProjectsApi = (
    processMultipartGqlSdk: ProcessMultipartGqlSdk,
): IBringYourOwnProjectsApi => {
    const requestDealImport = async (
        params: RequestImportParams,
    ): Promise<boolean> => {
        const response = await processMultipartGqlSdk.RequestDealImport(
            {
                targetPipelineId: params.targetPipelineId,
                externalSourceUrl: params.externalSourceUrl,
                externalSourceUpload: params.externalSourceUpload,
            },
            { signal: params.signal },
        );
        return response.requestDealImport.ok;
    };

    const createDealFromExternalSource = async (
        params: RequestImportParams,
    ): Promise<string> => {
        const response =
            await processMultipartGqlSdk.CreateDealFromExternalSource(
                {
                    pipelineId: params.targetPipelineId,
                    url: params.externalSourceUrl,
                    file: params.externalSourceUpload,
                },
                { signal: params.signal },
            );
        return response.createDealFromExternalSource.dealId;
    };

    return {
        requestDealImport,
        createDealFromExternalSource,
    };
};
