import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** Date with time (isoformat) */
    DateTime: { input: any; output: any };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: any; output: any };
};

export type AddressQl = {
    city?: Maybe<Scalars['String']['output']>;
    continent?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    countryCode?: Maybe<Scalars['String']['output']>;
    houseNumber?: Maybe<Scalars['String']['output']>;
    lat?: Maybe<Scalars['Float']['output']>;
    lng?: Maybe<Scalars['Float']['output']>;
    postCode?: Maybe<Scalars['String']['output']>;
    postCodeEnd?: Maybe<Scalars['String']['output']>;
    postCodeIsExact?: Maybe<Scalars['Boolean']['output']>;
    postCodeStart?: Maybe<Scalars['String']['output']>;
    precisionAccuracy?: Maybe<Scalars['Float']['output']>;
    precisionLevel?: Maybe<Scalars['String']['output']>;
    precisionType?: Maybe<Scalars['String']['output']>;
    rank?: Maybe<Scalars['Float']['output']>;
    road?: Maybe<Scalars['String']['output']>;
    state?: Maybe<Scalars['String']['output']>;
};

export type ArticleDocumentQl = {
    abstract?: Maybe<Scalars['String']['output']>;
    address?: Maybe<AddressQl>;
    categories?: Maybe<Array<CategoryQl>>;
    constructionEndDate?: Maybe<BrTimeQl>;
    constructionPhases?: Maybe<Array<PhaseQl>>;
    constructionStartDate?: Maybe<BrTimeQl>;
    contacts?: Maybe<Array<ContactQl>>;
    description?: Maybe<Scalars['String']['output']>;
    descriptions?: Maybe<Array<Scalars['String']['output']>>;
    gfa?: Maybe<Scalars['Int']['output']>;
    images?: Maybe<Array<ImageQl>>;
    itemCreatedDate: Scalars['DateTime']['output'];
    itemId: Scalars['String']['output'];
    itemUpdatedDate: Scalars['DateTime']['output'];
    language?: Maybe<Scalars['String']['output']>;
    nResidentialUnits?: Maybe<Scalars['Int']['output']>;
    paragraphs?: Maybe<Array<Scalars['String']['output']>>;
    sourceDomains?: Maybe<Array<Scalars['String']['output']>>;
    sourcePublishedDate?: Maybe<BrTimeQl>;
    sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
    title: Scalars['String']['output'];
    titleAlternative?: Maybe<Scalars['String']['output']>;
    titleAlternativeEnglish?: Maybe<Scalars['String']['output']>;
    titleEnglish?: Maybe<Scalars['String']['output']>;
    volumeReference?: Maybe<Scalars['String']['output']>;
};

export enum BrTimePrecision {
    Day = 'DAY',
    HourUtc = 'HOUR_UTC',
    McsUtc = 'MCS_UTC',
    MinUtc = 'MIN_UTC',
    Month = 'MONTH',
    SecUtc = 'SEC_UTC',
    Year = 'YEAR',
}

export type BrTimeQl = {
    day?: Maybe<Scalars['Int']['output']>;
    hour?: Maybe<Scalars['Int']['output']>;
    microsecond?: Maybe<Scalars['Int']['output']>;
    minute?: Maybe<Scalars['Int']['output']>;
    month?: Maybe<Scalars['Int']['output']>;
    precision: BrTimePrecision;
    second?: Maybe<Scalars['Int']['output']>;
    year: Scalars['Int']['output'];
};

export enum CategoryNames {
    Agriculture = 'AGRICULTURE',
    Commercial = 'COMMERCIAL',
    CulturalEvent = 'CULTURAL_EVENT',
    EducationScience = 'EDUCATION_SCIENCE',
    Health = 'HEALTH',
    HistoricalReligious = 'HISTORICAL_RELIGIOUS',
    Hospitality = 'HOSPITALITY',
    Industrial = 'INDUSTRIAL',
    Infrastructure = 'INFRASTRUCTURE',
    Landscaping = 'LANDSCAPING',
    Office = 'OFFICE',
    PublicServiceAdministration = 'PUBLIC_SERVICE_ADMINISTRATION',
    Residential = 'RESIDENTIAL',
    Sport = 'SPORT',
}

export type CategoryQl = {
    confidenceScore?: Maybe<Scalars['Float']['output']>;
    isPrecise?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    ranking?: Maybe<Scalars['Int']['output']>;
    subCategories?: Maybe<Array<Scalars['String']['output']>>;
};

export type ContactQl = {
    address?: Maybe<AddressQl>;
    associatedItemId?: Maybe<Scalars['String']['output']>;
    companyName?: Maybe<Scalars['String']['output']>;
    companyRoles?: Maybe<Array<Scalars['String']['output']>>;
    email?: Maybe<Scalars['String']['output']>;
    fax?: Maybe<Scalars['String']['output']>;
    homepage?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    personRoles?: Maybe<Array<Scalars['String']['output']>>;
    phoneLandline?: Maybe<Scalars['String']['output']>;
    phoneMobile?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
};

export type CountSearchByFilterModulesInputQl = {
    filterModules: Array<FilterModuleForSearchExecQl>;
    searchType: SearchType;
};

export type CountSearchByFilterModulesResultQl = {
    totalCount: Scalars['Int']['output'];
};

export type DocGroupWithFolderTagQl = {
    created: Scalars['DateTime']['output'];
    documentIds: Array<Scalars['String']['output']>;
    documents: Array<TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl>;
    folder?: Maybe<FolderTag>;
    id: Scalars['String']['output'];
    mergedDocument: TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl;
    pipeDbCollection: Scalars['String']['output'];
    updated: Scalars['DateTime']['output'];
};

export type FilterModuleForSearchExecQl = {
    data: Scalars['JSON']['input'];
    moduleId: Scalars['Int']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
};

export type FilterModuleQl = {
    companyId: Scalars['Int']['output'];
    created: Scalars['DateTime']['output'];
    data: Scalars['JSON']['output'];
    id: Scalars['Int']['output'];
    linkedSearchIds: Array<Scalars['Int']['output']>;
    name: Scalars['String']['output'];
    notReusable: Scalars['Boolean']['output'];
    type: FilterModuleType;
    updated: Scalars['DateTime']['output'];
    userId?: Maybe<Scalars['Int']['output']>;
};

export enum FilterModuleType {
    Article = 'ARTICLE',
    Generic = 'GENERIC',
    Project = 'PROJECT',
    Tender = 'TENDER',
}

export enum FolderTag {
    AllExported = 'ALL_EXPORTED',
    CompEnrich = 'COMP_ENRICH',
    Done = 'DONE',
    Duplicate = 'DUPLICATE',
    ExportedAsCsv = 'EXPORTED_AS_CSV',
    ExportedAsPdf = 'EXPORTED_AS_PDF',
    ExportedToCrm = 'EXPORTED_TO_CRM',
    Inbox = 'INBOX',
    Interesting = 'INTERESTING',
    NotInteresting = 'NOT_INTERESTING',
    PreInbox = 'PRE_INBOX',
    PreScreening = 'PRE_SCREENING',
    Resubmitted = 'RESUBMITTED',
    Snoozed = 'SNOOZED',
}

export type ImageQl = {
    qualityScore?: Maybe<Scalars['Float']['output']>;
    sourceDomain: Scalars['String']['output'];
    sourceParseId?: Maybe<Scalars['String']['output']>;
    sourceTitle?: Maybe<Scalars['String']['output']>;
    sourceUrl: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type LegacyProjectDocumentQl = {
    address?: Maybe<AddressQl>;
    awards?: Maybe<Array<Scalars['String']['output']>>;
    categories?: Maybe<Array<CategoryQl>>;
    constructionEndDate?: Maybe<BrTimeQl>;
    constructionPhases?: Maybe<Array<PhaseQl>>;
    constructionStartDate?: Maybe<BrTimeQl>;
    contacts?: Maybe<Array<ContactQl>>;
    description?: Maybe<Scalars['String']['output']>;
    descriptions?: Maybe<Array<Scalars['String']['output']>>;
    gfa?: Maybe<Scalars['Int']['output']>;
    height?: Maybe<Scalars['Int']['output']>;
    images?: Maybe<Array<ImageQl>>;
    itemCreatedDate: Scalars['DateTime']['output'];
    itemId: Scalars['String']['output'];
    itemUpdatedDate: Scalars['DateTime']['output'];
    language?: Maybe<Scalars['String']['output']>;
    materials?: Maybe<Scalars['String']['output']>;
    nFloors?: Maybe<Scalars['Int']['output']>;
    nResidentialUnits?: Maybe<Scalars['Int']['output']>;
    nRooms?: Maybe<Scalars['Int']['output']>;
    sourceDomains?: Maybe<Array<Scalars['String']['output']>>;
    sourcePublishedDate?: Maybe<BrTimeQl>;
    sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
    title: Scalars['String']['output'];
    titleAlternative?: Maybe<Scalars['String']['output']>;
    titleAlternativeEnglish?: Maybe<Scalars['String']['output']>;
    titleEnglish?: Maybe<Scalars['String']['output']>;
    volumeReference?: Maybe<Scalars['String']['output']>;
};

export type LinkedFilterModuleQl = {
    filter: Scalars['String']['output'];
    key: Scalars['Int']['output'];
    value: Scalars['String']['output'];
};

export type Mutation = {
    createFilterModule: FilterModuleQl;
    debug: ResultQl;
    deleteFilterModule: ResultQl;
    deleteSearch: ResultQl;
    pauseSearch: ResultQl;
    subscribeSearch: SearchQl;
    unpauseSearch: ResultQl;
    unsubscribeSearch: ResultQl;
    updateFilterModule: ResultQl;
    updateSearchPrescreeningSettings: ResultQl;
    upsertSearch: SearchIdQl;
};

export type MutationCreateFilterModuleArgs = {
    data: Scalars['JSON']['input'];
    filterModuleType: FilterModuleType;
    name: Scalars['String']['input'];
    notReusable: Scalars['Boolean']['input'];
};

export type MutationDeleteFilterModuleArgs = {
    filterModuleId: Scalars['Int']['input'];
};

export type MutationDeleteSearchArgs = {
    searchId: Scalars['Int']['input'];
};

export type MutationPauseSearchArgs = {
    searchId: Scalars['Int']['input'];
};

export type MutationSubscribeSearchArgs = {
    searchId: Scalars['Int']['input'];
    subscriberId: Scalars['Int']['input'];
};

export type MutationUnpauseSearchArgs = {
    searchId: Scalars['Int']['input'];
};

export type MutationUnsubscribeSearchArgs = {
    searchId: Scalars['Int']['input'];
    subscriberId: Scalars['Int']['input'];
};

export type MutationUpdateFilterModuleArgs = {
    data: Scalars['JSON']['input'];
    filterModuleId: Scalars['Int']['input'];
    name: Scalars['String']['input'];
    notReusable: Scalars['Boolean']['input'];
};

export type MutationUpdateSearchPrescreeningSettingsArgs = {
    autoPrescreeningEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    autoPrescreeningInboxLimit?: InputMaybe<Scalars['Int']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    searchId: Scalars['Int']['input'];
};

export type MutationUpsertSearchArgs = {
    filterModuleIds: Array<Scalars['Int']['input']>;
    searchId?: InputMaybe<Scalars['Int']['input']>;
    searchName: Scalars['String']['input'];
    searchType: SearchType;
};

export type PaginationConfQl = {
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PhaseQl = {
    confidenceScore?: Maybe<Scalars['Float']['output']>;
    isPrecise?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    ranking?: Maybe<Scalars['Int']['output']>;
    subPhases?: Maybe<Array<Scalars['String']['output']>>;
};

export type PrescreenOutputQl = {
    accept: Scalars['Boolean']['output'];
    confidence: Scalars['Int']['output'];
};

export type PrescreeningDocGroupWithFolderTagQl = {
    created: Scalars['DateTime']['output'];
    documentIds: Array<Scalars['String']['output']>;
    documents: Array<TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl>;
    folder?: Maybe<FolderTag>;
    id: Scalars['String']['output'];
    mergedDocument: TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl;
    pipeDbCollection: Scalars['String']['output'];
    prescreenOutput: PrescreenOutputQl;
    updated: Scalars['DateTime']['output'];
};

export type Query = {
    autocompleteSourceDomains: Array<Scalars['String']['output']>;
    countSearchByFilterModules: CountSearchByFilterModulesResultQl;
    debug: ResultQl;
    filterModules: Array<FilterModuleQl>;
    locationSuggestions: Array<SuggestedLocationResultQl>;
    searchByFilterModules: SearchByFilterModulesResultQl;
    searchWithPrescreening: SearchWithPrescreeningResultQl;
    searchesAndSubscriptions: Array<SearchAndSubscribersQl>;
    tenderChildCodes: Array<TenderCodeNodeQl>;
    tenderCodeTree: Array<TenderCodeRootNodeResultQl>;
    tenderCodesFiltered: Array<TenderCodeRootNodeResultQl>;
    tenderTextFromCodes: Array<Scalars['String']['output']>;
};

export type QueryAutocompleteSourceDomainsArgs = {
    partialString: Scalars['String']['input'];
};

export type QueryCountSearchByFilterModulesArgs = {
    days?: Scalars['Int']['input'];
    searchInput: CountSearchByFilterModulesInputQl;
};

export type QueryFilterModulesArgs = {
    filterModuleId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryLocationSuggestionsArgs = {
    partialString: Scalars['String']['input'];
};

export type QuerySearchByFilterModulesArgs = {
    searchInput: SearchByFilterModulesInputQl;
};

export type QuerySearchWithPrescreeningArgs = {
    narrative: Scalars['String']['input'];
    searchInput: SearchByFilterModulesInputQl;
};

export type QueryTenderChildCodesArgs = {
    tenderCodeId: Scalars['String']['input'];
};

export type QueryTenderCodeTreeArgs = {
    tenderCodeIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryTenderCodesFilteredArgs = {
    procurementSystem?: InputMaybe<TenderCodeSystem>;
    textSearch: Scalars['String']['input'];
};

export type QueryTenderTextFromCodesArgs = {
    cpvCodes: Array<Scalars['String']['input']>;
    naicsCodes: Array<Scalars['String']['input']>;
    unspscCodes: Array<Scalars['String']['input']>;
};

export type ResultQl = {
    ok: Scalars['Boolean']['output'];
};

export type SearchAndSubscribersQl = {
    autoPrescreeningEnabled: Scalars['Boolean']['output'];
    autoPrescreeningInboxLimit: Scalars['Int']['output'];
    created: Scalars['DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    itemId: Scalars['Int']['output'];
    lastCheckedNewItemsDate?: Maybe<Scalars['DateTime']['output']>;
    name: Scalars['String']['output'];
    paused: Scalars['Boolean']['output'];
    searchData: SearchDataQl;
    subscriberIds: Array<Scalars['Int']['output']>;
    type: SearchType;
    userCompanyId: Scalars['Int']['output'];
    userId: Scalars['Int']['output'];
};

export type SearchByFilterModulesInputQl = {
    filterModules: Array<FilterModuleForSearchExecQl>;
    paginationConf?: InputMaybe<PaginationConfQl>;
    searchType: SearchType;
};

export type SearchByFilterModulesResultQl = {
    docGroups: Array<DocGroupWithFolderTagQl>;
    totalCount: Scalars['Int']['output'];
};

export type SearchDataQl = {
    filterModules: Array<LinkedFilterModuleQl>;
};

export type SearchIdQl = {
    id: Scalars['Int']['output'];
};

export type SearchQl = {
    autoPrescreeningEnabled: Scalars['Boolean']['output'];
    autoPrescreeningInboxLimit: Scalars['Int']['output'];
    created: Scalars['DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    itemId: Scalars['Int']['output'];
    lastCheckedNewItemsDate?: Maybe<Scalars['DateTime']['output']>;
    name: Scalars['String']['output'];
    paused: Scalars['Boolean']['output'];
    searchData: SearchDataQl;
    type: SearchType;
    userCompanyId: Scalars['Int']['output'];
    userId: Scalars['Int']['output'];
};

export type SearchResultQl = {
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    itemId: Scalars['String']['output'];
    links: Array<Scalars['String']['output']>;
    publishedDate: Scalars['DateTime']['output'];
    state: Scalars['String']['output'];
    summary: Array<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    typeOfProject: Array<CategoryNames>;
};

export type SearchResultResponseQl = {
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    order: Scalars['Int']['output'];
    searchId: Scalars['String']['output'];
    searchResult: SearchResultQl;
    state: Scalars['String']['output'];
    typeOfProject: Array<CategoryNames>;
};

export enum SearchType {
    Article = 'ARTICLE',
    Tender = 'TENDER',
}

export type SearchWithPrescreeningResultQl = {
    docGroups: Array<PrescreeningDocGroupWithFolderTagQl>;
    totalCount: Scalars['Int']['output'];
};

export type Subscription = {
    neuralSearchSearch: SearchResultResponseQl;
};

export type SubscriptionNeuralSearchSearchArgs = {
    endDate?: InputMaybe<Scalars['DateTime']['input']>;
    prompt: Scalars['String']['input'];
    startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SuggestedLocationResultQl = {
    countryCode: Scalars['String']['output'];
    key: Scalars['String']['output'];
    population: Scalars['Int']['output'];
    twoFishesId: Scalars['String']['output'];
    type: Scalars['Int']['output'];
    value: Scalars['String']['output'];
};

export type TenderCodeNodeQl = {
    ancestors?: Maybe<Array<Scalars['String']['output']>>;
    children?: Maybe<Array<TenderCodeRootNodeResultQlTenderCodeNodeQl>>;
    hasChildren: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    label: Scalars['String']['output'];
    leaf: Scalars['Boolean']['output'];
    loadOnDemand: Scalars['Boolean']['output'];
    nChildren: Scalars['Int']['output'];
    name: Scalars['String']['output'];
    parent?: Maybe<Scalars['String']['output']>;
    procurementSystem: Scalars['String']['output'];
    root: Scalars['Boolean']['output'];
};

export type TenderCodeRootNodeResultQl = {
    children?: Maybe<Array<TenderCodeRootNodeResultQlTenderCodeNodeQl>>;
    hasChildren: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    loadOnDemand: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    root: Scalars['Boolean']['output'];
};

export type TenderCodeRootNodeResultQlTenderCodeNodeQl =
    | TenderCodeNodeQl
    | TenderCodeRootNodeResultQl;

export enum TenderCodeSystem {
    Cpv = 'CPV',
    Naics = 'NAICS',
    Unspsc = 'UNSPSC',
}

export type TenderDocumentQl = {
    allLotsAwarded?: Maybe<Scalars['Boolean']['output']>;
    announcedDate?: Maybe<BrTimeQl>;
    applicationUrl?: Maybe<Scalars['String']['output']>;
    awardCriteria?: Maybe<Scalars['String']['output']>;
    awardedDate?: Maybe<BrTimeQl>;
    categories?: Maybe<Array<CategoryQl>>;
    competitionDeadlineDate?: Maybe<BrTimeQl>;
    competitionDeadlineDateEst?: Maybe<Scalars['Boolean']['output']>;
    competitionPhase?: Maybe<Scalars['String']['output']>;
    constructionPhases?: Maybe<Array<PhaseQl>>;
    contacts?: Maybe<Array<ContactQl>>;
    contractAddress?: Maybe<AddressQl>;
    contractAwardWinners?: Maybe<Array<ContactQl>>;
    contractEndDate?: Maybe<BrTimeQl>;
    contractStartDate?: Maybe<BrTimeQl>;
    contractType?: Maybe<Scalars['String']['output']>;
    contractingAuthority?: Maybe<ContactQl>;
    cpvCodes?: Maybe<Array<Scalars['String']['output']>>;
    description?: Maybe<Scalars['String']['output']>;
    descriptions?: Maybe<Array<Scalars['String']['output']>>;
    directive?: Maybe<Scalars['String']['output']>;
    documentFamilyId?: Maybe<Scalars['String']['output']>;
    documentFamilyRefs?: Maybe<Array<Scalars['String']['output']>>;
    documentType?: Maybe<Scalars['String']['output']>;
    frameworkAgreement?: Maybe<Scalars['String']['output']>;
    images?: Maybe<Array<ImageQl>>;
    itemCreatedDate: Scalars['DateTime']['output'];
    itemId: Scalars['String']['output'];
    itemUpdatedDate: Scalars['DateTime']['output'];
    language?: Maybe<Scalars['String']['output']>;
    naicsCodes?: Maybe<Array<Scalars['String']['output']>>;
    openingDate?: Maybe<BrTimeQl>;
    procedureType?: Maybe<Scalars['String']['output']>;
    quantityScope?: Maybe<Scalars['String']['output']>;
    rawDataString?: Maybe<Scalars['String']['output']>;
    referenceNumber?: Maybe<Scalars['String']['output']>;
    sourceDomains?: Maybe<Array<Scalars['String']['output']>>;
    sourcePublishedDate?: Maybe<BrTimeQl>;
    sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
    tenderType?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    titleAlternative?: Maybe<Scalars['String']['output']>;
    titleAlternativeEnglish?: Maybe<Scalars['String']['output']>;
    titleEnglish?: Maybe<Scalars['String']['output']>;
    unspscCodes?: Maybe<Array<Scalars['String']['output']>>;
    volumeReference?: Maybe<Scalars['String']['output']>;
};

export type TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl =
    | ArticleDocumentQl
    | LegacyProjectDocumentQl
    | TenderDocumentQl;

export type TenderRootCodePartFragment = {
    id: string;
    name: string;
    hasChildren: boolean;
    root: boolean;
    children?: Array<
        | {
              id: string;
              name: string;
              hasChildren: boolean;
              nChildren: number;
              leaf: boolean;
              root: boolean;
              procurementSystem: string;
              parent?: string | null;
              children?: Array<
                  | {
                        id: string;
                        name: string;
                        hasChildren: boolean;
                        nChildren: number;
                        leaf: boolean;
                        root: boolean;
                        procurementSystem: string;
                        parent?: string | null;
                    }
                  | {}
              > | null;
          }
        | {}
    > | null;
};

export type TenderChildCodePartFragment = {
    id: string;
    name: string;
    hasChildren: boolean;
    nChildren: number;
    leaf: boolean;
    root: boolean;
    procurementSystem: string;
    parent?: string | null;
    children?: Array<
        | {
              id: string;
              name: string;
              hasChildren: boolean;
              nChildren: number;
              leaf: boolean;
              root: boolean;
              procurementSystem: string;
              parent?: string | null;
          }
        | {}
    > | null;
};

export type ChildPartFragment = {
    id: string;
    name: string;
    hasChildren: boolean;
    nChildren: number;
    leaf: boolean;
    root: boolean;
    procurementSystem: string;
    parent?: string | null;
};

export type NestedTenderCodesFilteredPartFragment = {
    id: string;
    name: string;
    hasChildren: boolean;
    root: boolean;
    children?: Array<
        | {
              id: string;
              name: string;
              hasChildren: boolean;
              nChildren: number;
              leaf: boolean;
              root: boolean;
              procurementSystem: string;
              parent?: string | null;
              children?: Array<
                  | {
                        id: string;
                        name: string;
                        hasChildren: boolean;
                        nChildren: number;
                        leaf: boolean;
                        root: boolean;
                        procurementSystem: string;
                        parent?: string | null;
                        children?: Array<
                            | {
                                  id: string;
                                  name: string;
                                  hasChildren: boolean;
                                  nChildren: number;
                                  leaf: boolean;
                                  root: boolean;
                                  procurementSystem: string;
                                  parent?: string | null;
                                  children?: Array<
                                      | {
                                            id: string;
                                            name: string;
                                            hasChildren: boolean;
                                            nChildren: number;
                                            leaf: boolean;
                                            root: boolean;
                                            procurementSystem: string;
                                            parent?: string | null;
                                            children?: Array<
                                                | {
                                                      id: string;
                                                      name: string;
                                                      hasChildren: boolean;
                                                      nChildren: number;
                                                      leaf: boolean;
                                                      root: boolean;
                                                      procurementSystem: string;
                                                      parent?: string | null;
                                                      children?: Array<
                                                          | {
                                                                id: string;
                                                                name: string;
                                                                hasChildren: boolean;
                                                                nChildren: number;
                                                                leaf: boolean;
                                                                root: boolean;
                                                                procurementSystem: string;
                                                                parent?:
                                                                    | string
                                                                    | null;
                                                                children?: Array<
                                                                    | {
                                                                          id: string;
                                                                          name: string;
                                                                          hasChildren: boolean;
                                                                          nChildren: number;
                                                                          leaf: boolean;
                                                                          root: boolean;
                                                                          procurementSystem: string;
                                                                          parent?:
                                                                              | string
                                                                              | null;
                                                                          children?: Array<
                                                                              | {
                                                                                    id: string;
                                                                                    name: string;
                                                                                    hasChildren: boolean;
                                                                                    nChildren: number;
                                                                                    leaf: boolean;
                                                                                    root: boolean;
                                                                                    procurementSystem: string;
                                                                                    parent?:
                                                                                        | string
                                                                                        | null;
                                                                                    children?: Array<
                                                                                        | {
                                                                                              id: string;
                                                                                              name: string;
                                                                                              hasChildren: boolean;
                                                                                              nChildren: number;
                                                                                              leaf: boolean;
                                                                                              root: boolean;
                                                                                              procurementSystem: string;
                                                                                              parent?:
                                                                                                  | string
                                                                                                  | null;
                                                                                              children?: Array<
                                                                                                  | {
                                                                                                        id: string;
                                                                                                        name: string;
                                                                                                        hasChildren: boolean;
                                                                                                        nChildren: number;
                                                                                                        leaf: boolean;
                                                                                                        root: boolean;
                                                                                                        procurementSystem: string;
                                                                                                        parent?:
                                                                                                            | string
                                                                                                            | null;
                                                                                                    }
                                                                                                  | {}
                                                                                              > | null;
                                                                                          }
                                                                                        | {}
                                                                                    > | null;
                                                                                }
                                                                              | {}
                                                                          > | null;
                                                                      }
                                                                    | {}
                                                                > | null;
                                                            }
                                                          | {}
                                                      > | null;
                                                  }
                                                | {}
                                            > | null;
                                        }
                                      | {}
                                  > | null;
                              }
                            | {}
                        > | null;
                    }
                  | {}
              > | null;
          }
        | {}
    > | null;
};

export type BrDateTimePartFragment = {
    day?: number | null;
    hour?: number | null;
    microsecond?: number | null;
    minute?: number | null;
    month?: number | null;
    precision: BrTimePrecision;
    second?: number | null;
    year: number;
};

export type AddressPartFragment = {
    city?: string | null;
    continent?: string | null;
    country?: string | null;
    countryCode?: string | null;
    houseNumber?: string | null;
    lat?: number | null;
    lng?: number | null;
    postCode?: string | null;
    postCodeEnd?: string | null;
    postCodeIsExact?: boolean | null;
    postCodeStart?: string | null;
    precisionAccuracy?: number | null;
    precisionLevel?: string | null;
    precisionType?: string | null;
    rank?: number | null;
    road?: string | null;
    state?: string | null;
};

export type ContactPartFragment = {
    associatedItemId?: string | null;
    companyName?: string | null;
    companyRoles?: Array<string> | null;
    email?: string | null;
    fax?: string | null;
    homepage?: string | null;
    name?: string | null;
    personRoles?: Array<string> | null;
    phoneLandline?: string | null;
    phoneMobile?: string | null;
    title?: string | null;
};

export type TenderDocumentPartFragment = {
    itemId: string;
    title: string;
    titleAlternative?: string | null;
    titleEnglish?: string | null;
    titleAlternativeEnglish?: string | null;
    volumeReference?: string | null;
    sourceUrls?: Array<string> | null;
    sourceDomains?: Array<string> | null;
    language?: string | null;
    description?: string | null;
    descriptions?: Array<string> | null;
    allLotsAwarded?: boolean | null;
    awardCriteria?: string | null;
    competitionDeadlineDateEst?: boolean | null;
    frameworkAgreement?: string | null;
    competitionPhase?: string | null;
    documentFamilyId?: string | null;
    documentFamilyRefs?: Array<string> | null;
    contractType?: string | null;
    directive?: string | null;
    documentType?: string | null;
    applicationUrl?: string | null;
    procedureType?: string | null;
    quantityScope?: string | null;
    referenceNumber?: string | null;
    tenderType?: string | null;
    cpvCodes?: Array<string> | null;
    naicsCodes?: Array<string> | null;
    unspscCodes?: Array<string> | null;
    rawDataString?: string | null;
    categories?: Array<{
        ranking?: number | null;
        name: string;
        confidenceScore?: number | null;
        isPrecise?: boolean | null;
    }> | null;
    constructionPhases?: Array<{
        ranking?: number | null;
        subPhases?: Array<string> | null;
        name: string;
    }> | null;
    sourcePublishedDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    images?: Array<{
        url: string;
        sourceUrl: string;
        sourceDomain: string;
        sourceTitle?: string | null;
        qualityScore?: number | null;
    }> | null;
    announcedDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contractEndDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contractStartDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    competitionDeadlineDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    openingDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contractAddress?: {
        city?: string | null;
        continent?: string | null;
        country?: string | null;
        countryCode?: string | null;
        houseNumber?: string | null;
        lat?: number | null;
        lng?: number | null;
        postCode?: string | null;
        postCodeEnd?: string | null;
        postCodeIsExact?: boolean | null;
        postCodeStart?: string | null;
        precisionAccuracy?: number | null;
        precisionLevel?: string | null;
        precisionType?: string | null;
        rank?: number | null;
        road?: string | null;
        state?: string | null;
    } | null;
    contacts?: Array<{
        associatedItemId?: string | null;
        companyName?: string | null;
        companyRoles?: Array<string> | null;
        email?: string | null;
        fax?: string | null;
        homepage?: string | null;
        name?: string | null;
        personRoles?: Array<string> | null;
        phoneLandline?: string | null;
        phoneMobile?: string | null;
        title?: string | null;
    }> | null;
    awardedDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
};

export type ArticleDocumentPartFragment = {
    itemId: string;
    title: string;
    titleAlternative?: string | null;
    titleEnglish?: string | null;
    titleAlternativeEnglish?: string | null;
    volumeReference?: string | null;
    sourceUrls?: Array<string> | null;
    sourceDomains?: Array<string> | null;
    language?: string | null;
    description?: string | null;
    descriptions?: Array<string> | null;
    abstract?: string | null;
    paragraphs?: Array<string> | null;
    gfa?: number | null;
    nResidentialUnits?: number | null;
    categories?: Array<{
        ranking?: number | null;
        name: string;
        confidenceScore?: number | null;
        isPrecise?: boolean | null;
    }> | null;
    constructionPhases?: Array<{
        ranking?: number | null;
        subPhases?: Array<string> | null;
        name: string;
    }> | null;
    sourcePublishedDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    images?: Array<{
        url: string;
        sourceUrl: string;
        sourceDomain: string;
        sourceTitle?: string | null;
        qualityScore?: number | null;
    }> | null;
    address?: {
        city?: string | null;
        continent?: string | null;
        country?: string | null;
        countryCode?: string | null;
        houseNumber?: string | null;
        lat?: number | null;
        lng?: number | null;
        postCode?: string | null;
        postCodeEnd?: string | null;
        postCodeIsExact?: boolean | null;
        postCodeStart?: string | null;
        precisionAccuracy?: number | null;
        precisionLevel?: string | null;
        precisionType?: string | null;
        rank?: number | null;
        road?: string | null;
        state?: string | null;
    } | null;
    constructionStartDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    constructionEndDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contacts?: Array<{
        associatedItemId?: string | null;
        companyName?: string | null;
        companyRoles?: Array<string> | null;
        email?: string | null;
        fax?: string | null;
        homepage?: string | null;
        name?: string | null;
        personRoles?: Array<string> | null;
        phoneLandline?: string | null;
        phoneMobile?: string | null;
        title?: string | null;
    }> | null;
};

export type DocumentSearchResultPartFragment = {
    created: any;
    documentIds: Array<string>;
    folder?: FolderTag | null;
    id: string;
    pipeDbCollection: string;
    updated: any;
    mergedDocument:
        | {
              itemId: string;
              title: string;
              titleAlternative?: string | null;
              titleEnglish?: string | null;
              titleAlternativeEnglish?: string | null;
              volumeReference?: string | null;
              sourceUrls?: Array<string> | null;
              sourceDomains?: Array<string> | null;
              language?: string | null;
              description?: string | null;
              descriptions?: Array<string> | null;
              abstract?: string | null;
              paragraphs?: Array<string> | null;
              gfa?: number | null;
              nResidentialUnits?: number | null;
              categories?: Array<{
                  ranking?: number | null;
                  name: string;
                  confidenceScore?: number | null;
                  isPrecise?: boolean | null;
              }> | null;
              constructionPhases?: Array<{
                  ranking?: number | null;
                  subPhases?: Array<string> | null;
                  name: string;
              }> | null;
              sourcePublishedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              images?: Array<{
                  url: string;
                  sourceUrl: string;
                  sourceDomain: string;
                  sourceTitle?: string | null;
                  qualityScore?: number | null;
              }> | null;
              address?: {
                  city?: string | null;
                  continent?: string | null;
                  country?: string | null;
                  countryCode?: string | null;
                  houseNumber?: string | null;
                  lat?: number | null;
                  lng?: number | null;
                  postCode?: string | null;
                  postCodeEnd?: string | null;
                  postCodeIsExact?: boolean | null;
                  postCodeStart?: string | null;
                  precisionAccuracy?: number | null;
                  precisionLevel?: string | null;
                  precisionType?: string | null;
                  rank?: number | null;
                  road?: string | null;
                  state?: string | null;
              } | null;
              constructionStartDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              constructionEndDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contacts?: Array<{
                  associatedItemId?: string | null;
                  companyName?: string | null;
                  companyRoles?: Array<string> | null;
                  email?: string | null;
                  fax?: string | null;
                  homepage?: string | null;
                  name?: string | null;
                  personRoles?: Array<string> | null;
                  phoneLandline?: string | null;
                  phoneMobile?: string | null;
                  title?: string | null;
              }> | null;
          }
        | {
              itemId: string;
              title: string;
              titleAlternative?: string | null;
              titleEnglish?: string | null;
              titleAlternativeEnglish?: string | null;
              volumeReference?: string | null;
              sourceUrls?: Array<string> | null;
              sourceDomains?: Array<string> | null;
              language?: string | null;
              description?: string | null;
              descriptions?: Array<string> | null;
              allLotsAwarded?: boolean | null;
              awardCriteria?: string | null;
              competitionDeadlineDateEst?: boolean | null;
              frameworkAgreement?: string | null;
              competitionPhase?: string | null;
              documentFamilyId?: string | null;
              documentFamilyRefs?: Array<string> | null;
              contractType?: string | null;
              directive?: string | null;
              documentType?: string | null;
              applicationUrl?: string | null;
              procedureType?: string | null;
              quantityScope?: string | null;
              referenceNumber?: string | null;
              tenderType?: string | null;
              cpvCodes?: Array<string> | null;
              naicsCodes?: Array<string> | null;
              unspscCodes?: Array<string> | null;
              rawDataString?: string | null;
              categories?: Array<{
                  ranking?: number | null;
                  name: string;
                  confidenceScore?: number | null;
                  isPrecise?: boolean | null;
              }> | null;
              constructionPhases?: Array<{
                  ranking?: number | null;
                  subPhases?: Array<string> | null;
                  name: string;
              }> | null;
              sourcePublishedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              images?: Array<{
                  url: string;
                  sourceUrl: string;
                  sourceDomain: string;
                  sourceTitle?: string | null;
                  qualityScore?: number | null;
              }> | null;
              announcedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractEndDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractStartDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              competitionDeadlineDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              openingDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractAddress?: {
                  city?: string | null;
                  continent?: string | null;
                  country?: string | null;
                  countryCode?: string | null;
                  houseNumber?: string | null;
                  lat?: number | null;
                  lng?: number | null;
                  postCode?: string | null;
                  postCodeEnd?: string | null;
                  postCodeIsExact?: boolean | null;
                  postCodeStart?: string | null;
                  precisionAccuracy?: number | null;
                  precisionLevel?: string | null;
                  precisionType?: string | null;
                  rank?: number | null;
                  road?: string | null;
                  state?: string | null;
              } | null;
              contacts?: Array<{
                  associatedItemId?: string | null;
                  companyName?: string | null;
                  companyRoles?: Array<string> | null;
                  email?: string | null;
                  fax?: string | null;
                  homepage?: string | null;
                  name?: string | null;
                  personRoles?: Array<string> | null;
                  phoneLandline?: string | null;
                  phoneMobile?: string | null;
                  title?: string | null;
              }> | null;
              awardedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
          }
        | {};
    documents: Array<{ title: string } | { title: string } | {}>;
    prescreenOutput: { accept: boolean; confidence: number };
};

export type SearchAndSubscriptionPartFragment = {
    userId: number;
    itemId: number;
    name: string;
    description?: string | null;
    autoPrescreeningEnabled: boolean;
    autoPrescreeningInboxLimit: number;
    paused: boolean;
    subscriberIds: Array<number>;
    type: SearchType;
    searchData: { filterModules: Array<{ key: number }> };
};

export type SearchSubscribedPartFragment = {
    userId: number;
    itemId: number;
    name: string;
    paused: boolean;
    type: SearchType;
    searchData: {
        filterModules: Array<{ key: number; filter: string; value: string }>;
    };
};

export type GetTenderRootCodesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenderRootCodesQuery = {
    tenderCodeTree: Array<{
        id: string;
        name: string;
        hasChildren: boolean;
        root: boolean;
        children?: Array<
            | {
                  id: string;
                  name: string;
                  hasChildren: boolean;
                  nChildren: number;
                  leaf: boolean;
                  root: boolean;
                  procurementSystem: string;
                  parent?: string | null;
                  children?: Array<
                      | {
                            id: string;
                            name: string;
                            hasChildren: boolean;
                            nChildren: number;
                            leaf: boolean;
                            root: boolean;
                            procurementSystem: string;
                            parent?: string | null;
                        }
                      | {}
                  > | null;
              }
            | {}
        > | null;
    }>;
};

export type GetChildTenderCodesQueryVariables = Exact<{
    tenderCodeId: Scalars['String']['input'];
}>;

export type GetChildTenderCodesQuery = {
    tenderChildCodes: Array<{
        id: string;
        name: string;
        hasChildren: boolean;
        nChildren: number;
        leaf: boolean;
        root: boolean;
        procurementSystem: string;
        parent?: string | null;
        children?: Array<
            | {
                  id: string;
                  name: string;
                  hasChildren: boolean;
                  nChildren: number;
                  leaf: boolean;
                  root: boolean;
                  procurementSystem: string;
                  parent?: string | null;
              }
            | {}
        > | null;
    }>;
};

export type GetFilteredTenderCodesQueryVariables = Exact<{
    textSearch: Scalars['String']['input'];
}>;

export type GetFilteredTenderCodesQuery = {
    tenderCodesFiltered: Array<{
        id: string;
        name: string;
        hasChildren: boolean;
        root: boolean;
        children?: Array<
            | {
                  id: string;
                  name: string;
                  hasChildren: boolean;
                  nChildren: number;
                  leaf: boolean;
                  root: boolean;
                  procurementSystem: string;
                  parent?: string | null;
                  children?: Array<
                      | {
                            id: string;
                            name: string;
                            hasChildren: boolean;
                            nChildren: number;
                            leaf: boolean;
                            root: boolean;
                            procurementSystem: string;
                            parent?: string | null;
                            children?: Array<
                                | {
                                      id: string;
                                      name: string;
                                      hasChildren: boolean;
                                      nChildren: number;
                                      leaf: boolean;
                                      root: boolean;
                                      procurementSystem: string;
                                      parent?: string | null;
                                      children?: Array<
                                          | {
                                                id: string;
                                                name: string;
                                                hasChildren: boolean;
                                                nChildren: number;
                                                leaf: boolean;
                                                root: boolean;
                                                procurementSystem: string;
                                                parent?: string | null;
                                                children?: Array<
                                                    | {
                                                          id: string;
                                                          name: string;
                                                          hasChildren: boolean;
                                                          nChildren: number;
                                                          leaf: boolean;
                                                          root: boolean;
                                                          procurementSystem: string;
                                                          parent?:
                                                              | string
                                                              | null;
                                                          children?: Array<
                                                              | {
                                                                    id: string;
                                                                    name: string;
                                                                    hasChildren: boolean;
                                                                    nChildren: number;
                                                                    leaf: boolean;
                                                                    root: boolean;
                                                                    procurementSystem: string;
                                                                    parent?:
                                                                        | string
                                                                        | null;
                                                                    children?: Array<
                                                                        | {
                                                                              id: string;
                                                                              name: string;
                                                                              hasChildren: boolean;
                                                                              nChildren: number;
                                                                              leaf: boolean;
                                                                              root: boolean;
                                                                              procurementSystem: string;
                                                                              parent?:
                                                                                  | string
                                                                                  | null;
                                                                              children?: Array<
                                                                                  | {
                                                                                        id: string;
                                                                                        name: string;
                                                                                        hasChildren: boolean;
                                                                                        nChildren: number;
                                                                                        leaf: boolean;
                                                                                        root: boolean;
                                                                                        procurementSystem: string;
                                                                                        parent?:
                                                                                            | string
                                                                                            | null;
                                                                                        children?: Array<
                                                                                            | {
                                                                                                  id: string;
                                                                                                  name: string;
                                                                                                  hasChildren: boolean;
                                                                                                  nChildren: number;
                                                                                                  leaf: boolean;
                                                                                                  root: boolean;
                                                                                                  procurementSystem: string;
                                                                                                  parent?:
                                                                                                      | string
                                                                                                      | null;
                                                                                                  children?: Array<
                                                                                                      | {
                                                                                                            id: string;
                                                                                                            name: string;
                                                                                                            hasChildren: boolean;
                                                                                                            nChildren: number;
                                                                                                            leaf: boolean;
                                                                                                            root: boolean;
                                                                                                            procurementSystem: string;
                                                                                                            parent?:
                                                                                                                | string
                                                                                                                | null;
                                                                                                        }
                                                                                                      | {}
                                                                                                  > | null;
                                                                                              }
                                                                                            | {}
                                                                                        > | null;
                                                                                    }
                                                                                  | {}
                                                                              > | null;
                                                                          }
                                                                        | {}
                                                                    > | null;
                                                                }
                                                              | {}
                                                          > | null;
                                                      }
                                                    | {}
                                                > | null;
                                            }
                                          | {}
                                      > | null;
                                  }
                                | {}
                            > | null;
                        }
                      | {}
                  > | null;
              }
            | {}
        > | null;
    }>;
};

export type GetFilterModulesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFilterModulesQuery = {
    filterModules: Array<{
        id: number;
        name: string;
        data: any;
        type: FilterModuleType;
        linkedSearchIds: Array<number>;
        updated: any;
        userId?: number | null;
    }>;
};

export type GetFilterModuleByIdQueryVariables = Exact<{
    id: Scalars['Int']['input'];
}>;

export type GetFilterModuleByIdQuery = {
    filterModules: Array<{
        id: number;
        name: string;
        data: any;
        type: FilterModuleType;
        linkedSearchIds: Array<number>;
        updated: any;
        userId?: number | null;
    }>;
};

export type SearchByFilterModulesWithNarrativeQueryVariables = Exact<{
    searchInput: SearchByFilterModulesInputQl;
    narrative: Scalars['String']['input'];
}>;

export type SearchByFilterModulesWithNarrativeQuery = {
    searchWithPrescreening: {
        totalCount: number;
        docGroups: Array<{
            created: any;
            documentIds: Array<string>;
            folder?: FolderTag | null;
            id: string;
            pipeDbCollection: string;
            updated: any;
            mergedDocument:
                | {
                      itemId: string;
                      title: string;
                      titleAlternative?: string | null;
                      titleEnglish?: string | null;
                      titleAlternativeEnglish?: string | null;
                      volumeReference?: string | null;
                      sourceUrls?: Array<string> | null;
                      sourceDomains?: Array<string> | null;
                      language?: string | null;
                      description?: string | null;
                      descriptions?: Array<string> | null;
                      abstract?: string | null;
                      paragraphs?: Array<string> | null;
                      gfa?: number | null;
                      nResidentialUnits?: number | null;
                      categories?: Array<{
                          ranking?: number | null;
                          name: string;
                          confidenceScore?: number | null;
                          isPrecise?: boolean | null;
                      }> | null;
                      constructionPhases?: Array<{
                          ranking?: number | null;
                          subPhases?: Array<string> | null;
                          name: string;
                      }> | null;
                      sourcePublishedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      images?: Array<{
                          url: string;
                          sourceUrl: string;
                          sourceDomain: string;
                          sourceTitle?: string | null;
                          qualityScore?: number | null;
                      }> | null;
                      address?: {
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                      constructionStartDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      constructionEndDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contacts?: Array<{
                          associatedItemId?: string | null;
                          companyName?: string | null;
                          companyRoles?: Array<string> | null;
                          email?: string | null;
                          fax?: string | null;
                          homepage?: string | null;
                          name?: string | null;
                          personRoles?: Array<string> | null;
                          phoneLandline?: string | null;
                          phoneMobile?: string | null;
                          title?: string | null;
                      }> | null;
                  }
                | {
                      itemId: string;
                      title: string;
                      titleAlternative?: string | null;
                      titleEnglish?: string | null;
                      titleAlternativeEnglish?: string | null;
                      volumeReference?: string | null;
                      sourceUrls?: Array<string> | null;
                      sourceDomains?: Array<string> | null;
                      language?: string | null;
                      description?: string | null;
                      descriptions?: Array<string> | null;
                      allLotsAwarded?: boolean | null;
                      awardCriteria?: string | null;
                      competitionDeadlineDateEst?: boolean | null;
                      frameworkAgreement?: string | null;
                      competitionPhase?: string | null;
                      documentFamilyId?: string | null;
                      documentFamilyRefs?: Array<string> | null;
                      contractType?: string | null;
                      directive?: string | null;
                      documentType?: string | null;
                      applicationUrl?: string | null;
                      procedureType?: string | null;
                      quantityScope?: string | null;
                      referenceNumber?: string | null;
                      tenderType?: string | null;
                      cpvCodes?: Array<string> | null;
                      naicsCodes?: Array<string> | null;
                      unspscCodes?: Array<string> | null;
                      rawDataString?: string | null;
                      categories?: Array<{
                          ranking?: number | null;
                          name: string;
                          confidenceScore?: number | null;
                          isPrecise?: boolean | null;
                      }> | null;
                      constructionPhases?: Array<{
                          ranking?: number | null;
                          subPhases?: Array<string> | null;
                          name: string;
                      }> | null;
                      sourcePublishedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      images?: Array<{
                          url: string;
                          sourceUrl: string;
                          sourceDomain: string;
                          sourceTitle?: string | null;
                          qualityScore?: number | null;
                      }> | null;
                      announcedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractEndDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractStartDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      competitionDeadlineDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      openingDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractAddress?: {
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                      contacts?: Array<{
                          associatedItemId?: string | null;
                          companyName?: string | null;
                          companyRoles?: Array<string> | null;
                          email?: string | null;
                          fax?: string | null;
                          homepage?: string | null;
                          name?: string | null;
                          personRoles?: Array<string> | null;
                          phoneLandline?: string | null;
                          phoneMobile?: string | null;
                          title?: string | null;
                      }> | null;
                      awardedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                  }
                | {};
            documents: Array<{ title: string } | { title: string } | {}>;
            prescreenOutput: { accept: boolean; confidence: number };
        }>;
    };
};

export type SearchCountByFilterModulesQueryVariables = Exact<{
    searchInput: CountSearchByFilterModulesInputQl;
}>;

export type SearchCountByFilterModulesQuery = {
    countSearchByFilterModules: { totalCount: number };
};

export type GetLocationSuggestionsQueryVariables = Exact<{
    partialText: Scalars['String']['input'];
}>;

export type GetLocationSuggestionsQuery = {
    locationSuggestions: Array<{
        value: string;
        key: string;
        type: number;
        countryCode: string;
        population: number;
        twoFishesId: string;
    }>;
};

export type GetSourceDomainSuggestionsQueryVariables = Exact<{
    partialText: Scalars['String']['input'];
}>;

export type GetSourceDomainSuggestionsQuery = {
    autocompleteSourceDomains: Array<string>;
};

export type GetSearchesAndSubscriptionsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetSearchesAndSubscriptionsQuery = {
    searchesAndSubscriptions: Array<{
        userId: number;
        itemId: number;
        name: string;
        description?: string | null;
        autoPrescreeningEnabled: boolean;
        autoPrescreeningInboxLimit: number;
        paused: boolean;
        subscriberIds: Array<number>;
        type: SearchType;
        searchData: { filterModules: Array<{ key: number }> };
    }>;
};

export type CreateFilterModuleMutationVariables = Exact<{
    name: Scalars['String']['input'];
    data: Scalars['JSON']['input'];
    type: FilterModuleType;
}>;

export type CreateFilterModuleMutation = {
    createFilterModule: {
        id: number;
        name: string;
        data: any;
        type: FilterModuleType;
        linkedSearchIds: Array<number>;
        updated: any;
        userId?: number | null;
    };
};

export type UpdateFilterModuleMutationVariables = Exact<{
    id: Scalars['Int']['input'];
    name: Scalars['String']['input'];
    data: Scalars['JSON']['input'];
}>;

export type UpdateFilterModuleMutation = {
    updateFilterModule: { ok: boolean };
};

export type DeleteFilterModuleMutationVariables = Exact<{
    id: Scalars['Int']['input'];
}>;

export type DeleteFilterModuleMutation = {
    deleteFilterModule: { ok: boolean };
};

export type DeleteSearchMutationVariables = Exact<{
    id: Scalars['Int']['input'];
}>;

export type DeleteSearchMutation = { deleteSearch: { ok: boolean } };

export type UpsertSearchMutationVariables = Exact<{
    id?: InputMaybe<Scalars['Int']['input']>;
    name: Scalars['String']['input'];
    type: SearchType;
    filterModuleIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type UpsertSearchMutation = { upsertSearch: { id: number } };

export type SubscribeToSearchMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
    subscriberId: Scalars['Int']['input'];
}>;

export type SubscribeToSearchMutation = {
    subscribeSearch: {
        userId: number;
        itemId: number;
        name: string;
        paused: boolean;
        type: SearchType;
        searchData: {
            filterModules: Array<{
                key: number;
                filter: string;
                value: string;
            }>;
        };
    };
};

export type UnsubscribeToSearchMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
    subscriberId: Scalars['Int']['input'];
}>;

export type UnsubscribeToSearchMutation = {
    unsubscribeSearch: { ok: boolean };
};

export type PauseSearchMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
}>;

export type PauseSearchMutation = { pauseSearch: { ok: boolean } };

export type UnpauseSearchMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
}>;

export type UnpauseSearchMutation = { unpauseSearch: { ok: boolean } };

export type UpdateSearchPrescreeningSettingsMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
    autoPrescreeningEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    autoPrescreeningInboxLimit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UpdateSearchPrescreeningSettingsMutation = {
    updateSearchPrescreeningSettings: { ok: boolean };
};

export type GetTenderTextFromCodesQueryVariables = Exact<{
    cpvCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
    naicsCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
    unspscCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetTenderTextFromCodesQuery = {
    tenderTextFromCodes: Array<string>;
};

export const TenderChildCodePartFragmentDoc = gql`
    fragment TenderChildCodePart on TenderCodeNodeQL {
        id
        name
        hasChildren
        nChildren
        leaf
        root
        procurementSystem
        parent
        children {
            ... on TenderCodeNodeQL {
                id
                name
                hasChildren
                nChildren
                leaf
                root
                procurementSystem
                parent
            }
        }
    }
`;
export const TenderRootCodePartFragmentDoc = gql`
    fragment TenderRootCodePart on TenderCodeRootNodeResultQL {
        id
        name
        hasChildren
        root
        children {
            ...TenderChildCodePart
        }
    }
    ${TenderChildCodePartFragmentDoc}
`;
export const ChildPartFragmentDoc = gql`
    fragment ChildPart on TenderCodeNodeQL {
        id
        name
        hasChildren
        nChildren
        leaf
        root
        procurementSystem
        parent
    }
`;
export const NestedTenderCodesFilteredPartFragmentDoc = gql`
    fragment NestedTenderCodesFilteredPart on TenderCodeRootNodeResultQL {
        id
        name
        hasChildren
        root
        children {
            ... on TenderCodeNodeQL {
                ...ChildPart
                children {
                    ... on TenderCodeNodeQL {
                        ...ChildPart
                        children {
                            ... on TenderCodeNodeQL {
                                ...ChildPart
                                children {
                                    ... on TenderCodeNodeQL {
                                        ...ChildPart
                                        children {
                                            ... on TenderCodeNodeQL {
                                                ...ChildPart
                                                children {
                                                    ... on TenderCodeNodeQL {
                                                        ...ChildPart
                                                        children {
                                                            ... on TenderCodeNodeQL {
                                                                ...ChildPart
                                                                children {
                                                                    ... on TenderCodeNodeQL {
                                                                        ...ChildPart
                                                                        children {
                                                                            ... on TenderCodeNodeQL {
                                                                                ...ChildPart
                                                                                children {
                                                                                    ... on TenderCodeNodeQL {
                                                                                        ...ChildPart
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${ChildPartFragmentDoc}
`;
export const BrDateTimePartFragmentDoc = gql`
    fragment BrDateTimePart on BRTimeQL {
        day
        hour
        microsecond
        minute
        month
        precision
        second
        year
    }
`;
export const AddressPartFragmentDoc = gql`
    fragment AddressPart on AddressQL {
        city
        continent
        country
        countryCode
        houseNumber
        lat
        lng
        postCode
        postCodeEnd
        postCodeIsExact
        postCodeStart
        precisionAccuracy
        precisionLevel
        precisionType
        rank
        road
        state
    }
`;
export const ContactPartFragmentDoc = gql`
    fragment ContactPart on ContactQL {
        associatedItemId
        companyName
        companyRoles
        email
        fax
        homepage
        name
        personRoles
        phoneLandline
        phoneMobile
        title
    }
`;
export const TenderDocumentPartFragmentDoc = gql`
    fragment TenderDocumentPart on TenderDocumentQL {
        itemId
        title
        titleAlternative
        titleEnglish
        titleAlternativeEnglish
        categories {
            ranking
            name
            confidenceScore
            isPrecise
        }
        constructionPhases {
            ranking
            subPhases
            name
        }
        volumeReference
        sourceUrls
        sourceDomains
        language
        description
        descriptions
        sourcePublishedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        images {
            url
            sourceUrl
            sourceDomain
            sourceTitle
            qualityScore
        }
        allLotsAwarded
        announcedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        awardCriteria
        contractEndDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        contractStartDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        competitionDeadlineDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        competitionDeadlineDateEst
        frameworkAgreement
        openingDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        competitionPhase
        documentFamilyId
        documentFamilyRefs
        contractType
        directive
        documentType
        applicationUrl
        contractAddress {
            ... on AddressQL {
                ...AddressPart
            }
        }
        contacts {
            ... on ContactQL {
                ...ContactPart
            }
        }
        procedureType
        quantityScope
        referenceNumber
        tenderType
        awardedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        cpvCodes
        naicsCodes
        unspscCodes
        rawDataString
    }
    ${BrDateTimePartFragmentDoc}
    ${AddressPartFragmentDoc}
    ${ContactPartFragmentDoc}
`;
export const ArticleDocumentPartFragmentDoc = gql`
    fragment ArticleDocumentPart on ArticleDocumentQL {
        itemId
        title
        titleAlternative
        titleEnglish
        titleAlternativeEnglish
        categories {
            ranking
            name
            confidenceScore
            isPrecise
        }
        constructionPhases {
            ranking
            subPhases
            name
        }
        volumeReference
        sourceUrls
        sourceDomains
        language
        description
        descriptions
        sourcePublishedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        images {
            url
            sourceUrl
            sourceDomain
            sourceTitle
            qualityScore
        }
        address {
            ... on AddressQL {
                ...AddressPart
            }
        }
        abstract
        paragraphs
        constructionStartDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        constructionEndDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        gfa
        nResidentialUnits
        contacts {
            ... on ContactQL {
                ...ContactPart
            }
        }
    }
    ${BrDateTimePartFragmentDoc}
    ${AddressPartFragmentDoc}
    ${ContactPartFragmentDoc}
`;
export const DocumentSearchResultPartFragmentDoc = gql`
    fragment DocumentSearchResultPart on PrescreeningDocGroupWithFolderTagQL {
        created
        documentIds
        folder
        id
        mergedDocument {
            ... on TenderDocumentQL {
                ...TenderDocumentPart
            }
            ... on ArticleDocumentQL {
                ...ArticleDocumentPart
            }
        }
        documents {
            ... on TenderDocumentQL {
                title
            }
            ... on ArticleDocumentQL {
                title
            }
        }
        pipeDbCollection
        updated
        prescreenOutput {
            accept
            confidence
        }
    }
    ${TenderDocumentPartFragmentDoc}
    ${ArticleDocumentPartFragmentDoc}
`;
export const SearchAndSubscriptionPartFragmentDoc = gql`
    fragment SearchAndSubscriptionPart on SearchAndSubscribersQL {
        userId
        itemId
        name
        description
        autoPrescreeningEnabled
        autoPrescreeningInboxLimit
        paused
        subscriberIds
        type
        searchData {
            filterModules {
                key
            }
        }
    }
`;
export const SearchSubscribedPartFragmentDoc = gql`
    fragment SearchSubscribedPart on SearchQL {
        userId
        itemId
        name
        paused
        type
        searchData {
            filterModules {
                key
                filter
                value
            }
        }
    }
`;
export const GetTenderRootCodesDocument = gql`
    query GetTenderRootCodes {
        tenderCodeTree {
            ...TenderRootCodePart
        }
    }
    ${TenderRootCodePartFragmentDoc}
`;
export const GetChildTenderCodesDocument = gql`
    query GetChildTenderCodes($tenderCodeId: String!) {
        tenderChildCodes(tenderCodeId: $tenderCodeId) {
            ...TenderChildCodePart
        }
    }
    ${TenderChildCodePartFragmentDoc}
`;
export const GetFilteredTenderCodesDocument = gql`
    query GetFilteredTenderCodes($textSearch: String!) {
        tenderCodesFiltered(textSearch: $textSearch) {
            ...NestedTenderCodesFilteredPart
        }
    }
    ${NestedTenderCodesFilteredPartFragmentDoc}
`;
export const GetFilterModulesDocument = gql`
    query GetFilterModules {
        filterModules {
            id
            name
            data
            type
            linkedSearchIds
            updated
            userId
        }
    }
`;
export const GetFilterModuleByIdDocument = gql`
    query GetFilterModuleById($id: Int!) {
        filterModules(filterModuleId: $id) {
            id
            name
            data
            type
            linkedSearchIds
            updated
            userId
        }
    }
`;
export const SearchByFilterModulesWithNarrativeDocument = gql`
    query SearchByFilterModulesWithNarrative(
        $searchInput: SearchByFilterModulesInputQL!
        $narrative: String!
    ) {
        searchWithPrescreening(
            searchInput: $searchInput
            narrative: $narrative
        ) {
            totalCount
            docGroups {
                ...DocumentSearchResultPart
            }
        }
    }
    ${DocumentSearchResultPartFragmentDoc}
`;
export const SearchCountByFilterModulesDocument = gql`
    query SearchCountByFilterModules(
        $searchInput: CountSearchByFilterModulesInputQL!
    ) {
        countSearchByFilterModules(searchInput: $searchInput) {
            totalCount
        }
    }
`;
export const GetLocationSuggestionsDocument = gql`
    query GetLocationSuggestions($partialText: String!) {
        locationSuggestions(partialString: $partialText) {
            value
            key
            type
            countryCode
            population
            twoFishesId
        }
    }
`;
export const GetSourceDomainSuggestionsDocument = gql`
    query GetSourceDomainSuggestions($partialText: String!) {
        autocompleteSourceDomains(partialString: $partialText)
    }
`;
export const GetSearchesAndSubscriptionsDocument = gql`
    query GetSearchesAndSubscriptions {
        searchesAndSubscriptions {
            ...SearchAndSubscriptionPart
        }
    }
    ${SearchAndSubscriptionPartFragmentDoc}
`;
export const CreateFilterModuleDocument = gql`
    mutation CreateFilterModule(
        $name: String!
        $data: JSON!
        $type: FilterModuleType!
    ) {
        createFilterModule(
            name: $name
            data: $data
            filterModuleType: $type
            notReusable: false
        ) {
            id
            name
            data
            type
            linkedSearchIds
            updated
            userId
        }
    }
`;
export const UpdateFilterModuleDocument = gql`
    mutation UpdateFilterModule($id: Int!, $name: String!, $data: JSON!) {
        updateFilterModule(
            filterModuleId: $id
            name: $name
            data: $data
            notReusable: false
        ) {
            ok
        }
    }
`;
export const DeleteFilterModuleDocument = gql`
    mutation DeleteFilterModule($id: Int!) {
        deleteFilterModule(filterModuleId: $id) {
            ok
        }
    }
`;
export const DeleteSearchDocument = gql`
    mutation DeleteSearch($id: Int!) {
        deleteSearch(searchId: $id) {
            ok
        }
    }
`;
export const UpsertSearchDocument = gql`
    mutation UpsertSearch(
        $id: Int
        $name: String!
        $type: SearchType!
        $filterModuleIds: [Int!]!
    ) {
        upsertSearch(
            searchId: $id
            searchName: $name
            searchType: $type
            filterModuleIds: $filterModuleIds
        ) {
            id
        }
    }
`;
export const SubscribeToSearchDocument = gql`
    mutation SubscribeToSearch($searchId: Int!, $subscriberId: Int!) {
        subscribeSearch(searchId: $searchId, subscriberId: $subscriberId) {
            ...SearchSubscribedPart
        }
    }
    ${SearchSubscribedPartFragmentDoc}
`;
export const UnsubscribeToSearchDocument = gql`
    mutation UnsubscribeToSearch($searchId: Int!, $subscriberId: Int!) {
        unsubscribeSearch(searchId: $searchId, subscriberId: $subscriberId) {
            ok
        }
    }
`;
export const PauseSearchDocument = gql`
    mutation PauseSearch($searchId: Int!) {
        pauseSearch(searchId: $searchId) {
            ok
        }
    }
`;
export const UnpauseSearchDocument = gql`
    mutation UnpauseSearch($searchId: Int!) {
        unpauseSearch(searchId: $searchId) {
            ok
        }
    }
`;
export const UpdateSearchPrescreeningSettingsDocument = gql`
    mutation updateSearchPrescreeningSettings(
        $searchId: Int!
        $description: String = null
        $autoPrescreeningEnabled: Boolean = null
        $autoPrescreeningInboxLimit: Int = null
    ) {
        updateSearchPrescreeningSettings(
            searchId: $searchId
            description: $description
            autoPrescreeningEnabled: $autoPrescreeningEnabled
            autoPrescreeningInboxLimit: $autoPrescreeningInboxLimit
        ) {
            ok
        }
    }
`;
export const GetTenderTextFromCodesDocument = gql`
    query GetTenderTextFromCodes(
        $cpvCodes: [String!]!
        $naicsCodes: [String!]!
        $unspscCodes: [String!]!
    ) {
        tenderTextFromCodes(
            cpvCodes: $cpvCodes
            naicsCodes: $naicsCodes
            unspscCodes: $unspscCodes
        )
    }
`;
export type Requester<C = {}> = <R, V>(
    doc: DocumentNode,
    vars?: V,
    options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        GetTenderRootCodes(
            variables?: GetTenderRootCodesQueryVariables,
            options?: C,
        ): Promise<GetTenderRootCodesQuery> {
            return requester<
                GetTenderRootCodesQuery,
                GetTenderRootCodesQueryVariables
            >(
                GetTenderRootCodesDocument,
                variables,
                options,
            ) as Promise<GetTenderRootCodesQuery>;
        },
        GetChildTenderCodes(
            variables: GetChildTenderCodesQueryVariables,
            options?: C,
        ): Promise<GetChildTenderCodesQuery> {
            return requester<
                GetChildTenderCodesQuery,
                GetChildTenderCodesQueryVariables
            >(
                GetChildTenderCodesDocument,
                variables,
                options,
            ) as Promise<GetChildTenderCodesQuery>;
        },
        GetFilteredTenderCodes(
            variables: GetFilteredTenderCodesQueryVariables,
            options?: C,
        ): Promise<GetFilteredTenderCodesQuery> {
            return requester<
                GetFilteredTenderCodesQuery,
                GetFilteredTenderCodesQueryVariables
            >(
                GetFilteredTenderCodesDocument,
                variables,
                options,
            ) as Promise<GetFilteredTenderCodesQuery>;
        },
        GetFilterModules(
            variables?: GetFilterModulesQueryVariables,
            options?: C,
        ): Promise<GetFilterModulesQuery> {
            return requester<
                GetFilterModulesQuery,
                GetFilterModulesQueryVariables
            >(
                GetFilterModulesDocument,
                variables,
                options,
            ) as Promise<GetFilterModulesQuery>;
        },
        GetFilterModuleById(
            variables: GetFilterModuleByIdQueryVariables,
            options?: C,
        ): Promise<GetFilterModuleByIdQuery> {
            return requester<
                GetFilterModuleByIdQuery,
                GetFilterModuleByIdQueryVariables
            >(
                GetFilterModuleByIdDocument,
                variables,
                options,
            ) as Promise<GetFilterModuleByIdQuery>;
        },
        SearchByFilterModulesWithNarrative(
            variables: SearchByFilterModulesWithNarrativeQueryVariables,
            options?: C,
        ): Promise<SearchByFilterModulesWithNarrativeQuery> {
            return requester<
                SearchByFilterModulesWithNarrativeQuery,
                SearchByFilterModulesWithNarrativeQueryVariables
            >(
                SearchByFilterModulesWithNarrativeDocument,
                variables,
                options,
            ) as Promise<SearchByFilterModulesWithNarrativeQuery>;
        },
        SearchCountByFilterModules(
            variables: SearchCountByFilterModulesQueryVariables,
            options?: C,
        ): Promise<SearchCountByFilterModulesQuery> {
            return requester<
                SearchCountByFilterModulesQuery,
                SearchCountByFilterModulesQueryVariables
            >(
                SearchCountByFilterModulesDocument,
                variables,
                options,
            ) as Promise<SearchCountByFilterModulesQuery>;
        },
        GetLocationSuggestions(
            variables: GetLocationSuggestionsQueryVariables,
            options?: C,
        ): Promise<GetLocationSuggestionsQuery> {
            return requester<
                GetLocationSuggestionsQuery,
                GetLocationSuggestionsQueryVariables
            >(
                GetLocationSuggestionsDocument,
                variables,
                options,
            ) as Promise<GetLocationSuggestionsQuery>;
        },
        GetSourceDomainSuggestions(
            variables: GetSourceDomainSuggestionsQueryVariables,
            options?: C,
        ): Promise<GetSourceDomainSuggestionsQuery> {
            return requester<
                GetSourceDomainSuggestionsQuery,
                GetSourceDomainSuggestionsQueryVariables
            >(
                GetSourceDomainSuggestionsDocument,
                variables,
                options,
            ) as Promise<GetSourceDomainSuggestionsQuery>;
        },
        GetSearchesAndSubscriptions(
            variables?: GetSearchesAndSubscriptionsQueryVariables,
            options?: C,
        ): Promise<GetSearchesAndSubscriptionsQuery> {
            return requester<
                GetSearchesAndSubscriptionsQuery,
                GetSearchesAndSubscriptionsQueryVariables
            >(
                GetSearchesAndSubscriptionsDocument,
                variables,
                options,
            ) as Promise<GetSearchesAndSubscriptionsQuery>;
        },
        CreateFilterModule(
            variables: CreateFilterModuleMutationVariables,
            options?: C,
        ): Promise<CreateFilterModuleMutation> {
            return requester<
                CreateFilterModuleMutation,
                CreateFilterModuleMutationVariables
            >(
                CreateFilterModuleDocument,
                variables,
                options,
            ) as Promise<CreateFilterModuleMutation>;
        },
        UpdateFilterModule(
            variables: UpdateFilterModuleMutationVariables,
            options?: C,
        ): Promise<UpdateFilterModuleMutation> {
            return requester<
                UpdateFilterModuleMutation,
                UpdateFilterModuleMutationVariables
            >(
                UpdateFilterModuleDocument,
                variables,
                options,
            ) as Promise<UpdateFilterModuleMutation>;
        },
        DeleteFilterModule(
            variables: DeleteFilterModuleMutationVariables,
            options?: C,
        ): Promise<DeleteFilterModuleMutation> {
            return requester<
                DeleteFilterModuleMutation,
                DeleteFilterModuleMutationVariables
            >(
                DeleteFilterModuleDocument,
                variables,
                options,
            ) as Promise<DeleteFilterModuleMutation>;
        },
        DeleteSearch(
            variables: DeleteSearchMutationVariables,
            options?: C,
        ): Promise<DeleteSearchMutation> {
            return requester<
                DeleteSearchMutation,
                DeleteSearchMutationVariables
            >(
                DeleteSearchDocument,
                variables,
                options,
            ) as Promise<DeleteSearchMutation>;
        },
        UpsertSearch(
            variables: UpsertSearchMutationVariables,
            options?: C,
        ): Promise<UpsertSearchMutation> {
            return requester<
                UpsertSearchMutation,
                UpsertSearchMutationVariables
            >(
                UpsertSearchDocument,
                variables,
                options,
            ) as Promise<UpsertSearchMutation>;
        },
        SubscribeToSearch(
            variables: SubscribeToSearchMutationVariables,
            options?: C,
        ): Promise<SubscribeToSearchMutation> {
            return requester<
                SubscribeToSearchMutation,
                SubscribeToSearchMutationVariables
            >(
                SubscribeToSearchDocument,
                variables,
                options,
            ) as Promise<SubscribeToSearchMutation>;
        },
        UnsubscribeToSearch(
            variables: UnsubscribeToSearchMutationVariables,
            options?: C,
        ): Promise<UnsubscribeToSearchMutation> {
            return requester<
                UnsubscribeToSearchMutation,
                UnsubscribeToSearchMutationVariables
            >(
                UnsubscribeToSearchDocument,
                variables,
                options,
            ) as Promise<UnsubscribeToSearchMutation>;
        },
        PauseSearch(
            variables: PauseSearchMutationVariables,
            options?: C,
        ): Promise<PauseSearchMutation> {
            return requester<PauseSearchMutation, PauseSearchMutationVariables>(
                PauseSearchDocument,
                variables,
                options,
            ) as Promise<PauseSearchMutation>;
        },
        UnpauseSearch(
            variables: UnpauseSearchMutationVariables,
            options?: C,
        ): Promise<UnpauseSearchMutation> {
            return requester<
                UnpauseSearchMutation,
                UnpauseSearchMutationVariables
            >(
                UnpauseSearchDocument,
                variables,
                options,
            ) as Promise<UnpauseSearchMutation>;
        },
        updateSearchPrescreeningSettings(
            variables: UpdateSearchPrescreeningSettingsMutationVariables,
            options?: C,
        ): Promise<UpdateSearchPrescreeningSettingsMutation> {
            return requester<
                UpdateSearchPrescreeningSettingsMutation,
                UpdateSearchPrescreeningSettingsMutationVariables
            >(
                UpdateSearchPrescreeningSettingsDocument,
                variables,
                options,
            ) as Promise<UpdateSearchPrescreeningSettingsMutation>;
        },
        GetTenderTextFromCodes(
            variables: GetTenderTextFromCodesQueryVariables,
            options?: C,
        ): Promise<GetTenderTextFromCodesQuery> {
            return requester<
                GetTenderTextFromCodesQuery,
                GetTenderTextFromCodesQueryVariables
            >(
                GetTenderTextFromCodesDocument,
                variables,
                options,
            ) as Promise<GetTenderTextFromCodesQuery>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
