import { type FC } from 'react';
import { observer } from 'mobx-react';
import { multiple } from 'src/utils/array.utils';
import { type Reminder } from 'src/domain/models/reminder/reminder.model';
import { ReminderCardContainer } from '../reminder-card/reminder-card.container';
import { NotificationPlaceholderComponent } from '../../shared/notification-placeholder/notification-placeholder.component';

interface ReminderListSectionProps {
    title: string;
    fetchingReminders: boolean;
    reminders: Reminder[];
}

export const ReminderListSection: FC<ReminderListSectionProps> = observer(
    ({ title, fetchingReminders, reminders }) => {
        const shouldDisplaySectionTitle =
            fetchingReminders || (!fetchingReminders && !!reminders.length);

        return (
            <div className="flex flex-col gap-2">
                {shouldDisplaySectionTitle && (
                    <h3 className="text-sm font-bold tracking-wider text-gray-500">
                        {title}
                    </h3>
                )}
                <ul className="flex list-none flex-col gap-2">
                    {fetchingReminders
                        ? multiple(
                              (index: number) => (
                                  <NotificationPlaceholderComponent
                                      key={index}
                                  />
                              ),
                              2,
                          )
                        : reminders.map((reminder) => (
                              <ReminderCardContainer
                                  key={reminder.id}
                                  reminder={reminder}
                              />
                          ))}
                </ul>
            </div>
        );
    },
);
