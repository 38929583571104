import { withFeatures } from 'src/utils/component.utils';
import { MentionComponent } from './mention.component';

export const MentionContainer = withFeatures(MentionComponent)((f) => ({
    user: f.authFeature.user,
    toggleNotificationsOpened: f.notificationsFeature.toggleNotificationsOpened,
    toggleMobileMenu: f.navbarFeature.toggleMobileMenu,
    markMentionAsRead: f.notificationsFeature.markMentionAsRead,
    dismissMention: f.notificationsFeature.dismissMention,
}));
