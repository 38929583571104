import { makeAutoObservable } from 'mobx';

import {
    createFolderCounters,
    FolderCounters,
} from 'src/domain/models/folder/folder.model';

export interface FolderCountersStore {
    folderCounters: FolderCounters;
    isLoading: boolean;
    setLoading: (loading: boolean) => void;
    setFolderCounters: (counters: FolderCounters) => void;
}

export class FolderCountersStoreImpl implements FolderCountersStore {
    folderCounters = createFolderCounters({});
    isLoading = false;

    setFolderCounters = (counters: FolderCounters) => {
        this.folderCounters = counters;
    };

    setLoading = (loading: boolean) => {
        this.isLoading = loading;
    };

    constructor() {
        makeAutoObservable(this);
    }
}
