import { QueryClient } from '@tanstack/react-query';

class _QueryClientService {
    constructor() {
        this._queryClient = new QueryClient({
            defaultOptions: {
                queries: {
                    staleTime: this._QUERY_STALE_TIME_MS,
                    gcTime: this._GC_TIME_MS,
                },
            },
        });
    }

    // How long the cached data will be used without making a new request.
    private readonly _QUERY_STALE_TIME_MS = 300000; // 5 minutes.

    // How long the cached data will be used instead of showing a loader when a new request is made.
    private readonly _GC_TIME_MS = 600000; // 10 minutes.

    private readonly _queryClient: QueryClient;

    get queryClient() {
        return this._queryClient;
    }
}

export const QueryClientService = new _QueryClientService();
