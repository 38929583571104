import { makeAutoObservable } from 'mobx';
import {
    DealContactType,
    PartialDealContactType,
    TextInputStatus,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { IDealContactsPresentationStore } from 'src/app-features/contact/data/stores/interfaces/deal-contacts-presentation.store.interface';
import { IDealContactsStore } from 'src/app-features/contact/data/stores/interfaces/deal-contacts.store.interface';
import {
    ContactFormFlyoutContext,
    IDealContactsFormFeature,
} from 'src/app-features/contact/domain/interfaces/deal-contacts-form.feature.interface';

export class DealContactsFormFeature implements IDealContactsFormFeature {
    dealId?: string;
    defaultContact?: PartialDealContactType;
    focusField?: string;

    constructor(
        private dealContactsStore: IDealContactsStore,
        private dealContactsPresentationStore: IDealContactsPresentationStore,
    ) {
        makeAutoObservable(this);
    }

    get fieldProgress(): Map<string, TextInputStatus> {
        return this.dealContactsPresentationStore.fieldProgress;
    }

    get initialContact(): PartialDealContactType | undefined {
        if (!this.defaultContact?.id) {
            return this.defaultContact;
        } else {
            return this.defaultContact.type === DealContactType.person
                ? this.dealContactsStore.personsMap.get(this.defaultContact.id)
                : this.dealContactsStore.companiesMap.get(
                      this.defaultContact.id,
                  );
        }
    }

    closeFlyout = () => {
        this.dealId = undefined;
        this.defaultContact = undefined;
        this.focusField = undefined;
    };

    openFlyout = (data: ContactFormFlyoutContext) => {
        this.dealId = data.dealId;
        this.defaultContact = data.initialContact;
        this.focusField = data.focusField;
    };
}
