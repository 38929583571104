import { type FC, type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { motion } from 'motion/react';
import { useMediaQuery } from '@mui/material';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { MobileMenuSwiperContainer } from 'src/presentation/modules/mobile-menu/mobile-menu-swiper.container';
import { NotificationsFlyoutContainer } from 'src/presentation/modules/notifications-flyout/notifications-flyout.container';
import { PrimaryNavbarComponent } from 'src/presentation/modules/primary-nav-bar/primary-nav-bar.component';
import { ContentWrapperStyled } from './session-layout.styled';

export const SessionLayout: FC<{ children?: ReactNode }> = observer(
    ({ children }) => {
        const isTablet = useMediaQuery(MediaQuery.tablet);
        const path = useLocation().pathname;
        const hasSecondaryNavbar = path.startsWith(AppRoute.pipelines);

        return (
            <div className="flex w-full bg-gray-100">
                {isTablet ? (
                    <MobileMenuSwiperContainer />
                ) : (
                    <>
                        <PrimaryNavbarComponent />
                        <NotificationsFlyoutContainer />
                    </>
                )}
                <ContentWrapperStyled hasSecondaryNavbar={hasSecondaryNavbar}>
                    <motion.div
                        key={path}
                        className="flex h-full w-full"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        {children}
                    </motion.div>
                </ContentWrapperStyled>
            </div>
        );
    },
);
