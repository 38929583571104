import { isLocalDevelopment } from 'src/utils/is-local-development.utils';

export const processUrl = '/apps/process/graphql';
export const processMultipartUrl = '/apps/process/graphql_multipart';
export const projectUrl = '/apps/project/graphql';
export const userUrl = '/apps/user/graphql';
export const searchUrl = '/apps/search/graphql';

const platform = import.meta.env.VITE_API_PLATFORM || 'beta1';

export const getGraphqlUrl = (url: string) =>
    `${
        isLocalDevelopment() ? 'http' : 'https'
    }://${window.location.host}${url}`;

export const getWsUrl = (url: string) =>
    isLocalDevelopment()
        ? `wss://${platform}.buildingradar.com${url}`
        : `wss://${window.location.host}${url}`;
