import {
    GoogleEnrichmentOption,
    GoogleEnrichmentResultKey,
} from 'src/app-features/google-enrichment/data/google-enrichment.model';
import { isNotEmpty } from 'src/utils/string.utils';
export const getValidItemsLength = (
    options: GoogleEnrichmentOption[],
    field: GoogleEnrichmentResultKey,
) => {
    return options.filter((op) => isNotEmpty(op[field])).length;
};

