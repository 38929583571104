import { makeAutoObservable } from 'mobx';
import { getFullName } from 'src/domain/models/user/user.model';
import { type IContactExtractionStore } from './contact-extraction.interface.store';

export class ContactExtractionStore implements IContactExtractionStore {
    constructor() {
        makeAutoObservable(this);
    }

    contactExtractionCandidates: IContactExtractionStore['contactExtractionCandidates'] =
        [];
    companyExtractionCandidates: IContactExtractionStore['companyExtractionCandidates'] =
        [];
    selectedContactCandidateNames: IContactExtractionStore['selectedContactCandidateNames'] =
        new Set();
    selectedCompanyCandidateNames: IContactExtractionStore['selectedCompanyCandidateNames'] =
        new Set();
    contactExtractionResultStatus: IContactExtractionStore['contactExtractionResultStatus'] =
        undefined;

    selectContactCandidate: IContactExtractionStore['selectContactCandidate'] =
        (candidate) => {
            const name = getFullName(candidate.firstName, candidate.lastName);
            this.selectedContactCandidateNames.add(name);
        };

    deselectContact: IContactExtractionStore['deselectContact'] = (
        candidate,
    ) => {
        const name = getFullName(candidate.firstName, candidate.lastName);
        this.selectedContactCandidateNames.delete(name);
    };

    selectCompanyCandidate: IContactExtractionStore['selectCompanyCandidate'] =
        (candidate) => {
            this.selectedCompanyCandidateNames.add(candidate.name);
        };

    deselectCompany: IContactExtractionStore['deselectCompany'] = (
        candidate,
    ) => {
        this.selectedCompanyCandidateNames.delete(candidate.name);
    };

    setContactExtractionCandidates: IContactExtractionStore['setContactExtractionCandidates'] =
        (candidates) => {
            this.contactExtractionCandidates = candidates;
        };

    setCompanyExtractionCandidates: IContactExtractionStore['setCompanyExtractionCandidates'] =
        (candidates) => {
            this.companyExtractionCandidates = candidates;
        };

    setContactExtractionResultStatus: IContactExtractionStore['setContactExtractionResultStatus'] =
        (status) => {
            this.contactExtractionResultStatus = status;
        };

    clear: IContactExtractionStore['clear'] = () => {
        this.contactExtractionCandidates = [];
        this.companyExtractionCandidates = [];
        this.selectedContactCandidateNames = new Set();
        this.selectedCompanyCandidateNames = new Set();
        this.contactExtractionResultStatus = undefined;
    };

    clearSelected = () => {
        this.selectedContactCandidateNames = new Set();
        this.selectedCompanyCandidateNames = new Set();
    };
}
