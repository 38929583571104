import { TFunction } from 'react-i18next';

import { DealContactCompanyForm } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import {
    DealContactType,
    PredefinedCustomFields,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { ContactUpdateMetricsInfo } from 'src/app-features/contact/domain/interfaces/deal-contacts.feature.interface';
import {
    CustomParameterConfig,
    CustomParameterEntityType,
} from 'src/app-features/custom-parameter/data/model/custom-parameter.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { dateToString } from 'src/utils/datetime.utils';
import { isNotEmpty } from 'src/utils/string.utils';

import {
    ContactEnrichmentData,
    DataProviderDisplayValues,
    DataProviderSource,
    DomainEnrichmentOption,
    EnrichmentResultSelection,
} from './contact-enrichment.model';

export interface DataToBuildEnrichmentNote {
    fieldType: PredefinedCustomFields;
    fieldEntityType: CustomParameterEntityType;
    providers?: DataProviderSource[] | undefined;
}

/**
 * Sorts enrichment data by provider count in a descending order
 * @param data Data to be sorted
 * @returns Sorted data
 */
export const sortEnrichmentDataByProviderCount = <
    T extends { providers?: DataProviderSource[] },
>(
    data: T[],
) =>
    data.sort(
        (a, b) => (b.providers?.length ?? 0) - (a.providers?.length ?? 0),
    );

/**
 * Builds a formatted note for a given enriched data field
 * @param fieldData Data for the enriched field
 * @param t Translation function
 * @param language Language to use for the note
 * @param getCustomParameterByName Function callback to get custom parameter by name
 * @returns Formatted note
 */
const buildNoteForEnrichedData = (
    fieldData: DataToBuildEnrichmentNote,
    t: TFunction<'translation', undefined>,
    language: Language,
    getCustomParameterByName: (
        name: string,
        fieldType: CustomParameterEntityType,
    ) => CustomParameterConfig | undefined,
): string => {
    const field = getCustomParameterByName(
        fieldData.fieldType,
        fieldData.fieldEntityType,
    );

    if (!field) {
        return '';
    }

    if (!fieldData.providers || fieldData.providers.length === 0) {
        return '';
    }

    const providerDisplayValues =
        DataProviderDisplayValues[fieldData.providers[0]];

    return t('actions.contact_enrichment.data_provided_by', {
        field: field.label,
        providerName: providerDisplayValues.name,
        providerUrl: providerDisplayValues.url,
        date: dateToString(new Date(), language),
        interpolation: { escapeValue: false },
    });
};

/**
 * Builds notes for enriched data
 * @param fieldDataList Data list for the enriched fields
 * @param t Translation function
 * @param language Language to use for the note
 * @param getCustomParameterByName Function callback to get custom parameter by name
 * @returns Notes
 */
export const buildNotesForEnrichedData = (
    fieldDataList: DataToBuildEnrichmentNote[],
    t: TFunction<'translation', undefined>,
    language: Language,
    getCustomParameterByName: (
        name: string,
        fieldType: CustomParameterEntityType,
    ) => CustomParameterConfig | undefined,
) => {
    const notes = fieldDataList
        .map((data) =>
            buildNoteForEnrichedData(
                data,
                t,
                language,
                getCustomParameterByName,
            ),
        )
        .join('\n');

    return notes;
};

export const appendEnrichmentNote = (
    noteToAppend?: string,
    currentNotes?: string,
) => {
    if (!noteToAppend) {
        return undefined;
    }
    return currentNotes?.length
        ? `${currentNotes}\n\n${noteToAppend}`
        : noteToAppend;
};

/**
 * Creates a payload for enriched company domain
 * @param domainOption Enriched domain
 * @param currentNotes Current notes
 * @returns Payload
 */
export const createEnrichedDomainPayload = (
    domainOption: DomainEnrichmentOption,
    t: TFunction<'translation', undefined>,
    language: Language,
    getCustomParameterByName: (
        name: string,
        fieldType: CustomParameterEntityType,
    ) => CustomParameterConfig | undefined,
    currentNotes?: string,
): DealContactCompanyForm => {
    let noteForEnrichedData = undefined;

    if (!domainOption.isCustom) {
        noteForEnrichedData = buildNoteForEnrichedData(
            {
                fieldType: PredefinedCustomFields.url,
                fieldEntityType: 'companyCustomFields',
                providers: domainOption.providers,
            },
            t,
            language,
            getCustomParameterByName,
        );
    }

    return {
        customInput: {
            customValues: [
                {
                    name: PredefinedCustomFields.url,
                    value: domainOption.domain,
                },
            ],
        },
        notes: domainOption.isCustom
            ? undefined
            : appendEnrichmentNote(noteForEnrichedData, currentNotes),
        formType: DealContactType.company,
    };
};

/**
 * Creates payload for enriched company domain metrics info
 * @param result Enriched domain
 * @returns Payload
 */
export const createEnrichedDomainMetricsInfoPayload = (
    result: DomainEnrichmentOption,
): ContactUpdateMetricsInfo => {
    return {
        field: PredefinedCustomFields.url,
        enrichmentInfo: {
            url: { value: result.domain, providers: result.providers ?? [] },
        },
    };
};

/**
 * Creates payload for enriched contact field
 * @param selectedValues Selected values
 * @returns Payload
 */
export const createEnrichedContactFieldPayload = (
    selectedValues: EnrichmentResultSelection,
) => {
    const customValues: { name: string; value: string }[] = [];
    Object.entries(selectedValues).forEach(([key, val]) => {
        if (val) {
            customValues.push({ name: key, value: val.value });
        }
    });
    return customValues;
};

/**
 * Creates enrichment result selection
 * @param selectedValues Selected values
 * @param key Key
 * @param val Value
 * @returns Enrichment result selection
 */
export const createEnrichmentResultSelection = (
    selectedValues: EnrichmentResultSelection,
    key: PredefinedCustomFields,
    val?: ContactEnrichmentData,
) => {
    const values = Object.assign({}, selectedValues);
    if (!val && values[key] !== undefined) {
        delete values[key];
    } else if (val) {
        values[key] = val;
    }
    return values;
};

export const buildSelectedDomainOption = (
    results: DomainEnrichmentOption[],
    domainOption?: DomainEnrichmentOption,
) => {
    if (!domainOption) {
        return undefined;
    }
    const result = results.find((item) => item.domain === domainOption.domain);
    if (result) {
        return result;
    } else if (isNotEmpty(domainOption.domain)) {
        return {
            domain: domainOption.domain,
            name: domainOption.name,
            providers: [],
            isCustom: true,
        };
    }
    return undefined;
};
