import { IconName } from '@buildingradar/br_component_lib';
import {
    type DealContactUnionType,
    DealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import {
    ActivityLogSubType,
    DealActivity,
    DealActivityType,
    OutreachActivityResult,
} from './deal-activity.model';

export enum OutreachActivityLaunchSource {
    contactCard = 'Contact Card',
    contactCardCallAction = 'Contact Card: Prepare call',
    contactCardEmailAction = 'Contact Card: Prepare mail',
    contactCardLogAction = 'Contact Card: Log outreach',
    companyCardCallAction = 'Company Card: Prepare call',
    companyCardEmailAction = 'Company Card: Prepare mail',
}

export enum OutreachActivityLogSource {
    outreachLogModal = 'Log Outreach Modal',
    outreachModalCallTab = 'Outreach Modal: Prepare call tab',
    outreachModalEmailTab = 'Outreach Modal: Prepare mail tab',
    outreachModalLogTab = 'Outreach Modal: Log outreach tab',
}

export const ActivitySubTypeToOutreachLogSource: Partial<
    Record<ActivityLogSubType, OutreachActivityLogSource>
> = {
    [ActivityLogSubType.phoneCall]:
        OutreachActivityLogSource.outreachModalCallTab,
    [ActivityLogSubType.email]: OutreachActivityLogSource.outreachModalEmailTab,
    [ActivityLogSubType.other]: OutreachActivityLogSource.outreachModalLogTab,
};

export const ActivitySubTypeToOutreachMetricsTabName: Partial<
    Record<ActivityLogSubType, string>
> = {
    [ActivityLogSubType.phoneCall]: 'Prepare call tab',
    [ActivityLogSubType.email]: 'Prepare email tab',
    [ActivityLogSubType.other]: 'Log outreach tab',
};

export enum OutreachModeMetricsType {
    FIRST_MAIL = 'FIRST_MAIL',
    FOLLOW_UP_MAIL = 'FOLLOW_UP_MAIL',
    PHONE_CALL = ActivityLogSubType.phoneCall,
    OTHER = ActivityLogSubType.other,
}

export const OutreachLogDefaultResults: OutreachActivityResult[] = [
    OutreachActivityResult.interested,
    OutreachActivityResult.referral,
    OutreachActivityResult.futureInterest,
    OutreachActivityResult.notInterested,
    OutreachActivityResult.didNotConnect,
    OutreachActivityResult.emailSent,
    OutreachActivityResult.other,
];

export const OutreachResultTypeToTagColorClassName: Record<
    OutreachActivityResult,
    string
> = {
    [OutreachActivityResult.interested]: 'text-lime-700 bg-lime-100',
    [OutreachActivityResult.referral]: 'bg-lime-100 text-lime-700',
    [OutreachActivityResult.futureInterest]: 'bg-lime-100 text-lime-700',
    [OutreachActivityResult.notInterested]: 'bg-red-100 text-red-700',
    [OutreachActivityResult.didNotConnect]: 'bg-slate-200 text-slate-700',
    [OutreachActivityResult.other]: 'bg-slate-200 text-slate-700',
    [OutreachActivityResult.emailSent]: 'bg-lime-100 text-lime-700',
};

export const ActivitySubTypeToOutreachIconClassName: Partial<
    Record<ActivityLogSubType, string>
> = {
    [ActivityLogSubType.phoneCall]: 'bg-outreach-call',
    [ActivityLogSubType.email]: 'bg-outreach-email',
    [ActivityLogSubType.other]: 'bg-outreach-other',
};

export const ActivitySubTypeToOutreachCardRootClassName: Partial<
    Record<ActivityLogSubType, string>
> = {
    [ActivityLogSubType.phoneCall]: 'border-l-outreach-call',
    [ActivityLogSubType.email]: 'border-l-outreach-email',
    [ActivityLogSubType.other]: 'border-l-outreach-other',
};

export const ActivityLogSubTypeToOutreachName: Partial<
    Record<ActivityLogSubType, 'call' | 'email' | 'log_update'>
> = {
    [ActivityLogSubType.phoneCall]: 'call',
    [ActivityLogSubType.email]: 'email',
    [ActivityLogSubType.other]: 'log_update',
};

export const ActivityLogSubTypeToOutreachIcon: Partial<
    Record<ActivityLogSubType, IconName>
> = {
    [ActivityLogSubType.phoneCall]: IconName.BrCallForwardIcon,
    [ActivityLogSubType.email]: IconName.BrSendEmailIcon,
    [ActivityLogSubType.other]: IconName.BrDescriptionOutlinedIcon,
};

export const filterOutreachAttempts = (
    activities: DealActivity[],
    contact: DealContactUnionType,
) => {
    const isCompany = contact.type === DealContactType.company;

    return activities.filter((a) => {
        if (a.type !== DealActivityType.outreachAttempt) return false;
        if (isCompany) return a.relevantCompanyId === contact.id;
        return a.contactId === contact.id;
    });
};

export const hasAtLeastOneActivityOfType = (
    activities: DealActivity[],
    type: ActivityLogSubType,
) => activities.filter((activity) => activity.subType === type).length > 0;

export const OutreachActivityTargetNumber = 7;
