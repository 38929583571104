import { makeAutoObservable } from 'mobx';
import { getI18n } from 'react-i18next';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { type IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { type ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { sortNewArray } from 'src/utils/array.utils';
import {
    type Cancellable,
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';
import { Localized } from 'src/presentation/shared/localized/localized';
import { type IDealAttachmentsApi } from '../data/deal-attachments.api';
import {
    type TDealAttachment,
    type TDealUrlAttachment,
    EDealAttachmentType,
    TDealFileAttachment,
} from '../domain/deal-attachments.model';
import { IDealAttachmentsStore } from './store/deal-attachments.store.interface';
import { getAttachmentName } from './deal-attachments.utils';

export interface IDealAttachmentsFeature {
    attachmentList: TDealAttachment[];
    attachedFile: File | null;
    attachedWebLink: string | null;
    isAttachmentDialogOpen: boolean;
    areAttachmentsLoading: boolean;
    isAttachmentsLoadingFailed: boolean;
    isAttachmentUploading: boolean;
    isAttachmentDeleting: boolean;
    fetchingPreviewUrl: boolean;
    fetchingDownloadUrl: boolean;
    fetchAttachmentList: (dealId: string) => Cancellable;
    downloadAttachment: (
        dealId: string,
        attachment: TDealFileAttachment,
    ) => Promise<void>;
    trackURLAttachmentClick: (
        dealId: string,
        attachment: TDealUrlAttachment,
    ) => void;
    uploadAttachment: (
        dealId: string,
        attachment: File | string,
    ) => Promise<void>;
    deleteAttachment: (
        dealId: string,
        attachment: TDealAttachment,
    ) => Promise<void>;
    reportAndDeleteAttachment: (
        dealId: string,
        attachment: TDealAttachment,
        reportReason: string,
        reportDescription: string,
    ) => Promise<void>;
    setAttachedFile: (file: File | null) => void;
    setAttachedWebLink: (webLink: string | null) => void;
    setIsAttachmentDialogOpen: (isOpen: boolean) => void;
    resetAttachmentDialog: () => void;
    getAttachmentPreviewDownloadUrl: (
        dealId: string,
        attachment: TDealFileAttachment,
        inlineContent?: boolean,
    ) => Promise<string | null>;
}

export class DealAttachmentsFeature implements IDealAttachmentsFeature {
    constructor(
        private dealAttachmentsApi: IDealAttachmentsApi,
        private baseStore: IBaseStore,
        private dealAttachmentsStore: IDealAttachmentsStore,
        private toasterStore: ToasterStore,
        private mixpanelService: MixpanelService,
    ) {
        makeAutoObservable(this);
    }

    get attachmentList(): IDealAttachmentsFeature['attachmentList'] {
        return this.dealAttachmentsStore.attachmentList;
    }

    attachedFile: IDealAttachmentsFeature['attachedFile'] = null;
    attachedWebLink: IDealAttachmentsFeature['attachedWebLink'] = null;
    isAttachmentDialogOpen: IDealAttachmentsFeature['isAttachmentDialogOpen'] =
        false;
    areAttachmentsLoading: IDealAttachmentsFeature['areAttachmentsLoading'] =
        false;
    fetchingPreviewUrl: IDealAttachmentsFeature['fetchingPreviewUrl'] = false;
    fetchingDownloadUrl: IDealAttachmentsFeature['fetchingDownloadUrl'] = false;
    isAttachmentsLoadingFailed: IDealAttachmentsFeature['isAttachmentsLoadingFailed'] =
        false;
    isAttachmentUploading: IDealAttachmentsFeature['isAttachmentUploading'] =
        false;
    isAttachmentDeleting: IDealAttachmentsFeature['isAttachmentDeleting'] =
        false;

    attachmentPreviews: Map<string, string> = new Map();
    private _handleAttachmentDelete = async (
        dealId: string,
        attachment: TDealAttachment,
    ) => {
        const response = await handleRequestAsync(
            this.dealAttachmentsApi.deleteAttachment,
            {
                dealId,
                contentId: attachment.id,
            },
            (isDeleting) => {
                this.isAttachmentDeleting = isDeleting;
            },
            (error) => {
                if (error) {
                    this.baseStore.onRequestFailed(
                        'delete-deal-attachment',
                        error as Error,
                    );
                }
            },
        );

        if (response?.removeDealContent.ok) {
            this.dealAttachmentsStore.setAttachmentList(
                this.attachmentList.filter((item) => item.id !== attachment.id),
            );
        }

        return response;
    };

    fetchAttachmentList: IDealAttachmentsFeature['fetchAttachmentList'] = (
        dealId,
    ) => {
        return handleRequest(
            this.dealAttachmentsApi.getAttachmentList,
            { dealId },
            (list) => {
                if (list) {
                    this.dealAttachmentsStore.setAttachmentList(
                        sortNewArray(list)(
                            (a, b) =>
                                (b.createdDate?.getTime() ?? 0) -
                                (a.createdDate?.getTime() ?? 0),
                        ),
                    );
                }
            },
            (isLoading) => {
                this.areAttachmentsLoading = isLoading;
            },
            (error) => {
                if (error) {
                    this.baseStore.onRequestFailed(
                        'upload-deal-attachment',
                        error as Error,
                    );
                }

                this.isAttachmentsLoadingFailed = !!error;
            },
        );
    };

    private _fetchAttachmentUrl = async (
        attachment: TDealFileAttachment,
        inlineContent = false,
        loadingCallback?: (isLoading: boolean) => void,
    ) => {
        try {
            const fileUrl = await handleRequestAsync(
                this.dealAttachmentsApi.getFileAttachmentDownloadUrl,
                { contentId: attachment.id, inlineContent },
                loadingCallback,
            );

            return fileUrl ?? null;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'get-deal-file-attachment-url',
                error as Error,
            );
            return null;
        }
    };

    getAttachmentPreviewDownloadUrl: IDealAttachmentsFeature['getAttachmentPreviewDownloadUrl'] =
        async (dealId, attachment) => {
            let previewUrl: string | null = null;
            if (this.attachmentPreviews.has(attachment.id)) {
                previewUrl = this.attachmentPreviews.get(attachment.id) ?? null;
            } else {
                previewUrl = await this._fetchAttachmentUrl(
                    attachment,
                    true,
                    (loading) => (this.fetchingPreviewUrl = loading),
                );

                if (previewUrl) {
                    this.attachmentPreviews.set(attachment.id, previewUrl);
                }
            }

            if (previewUrl) {
                this.mixpanelService.trackEvent(
                    MixpanelEventName.PreviewedDealResource,
                    { dealAttachmentName: attachment.sourceName },
                    dealId,
                );
            }

            return previewUrl ?? null;
        };

    downloadAttachment: IDealAttachmentsFeature['downloadAttachment'] = async (
        dealId,
        attachment,
    ) => {
        const downloadUrl = await this._fetchAttachmentUrl(
            attachment,
            false,
            (loading) => (this.fetchingDownloadUrl = loading),
        );

        if (!downloadUrl) return;

        window.open(downloadUrl, '_self', 'noopener,noreferrer');

        this.mixpanelService.trackEvent(
            MixpanelEventName.DownloadedDealResource,
            { dealAttachmentName: attachment.sourceName },
            dealId,
        );
    };

    trackURLAttachmentClick: IDealAttachmentsFeature['trackURLAttachmentClick'] =
        (dealId, attachment) => {
            this.mixpanelService.trackEvent(
                MixpanelEventName.OpenedDealLinkResource,
                { dealAttachmentName: attachment.source },
                dealId,
            );
        };

    uploadAttachment = async (dealId: string, attachment: File | string) => {
        const isFile = attachment instanceof File;
        const params = isFile
            ? { dealId, file: attachment }
            : { dealId, url: attachment };

        let isError = false;

        const newAttachment = await handleRequestAsync(
            this.dealAttachmentsApi.uploadAttachment,
            params,
            (isUploading) => {
                this.isAttachmentUploading = isUploading;
            },
            (error) => {
                if (error) {
                    isError = true;
                    this.baseStore.onRequestFailed(
                        'upload-deal-attachment',
                        error as Error,
                    );
                }
            },
        );

        if (isError) return;

        this.isAttachmentDialogOpen = false;

        if (newAttachment) {
            this.dealAttachmentsStore.setAttachmentList([
                newAttachment,
                ...this.dealAttachmentsStore.attachmentList,
            ]);

            const attachmentName =
                newAttachment.type === EDealAttachmentType.FILE
                    ? newAttachment.sourceName
                    : newAttachment.source;

            this.mixpanelService.trackEvent(
                MixpanelEventName.AttachedDealResource,
                { dealAttachmentName: attachmentName },
                dealId,
            );
        }

        const { t } = getI18n();

        this.toasterStore.showMessage({
            type: 'success',
            title: t<string>('deal_attachments.attachment_add_success.title'),
            message: t<string>('deal_attachments.attachment_add_success.text'),
        });

        // Delay reset to prevent layout jump during dialog close animation.
        setTimeout(() => {
            this.resetAttachmentDialog();
        }, 100);
    };

    deleteAttachment: IDealAttachmentsFeature['deleteAttachment'] = async (
        dealId,
        attachment,
    ) => {
        const response = await this._handleAttachmentDelete(dealId, attachment);
        if (!response?.removeDealContent.ok) return;

        const { t } = getI18n();
        const attachmentName = getAttachmentName(attachment);

        this.toasterStore.showMessage({
            type: 'success',
            title: t<string>(
                'deal_attachments.attachment_delete_success.title',
            ),
            message: (
                <Localized
                    components={{
                        resourceName: (
                            <span className="font-bold">{attachmentName}</span>
                        ),
                    }}
                    placeholderMap={{
                        name: attachmentName,
                    }}
                    shouldUnescape
                >
                    deal_attachments.attachment_delete_success.text
                </Localized>
            ),
        });

        this.mixpanelService.trackEvent(
            MixpanelEventName.DeletedDealResource,
            { dealAttachmentName: attachmentName },
            dealId,
        );
    };

    reportAndDeleteAttachment: IDealAttachmentsFeature['reportAndDeleteAttachment'] =
        async (dealId, attachment, reportReason, reportDescription) => {
            const response = await this._handleAttachmentDelete(
                dealId,
                attachment,
            );
            if (!response?.removeDealContent.ok) return;

            const { t } = getI18n();
            const attachmentName = getAttachmentName(attachment);

            this.toasterStore.showMessage({
                type: 'success',
                title: t<string>(
                    'deal_attachments.attachment_report_success.title',
                ),
                message: (
                    <div className="whitespace-pre-wrap">
                        <Localized
                            components={{
                                resourceName: (
                                    <span className="font-bold">
                                        {attachmentName}
                                    </span>
                                ),
                            }}
                            placeholderMap={{
                                name: attachmentName,
                            }}
                            shouldUnescape
                        >
                            deal_attachments.attachment_report_success.text
                        </Localized>
                    </div>
                ),
            });

            this.mixpanelService.trackEvent(
                MixpanelEventName.ReportedDealResource,
                {
                    dealAttachmentName: getAttachmentName(attachment),
                    reportReason: reportReason,
                    reportDescription: reportDescription,
                },
                dealId,
            );
        };

    setAttachedFile: IDealAttachmentsFeature['setAttachedFile'] = (file) => {
        this.attachedFile = file;
    };

    setAttachedWebLink: IDealAttachmentsFeature['setAttachedWebLink'] = (
        webLink,
    ) => {
        this.attachedWebLink = webLink;
    };

    setIsAttachmentDialogOpen: IDealAttachmentsFeature['setIsAttachmentDialogOpen'] =
        (isOpen) => {
            this.isAttachmentDialogOpen = isOpen;
        };

    resetAttachmentDialog: IDealAttachmentsFeature['resetAttachmentDialog'] =
        () => {
            this.attachedFile = null;
            this.attachedWebLink = null;
        };
}
