import { withFeatures } from 'src/utils/component.utils';
import { OtherSectionComponent } from './other-section.component';

export const OtherSectionContainer = withFeatures(OtherSectionComponent)(
    (f) => ({
        overdueReminders: f.notificationsFeature.overdueReminders,
        unreadMentions: f.notificationsFeature.unreadMentions,
        areNotificationsOpened: f.notificationsFeature.isOpened,
        toggleNotifications: f.notificationsFeature.toggleNotificationsOpened,
    }),
);
