import { SearchType } from 'src/app-features/searches-configuration/domain/models/configured-search';
import {
    LoginMethod,
    SearchType as SdkSearchType,
    UserJobRoles,
    UserPartFragment,
} from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { Colleague } from 'src/domain/models/colleague/colleague.model';
import { Currency } from 'src/domain/models/currency-value/currency-value.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { Search } from 'src/domain/models/search/search.model';
import {
    createUserSettings,
    Settings,
} from 'src/domain/models/settings/settings.model';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';
import { AccountType, LicenseType } from '../company/company.model';

export enum UserRole {
    owner = 'owner',
    user = 'user',
}

export interface FeatureToggle {
    inboxOnly: boolean;
}

export interface CompanySettings {
    useAppBrDomain: boolean;
    accountType: AccountType;
    licenseType: LicenseType;
    preScreening: boolean;
}

export interface User {
    itemId: number;
    firstName: string;
    lastName: string;
    email: string;
    searches: Search[];
    language: Language;
    colleagues: Colleague[];
    roles: UserRole[];
    company: string;
    companyId: number;
    currency: Currency;
    settings: Settings;
    featureToggle: FeatureToggle;
    isImpersonated: boolean;
    impersonatedBy: string | null;
    companySettings: CompanySettings;
    jobRoles: UserJobRoles[];
}

export const createUser = (
    user: UserPartFragment,
    accountType: AccountType,
    licenseType: LicenseType,
): User => {
    return {
        ...user,
        featureToggle: {
            inboxOnly: user.featureToggles.includes('inbox_only'),
        },
        currency: user.company.settings.currency
            ? (user.company.settings.currency as unknown as Currency)
            : Currency.EUR,
        companyId: user.company.itemId,
        isImpersonated: user.loginMethod === LoginMethod.Impersonation,
        impersonatedBy: user.impersonatorInfo?.email ?? null,
        companySettings: {
            useAppBrDomain: user.company.settings.useAppDotBr,
            preScreening: user.company.settings.preScreening,
            accountType,
            licenseType,
        },
        searches: user.searches.map((search) => ({
            id: search.itemId,
            name: search.name,
            ownerId: search.userId,
            type:
                search.type === SdkSearchType.Article
                    ? SearchType.Article
                    : SearchType.Tender,
            searchData: {
                filterModules: search.searchData.filterModules,
            },
        })),
        language: user.language.toLowerCase() as Language,
        colleagues: user.colleagues.map((colleague) => ({
            ...colleague,
            companyId: user.company.itemId,
            roles: colleague.roles as UserRole[],
        })),
        roles: user.roles as UserRole[],
        company: user.company.name,
        settings: createUserSettings(user.settings),
        jobRoles: user.jobRoles ?? [],
    };
};

export interface UserLike {
    itemId: number;
    firstName: string;
    lastName: string;
    email: string;
}

export const getFullName = (
    firstName?: string | null,
    lastName?: string | null,
) => {
    return [firstName, lastName].filter(isNonNullable).join(' ').trim();
};

export const getUserFullName = (
    user: { firstName?: string; lastName?: string } | undefined,
) => {
    return getFullName(user?.firstName, user?.lastName);
};

export const BR_EMAIL_DOMAIN = '@buildingradar.com';
export const isBrUser = (user: UserLike) =>
    user.email.includes(BR_EMAIL_DOMAIN);
