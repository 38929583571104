import { AbortParams } from 'src/data/api/api-client';
import {
    LiveUserNotificationsDocument,
    LiveUserNotificationsSubscription,
    LiveUserNotificationsSubscriptionVariables,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    InstantUserNotification,
    mapInstantUserNotification,
} from './live-notification.model';

interface SubscriptionParams extends AbortParams {
    onNext: (data: InstantUserNotification) => void;
}
export interface ILiveNotificationApi {
    subscribeToLiveNotifications: (
        callbacks: SubscriptionParams,
    ) => Promise<void>;
}

export const createLiveNotificationApi = (
    processGqlSdk: ProcessGqlSdk,
): ILiveNotificationApi => {
    const subscribeToLiveNotifications = ({
        onNext,
        signal,
    }: SubscriptionParams) => {
        return new Promise<void>((resolve, reject) => {
            processGqlSdk.runSubscription<
                LiveUserNotificationsSubscription,
                LiveUserNotificationsSubscriptionVariables
            >(
                {
                    query: LiveUserNotificationsDocument,
                },
                (data) => {
                    onNext(
                        mapInstantUserNotification(data.subscribeUserUpdates),
                    );
                },
                () => {
                    resolve();
                },
                (error) => reject(error),
                signal,
            );
        });
    };

    return {
        subscribeToLiveNotifications,
    };
};
