import {
    AccountType as RawAccountType,
    LicenseType as RawLicenseType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export enum AccountType {
    Dummy = 'Dummy',
    Production = 'Production',
    Template = 'Template',
}

export enum LicenseType {
    Enterprise = 'Enterprise',
    Medium = 'Medium',
    Small = 'Small',
    PaidTrial = 'Paid trial',
    Other = 'Other',
}

//Product wants PascalCase
export const mapAccountType: Record<RawAccountType, AccountType> = {
    [RawAccountType.Dummy]: AccountType.Dummy,
    [RawAccountType.Production]: AccountType.Production,
    [RawAccountType.Template]: AccountType.Template,
};

export const mapLicenseType: Record<RawLicenseType, LicenseType> = {
    [RawLicenseType.Enterprise]: LicenseType.Enterprise,
    [RawLicenseType.Medium]: LicenseType.Medium,
    [RawLicenseType.Small]: LicenseType.Small,
    [RawLicenseType.PaidTrial]: LicenseType.PaidTrial,
    [RawLicenseType.Other]: LicenseType.Other,
};
