import { TFunction } from 'react-i18next';

import { Stage } from 'src/domain/models/deal-stage/deal-stage.model';
import { getFullName, UserLike } from 'src/domain/models/user/user.model';

import { Comment, ParsedComment } from './comment.model';

export const CONGRATULATE_PARAMETER = 'congratulate-comment';
export const USER_MENTION_TRIGGER = '@';
export const MARKED_USER_ID = `<${USER_MENTION_TRIGGER}`;
export const USER_COMMENT_REGEX = /<@(\d+)>/;
export const USER_COMMENT_REGEX_GLOBAL = /<@(\d+)>/g;

export const parseCommentToHTML = (
    comment: string,
    colleagues: UserLike[],
    placeholder: string,
) => {
    return comment.replace(USER_COMMENT_REGEX_GLOBAL, (_, id) => {
        const colleague = colleagues.find((c) => c.itemId === parseInt(id));
        const label = colleague
            ? getFullName(colleague.firstName, colleague.lastName)
            : placeholder;
        return `<span data-type="mention" data-id="${id}" data-label="${label}" class="mention font-medium">@${label}</span>`;
    });
};

export const parseTextToComment = (
    text: string,
    colleagues: UserLike[],
    placeholder: string,
): ParsedComment => {
    const mentions = Array.from(
        text.matchAll(USER_COMMENT_REGEX_GLOBAL),
        ([, userId]) => parseInt(userId),
    );

    const plainText = text.replace(USER_COMMENT_REGEX_GLOBAL, (_, id) => {
        const colleague = colleagues.find((c) => c.itemId === parseInt(id));
        const label = colleague
            ? getFullName(colleague.firstName, colleague.lastName)
            : placeholder;
        return `@${label}`;
    });

    return {
        textWithMentions: text,
        plainText,
        mentions,
    };
};

export const sortCommentByDate = (
    commentA: Comment,
    commentB: Comment,
): number => {
    if (commentA.created < commentB.created) {
        return 1;
    } else if (commentA.created > commentB.created) {
        return -1;
    }
    return 0;
};

export const createAllObjectivesCompletedPrefilledText = (
    stages: Stage[],
    currentStageIndex: number,
    t: TFunction,
) => {
    return `${t('common.stage')} ${stages[currentStageIndex]?.name} → ${
        stages[currentStageIndex + 1]?.name
    }: `;
};
