import { makeAutoObservable } from 'mobx';
import { handleRequest } from 'src/utils/handle-request.utils';
import { type ISearchesApi } from 'src/app-features/searches-configuration/api/searches.api';
import { type FolderCountersApi } from 'src/data/api/folder-counters/folder-counters.api';
import { type MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { UserAuthenticationStatus } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { type LeadsApi } from 'src/data/api/leads/leads.api';
import { type SubscriptionApi } from 'src/data/api/subscriptions/subscriptions.api';
import { type UsersApi } from 'src/data/api/user/user.api';
import { type FolderCountersStore } from 'src/data/stores/folder-counters/folder-counters.store';
import { type IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { type SubscriptionsStore } from 'src/data/stores/subscriptions/subscriptions.store';
import { type UserStore } from 'src/data/stores/user/user.store';
import { type SearchSubscriptionData } from 'src/domain/models/subscription/subscription.model';

export interface IScreeningSearchConfigurationFeature {
    baseStore: IBaseStore;
    userStore: UserStore;
    subscriptionsStore: SubscriptionsStore;
    subscriptionApi: SubscriptionApi;
    searchesApi: ISearchesApi;
    leadsApi: LeadsApi;
    mixpanelService: MixpanelService;
    searchToDelete: SearchSubscriptionData | null;
    searchToClearInbox: SearchSubscriptionData | null;
    getDoesSearchHaveProjectsInInbox: (searchId: number) => boolean;
    setSearchToDelete: (search: SearchSubscriptionData | null) => void;
    setSearchToClearInbox: (search: SearchSubscriptionData | null) => void;
    fetchAllFolderCounters: () => void;
    updateUserSearches: () => void;
}

export class ScreeningSearchConfigurationFeature
    implements IScreeningSearchConfigurationFeature
{
    constructor(
        readonly baseStore: IBaseStore,
        readonly userStore: UserStore,
        private readonly folderCountersStore: FolderCountersStore,
        readonly subscriptionsStore: SubscriptionsStore,
        private readonly userApi: UsersApi,
        readonly subscriptionApi: SubscriptionApi,
        readonly searchesApi: ISearchesApi,
        readonly leadsApi: LeadsApi,
        private readonly folderCountersApi: FolderCountersApi,
        readonly mixpanelService: MixpanelService,
    ) {
        makeAutoObservable(this);
    }

    searchToDelete: IScreeningSearchConfigurationFeature['searchToDelete'] =
        null;
    searchToClearInbox: IScreeningSearchConfigurationFeature['searchToClearInbox'] =
        null;

    getDoesSearchHaveProjectsInInbox: IScreeningSearchConfigurationFeature['getDoesSearchHaveProjectsInInbox'] =
        (searchId) => {
            return !!this.folderCountersStore.folderCounters.inbox[searchId];
        };

    setSearchToDelete: IScreeningSearchConfigurationFeature['setSearchToDelete'] =
        (search) => {
            this.searchToDelete = search;
        };

    setSearchToClearInbox: IScreeningSearchConfigurationFeature['setSearchToClearInbox'] =
        (search) => {
            this.searchToClearInbox = search;
        };

    fetchAllFolderCounters: IScreeningSearchConfigurationFeature['fetchAllFolderCounters'] =
        () => {
            handleRequest(
                this.folderCountersApi.getFolderCounters,
                {
                    searchTags: this.userStore.searchTags.map((tag) =>
                        Number(tag),
                    ),
                },
                this.folderCountersStore.setFolderCounters,
                this.folderCountersStore.setLoading,
                (error) =>
                    this.baseStore.onRequestFailed(
                        'request-folder-counters',
                        error,
                    ),
            );
        };

    updateUserSearches: IScreeningSearchConfigurationFeature['updateUserSearches'] =
        () => {
            handleRequest(this.userApi.getCurrentUser, {}, (response) => {
                if (response.status === UserAuthenticationStatus.Success) {
                    this.userStore.setUser(response.user);
                }
            });
        };
}
