import { makeAutoObservable } from 'mobx';

import {
    DealContactCompany,
    DealContactPerson,
    PredefinedCustomFields,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { IDealContactsStore } from 'src/app-features/contact/data/stores/interfaces/deal-contacts.store.interface';
import { IContactEnrichmentApi } from 'src/app-features/contact-enrichment/data/api/contact-enrichment.api';
import { createEnrichmentResultSelection } from 'src/app-features/contact-enrichment/domain/utils';
import { QuickActionsStoreImpl } from 'src/data/stores/actions/quick-actions.store';
import { UserStore } from 'src/data/stores/user/user.store';
import { getFullName } from 'src/domain/models/user/user.model';
import { doNothing } from 'src/utils/function.utils';
import { handleRequest } from 'src/utils/handle-request.utils';
import { isNotEmpty } from 'src/utils/string.utils';

import {
    ContactEnrichmentResult,
    ContactEnrichmentParams,
    DomainEnrichmentOption,
    EnrichmentResultSelection,
    ContactEnrichmentData,
    EnrichmentStep,
} from './contact-enrichment.model';
import { sortEnrichmentDataByProviderCount } from './utils';

export interface EnrichmentIterateStateParams {
    to?: EnrichmentStep;
    refined?: boolean;
}
export interface IContactEnrichmentFeature {
    isContactEnrichmentLoading: boolean;
    isDomainEnrichmentLoading: boolean;
    currentStep: EnrichmentStep;
    contactEnrichmentResults: ContactEnrichmentResult;
    contactEnrichmentResultSelection: EnrichmentResultSelection;
    domainEnrichmentResults: DomainEnrichmentOption[];
    domainEnrichmentResultSelection?: DomainEnrichmentOption;
    selectedContact?: DealContactPerson;
    selectedCompany?: DealContactCompany;
    selectedCompanyDomain?: string;
    onSelectContactEnrichmentResult: (
        key: PredefinedCustomFields,
        value?: ContactEnrichmentData,
    ) => void;
    onSelectDomainEnrichmentResult: (option?: DomainEnrichmentOption) => void;
    getContactEnrichmentData: (param?: ContactEnrichmentParams) => void;
    getDomainEnrichmentData: () => void;
    setTargetContact: (contact?: DealContactPerson) => void;
    clean: () => void;
    iterateState: (params?: EnrichmentIterateStateParams) => void;
}

export class ContactEnrichmentFeature implements IContactEnrichmentFeature {
    isContactEnrichmentLoading = false;
    isDomainEnrichmentLoading = false;
    contactEnrichmentResults: ContactEnrichmentResult = {
        emails: [],
        phones: [],
        linkedins: [],
        positions: [],
    };
    contactEnrichmentResultSelection: EnrichmentResultSelection = {};
    domainEnrichmentResults: DomainEnrichmentOption[] = [];
    domainEnrichmentResultSelection?: DomainEnrichmentOption;
    currentStep: EnrichmentStep = EnrichmentStep.idle;
    refined = false;
    get selectedCompany() {
        return this.selectedContact?.relevantCompanyId
            ? this.contactsStore.companiesMap.get(
                  this.selectedContact.relevantCompanyId,
              )
            : undefined;
    }

    get selectedCompanyDomain() {
        const domain = this.selectedCompany?.customFields?.find(
            (param) => param.name === 'url',
        )?.value;
        return isNotEmpty(domain) ? domain : undefined;
    }
    get selectedContactLinkedin() {
        const linkedin = this.selectedContact?.customFields?.find(
            (param) => param.name === 'linkedin',
        )?.value;
        return isNotEmpty(linkedin) ? linkedin : undefined;
    }

    get selectedContact(): DealContactPerson | undefined {
        return this.quickActionsStore.selectedEntityId
            ? this.contactsStore.personsMap.get(
                  this.quickActionsStore.selectedEntityId,
              )
            : undefined;
    }

    get dealId() {
        return this.quickActionsStore.selectedDealId;
    }

    iterateState = (params?: EnrichmentIterateStateParams) => {
        const refined = params?.refined ?? false;
        const to = params?.to;
        this.refined = refined;
        if (to) {
            return this.switchToStep(to);
        }
        switch (this.currentStep) {
            case EnrichmentStep.idle:
            case EnrichmentStep.companySelection:
            case EnrichmentStep.domainEnrichment:
                if (!this.selectedCompany) {
                    this.switchToStep(EnrichmentStep.companySelection);
                } else if (!this.selectedCompanyDomain) {
                    this.switchToStep(EnrichmentStep.domainEnrichment);
                } else if (this.selectedCompanyDomain) {
                    this.switchToStep(EnrichmentStep.contactEnrichment);
                }
                break;
            default:
                break;
        }
    };

    switchToStep = (to: EnrichmentStep) => {
        if (this.currentStep === to) {
            return;
        }
        switch (to) {
            case EnrichmentStep.domainEnrichment:
                if (
                    !this.selectedCompanyDomain &&
                    this.domainEnrichmentResults.length === 0
                ) {
                    this.getDomainEnrichmentData();
                }
                break;
            case EnrichmentStep.refinement:
                if (this.domainEnrichmentResults.length === 0) {
                    this.getDomainEnrichmentData();
                }
                break;
            case EnrichmentStep.contactEnrichment:
                this.getContactEnrichmentData();
                break;
            default:
                break;
        }
        this.currentStep = to;
    };

    onSelectContactEnrichmentResult = (
        key: PredefinedCustomFields,
        val?: ContactEnrichmentData,
    ) => {
        const values = createEnrichmentResultSelection(
            this.contactEnrichmentResultSelection,
            key,
            val,
        );
        this.contactEnrichmentResultSelection = values;
    };

    onSelectDomainEnrichmentResult = (option?: DomainEnrichmentOption) => {
        this.domainEnrichmentResultSelection = option;
    };

    getContactEnrichmentData = () => {
        if (!this.selectedContact) {
            return;
        }
        this.clearContactEnrichmentResults();
        handleRequest(
            this.contactEnrichmentApi.getContactEnrichmentData,
            {
                dealId: this.quickActionsStore.selectedDealId ?? '',
                companyName: this.selectedContact.relevantCompany?.name ?? '',
                fullName: getFullName(
                    this.selectedContact.firstName,
                    this.selectedContact.lastName,
                ),
                linkedin: this.selectedContactLinkedin,
                domain: this.selectedCompanyDomain,
                contactId: this.selectedContact.id,
                triggeredFromSearchRefinement: this.refined,
            },
            this.onContactEnrichmentResult,
            this.setIsContactEnrichmentLoading,
            doNothing,
            'enrich-contact',
        );
    };

    getDomainEnrichmentData = () => {
        if (
            !this.selectedContact ||
            !this.selectedCompany?.name ||
            !this.dealId
        ) {
            return;
        }
        const user = this.userStore.user;
        if (!user) {
            return;
        }
        this.clearDomainEnrichmentResults();
        handleRequest(
            this.contactEnrichmentApi.getDomainEnrichmentData,
            {
                name: this.selectedCompany.name,
                language: user.language,
                dealId: this.dealId,
                relevantCompanyId: this.selectedCompany.id,
                contactId: this.selectedContact.id,
            },

            this.onDomainEnrichmentResult,
            this.setIsDomainEnrichmentLoading,
            doNothing,
            'enrich-domain',
        );
    };

    setTargetContact = (contact?: DealContactPerson) => {
        this.quickActionsStore.setSelectedEntityId(contact?.id);
        this.quickActionsStore.setSelectedEntity(contact);
    };

    onContactEnrichmentResult = (result: ContactEnrichmentResult) => {
        sortEnrichmentDataByProviderCount(result.emails);
        sortEnrichmentDataByProviderCount(result.phones);
        sortEnrichmentDataByProviderCount(result.linkedins);
        sortEnrichmentDataByProviderCount(result.positions);

        this.contactEnrichmentResults = result;
        if (result.emails.length) {
            this.onSelectContactEnrichmentResult(
                PredefinedCustomFields.mail,
                result.emails[0],
            );
        }
        if (result.phones.length) {
            this.onSelectContactEnrichmentResult(
                PredefinedCustomFields.mobile,
                result.phones[0],
            );
        }
        if (result.linkedins.length) {
            this.onSelectContactEnrichmentResult(
                PredefinedCustomFields.linkedin,
                result.linkedins[0],
            );
        }
        if (result.positions.length) {
            this.onSelectContactEnrichmentResult(
                PredefinedCustomFields.position,
                result.positions[0],
            );
        }
    };

    onDomainEnrichmentResult = (result: DomainEnrichmentOption[]) => {
        this.domainEnrichmentResults =
            sortEnrichmentDataByProviderCount(result);
    };

    setIsContactEnrichmentLoading = (isLoading: boolean) => {
        this.isContactEnrichmentLoading = isLoading;
    };

    setIsDomainEnrichmentLoading = (isLoading: boolean) => {
        this.isDomainEnrichmentLoading = isLoading;
    };

    clearContactEnrichmentResults = () => {
        this.contactEnrichmentResultSelection = {};
        this.contactEnrichmentResults = {
            emails: [],
            phones: [],
            linkedins: [],
            positions: [],
        };
    };

    clearDomainEnrichmentResults = () => {
        this.domainEnrichmentResults = [];
    };

    clean = () => {
        this.clearDomainEnrichmentResults();
        this.clearContactEnrichmentResults();
        this.isDomainEnrichmentLoading = false;
        this.isContactEnrichmentLoading = false;
        this.currentStep = EnrichmentStep.idle;
        this.refined = false;
    };

    constructor(
        private contactEnrichmentApi: IContactEnrichmentApi,
        private quickActionsStore: QuickActionsStoreImpl,
        private userStore: UserStore,
        private contactsStore: IDealContactsStore,
    ) {
        makeAutoObservable(this);
    }
}
