import {
    InstantMessageFragment,
    InstantUserMessageType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export type InstantUserNotification = UserDealImportCompletedNotification;

interface InstantUserNotificationBase {
    userTargetId: number;
    userSourceId: number;
}

interface UserDealImportCompletedNotificationContent {
    dealId: string;
    title: string;
}

interface UserDealImportCompletedNotification
    extends InstantUserNotificationBase {
    type: InstantUserMessageType.UserDealImportCompleted;
    content: UserDealImportCompletedNotificationContent;
}

export const mapInstantUserNotification = (
    raw: InstantMessageFragment,
): InstantUserNotification => {
    const basis = {
        userTargetId: raw.userTargetId,
        userSourceId: raw.userSourceId,
    };
    switch (raw.type) {
        case InstantUserMessageType.UserDealImportCompleted:
            return {
                ...basis,
                type: raw.type,
                content:
                    raw.content as UserDealImportCompletedNotificationContent,
            };
    }
};
