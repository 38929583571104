import {
    GoogleEnrichmentPartFragment,
    PlacePartFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { isNotEmpty } from 'src/utils/type.utils';

export type GoogleEnrichmentResultKey =
    | 'url'
    | 'mail'
    | 'address'
    | 'telephone'
    | 'street'
    | 'city'
    | 'post_code'
    | 'country'
    | 'country_iso';

export const GoogleEnrichmentResultKeyList: GoogleEnrichmentResultKey[] = [
    'url',
    'mail',
    'address',
    'telephone',
    'street',
    'city',
    'post_code',
    'country',
    'country_iso',
];

export interface GooglePlaceOption {
    placeId: string;
    placeName: string;
}

export type GoogleEnrichmentOption = {
    id: string;
    name?: string;
    type?: string;
} & {
    /** enrichment result fields below */
    [key in GoogleEnrichmentResultKey]: string | undefined;
};

export const transformGoogleEnrichmentFromFragment = (
    enrichment: GoogleEnrichmentPartFragment,
): GoogleEnrichmentOption => {
    return {
        id: enrichment.id,
        name: enrichment.name ?? undefined,
        type:
            !!enrichment.type && enrichment.type !== 'point_of_interest'
                ? enrichment.type
                : undefined,
        url: enrichment.website ?? undefined,
        mail: enrichment.email ?? undefined,
        address: enrichment.formattedAddress ?? undefined,
        telephone: enrichment.internationalPhoneNumber ?? undefined,
        street: transformStreetFromPlaceDetail(enrichment),
        city: enrichment.addressDetail?.city ?? undefined,
        post_code: enrichment.addressDetail?.postalCode ?? undefined,
        country: enrichment.addressDetail?.country ?? undefined,
        country_iso: enrichment.addressDetail?.countryCode ?? undefined,
    };
};

const transformStreetFromPlaceDetail = (
    raw: GoogleEnrichmentPartFragment,
): string | undefined => {
    const street =
        `${raw.addressDetail?.street ?? ''} ${raw.addressDetail?.streetNumber ?? ''}`.trim();
    return isNotEmpty(street) ? street : undefined;
};

export const createLocationData = (
    rawLocationData: PlacePartFragment,
): GooglePlaceOption => {
    const { id, name } = rawLocationData;
    return {
        placeId: id,
        placeName: name,
    };
};
