import mixpanel from 'mixpanel-browser';

import {
    AccountType,
    LicenseType,
} from 'src/domain/models/company/company.model';
import {
    Deal,
    DealState,
    MinimalDeal,
} from 'src/domain/models/deal/deal.model';
import { Pipeline } from 'src/domain/models/pipeline/pipeline.model';
import { User } from 'src/domain/models/user/user.model';

import { IMixpanelEventProperties, MixpanelEventName } from './mixpanel.model';
import { getUTMTags, jobRolesToName } from './mixpanel.utils';
import { IContextAccessor } from '../context-accessor/context-acessor.service.interface';

export type EventProps = {
    [key: string]: string | string[] | boolean | number | null | undefined;
};

export interface MixpanelService {
    init: (
        user: User,
        accountType: AccountType,
        licenseType: LicenseType,
    ) => void;
    trackEvent: (
        eventName: MixpanelEventName,
        eventProperties?: EventProps,
        dealId?: string,
    ) => void;
    reset: () => void;
    stopSessionRecording: () => void;
}

export const createMixpanelService = (
    contextAccessor: IContextAccessor,
): MixpanelService => {
    const { hostname } = window.location;
    const isProduction = hostname.includes('app');
    const devProjectId = '627494509a39f9c54a186e6901211b3a';
    const releaseProjectId = '5b69af12b0535ac257a51c566d295b5e';
    const projectId = isProduction ? releaseProjectId : devProjectId;

    const init = (
        user: User,
        accountType: AccountType,
        licenseType: LicenseType,
    ) => {
        mixpanel.init(projectId, {
            debug: !isProduction,
            ignore_dnt: true,
            api_host: 'https://appstats.buildingradar.com',
            // Enable session recording on Production.
            record_sessions_percent: isProduction ? 100 : 0,
            record_mask_text_selector: 'input[type="password"]',
        });
        mixpanel.identify(`${user.itemId}`);
        mixpanel.set_group('accountId', user.companyId);
        mixpanel.register({
            accountType,
            licenseType,
        });
        mixpanel.register(getUTMTags(), { persistent: false });
        mixpanel.register({
            session_id: `${user.itemId}_${new Date().getTime()}`,
        });
        mixpanel.register({ isImpersonated: user.isImpersonated });
        if (user.isImpersonated && user.impersonatedBy)
            mixpanel.register({
                impersonator: user.impersonatedBy,
            });
        mixpanel.register({
            userRoles: user.jobRoles.map((r) => jobRolesToName[r]),
        });
        mixpanel.register({ email: user.email });
        mixpanel.start_session_recording();
        trackEvent(MixpanelEventName.Login);
    };

    const trackEvent = (
        eventName: MixpanelEventName,
        eventProperties?: EventProps,
        dealId?: string,
    ) => {
        let properties;
        if (eventProperties) {
            properties = eventProperties;
        }
        if (dealId) {
            properties = {
                ...eventProperties,
                ...getMixpanelEventPropertiesFromDealId(
                    dealId,
                    contextAccessor.getDeal,
                    contextAccessor.getPipeline,
                ),
            };
        }
        if (properties) {
            mixpanel.track_with_groups(eventName, properties, properties);
        } else {
            mixpanel.track(eventName);
        }
    };

    const reset = () => {
        trackEvent(MixpanelEventName.Logout);
    };

    const getMixpanelEventPropertiesFromDealId = (
        dealId: string,
        getDeal: (dealId: string) => Deal | MinimalDeal | undefined,
        getPipeline: (pipelineID: string) => Pipeline | null,
    ): IMixpanelEventProperties | null => {
        const deal = getDeal(dealId);
        if (!deal) {
            return null;
        }
        const pipeline = getPipeline(deal.pipeline.id);
        if (!pipeline) {
            return null;
        }

        const currentStageId =
            deal.stageId === pipeline.config.stageConfig.lastStageId
                ? 'last'
                : deal.stageId;

        const currentStageName =
            pipeline.config.stageConfig.stageMap.get(currentStageId)?.name ??
            '';

        return {
            projectId: deal.project?.id ?? undefined,
            pipelineId: deal.pipeline.id,
            pipelineName: deal.pipeline.name,
            dealId: deal.id,
            currentStageId,
            currentStageName,
            currentState: deal.state ?? DealState.inPlay,
        };
    };

    const stopSessionRecording = () => {
        mixpanel.stop_session_recording();
    };

    return {
        init,
        trackEvent,
        reset,
        stopSessionRecording,
    };
};
