import { type FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BrIcon, IconName } from '@buildingradar/br_component_lib';
import { FolderType } from 'src/domain/models/folder/folder.model';
import { type Pipeline } from 'src/domain/models/pipeline/pipeline.model';
import { createPipelineRoute } from 'src/presentation/modules/pipeline/pipeline.utils';
import { NavItemComponent } from 'src/presentation/modules/primary-nav-bar/components/nav-item/nav-item.component';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { useFolderRoute } from 'src/presentation/modules/router/utils/use-route-data.hook';
import { StyledFolderSection } from './folders-section.styled';
import { getFolderIcon, isFolderActive } from './folders-section.util';

interface FoldersSectionComponentProps {
    showFolderShadow?: boolean;
    myPipeline: Pipeline | null;
}

export const FoldersSectionComponent: FC<FoldersSectionComponentProps> =
    observer(({ showFolderShadow = false, myPipeline }) => {
        const { t } = useTranslation();

        const currentFolder = useFolderRoute();
        const currentRoute = useLocation().pathname;

        const isPipelineActive = currentRoute.startsWith(AppRoute.pipelines);
        const pipelineIcon = getFolderIcon(
            isPipelineActive,
            FolderType.interesting,
        );

        const isMarkedDoneActive = currentRoute === AppRoute.markedDone;
        const markedDoneIcon = getFolderIcon(
            isMarkedDoneActive,
            FolderType.exported,
        );

        const isScreeningActive =
            isFolderActive(FolderType.inbox, currentFolder) ||
            !!matchPath(currentRoute, AppRoute.screening);

        const screeningIcon = getFolderIcon(
            isScreeningActive,
            FolderType.inbox,
        );

        const isHomeActive = currentRoute === AppRoute.home;

        return (
            <StyledFolderSection>
                <NavItemComponent
                    hideTooltip
                    isActive={isHomeActive}
                    name="Home"
                    className="home"
                    icon={
                        <BrIcon
                            iconName={IconName.BrLogoIcon}
                            iconColor={
                                isHomeActive ? 'var(--accent-link)' : undefined
                            }
                            iconSize={30}
                        />
                    }
                    route={AppRoute.home}
                    isShadowVisible={showFolderShadow}
                />

                <NavItemComponent
                    key={FolderType.inbox}
                    name={t('screening.title')}
                    className="screening"
                    icon={screeningIcon}
                    route={AppRoute.screening}
                    isActive={isScreeningActive}
                    isShadowVisible={showFolderShadow}
                />

                {myPipeline && (
                    <NavItemComponent
                        name="Pipelines"
                        className="pipelines"
                        icon={pipelineIcon}
                        route={createPipelineRoute(myPipeline.id)}
                        isActive={isPipelineActive}
                        isShadowVisible={showFolderShadow}
                    />
                )}

                <NavItemComponent
                    name={t('deal_view.state.marked_done')}
                    className="marked-done"
                    icon={markedDoneIcon}
                    route={AppRoute.markedDone}
                    isActive={isMarkedDoneActive}
                    isShadowVisible={showFolderShadow}
                />
            </StyledFolderSection>
        );
    });
