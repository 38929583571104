import { type DealContentMetadataFragment } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export const DEAL_ATTACHMENTS_LIMIT = 20;

export enum EDealAttachmentType {
    FILE = 'FILE',
    URL = 'URL',
    TEXT = 'TEXT',
}

export enum EDealAttachmentCreationSource {
    USER_CREATED = 'USER_CREATED',
    DEAL_IMPORT = 'DEAL_IMPORT',
    ADDITIONAL_SOURCES_LLM = 'ADDITIONAL_SOURCES_LLM',
}

export type TDealFileAttachment = {
    id: string;
    type: EDealAttachmentType.FILE;
    sourceName: string;
    createdDate: Date;
    hasContent: boolean;
    creationSource: EDealAttachmentCreationSource;
};

export type TDealUrlAttachment = {
    id: string;
    type: EDealAttachmentType.URL;
    source: string;
    createdDate: Date;
    hasContent: boolean;
    creationSource: EDealAttachmentCreationSource;
};

export type TDealTextAttachment = {
    id: string;
    type: EDealAttachmentType.TEXT;
    sourceName: string;
    source: string;
    createdDate?: Date;
    hasContent: boolean;
    creationSource: EDealAttachmentCreationSource;
};

export type TDealAttachment =
    | TDealFileAttachment
    | TDealUrlAttachment
    | TDealTextAttachment;

export enum EDealAttachmentUploadDialogTab {
    fileUpload = 'file-upload',
    webLink = 'web-link',
}

export const mapDealAttachment = (
    attachment: DealContentMetadataFragment,
): TDealAttachment => {
    if (attachment.type.toString() === EDealAttachmentType.FILE) {
        return {
            id: attachment.id,
            type: EDealAttachmentType.FILE,
            sourceName: attachment.name,
            createdDate: new Date(attachment.publishedDate),
            hasContent: attachment.hasContent,
            creationSource:
                attachment.creationSource as unknown as EDealAttachmentCreationSource,
        } as TDealFileAttachment;
    } else if (attachment.type.toString() === EDealAttachmentType.TEXT) {
        return {
            id: attachment.id,
            type: EDealAttachmentType.TEXT,
            sourceName: attachment.name,
            source: attachment.source,
            createdDate: new Date(attachment.publishedDate),
            hasContent: attachment.hasContent,
            creationSource:
                attachment.creationSource as unknown as EDealAttachmentCreationSource,
        } as TDealTextAttachment;
    }

    return {
        id: attachment.id,
        type: EDealAttachmentType.URL,
        source: attachment.source,
        createdDate: attachment.publishedDate
            ? new Date(attachment.publishedDate)
            : undefined,
        hasContent: attachment.hasContent,
        creationSource:
            attachment.creationSource as unknown as EDealAttachmentCreationSource,
    } as TDealUrlAttachment;
};
