import { UserJobRoles } from 'src/data/api/graphql/br_user/generated/graphql-sdk';

export const jobRolesToName: Record<UserJobRoles, string> = {
    [UserJobRoles.Screener]: 'Screener',
    [UserJobRoles.Researcher]: 'Researcher',
    [UserJobRoles.MeetingBooker]: 'Meeting booker',
    [UserJobRoles.SaleOwner]: 'Sale owner',
    [UserJobRoles.Supervisor]: 'Supervisor',
    [UserJobRoles.Observer]: 'Observer',
};

export const getUTMTags = () => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const params: Record<string, string> = {};
    ['utm_source', 'utm_emailblock', 'utm_cta'].forEach((utm) => {
        const utm_value = searchParams.get(utm);
        if (utm_value) {
            params[utm + '_br'] = utm_value;
        }
    });
    return params;
};
