import { makeAutoObservable } from 'mobx';
import { ILiveNotificationApi } from '../data/live-notification.api';
import { ILiveNotificationFeature } from './live-notification.feature.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { doNothing } from 'src/utils/function.utils';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { InstantUserNotification } from '../data/live-notification.model';
import { InstantUserMessageType } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { t, TFunction } from 'i18next';
import { getI18n } from 'react-i18next';
import { createDealPageLink } from 'src/presentation/modules/router/utils/route.utils';
import { DealOpenSource } from 'src/domain/models/deal/deal.model';

export class LiveNotificationFeature implements ILiveNotificationFeature {
    private t: TFunction = getI18n().t;
    constructor(
        private liveNotificationApi: ILiveNotificationApi,
        private toasterStore: ToasterStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }
    subscribeToLiveNotifications = async () => {
        await handleRequestAsync(
            this.liveNotificationApi.subscribeToLiveNotifications,
            {
                onNext: this.onNewMessage,
            },
            doNothing,
            (e) => {
                this.baseStore.onRequestFailed(
                    'subscribe-to-live-notifications',
                    e,
                );
            },
            'live-notifications',
        );
        // do not resubscribe, let it happen naturally when user reloads page.
    };
    onNewMessage = (msg: InstantUserNotification) => {
        switch (msg.type) {
            case InstantUserMessageType.UserDealImportCompleted:
                this.toasterStore.showMessage({
                    type: 'success',
                    title: t<string>(
                        'live_notification.deal_import_completed.toast.title',
                    ),
                    autoClose: 6000,
                    message: msg.content.title,
                    primaryLink: createDealPageLink(
                        msg.content.dealId,
                        DealOpenSource.ImportCompletedNotification,
                    ),
                    primaryLinkText: this.t(
                        'live_notification.deal_import_completed.toast.link_text',
                    ),
                });
                break;
            default:
                break;
        }
    };
}
