import { makeAutoObservable } from 'mobx';

import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import { DealsApi } from 'src/data/api/deal/deal.api';
import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { Deal } from 'src/domain/models/deal/deal.model';
import { doNothing } from 'src/utils/function.utils';
import { Cancellable, handleRequest } from 'src/utils/handle-request.utils';

export interface DealActionsFeature {
    disqualifyDeal: (
        deal: Deal,
        disqualifyReason?: string,
        onSuccess?: () => void,
    ) => Cancellable;
    restoreDeal: (deal: Deal) => Cancellable;
    markDealAsDone: (deal: Deal) => Cancellable;
    parameterElementsMap: Map<string, HTMLDivElement>;
    setParameterElementsMap: (refMap: Map<string, HTMLDivElement>) => void;
    isDisqualifyModalOpen: boolean;
    openDisqualifyModal: () => void;
    closeDisqualifyModal: () => void;
    isBypassModalOpen: boolean;
    openBypassModal: () => void;
    closeBypassModal: () => void;
}

export class DealActionsFeatureImpl implements DealActionsFeature {
    isDisqualifyModalOpen = false;
    isBypassModalOpen = false;
    parameterElementsMap: Map<string, HTMLDivElement> = new Map();

    constructor(
        private dealsStore: DealsStore,
        private pipelineStore: PipelineStore,
        private dealsApi: DealsApi,
        private baseStore: IBaseStore,
        private achievementService: AchievementService,
    ) {
        makeAutoObservable(this);
    }

    openDisqualifyModal = () => {
        this.isDisqualifyModalOpen = true;
    };

    closeDisqualifyModal = () => {
        this.isDisqualifyModalOpen = false;
    };

    setParameterElementsMap = (offsetMap: Map<string, HTMLDivElement>) => {
        offsetMap.forEach((element, key) => {
            this.parameterElementsMap.set(key, element);
        });
    };

    disqualifyDeal = (
        deal: Deal,
        disqualifyReason?: string,
        onSuccess?: () => void,
    ): Cancellable => {
        return handleRequest(
            this.dealsApi.disqualifyDeal,
            { dealId: deal.id, reason: disqualifyReason },
            (deal: Deal | undefined) =>
                this.onUpdatedDealResponse(deal, true, onSuccess),
            doNothing,
            (error) => this.baseStore.onRequestFailed('disqualify-deal', error),
        );
    };

    onUpdatedDealResponse = (
        deal: Deal | undefined,
        isDisqualify?: boolean,
        onSuccess?: () => void,
    ) => {
        if (deal) {
            this.pipelineStore.setNeedToRequestPipelines(true);
            this.dealsStore.dealsMap.set(deal.id, deal);
            this.dealsStore.setNeedToSyncDealAlert(true);

            if (isDisqualify) {
                this.achievementService.call(
                    UserAchievementName.DisqualifyADeal,
                );
            }
            onSuccess?.();
        }
    };

    restoreDeal = (deal: Deal): Cancellable => {
        return handleRequest(
            this.dealsApi.restoreDeal,
            { dealId: deal.id },
            (deal: Deal | undefined) => this.onUpdatedDealResponse(deal),
            doNothing,
            (error) => this.baseStore.onRequestFailed('restore-deal', error),
        );
    };

    markDealAsDone = (deal: Deal): Cancellable => {
        return handleRequest(
            this.dealsApi.markDealAsDone,
            { dealId: deal.id },
            (deal: Deal | undefined) => this.onUpdatedDealResponse(deal),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('mark-deal-as-done', error),
        );
    };

    openBypassModal = () => {
        this.isBypassModalOpen = true;
    };

    closeBypassModal = () => {
        this.isBypassModalOpen = false;
    };
}
