import { makeAutoObservable } from 'mobx';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { type NotificationsApi } from 'src/data/api/notifications/notifications.api';
import { type NotificationsStore } from 'src/data/stores/notifications/notifications.store';
import { type IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { sortUncompletedRemindersByDueDateAsc } from 'src/presentation/modules/notifications-flyout/components/reminders-dashboard/reminder-card/reminder-due-date.helper';
import { type NotificationsFeature } from './notifications-interface.feature';

export class NotificationsFeatureImpl implements NotificationsFeature {
    constructor(
        private notificationsApi: NotificationsApi,
        private notificationStore: NotificationsStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    isOpened: NotificationsFeature['isOpened'] = false;
    isLoading: NotificationsFeature['isLoading'] = false;

    get mentions(): NotificationsFeature['mentions'] {
        return this.notificationStore.mentions;
    }

    get unreadMentions(): NotificationsFeature['unreadMentions'] {
        return this.notificationStore.mentions.filter(({ isRead }) => !isRead);
    }

    get uncompletedReminders(): NotificationsFeature['uncompletedReminders'] {
        return sortUncompletedRemindersByDueDateAsc(
            this.notificationStore.reminders,
        );
    }

    get overdueReminders(): NotificationsFeature['overdueReminders'] {
        return this.uncompletedReminders.filter(
            ({ dueDate }) => dueDate <= new Date(),
        );
    }

    get upcomingReminders(): NotificationsFeature['upcomingReminders'] {
        return this.uncompletedReminders.filter(
            ({ dueDate }) => dueDate > new Date(),
        );
    }

    setIsLoading = (flag: boolean) => {
        this.isLoading = flag;
    };

    toggleNotificationsOpened: NotificationsFeature['toggleNotificationsOpened'] =
        (flag: boolean) => {
            this.isOpened = flag;
        };

    fetchRemindersAndMentions: NotificationsFeature['fetchRemindersAndMentions'] =
        async () => {
            const res = await handleRequestAsync(
                this.notificationsApi.getRemindersAndMentions,
                {},
                (isLoading) => {
                    if (!isLoading) {
                        this.setIsLoading(false);
                    } else {
                        const hasReminders =
                            this.notificationStore.reminders.length;
                        const hasMentions =
                            this.notificationStore.mentions.length;

                        // Do not show loader if there are already reminders or mentions.
                        // Just refresh them.
                        if (!hasReminders && !hasMentions) {
                            this.setIsLoading(true);
                        }
                    }
                },
                (error) =>
                    this.baseStore.onRequestFailed(
                        'fetch-reminders-and-mentions',
                        error,
                    ),
            );

            if (res) {
                this.notificationStore.setReminders(res.reminders);
                this.notificationStore.setMentions(res.mentions);
            }
        };

    markMentionAsRead: NotificationsFeature['markMentionAsRead'] = async (
        mentionId,
    ) => {
        const res = await handleRequestAsync(
            this.notificationsApi.markMentionAsRead,
            { mentionId },
            () => {},
            (error) =>
                this.baseStore.onRequestFailed(
                    `mark-mention-as-read-${mentionId}`,
                    error,
                ),
        );

        if (res?.readCommentMention.ok) {
            this.notificationStore.markMentionAsRead(mentionId);
        }

        return res;
    };

    dismissMention: NotificationsFeature['dismissMention'] = async (
        mentionId,
    ) => {
        const res = await handleRequestAsync(
            this.notificationsApi.dismissMention,
            { mentionId },
            () => {},
            (error) =>
                this.baseStore.onRequestFailed(
                    `dismiss-mention-${mentionId}`,
                    error,
                ),
        );

        if (res?.dismissCommentMention.ok) {
            this.notificationStore.dismissMention(mentionId);
        }

        return res;
    };
}
