import { makeAutoObservable } from 'mobx';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import {
    IQuickActionsStoreInterface,
    QuickActionData,
} from 'src/data/stores/actions/quick-actions.store.interface';
import {
    ActionFeatureIdentifierNamesMap,
    ActionFeatureLaunchSource,
    ActionFeaturesIdentifiers,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';

import { IQuickActionsFeatureInterface } from './quick-actions-interface.feature';

const enablementActionsList = [
    ActionFeaturesIdentifiers.xray,
    ActionFeaturesIdentifiers.save_contact_to_phone,
    ActionFeaturesIdentifiers.email_templates,
    ActionFeaturesIdentifiers.reference_details,
    ActionFeaturesIdentifiers.contact_panel,
    ActionFeaturesIdentifiers.contact_enrichment,
];

export class QuickActionsFeature implements IQuickActionsFeatureInterface {
    get currentQuickAction() {
        return this.quickActionsStore.currentQuickAction;
    }

    get selectedEntityId() {
        return this.quickActionsStore.selectedEntityId;
    }

    get selectedEntity() {
        return this.quickActionsStore.selectedEntity;
    }

    get isQuickActionsPanelOpen() {
        return (
            !!this.currentQuickAction &&
            enablementActionsList.includes(this.currentQuickAction)
        );
    }

    get selectedDealId() {
        return this.quickActionsStore.selectedDealId;
    }

    setQuickAction = (data: QuickActionData) => {
        const {
            launchSource,
            dealId,
            quickAction,
            mixpanelEventName = MixpanelEventName.LaunchedQuickAction,
            additionalMixpanelProps,
        } = data;
        this.quickActionsStore.setQuickAction(data);
        if (launchSource && dealId && quickAction) {
            this.sendMixpanelEvent(
                mixpanelEventName,
                launchSource,
                dealId,
                quickAction,
                additionalMixpanelProps,
            );
        }
    };

    private sendMixpanelEvent = (
        mixpanelEventName: MixpanelEventName,
        launchSource: ActionFeatureLaunchSource,
        dealId: string,
        quickAction: ActionFeaturesIdentifiers,
        additionalMixpanelProps?: { [key: string]: string },
    ) => {
        this.mixpanelService.trackEvent(
            mixpanelEventName,
            {
                quickActionName:
                    mixpanelEventName === MixpanelEventName.LaunchedQuickAction
                        ? ActionFeatureIdentifierNamesMap[quickAction]
                        : undefined,
                launchSource: launchSource.toString(),
                ...additionalMixpanelProps,
            },
            dealId,
        );
    };

    get clean() {
        return this.quickActionsStore.clean;
    }

    setSelectedEntityId = (id: string) => {
        this.quickActionsStore.setSelectedEntityId(id);
    };

    constructor(
        private quickActionsStore: IQuickActionsStoreInterface,
        private mixpanelService: MixpanelService,
    ) {
        makeAutoObservable(this);
    }
}
