import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app';
import { initializeSentry } from './sentry/instrument';

import './index.css';
import '@buildingradar/ui_library/styles.css';

import './resources/translations/i18next';
import { getRoot } from './utils/element.utils';

initializeSentry();

const container = getRoot();
const root = createRoot(container as HTMLElement);

root.render(
    <StrictMode>
        <Suspense fallback="Loading translations, please wait">
            <App />
        </Suspense>
    </StrictMode>,
);
