import { encodeStringHighlightToUrl } from 'src/utils/url.utils';
import { ContactExtractionStatus } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { getFullName } from 'src/domain/models/user/user.model';
import {
    type DealCompanyCandidate,
    type DealContactCandidate,
    type ContactExtractionResultData,
    ContactExtractionResultStatus,
    ContactCandidateGroup,
} from '../../data/contact-extraction.model';
import {
    DealContactCompany,
    DealContactPerson,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { TDealAttachment } from 'src/app-features/deal-attachments/domain/deal-attachments.model';

export const MAX_CANDIDATE_SOURCE_SIZE = 10;

export const normalizeCandidateNameForComparison = (name: string): string => {
    // Replaces umlaut for german names
    let normalized = name
        .replace(/[ä]/g, 'ae')
        .replace(/[ö]/g, 'oe')
        .replace(/[ü]/g, 'ue');

    // Removes common accents (eg.: é è ê)
    normalized = normalized
        .replace(/[àáâÀÁÂ]/g, 'a')
        .replace(/[èéêÈÉÊ]/g, 'e')
        .replace(/[ìíîÌÍÎ]/g, 'i')
        .replace(/[òóôÒÓÔ]/g, 'o')
        .replace(/[ùúûÙÚÛ]/g, 'u')
        .replace(/[ýŷÝŶ]/g, 'y')
        .replace(/[ñÑ]/g, 'n');

    // Any other chars like quotation and apostrophe
    normalized = normalized
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '')
        .trim();

    return normalized;
};

export const checkIfHasExtractedData = (
    result: ContactExtractionResultData | undefined,
): boolean => {
    if (!result) return false;
    return (
        result.extractedContacts.length > 0 ||
        result.extractedCompanies.length > 0
    );
};

export const determineExtractorStatus = (
    projectContextResult: ContactExtractionResultData | null | undefined,
    webSearchResult: ContactExtractionResultData | null | undefined,
): ContactExtractionResultStatus => {
    if (!projectContextResult || !webSearchResult)
        return ContactExtractionResultStatus.NeverRun;

    if (
        projectContextResult.status === ContactExtractionStatus.Failed &&
        webSearchResult.status === ContactExtractionStatus.Failed
    ) {
        return ContactExtractionResultStatus.Error;
    }

    if (projectContextResult.status === ContactExtractionStatus.InProgress) {
        return ContactExtractionResultStatus.ArticlesSearchInProgress;
    }

    if (webSearchResult.status === ContactExtractionStatus.InProgress) {
        return ContactExtractionResultStatus.WebSearchInProgress;
    }

    if (
        projectContextResult.status === ContactExtractionStatus.Success ||
        webSearchResult.status === ContactExtractionStatus.Success
    ) {
        return checkIfHasExtractedData(projectContextResult)
            ? ContactExtractionResultStatus.Success
            : ContactExtractionResultStatus.NoContacts;
    }

    return ContactExtractionResultStatus.NeverRun;
};

const deduplicate = (
    candidateName: string,
    currentCandidate: DealContactCandidate | DealCompanyCandidate,
    result:
        | Map<string, DealContactCandidate>
        | Map<string, DealCompanyCandidate>,
) => {
    const normalizedName = normalizeCandidateNameForComparison(candidateName);
    if (result.has(normalizedName)) {
        // If there is a duplicate, we just append its extraction source to the primary one.
        // and MERGE sources together
        const existingContact = result.get(normalizedName)!;
        currentCandidate.extractedFromSources.forEach((source) => {
            if (!existingContact.extractedFromSources.includes(source)) {
                existingContact.extractedFromSources.push(source);
            }
        });
        currentCandidate.sourceUrls.forEach((source) => {
            if (!existingContact.sourceUrls.includes(source)) {
                existingContact.sourceUrls.push(
                    encodeStringHighlightToUrl(source, candidateName),
                );
            }
        });
        currentCandidate.sourceContents.forEach((source) => {
            if (
                !existingContact.sourceContents.find((s) => s.id === source.id)
            ) {
                existingContact.sourceContents.push(source);
            }
        });

        (result as Map<string, unknown>).set(normalizedName, existingContact);
        return result;
    }

    (result as Map<string, unknown>).set(normalizedName, {
        ...currentCandidate,
        // Highlight contact name in source URLs.
        sourceUrls: currentCandidate.sourceUrls.map((url) =>
            encodeStringHighlightToUrl(url, candidateName),
        ),
    });

    return result;
};

export const getCombinedCandidates = (
    projectContextResult: ContactExtractionResultData | null | undefined,
    webSearchResult?: ContactExtractionResultData | null | undefined,
) => {
    const articleSearchContacts = projectContextResult?.extractedContacts ?? [];
    const articleSearchCompanies =
        projectContextResult?.extractedCompanies ?? [];
    const webSearchContacts = webSearchResult?.extractedContacts ?? [];
    const webSearchCompanies = webSearchResult?.extractedCompanies ?? [];

    const contactMap = [...articleSearchContacts, ...webSearchContacts].reduce(
        (result, contact) => {
            const contactName = getFullName(
                contact.firstName,
                contact.lastName,
            );
            return deduplicate(contactName, contact, result) as Map<
                string,
                DealContactCandidate
            >;
        },
        new Map<string, DealContactCandidate>(),
    );

    const companyMap = [
        ...articleSearchCompanies,
        ...webSearchCompanies,
    ].reduce((result, company) => {
        const companyName = company.name;

        return deduplicate(companyName, company, result) as Map<
            string,
            DealCompanyCandidate
        >;
    }, new Map<string, DealCompanyCandidate>());

    return {
        contacts: Array.from(contactMap.values()),
        companies: Array.from(companyMap.values()),
    };
};

export const groupCandidatesByCompany = (
    contacts: DealContactCandidate[],
    companies: DealCompanyCandidate[],
): ContactCandidateGroup[] => {
    const groups: ContactCandidateGroup[] = companies.map((company) => ({
        company,
        contacts: contacts.filter(
            (contact) => contact.companyName === company.name,
        ),
    }));
    const contactsWithoutCompany = contacts.filter(
        (contact) =>
            !companies.some((company) => company.name === contact.companyName),
    );

    if (contactsWithoutCompany.length > 0) {
        groups.push({
            company: undefined,
            contacts: contactsWithoutCompany,
        });
    }

    return groups;
};

export const mapCandidateSources = (
    attachmentList: TDealAttachment[],
    candidates: DealContactCandidate[] | DealCompanyCandidate[],
): (DealContactCandidate | DealCompanyCandidate)[] => {
    return candidates.map((candidate) => {
        const sourceUrlSize = candidate.sourceUrls.length;
        const sourceContentSize = candidate.sourceContents.length;
        const sourceUrlDisplaySize = Math.min(
            sourceUrlSize,
            MAX_CANDIDATE_SOURCE_SIZE,
        );
        const sourceContentDisplaySize = Math.min(
            sourceContentSize,
            MAX_CANDIDATE_SOURCE_SIZE - sourceUrlDisplaySize,
        );
        return {
            ...candidate,
            sourceUrls: candidate.sourceUrls?.slice(0, sourceUrlDisplaySize),
            sourceContents: candidate.sourceContents
                ?.slice(0, sourceContentDisplaySize)
                .map((source) => ({
                    id: source.id,
                    attachment: attachmentList.find(
                        (attachment) => attachment.id === source.id,
                    ),
                })),
        };
    });
};

export const removeSavedCandidates = (
    groups: ContactCandidateGroup[],
    savedContacts: DealContactPerson[],
    savedCompanies: DealContactCompany[],
): ContactCandidateGroup[] => {
    const filteredGroups: ContactCandidateGroup[] = [];
    groups.forEach((group) => {
        const isCompanySaved =
            group.company &&
            savedCompanies.some((c) => c.name === group.company?.name);

        const unsavedContacts = group.contacts.filter(
            (contact) =>
                !savedContacts.some(
                    (saved) =>
                        getFullName(saved.firstName, saved.lastName) ===
                        getFullName(contact.firstName, contact.lastName),
                ),
        );
        if (isCompanySaved && unsavedContacts.length === 0) {
            return;
        }
        if (group.company) {
            group.company.saved = isCompanySaved;
        }
        if (!group.company && unsavedContacts.length === 0) {
            return;
        }
        filteredGroups.push({
            ...group,
            contacts: unsavedContacts,
        });
    });
    return filteredGroups;
};

export const isContactCandidate = (
    candidate: DealContactCandidate | DealCompanyCandidate | undefined,
): candidate is DealContactCandidate => {
    if (!candidate) return false;
    return 'firstName' in candidate;
};
