import { AbortParams } from 'src/data/api/api-client';
import { SearchGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    createSearchFromSubscribedSearch,
    Search,
} from 'src/domain/models/search/search.model';
import {
    createSubscription,
    SearchSubscriptionData,
} from 'src/domain/models/subscription/subscription.model';

export interface SubscriptionApi {
    getUserSubscriptionsQueryKey: () => string[];
    getSubscribeToSearchQueryKey: () => string[];
    getUnsubscribeFromSearchQueryKey: () => string[];
    getPauseSearchQueryKey: () => string[];
    getResumeSearchQueryKey: () => string[];
    getUserSubscriptions: () => Promise<SearchSubscriptionData[]>;
    subscribeToSearch: (
        params: SubscribeOrUnsubscribeToSearchParams,
    ) => Promise<Search>;
    unsubscribeFromSearch: (
        params: SubscribeOrUnsubscribeToSearchParams,
    ) => Promise<boolean>;
    pauseSearch: (params: UpdateSearchParams) => Promise<boolean>;
    resumeSearch: (params: UpdateSearchParams) => Promise<boolean>;
}

interface SubscribeOrUnsubscribeToSearchParams extends Partial<AbortParams> {
    subscriberId: number;
    searchId: number;
}

interface UpdateSearchParams extends Partial<AbortParams> {
    searchId: number;
}

export const createSubscriptionsApi = (
    searchGqlSdk: SearchGqlSdk,
): SubscriptionApi => {
    const queryKeyNamespace = 'subscriptionApi';
    const buildQueryKey = (key: string) => `${queryKeyNamespace}:${key}`;

    const getUserSubscriptionsQueryKey = () => [
        buildQueryKey('searches-and-subscriptions'),
    ];
    const getSubscribeToSearchQueryKey = () => [
        buildQueryKey('subscribe-to-search'),
    ];
    const getUnsubscribeFromSearchQueryKey = () => [
        buildQueryKey('unsubscribe-from-search'),
    ];
    const getPauseSearchQueryKey = () => [buildQueryKey('pause-search')];
    const getResumeSearchQueryKey = () => [buildQueryKey('resume-search')];

    const getUserSubscriptions = async () => {
        const { searchesAndSubscriptions } =
            await searchGqlSdk.GetSearchesAndSubscriptions();
        return searchesAndSubscriptions.map(createSubscription);
    };

    const subscribeToSearch = async (
        params: SubscribeOrUnsubscribeToSearchParams,
    ) => {
        const { subscribeSearch } =
            await searchGqlSdk.SubscribeToSearch(params);
        return createSearchFromSubscribedSearch(subscribeSearch);
    };

    const unsubscribeFromSearch = async (
        params: SubscribeOrUnsubscribeToSearchParams,
    ) => {
        const { unsubscribeSearch } =
            await searchGqlSdk.UnsubscribeToSearch(params);
        return unsubscribeSearch.ok;
    };

    const pauseSearch = async (params: UpdateSearchParams) => {
        const { pauseSearch } = await searchGqlSdk.PauseSearch(params);
        return pauseSearch.ok;
    };

    const resumeSearch = async (params: UpdateSearchParams) => {
        const { unpauseSearch } = await searchGqlSdk.UnpauseSearch(params);
        return unpauseSearch.ok;
    };

    return {
        getUserSubscriptionsQueryKey,
        getSubscribeToSearchQueryKey,
        getUnsubscribeFromSearchQueryKey,
        getPauseSearchQueryKey,
        getResumeSearchQueryKey,
        getUserSubscriptions,
        subscribeToSearch,
        unsubscribeFromSearch,
        pauseSearch,
        resumeSearch,
    };
};
