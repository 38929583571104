import {
    type DismissMentionMutation,
    type MarkMentionAsReadMutation,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { type ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { Reminder } from 'src/domain/models/reminder/reminder.model';
import {
    type Mention,
    createMention,
} from 'src/domain/models/mention/mention.model';

type TMarkMentionAsReadParams = {
    mentionId: string;
};

type TDismissMentionParams = {
    mentionId: string;
};

export interface NotificationsApi {
    getRemindersAndMentions: () => Promise<{
        mentions: Mention[];
        reminders: Reminder[];
    }>;
    markMentionAsRead: (
        params: TMarkMentionAsReadParams,
    ) => Promise<MarkMentionAsReadMutation>;
    dismissMention: (
        params: TDismissMentionParams,
    ) => Promise<DismissMentionMutation>;
}

export const createNotificationsApi = (
    processGqlSdk: ProcessGqlSdk,
): NotificationsApi => {
    const getRemindersAndMentions: NotificationsApi['getRemindersAndMentions'] =
        async () => {
            const { me } = await processGqlSdk.GetMyRemindersAndMentions();

            return {
                mentions: me.mentions?.map(createMention) ?? [],
                reminders: (me.reminders ?? []).map(
                    (rawReminder) => new Reminder(rawReminder),
                ),
            };
        };

    const markMentionAsRead: NotificationsApi['markMentionAsRead'] = ({
        mentionId,
    }) => processGqlSdk.MarkMentionAsRead({ mentionId });

    const dismissMention: NotificationsApi['dismissMention'] = ({
        mentionId,
    }) => processGqlSdk.DismissMention({ mentionId });

    return { getRemindersAndMentions, markMentionAsRead, dismissMention };
};
