import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
    useAuthFeature,
    useNavbarFeature,
    useNotificationsFeature,
} from 'src/app-context/use-features';

import { MobileMenuComponent } from './mobile-menu.component';

export const MobileMenuContainer = observer(() => {
    const { toggleMobileMenu } = useNavbarFeature();
    const { isOpened: areNotificationsOpened, toggleNotificationsOpened } =
        useNotificationsFeature();
    const { user } = useAuthFeature();
    const currentRoute = useLocation().pathname;

    if (!user) {
        return null;
    }

    return (
        <MobileMenuComponent
            user={user}
            areNotificationsOpened={areNotificationsOpened}
            toggleMenuOpen={toggleMobileMenu}
            toggleNotifications={toggleNotificationsOpened}
            currentRoute={currentRoute}
        />
    );
});
