import { EnrichmentResultSelection } from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import {
    DealContactCompany,
    DealContactPerson,
    DealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { IDealContactsStore } from 'src/app-features/contact/data/stores/interfaces/deal-contacts.store.interface';
import {
    ContactUpdateMetricsInfo,
    RoleUpdateSource,
} from 'src/app-features/contact/domain/interfaces/deal-contacts.feature.interface';
import { CustomParameter } from 'src/app-features/custom-parameter/data/model/custom-parameter.model';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { isNotEmpty } from 'src/utils/string.utils';

export enum ContactOrCompanyCreationSource {
    Manual = 'Manual',
    OneClickNextAction = '1 Click Next Action',
    ContactFinder = 'Contact Finder',
}

export interface IDealContactsTrackingService {
    trackContactOrCompanyFieldEdition: (
        contactOrCompany: DealContactPerson | DealContactCompany,
        dealId: string,
        metricsInfo?: ContactUpdateMetricsInfo,
    ) => void;

    trackToggleContactOrCompanyArchiveStatus: (
        dealId: string,
        contactId: string,
        contactType: DealContactType,
        archive: boolean,
    ) => void;

    trackContactOrCompanyRoleUpdate: (
        contactType: DealContactType,
        dealId: string,
        contactId: string,
        updateSource: RoleUpdateSource,
        role?: string,
    ) => void;
}

export const createDealContactsTrackingService = (
    mixpanelService: MixpanelService,
    dealContactsStore: IDealContactsStore,
): IDealContactsTrackingService => {
    const trackContactOrCompanyFieldEdition = (
        contactOrCompany: DealContactPerson | DealContactCompany,
        dealId: string,
        metricsInfo?: ContactUpdateMetricsInfo,
    ) => {
        if (metricsInfo?.field) {
            const { field, enrichmentInfo } = metricsInfo;

            const isPerson = contactOrCompany.type === DealContactType.person;

            const oldContactOrCompany = dealContactsStore[
                isPerson ? 'personsMap' : 'companiesMap'
            ].get(contactOrCompany.id);

            const entityIdPropertyName = isPerson ? 'contactId' : 'companyId';
            const eventName = isPerson
                ? MixpanelEventName.EditedContactParameter
                : MixpanelEventName.EditedCompanyParameter;
            const fields = Array.isArray(field) ? field : [field];
            const fromEnrichment = !!enrichmentInfo;
            fields.forEach((fieldItem) => {
                const enrichmentProviders =
                    enrichmentInfo?.[
                        fieldItem as keyof EnrichmentResultSelection
                    ]?.providers ?? [];
                if (oldContactOrCompany) {
                    const oldValue = getContactFieldValue(
                        fieldItem,
                        oldContactOrCompany,
                    );
                    const newValue = getContactFieldValue(
                        fieldItem,
                        contactOrCompany,
                    );
                    mixpanelService.trackEvent(
                        eventName,
                        {
                            [entityIdPropertyName]: contactOrCompany.id,
                            identifierEditedParameter: getContactFieldName(
                                fieldItem,
                                contactOrCompany,
                            ),
                            editAction: getActionToTrackOnEdition(
                                oldValue?.toString(),
                                newValue?.toString(),
                            ),
                            fromEnrichment,
                            enrichmentProviders,
                        },
                        dealId,
                    );
                }
            });
        }
    };

    const trackToggleContactOrCompanyArchiveStatus = (
        dealId: string,
        contactId: string,
        contactType: DealContactType,
        archive: boolean,
    ) => {
        let eventName = MixpanelEventName.ArchivedContact;
        let entityIdPropertyName = 'contactId';
        if (contactType === DealContactType.person) {
            eventName = archive
                ? MixpanelEventName.ArchivedContact
                : MixpanelEventName.UnarchivedContact;
            entityIdPropertyName = 'contactId';
        } else {
            eventName = archive
                ? MixpanelEventName.ArchivedCompany
                : MixpanelEventName.UnarchivedCompany;

            entityIdPropertyName = 'companyId';
        }

        mixpanelService.trackEvent(
            eventName,
            {
                [entityIdPropertyName]: contactId,
            },
            dealId,
        );
    };

    const trackContactOrCompanyRoleUpdate = (
        contactType: DealContactType,
        dealId: string,
        contactId: string,
        updateSource: RoleUpdateSource,
        role?: string,
    ) => {
        const eventName =
            contactType === DealContactType.person
                ? MixpanelEventName.AssignedContactDealRole
                : MixpanelEventName.AssignedCompanyDealRole;

        const entityIdPropertyName =
            contactType === DealContactType.person ? 'contactId' : 'companyId';

        mixpanelService.trackEvent(
            eventName,
            {
                dealRoleName: role,
                [entityIdPropertyName]: contactId,
                dealRoleAssignmentSource: updateSource,
            },
            dealId,
        );
    };

    /** Util functions ******/
    const getActionToTrackOnEdition = (
        oldValue?: string,
        newValue?: string,
    ) => {
        let action = '';
        if (!isNotEmpty(oldValue) && isNotEmpty(newValue)) {
            action = 'Add';
        } else if (isNotEmpty(oldValue) && !isNotEmpty(newValue)) {
            action = 'Clear';
        } else if (isNotEmpty(oldValue) && isNotEmpty(newValue)) {
            if (oldValue === newValue) {
                action = 'Same value';
            } else {
                action = 'Edit';
            }
        }

        return action;
    };

    const getContactFieldValue = (
        field: string,
        contactOrCompany: DealContactPerson | DealContactCompany,
    ) => {
        if (field.includes('customFields.')) {
            return getCustomFieldFromCustomFieldArrayByFormPosition(
                field,
                contactOrCompany,
            )?.value;
        } else if (Object.keys(contactOrCompany).includes(field)) {
            const isPerson = contactOrCompany.type === DealContactType.person;
            return isPerson
                ? contactOrCompany[field as keyof DealContactPerson]
                : contactOrCompany[field as keyof DealContactCompany];
        } else {
            return contactOrCompany.customFields?.find(
                (param) => param.name === field,
            )?.value;
        }
    };

    const getContactFieldName = (
        field: string,
        contactOrCompany: DealContactPerson | DealContactCompany,
    ) => {
        if (field.includes('customFields.')) {
            return getCustomFieldFromCustomFieldArrayByFormPosition(
                field,
                contactOrCompany,
            )?.name;
        } else {
            return field;
        }
    };

    const getCustomFieldFromCustomFieldArrayByFormPosition = (
        formPositionCustomField: string,
        contactOrCompany: DealContactPerson | DealContactCompany,
    ): CustomParameter | undefined => {
        const segments = formPositionCustomField.split('.');
        const position = segments[1];
        return contactOrCompany.customFields?.[Number(position)];
    };

    /** Util functions end ******/

    return {
        trackContactOrCompanyFieldEdition,
        trackToggleContactOrCompanyArchiveStatus,
        trackContactOrCompanyRoleUpdate,
    };
};
