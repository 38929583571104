import { makeAutoObservable } from 'mobx';

import { DealContactCompany } from 'src/app-features/contact/data/model/deal-contacts.model';
import { IDealContactsStore } from 'src/app-features/contact/data/stores/interfaces/deal-contacts.store.interface';
import { IGoogleEnrichmentApi } from 'src/app-features/google-enrichment/data/google-enrichment.api';
import {
    GoogleEnrichmentOption,
    GoogleEnrichmentResultKey,
    GoogleEnrichmentResultKeyList,
} from 'src/app-features/google-enrichment/data/google-enrichment.model';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

import {
    GoogleEnrichmentFlyoutContext,
    IGoogleEnrichmentFeature,
    PlaceId,
} from './google-enrichment.feature.interface';
import {
    ActionFeatureIdentifierNamesMap,
    ActionFeaturesIdentifiers,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';

export const createGoogleEnrichmentFeature = (
    googleEnrichmentApi: IGoogleEnrichmentApi,
    contactsStore: IDealContactsStore,
    userStore: UserStore,
    accountConfigurationStore: IAccountConfigurationStore,
    baseStore: IBaseStore,
    mixpanelService: MixpanelService,
): IGoogleEnrichmentFeature => {
    const feature = {
        companyId: null as string | null,
        dealId: null as string | null,
        isFlyoutOpen: false as boolean,
        isGoogleEnrichmentLoading: false as boolean,
        enrichmentOptions: [] as GoogleEnrichmentOption[],
        selectedData: new Map<GoogleEnrichmentResultKey, PlaceId>(),

        get selectedCompany(): DealContactCompany | undefined {
            return feature.companyId
                ? contactsStore.companiesMap.get(feature.companyId)
                : undefined;
        },

        get companyOptions(): DealContactCompany[] {
            return Array.from(contactsStore.companiesMap.values());
        },

        get companyCustomFields(): GoogleEnrichmentResultKey[] {
            const parameters =
                accountConfigurationStore.accountConfiguration
                    ?.companyCustomFields ?? [];
            return parameters
                .map((parameter) => parameter.name)
                .filter((name) =>
                    GoogleEnrichmentResultKeyList.find((key) => name === key),
                ) as GoogleEnrichmentResultKey[];
        },

        openFlyout: (data: GoogleEnrichmentFlyoutContext) => {
            const { launchSource, quickActionCategory, companyId, dealId } =
                data;
            feature.isFlyoutOpen = true;
            feature.dealId = dealId;
            feature.companyId = companyId;
            feature.runLiveGoogleEnrichment(
                feature.selectedCompany?.name ?? '',
            );
            mixpanelService.trackEvent(
                MixpanelEventName.LaunchedQuickAction,
                {
                    quickActionName:
                        ActionFeatureIdentifierNamesMap[
                            ActionFeaturesIdentifiers.google_enrichment
                        ],
                    launchSource: launchSource.toString(),
                    companyId,
                    quickActionCategory,
                },
                dealId,
            );
        },

        closeFlyout: () => {
            feature.isFlyoutOpen = false;
            feature.companyId = null;
            feature.isGoogleEnrichmentLoading = false;
            feature.enrichmentOptions = [];
            feature.selectedData.clear();
        },

        runLiveGoogleEnrichment: async (name: string) => {
            const { user } = userStore;
            if (!user || !feature.dealId) {
                return;
            }
            feature.enrichmentOptions = [];
            feature.isGoogleEnrichmentLoading = true;
            feature.selectedData.clear();
            try {
                const locationOptions = await handleRequestAsync(
                    googleEnrichmentApi.getLocationOptions,
                    { dealId: feature.dealId, name: name.trim() },
                );
                if (!locationOptions || locationOptions.length === 0) {
                    feature.isGoogleEnrichmentLoading = false;
                    return;
                }
                if (locationOptions.length > 7) {
                    locationOptions.splice(7);
                }

                const results = (
                    await Promise.all(
                        locationOptions.map(async (option) => {
                            return await handleRequestAsync(
                                googleEnrichmentApi.getLocationEnrichment,
                                {
                                    placeId: option.placeId,
                                    language: user.language,
                                },
                            );
                        }),
                    )
                ).filter((option) => !!option);
                feature.onGetEnrichmentData(results);
            } catch (error) {
                feature.isGoogleEnrichmentLoading = false;
                baseStore.onRequestFailed(
                    'fetch-google-enrichment-data',
                    error as Error,
                );
            }
        },

        selectDataToSave: (key: GoogleEnrichmentResultKey, value?: string) => {
            if (value) {
                feature.selectedData.set(key, value);
            } else {
                feature.selectedData.delete(key);
            }
        },

        setSelectedCompany: (companyId?: string) => {
            if (companyId && !!contactsStore.companiesMap.get(companyId)) {
                feature.companyId = companyId;
                feature.runLiveGoogleEnrichment(
                    feature.selectedCompany?.name ?? '',
                );
            }
        },

        setIsGoogleEnrichmentLoading: (flag: boolean) =>
            (feature.isGoogleEnrichmentLoading = flag),

        onGetEnrichmentData: (data: GoogleEnrichmentOption[]) => {
            feature.enrichmentOptions = data;
            feature.isGoogleEnrichmentLoading = false;
            if (data.length) {
                const defaultData = data[0];
                const keysToSave = GoogleEnrichmentResultKeyList.filter((key) =>
                    feature.companyCustomFields.includes(key),
                );
                keysToSave.forEach((key) => {
                    if (defaultData[key]) {
                        feature.selectedData.set(key, defaultData.id);
                    }
                });
            }
            mixpanelService.trackEvent(
                MixpanelEventName.GoogleEnrichmentOutcome,
                {
                    companyId: feature.companyId,
                    totalGooglePlacesFound: feature.enrichmentOptions.length,
                },
                feature.dealId ?? undefined,
            );
        },
    };
    makeAutoObservable(feature);
    return feature;
};
