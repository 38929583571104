import { withFeatures } from 'src/utils/component.utils';

import { FoldersSectionComponent } from './folders-section.component';

export const FoldersSectionContainer = withFeatures(FoldersSectionComponent)(
    (f) => ({
        showFolderShadow: f.notificationsFeature.isOpened,
        myPipeline: f.pipelineFeature.myPipeline,
    }),
);
