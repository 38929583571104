import { Cancellable } from './handle-request.utils';

export const debounce = <Args extends unknown[]>(
    fn: (...a: Args) => void,
    debounceMs: number,
): ((...args: Args) => Cancellable) => {
    let timer: number | undefined;

    return (...args: Args) => {
        window.clearTimeout(timer);
        timer = window.setTimeout(() => fn(...args), debounceMs);

        return {
            cancel: () => window.clearTimeout(timer),
        };
    };
};

export const doNothing = () => {
    // This is a no-op function
};
