import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** Date with time (isoformat) */
    DateTime: { input: any; output: any };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: { input: any; output: any };
};

export type AddressQl = {
    city?: Maybe<Scalars['String']['output']>;
    continent?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    countryCode?: Maybe<Scalars['String']['output']>;
    houseNumber?: Maybe<Scalars['String']['output']>;
    lat?: Maybe<Scalars['Float']['output']>;
    lng?: Maybe<Scalars['Float']['output']>;
    postCode?: Maybe<Scalars['String']['output']>;
    postCodeEnd?: Maybe<Scalars['String']['output']>;
    postCodeIsExact?: Maybe<Scalars['Boolean']['output']>;
    postCodeStart?: Maybe<Scalars['String']['output']>;
    precisionAccuracy?: Maybe<Scalars['Float']['output']>;
    precisionLevel?: Maybe<Scalars['String']['output']>;
    precisionType?: Maybe<Scalars['String']['output']>;
    rank?: Maybe<Scalars['Float']['output']>;
    road?: Maybe<Scalars['String']['output']>;
    state?: Maybe<Scalars['String']['output']>;
};

export type ArticleDocumentQl = {
    abstract?: Maybe<Scalars['String']['output']>;
    address?: Maybe<AddressQl>;
    categories?: Maybe<Array<CategoryQl>>;
    constructionEndDate?: Maybe<BrTimeQl>;
    constructionPhases?: Maybe<Array<PhaseQl>>;
    constructionStartDate?: Maybe<BrTimeQl>;
    contacts?: Maybe<Array<ContactQl>>;
    description?: Maybe<Scalars['String']['output']>;
    descriptions?: Maybe<Array<Scalars['String']['output']>>;
    gfa?: Maybe<Scalars['Int']['output']>;
    images?: Maybe<Array<ImageQl>>;
    itemCreatedDate: Scalars['DateTime']['output'];
    itemId: Scalars['String']['output'];
    itemUpdatedDate: Scalars['DateTime']['output'];
    language?: Maybe<Scalars['String']['output']>;
    nResidentialUnits?: Maybe<Scalars['Int']['output']>;
    paragraphs?: Maybe<Array<Scalars['String']['output']>>;
    sourceDomains?: Maybe<Array<Scalars['String']['output']>>;
    sourcePublishedDate?: Maybe<BrTimeQl>;
    sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
    title: Scalars['String']['output'];
    titleAlternative?: Maybe<Scalars['String']['output']>;
    titleAlternativeEnglish?: Maybe<Scalars['String']['output']>;
    titleEnglish?: Maybe<Scalars['String']['output']>;
    volumeReference?: Maybe<Scalars['String']['output']>;
};

export enum BrTimePrecision {
    Day = 'DAY',
    HourUtc = 'HOUR_UTC',
    McsUtc = 'MCS_UTC',
    MinUtc = 'MIN_UTC',
    Month = 'MONTH',
    SecUtc = 'SEC_UTC',
    Year = 'YEAR',
}

export type BrTimeQl = {
    day?: Maybe<Scalars['Int']['output']>;
    hour?: Maybe<Scalars['Int']['output']>;
    microsecond?: Maybe<Scalars['Int']['output']>;
    minute?: Maybe<Scalars['Int']['output']>;
    month?: Maybe<Scalars['Int']['output']>;
    precision: BrTimePrecision;
    second?: Maybe<Scalars['Int']['output']>;
    year: Scalars['Int']['output'];
};

export type CategoryQl = {
    confidenceScore?: Maybe<Scalars['Float']['output']>;
    isPrecise?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    ranking?: Maybe<Scalars['Int']['output']>;
    subCategories?: Maybe<Array<Scalars['String']['output']>>;
};

export type ContactQl = {
    address?: Maybe<AddressQl>;
    associatedItemId?: Maybe<Scalars['String']['output']>;
    companyName?: Maybe<Scalars['String']['output']>;
    companyRoles?: Maybe<Array<Scalars['String']['output']>>;
    email?: Maybe<Scalars['String']['output']>;
    fax?: Maybe<Scalars['String']['output']>;
    homepage?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    personRoles?: Maybe<Array<Scalars['String']['output']>>;
    phoneLandline?: Maybe<Scalars['String']['output']>;
    phoneMobile?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
};

export type DocGroupInfoInputQl = {
    collection: Scalars['String']['input'];
    id: Scalars['String']['input'];
};

export type FolderCountsQl = {
    folderCounts: Scalars['JSON']['output'];
};

export enum FolderTag {
    AllExported = 'ALL_EXPORTED',
    CompEnrich = 'COMP_ENRICH',
    Done = 'DONE',
    Duplicate = 'DUPLICATE',
    ExportedAsCsv = 'EXPORTED_AS_CSV',
    ExportedAsPdf = 'EXPORTED_AS_PDF',
    ExportedToCrm = 'EXPORTED_TO_CRM',
    Inbox = 'INBOX',
    Interesting = 'INTERESTING',
    NotInteresting = 'NOT_INTERESTING',
    PreInbox = 'PRE_INBOX',
    PreScreening = 'PRE_SCREENING',
    Resubmitted = 'RESUBMITTED',
    Snoozed = 'SNOOZED',
}

export type GeneratedDgrpDataQl = {
    created: Scalars['DateTime']['output'];
    dgrpId: Scalars['String']['output'];
    language: Scalars['String']['output'];
    summary: Scalars['String']['output'];
    title: Scalars['String']['output'];
    updated: Scalars['DateTime']['output'];
};

export type ImageQl = {
    qualityScore?: Maybe<Scalars['Float']['output']>;
    sourceDomain: Scalars['String']['output'];
    sourceParseId?: Maybe<Scalars['String']['output']>;
    sourceTitle?: Maybe<Scalars['String']['output']>;
    sourceUrl: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export enum Language {
    De = 'DE',
    En = 'EN',
}

export type LegacyProjectDocumentQl = {
    address?: Maybe<AddressQl>;
    awards?: Maybe<Array<Scalars['String']['output']>>;
    categories?: Maybe<Array<CategoryQl>>;
    constructionEndDate?: Maybe<BrTimeQl>;
    constructionPhases?: Maybe<Array<PhaseQl>>;
    constructionStartDate?: Maybe<BrTimeQl>;
    contacts?: Maybe<Array<ContactQl>>;
    description?: Maybe<Scalars['String']['output']>;
    descriptions?: Maybe<Array<Scalars['String']['output']>>;
    gfa?: Maybe<Scalars['Int']['output']>;
    height?: Maybe<Scalars['Int']['output']>;
    images?: Maybe<Array<ImageQl>>;
    itemCreatedDate: Scalars['DateTime']['output'];
    itemId: Scalars['String']['output'];
    itemUpdatedDate: Scalars['DateTime']['output'];
    language?: Maybe<Scalars['String']['output']>;
    materials?: Maybe<Scalars['String']['output']>;
    nFloors?: Maybe<Scalars['Int']['output']>;
    nResidentialUnits?: Maybe<Scalars['Int']['output']>;
    nRooms?: Maybe<Scalars['Int']['output']>;
    sourceDomains?: Maybe<Array<Scalars['String']['output']>>;
    sourcePublishedDate?: Maybe<BrTimeQl>;
    sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
    title: Scalars['String']['output'];
    titleAlternative?: Maybe<Scalars['String']['output']>;
    titleAlternativeEnglish?: Maybe<Scalars['String']['output']>;
    titleEnglish?: Maybe<Scalars['String']['output']>;
    volumeReference?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
    createProjectFromDocGroup: ProjectIDsQl;
    debug: ResultQl;
    moveProject: ResultQl;
    removeSearchIdFromProjects: ResultQl;
    updateProjectCustomTitle: ResultQl;
    wipeInboxedProjectsOfSearch: ResultQl;
};

export type MutationCreateProjectFromDocGroupArgs = {
    docGroupsInfo: Array<DocGroupInfoInputQl>;
    folderTag: FolderTag;
    searchId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationMoveProjectArgs = {
    disqualificationExtraInfo?: InputMaybe<Scalars['String']['input']>;
    disqualificationReason?: InputMaybe<Scalars['String']['input']>;
    folder: FolderTag;
    projectId: Scalars['Int']['input'];
};

export type MutationRemoveSearchIdFromProjectsArgs = {
    searchId: Scalars['Int']['input'];
};

export type MutationUpdateProjectCustomTitleArgs = {
    projectId: Scalars['Int']['input'];
    title: Scalars['String']['input'];
};

export type MutationWipeInboxedProjectsOfSearchArgs = {
    searchId: Scalars['Int']['input'];
};

export type PhaseQl = {
    confidenceScore?: Maybe<Scalars['Float']['output']>;
    isPrecise?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    ranking?: Maybe<Scalars['Int']['output']>;
    subPhases?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProjectCustomTitleQl = {
    projectId: Scalars['Int']['output'];
    title: Scalars['String']['output'];
};

export type ProjectIDsQl = {
    projectIds: Array<Scalars['Int']['output']>;
};

export type ProjectsOfFolderQl = {
    projects: Array<RdbProjectQl>;
};

export type Query = {
    debug: ResultQl;
    getAllFolderCounts: FolderCountsQl;
    getCustomTitlesOfProjects: Array<ProjectCustomTitleQl>;
    getFolderTagForDocIds?: Maybe<FolderTag>;
    getGeneratedDataOfDgrps: Array<GeneratedDgrpDataQl>;
    getProject?: Maybe<RdbProjectQl>;
    getProjectsOfFolder: ProjectsOfFolderQl;
};

export type QueryGetAllFolderCountsArgs = {
    searchTags: Array<Scalars['Int']['input']>;
};

export type QueryGetCustomTitlesOfProjectsArgs = {
    projectIds: Array<Scalars['Int']['input']>;
};

export type QueryGetFolderTagForDocIdsArgs = {
    docIds: Array<Scalars['String']['input']>;
};

export type QueryGetGeneratedDataOfDgrpsArgs = {
    dgrpIds: Array<Scalars['String']['input']>;
    language: Language;
};

export type QueryGetProjectArgs = {
    projectId: Scalars['Int']['input'];
};

export type QueryGetProjectsOfFolderArgs = {
    count: Scalars['Int']['input'];
    cursorDate: Scalars['DateTime']['input'];
    cursorProjectId?: InputMaybe<Scalars['Int']['input']>;
    folderName: FolderTag;
    includeUnassigned: Scalars['Boolean']['input'];
    searchTags: Array<Scalars['Int']['input']>;
};

export type RdbProjectQl = {
    companyId: Scalars['Int']['output'];
    created: Scalars['DateTime']['output'];
    disqualificationExtraInfo?: Maybe<Scalars['String']['output']>;
    disqualificationReason?: Maybe<Scalars['String']['output']>;
    docGroupId: Scalars['String']['output'];
    docGroupUpdated: Scalars['DateTime']['output'];
    documents: Array<TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl>;
    folderTag: FolderTag;
    folderUpdated: Scalars['DateTime']['output'];
    folderedBy?: Maybe<Scalars['Int']['output']>;
    history: Scalars['String']['output'];
    id: Scalars['Int']['output'];
    mergedDocument: TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl;
    searchIds: Array<Scalars['Int']['output']>;
    updated: Scalars['DateTime']['output'];
};

export type ResultQl = {
    ok: Scalars['Boolean']['output'];
};

export type TenderDocumentQl = {
    allLotsAwarded?: Maybe<Scalars['Boolean']['output']>;
    announcedDate?: Maybe<BrTimeQl>;
    applicationUrl?: Maybe<Scalars['String']['output']>;
    awardCriteria?: Maybe<Scalars['String']['output']>;
    awardedDate?: Maybe<BrTimeQl>;
    categories?: Maybe<Array<CategoryQl>>;
    competitionDeadlineDate?: Maybe<BrTimeQl>;
    competitionDeadlineDateEst?: Maybe<Scalars['Boolean']['output']>;
    competitionPhase?: Maybe<Scalars['String']['output']>;
    constructionPhases?: Maybe<Array<PhaseQl>>;
    contacts?: Maybe<Array<ContactQl>>;
    contractAddress?: Maybe<AddressQl>;
    contractAwardWinners?: Maybe<Array<ContactQl>>;
    contractEndDate?: Maybe<BrTimeQl>;
    contractStartDate?: Maybe<BrTimeQl>;
    contractType?: Maybe<Scalars['String']['output']>;
    contractingAuthority?: Maybe<ContactQl>;
    cpvCodes?: Maybe<Array<Scalars['String']['output']>>;
    description?: Maybe<Scalars['String']['output']>;
    descriptions?: Maybe<Array<Scalars['String']['output']>>;
    directive?: Maybe<Scalars['String']['output']>;
    documentFamilyId?: Maybe<Scalars['String']['output']>;
    documentFamilyRefs?: Maybe<Array<Scalars['String']['output']>>;
    documentType?: Maybe<Scalars['String']['output']>;
    frameworkAgreement?: Maybe<Scalars['String']['output']>;
    images?: Maybe<Array<ImageQl>>;
    itemCreatedDate: Scalars['DateTime']['output'];
    itemId: Scalars['String']['output'];
    itemUpdatedDate: Scalars['DateTime']['output'];
    language?: Maybe<Scalars['String']['output']>;
    naicsCodes?: Maybe<Array<Scalars['String']['output']>>;
    openingDate?: Maybe<BrTimeQl>;
    procedureType?: Maybe<Scalars['String']['output']>;
    quantityScope?: Maybe<Scalars['String']['output']>;
    rawDataString?: Maybe<Scalars['String']['output']>;
    referenceNumber?: Maybe<Scalars['String']['output']>;
    sourceDomains?: Maybe<Array<Scalars['String']['output']>>;
    sourcePublishedDate?: Maybe<BrTimeQl>;
    sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
    tenderType?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    titleAlternative?: Maybe<Scalars['String']['output']>;
    titleAlternativeEnglish?: Maybe<Scalars['String']['output']>;
    titleEnglish?: Maybe<Scalars['String']['output']>;
    unspscCodes?: Maybe<Array<Scalars['String']['output']>>;
    volumeReference?: Maybe<Scalars['String']['output']>;
};

export type TenderDocumentQlArticleDocumentQlLegacyProjectDocumentQl =
    | ArticleDocumentQl
    | LegacyProjectDocumentQl
    | TenderDocumentQl;

export type BrDateTimePartFragment = {
    day?: number | null;
    hour?: number | null;
    microsecond?: number | null;
    minute?: number | null;
    month?: number | null;
    precision: BrTimePrecision;
    second?: number | null;
    year: number;
};

export type AddressPartFragment = {
    city?: string | null;
    continent?: string | null;
    country?: string | null;
    countryCode?: string | null;
    houseNumber?: string | null;
    lat?: number | null;
    lng?: number | null;
    postCode?: string | null;
    postCodeEnd?: string | null;
    postCodeIsExact?: boolean | null;
    postCodeStart?: string | null;
    precisionAccuracy?: number | null;
    precisionLevel?: string | null;
    precisionType?: string | null;
    rank?: number | null;
    road?: string | null;
    state?: string | null;
};

export type ContactPartFragment = {
    associatedItemId?: string | null;
    companyName?: string | null;
    companyRoles?: Array<string> | null;
    email?: string | null;
    fax?: string | null;
    homepage?: string | null;
    name?: string | null;
    personRoles?: Array<string> | null;
    phoneLandline?: string | null;
    phoneMobile?: string | null;
    title?: string | null;
    address?: {
        city?: string | null;
        continent?: string | null;
        country?: string | null;
        countryCode?: string | null;
        houseNumber?: string | null;
        lat?: number | null;
        lng?: number | null;
        postCode?: string | null;
        postCodeEnd?: string | null;
        postCodeIsExact?: boolean | null;
        postCodeStart?: string | null;
        precisionAccuracy?: number | null;
        precisionLevel?: string | null;
        precisionType?: string | null;
        rank?: number | null;
        road?: string | null;
        state?: string | null;
    } | null;
};

export type ArticleDocumentPartFragment = {
    itemId: string;
    title: string;
    titleAlternative?: string | null;
    titleEnglish?: string | null;
    titleAlternativeEnglish?: string | null;
    volumeReference?: string | null;
    sourceUrls?: Array<string> | null;
    sourceDomains?: Array<string> | null;
    language?: string | null;
    description?: string | null;
    descriptions?: Array<string> | null;
    abstract?: string | null;
    paragraphs?: Array<string> | null;
    gfa?: number | null;
    nResidentialUnits?: number | null;
    categories?: Array<{
        ranking?: number | null;
        name: string;
        confidenceScore?: number | null;
        isPrecise?: boolean | null;
    }> | null;
    constructionPhases?: Array<{
        ranking?: number | null;
        subPhases?: Array<string> | null;
        name: string;
    }> | null;
    sourcePublishedDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    images?: Array<{
        url: string;
        sourceUrl: string;
        sourceDomain: string;
        sourceTitle?: string | null;
        qualityScore?: number | null;
    }> | null;
    contacts?: Array<{
        associatedItemId?: string | null;
        companyName?: string | null;
        companyRoles?: Array<string> | null;
        email?: string | null;
        fax?: string | null;
        homepage?: string | null;
        name?: string | null;
        personRoles?: Array<string> | null;
        phoneLandline?: string | null;
        phoneMobile?: string | null;
        title?: string | null;
        address?: {
            city?: string | null;
            continent?: string | null;
            country?: string | null;
            countryCode?: string | null;
            houseNumber?: string | null;
            lat?: number | null;
            lng?: number | null;
            postCode?: string | null;
            postCodeEnd?: string | null;
            postCodeIsExact?: boolean | null;
            postCodeStart?: string | null;
            precisionAccuracy?: number | null;
            precisionLevel?: string | null;
            precisionType?: string | null;
            rank?: number | null;
            road?: string | null;
            state?: string | null;
        } | null;
    }> | null;
    address?: {
        city?: string | null;
        continent?: string | null;
        country?: string | null;
        countryCode?: string | null;
        houseNumber?: string | null;
        lat?: number | null;
        lng?: number | null;
        postCode?: string | null;
        postCodeEnd?: string | null;
        postCodeIsExact?: boolean | null;
        postCodeStart?: string | null;
        precisionAccuracy?: number | null;
        precisionLevel?: string | null;
        precisionType?: string | null;
        rank?: number | null;
        road?: string | null;
        state?: string | null;
    } | null;
    constructionStartDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    constructionEndDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
};

export type TenderDocumentPartFragment = {
    itemId: string;
    title: string;
    titleAlternative?: string | null;
    titleEnglish?: string | null;
    titleAlternativeEnglish?: string | null;
    volumeReference?: string | null;
    sourceUrls?: Array<string> | null;
    sourceDomains?: Array<string> | null;
    language?: string | null;
    description?: string | null;
    descriptions?: Array<string> | null;
    allLotsAwarded?: boolean | null;
    awardCriteria?: string | null;
    competitionDeadlineDateEst?: boolean | null;
    frameworkAgreement?: string | null;
    competitionPhase?: string | null;
    documentFamilyId?: string | null;
    documentFamilyRefs?: Array<string> | null;
    contractType?: string | null;
    directive?: string | null;
    documentType?: string | null;
    applicationUrl?: string | null;
    procedureType?: string | null;
    quantityScope?: string | null;
    referenceNumber?: string | null;
    tenderType?: string | null;
    cpvCodes?: Array<string> | null;
    naicsCodes?: Array<string> | null;
    unspscCodes?: Array<string> | null;
    rawDataString?: string | null;
    categories?: Array<{
        ranking?: number | null;
        name: string;
        confidenceScore?: number | null;
        isPrecise?: boolean | null;
    }> | null;
    constructionPhases?: Array<{
        ranking?: number | null;
        subPhases?: Array<string> | null;
        name: string;
    }> | null;
    sourcePublishedDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    images?: Array<{
        url: string;
        sourceUrl: string;
        sourceDomain: string;
        sourceTitle?: string | null;
        qualityScore?: number | null;
    }> | null;
    contacts?: Array<{
        associatedItemId?: string | null;
        companyName?: string | null;
        companyRoles?: Array<string> | null;
        email?: string | null;
        fax?: string | null;
        homepage?: string | null;
        name?: string | null;
        personRoles?: Array<string> | null;
        phoneLandline?: string | null;
        phoneMobile?: string | null;
        title?: string | null;
        address?: {
            city?: string | null;
            continent?: string | null;
            country?: string | null;
            countryCode?: string | null;
            houseNumber?: string | null;
            lat?: number | null;
            lng?: number | null;
            postCode?: string | null;
            postCodeEnd?: string | null;
            postCodeIsExact?: boolean | null;
            postCodeStart?: string | null;
            precisionAccuracy?: number | null;
            precisionLevel?: string | null;
            precisionType?: string | null;
            rank?: number | null;
            road?: string | null;
            state?: string | null;
        } | null;
    }> | null;
    announcedDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contractEndDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contractStartDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    competitionDeadlineDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    openingDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
    contractAddress?: {
        city?: string | null;
        continent?: string | null;
        country?: string | null;
        countryCode?: string | null;
        houseNumber?: string | null;
        lat?: number | null;
        lng?: number | null;
        postCode?: string | null;
        postCodeEnd?: string | null;
        postCodeIsExact?: boolean | null;
        postCodeStart?: string | null;
        precisionAccuracy?: number | null;
        precisionLevel?: string | null;
        precisionType?: string | null;
        rank?: number | null;
        road?: string | null;
        state?: string | null;
    } | null;
    awardedDate?: {
        day?: number | null;
        hour?: number | null;
        microsecond?: number | null;
        minute?: number | null;
        month?: number | null;
        precision: BrTimePrecision;
        second?: number | null;
        year: number;
    } | null;
};

export type ProjectPartFragment = {
    id: number;
    searchIds: Array<number>;
    created: any;
    updated: any;
    folderUpdated: any;
    folderTag: FolderTag;
    docGroupId: string;
    documents: Array<
        | {
              itemId: string;
              title: string;
              titleAlternative?: string | null;
              titleEnglish?: string | null;
              titleAlternativeEnglish?: string | null;
              volumeReference?: string | null;
              sourceUrls?: Array<string> | null;
              sourceDomains?: Array<string> | null;
              language?: string | null;
              description?: string | null;
              descriptions?: Array<string> | null;
              abstract?: string | null;
              paragraphs?: Array<string> | null;
              gfa?: number | null;
              nResidentialUnits?: number | null;
              categories?: Array<{
                  ranking?: number | null;
                  name: string;
                  confidenceScore?: number | null;
                  isPrecise?: boolean | null;
              }> | null;
              constructionPhases?: Array<{
                  ranking?: number | null;
                  subPhases?: Array<string> | null;
                  name: string;
              }> | null;
              sourcePublishedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              images?: Array<{
                  url: string;
                  sourceUrl: string;
                  sourceDomain: string;
                  sourceTitle?: string | null;
                  qualityScore?: number | null;
              }> | null;
              contacts?: Array<{
                  associatedItemId?: string | null;
                  companyName?: string | null;
                  companyRoles?: Array<string> | null;
                  email?: string | null;
                  fax?: string | null;
                  homepage?: string | null;
                  name?: string | null;
                  personRoles?: Array<string> | null;
                  phoneLandline?: string | null;
                  phoneMobile?: string | null;
                  title?: string | null;
                  address?: {
                      city?: string | null;
                      continent?: string | null;
                      country?: string | null;
                      countryCode?: string | null;
                      houseNumber?: string | null;
                      lat?: number | null;
                      lng?: number | null;
                      postCode?: string | null;
                      postCodeEnd?: string | null;
                      postCodeIsExact?: boolean | null;
                      postCodeStart?: string | null;
                      precisionAccuracy?: number | null;
                      precisionLevel?: string | null;
                      precisionType?: string | null;
                      rank?: number | null;
                      road?: string | null;
                      state?: string | null;
                  } | null;
              }> | null;
              address?: {
                  city?: string | null;
                  continent?: string | null;
                  country?: string | null;
                  countryCode?: string | null;
                  houseNumber?: string | null;
                  lat?: number | null;
                  lng?: number | null;
                  postCode?: string | null;
                  postCodeEnd?: string | null;
                  postCodeIsExact?: boolean | null;
                  postCodeStart?: string | null;
                  precisionAccuracy?: number | null;
                  precisionLevel?: string | null;
                  precisionType?: string | null;
                  rank?: number | null;
                  road?: string | null;
                  state?: string | null;
              } | null;
              constructionStartDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              constructionEndDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
          }
        | {
              itemId: string;
              title: string;
              titleAlternative?: string | null;
              titleEnglish?: string | null;
              titleAlternativeEnglish?: string | null;
              volumeReference?: string | null;
              sourceUrls?: Array<string> | null;
              sourceDomains?: Array<string> | null;
              language?: string | null;
              description?: string | null;
              descriptions?: Array<string> | null;
              allLotsAwarded?: boolean | null;
              awardCriteria?: string | null;
              competitionDeadlineDateEst?: boolean | null;
              frameworkAgreement?: string | null;
              competitionPhase?: string | null;
              documentFamilyId?: string | null;
              documentFamilyRefs?: Array<string> | null;
              contractType?: string | null;
              directive?: string | null;
              documentType?: string | null;
              applicationUrl?: string | null;
              procedureType?: string | null;
              quantityScope?: string | null;
              referenceNumber?: string | null;
              tenderType?: string | null;
              cpvCodes?: Array<string> | null;
              naicsCodes?: Array<string> | null;
              unspscCodes?: Array<string> | null;
              rawDataString?: string | null;
              categories?: Array<{
                  ranking?: number | null;
                  name: string;
                  confidenceScore?: number | null;
                  isPrecise?: boolean | null;
              }> | null;
              constructionPhases?: Array<{
                  ranking?: number | null;
                  subPhases?: Array<string> | null;
                  name: string;
              }> | null;
              sourcePublishedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              images?: Array<{
                  url: string;
                  sourceUrl: string;
                  sourceDomain: string;
                  sourceTitle?: string | null;
                  qualityScore?: number | null;
              }> | null;
              contacts?: Array<{
                  associatedItemId?: string | null;
                  companyName?: string | null;
                  companyRoles?: Array<string> | null;
                  email?: string | null;
                  fax?: string | null;
                  homepage?: string | null;
                  name?: string | null;
                  personRoles?: Array<string> | null;
                  phoneLandline?: string | null;
                  phoneMobile?: string | null;
                  title?: string | null;
                  address?: {
                      city?: string | null;
                      continent?: string | null;
                      country?: string | null;
                      countryCode?: string | null;
                      houseNumber?: string | null;
                      lat?: number | null;
                      lng?: number | null;
                      postCode?: string | null;
                      postCodeEnd?: string | null;
                      postCodeIsExact?: boolean | null;
                      postCodeStart?: string | null;
                      precisionAccuracy?: number | null;
                      precisionLevel?: string | null;
                      precisionType?: string | null;
                      rank?: number | null;
                      road?: string | null;
                      state?: string | null;
                  } | null;
              }> | null;
              announcedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractEndDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractStartDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              competitionDeadlineDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              openingDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractAddress?: {
                  city?: string | null;
                  continent?: string | null;
                  country?: string | null;
                  countryCode?: string | null;
                  houseNumber?: string | null;
                  lat?: number | null;
                  lng?: number | null;
                  postCode?: string | null;
                  postCodeEnd?: string | null;
                  postCodeIsExact?: boolean | null;
                  postCodeStart?: string | null;
                  precisionAccuracy?: number | null;
                  precisionLevel?: string | null;
                  precisionType?: string | null;
                  rank?: number | null;
                  road?: string | null;
                  state?: string | null;
              } | null;
              awardedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
          }
        | {}
    >;
    mergedDocument:
        | {
              itemId: string;
              title: string;
              titleAlternative?: string | null;
              titleEnglish?: string | null;
              titleAlternativeEnglish?: string | null;
              volumeReference?: string | null;
              sourceUrls?: Array<string> | null;
              sourceDomains?: Array<string> | null;
              language?: string | null;
              description?: string | null;
              descriptions?: Array<string> | null;
              abstract?: string | null;
              paragraphs?: Array<string> | null;
              gfa?: number | null;
              nResidentialUnits?: number | null;
              categories?: Array<{
                  ranking?: number | null;
                  name: string;
                  confidenceScore?: number | null;
                  isPrecise?: boolean | null;
              }> | null;
              constructionPhases?: Array<{
                  ranking?: number | null;
                  subPhases?: Array<string> | null;
                  name: string;
              }> | null;
              sourcePublishedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              images?: Array<{
                  url: string;
                  sourceUrl: string;
                  sourceDomain: string;
                  sourceTitle?: string | null;
                  qualityScore?: number | null;
              }> | null;
              contacts?: Array<{
                  associatedItemId?: string | null;
                  companyName?: string | null;
                  companyRoles?: Array<string> | null;
                  email?: string | null;
                  fax?: string | null;
                  homepage?: string | null;
                  name?: string | null;
                  personRoles?: Array<string> | null;
                  phoneLandline?: string | null;
                  phoneMobile?: string | null;
                  title?: string | null;
                  address?: {
                      city?: string | null;
                      continent?: string | null;
                      country?: string | null;
                      countryCode?: string | null;
                      houseNumber?: string | null;
                      lat?: number | null;
                      lng?: number | null;
                      postCode?: string | null;
                      postCodeEnd?: string | null;
                      postCodeIsExact?: boolean | null;
                      postCodeStart?: string | null;
                      precisionAccuracy?: number | null;
                      precisionLevel?: string | null;
                      precisionType?: string | null;
                      rank?: number | null;
                      road?: string | null;
                      state?: string | null;
                  } | null;
              }> | null;
              address?: {
                  city?: string | null;
                  continent?: string | null;
                  country?: string | null;
                  countryCode?: string | null;
                  houseNumber?: string | null;
                  lat?: number | null;
                  lng?: number | null;
                  postCode?: string | null;
                  postCodeEnd?: string | null;
                  postCodeIsExact?: boolean | null;
                  postCodeStart?: string | null;
                  precisionAccuracy?: number | null;
                  precisionLevel?: string | null;
                  precisionType?: string | null;
                  rank?: number | null;
                  road?: string | null;
                  state?: string | null;
              } | null;
              constructionStartDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              constructionEndDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
          }
        | {
              itemId: string;
              title: string;
              titleAlternative?: string | null;
              titleEnglish?: string | null;
              titleAlternativeEnglish?: string | null;
              volumeReference?: string | null;
              sourceUrls?: Array<string> | null;
              sourceDomains?: Array<string> | null;
              language?: string | null;
              description?: string | null;
              descriptions?: Array<string> | null;
              allLotsAwarded?: boolean | null;
              awardCriteria?: string | null;
              competitionDeadlineDateEst?: boolean | null;
              frameworkAgreement?: string | null;
              competitionPhase?: string | null;
              documentFamilyId?: string | null;
              documentFamilyRefs?: Array<string> | null;
              contractType?: string | null;
              directive?: string | null;
              documentType?: string | null;
              applicationUrl?: string | null;
              procedureType?: string | null;
              quantityScope?: string | null;
              referenceNumber?: string | null;
              tenderType?: string | null;
              cpvCodes?: Array<string> | null;
              naicsCodes?: Array<string> | null;
              unspscCodes?: Array<string> | null;
              rawDataString?: string | null;
              categories?: Array<{
                  ranking?: number | null;
                  name: string;
                  confidenceScore?: number | null;
                  isPrecise?: boolean | null;
              }> | null;
              constructionPhases?: Array<{
                  ranking?: number | null;
                  subPhases?: Array<string> | null;
                  name: string;
              }> | null;
              sourcePublishedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              images?: Array<{
                  url: string;
                  sourceUrl: string;
                  sourceDomain: string;
                  sourceTitle?: string | null;
                  qualityScore?: number | null;
              }> | null;
              contacts?: Array<{
                  associatedItemId?: string | null;
                  companyName?: string | null;
                  companyRoles?: Array<string> | null;
                  email?: string | null;
                  fax?: string | null;
                  homepage?: string | null;
                  name?: string | null;
                  personRoles?: Array<string> | null;
                  phoneLandline?: string | null;
                  phoneMobile?: string | null;
                  title?: string | null;
                  address?: {
                      city?: string | null;
                      continent?: string | null;
                      country?: string | null;
                      countryCode?: string | null;
                      houseNumber?: string | null;
                      lat?: number | null;
                      lng?: number | null;
                      postCode?: string | null;
                      postCodeEnd?: string | null;
                      postCodeIsExact?: boolean | null;
                      postCodeStart?: string | null;
                      precisionAccuracy?: number | null;
                      precisionLevel?: string | null;
                      precisionType?: string | null;
                      rank?: number | null;
                      road?: string | null;
                      state?: string | null;
                  } | null;
              }> | null;
              announcedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractEndDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractStartDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              competitionDeadlineDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              openingDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
              contractAddress?: {
                  city?: string | null;
                  continent?: string | null;
                  country?: string | null;
                  countryCode?: string | null;
                  houseNumber?: string | null;
                  lat?: number | null;
                  lng?: number | null;
                  postCode?: string | null;
                  postCodeEnd?: string | null;
                  postCodeIsExact?: boolean | null;
                  postCodeStart?: string | null;
                  precisionAccuracy?: number | null;
                  precisionLevel?: string | null;
                  precisionType?: string | null;
                  rank?: number | null;
                  road?: string | null;
                  state?: string | null;
              } | null;
              awardedDate?: {
                  day?: number | null;
                  hour?: number | null;
                  microsecond?: number | null;
                  minute?: number | null;
                  month?: number | null;
                  precision: BrTimePrecision;
                  second?: number | null;
                  year: number;
              } | null;
          }
        | {};
};

export type CreateProjectFromDocGroupMutationVariables = Exact<{
    folderTag: FolderTag;
    docGroupsInfo: Array<DocGroupInfoInputQl> | DocGroupInfoInputQl;
    searchId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CreateProjectFromDocGroupMutation = {
    createProjectFromDocGroup: { projectIds: Array<number> };
};

export type GetProjectsOfFolderQueryVariables = Exact<{
    folderName: FolderTag;
    includeUnassigned: Scalars['Boolean']['input'];
    searchTags: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
    count: Scalars['Int']['input'];
    cursorDate: Scalars['DateTime']['input'];
    cursorProjectId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetProjectsOfFolderQuery = {
    getProjectsOfFolder: {
        projects: Array<{
            id: number;
            searchIds: Array<number>;
            created: any;
            updated: any;
            folderUpdated: any;
            folderTag: FolderTag;
            docGroupId: string;
            documents: Array<
                | {
                      itemId: string;
                      title: string;
                      titleAlternative?: string | null;
                      titleEnglish?: string | null;
                      titleAlternativeEnglish?: string | null;
                      volumeReference?: string | null;
                      sourceUrls?: Array<string> | null;
                      sourceDomains?: Array<string> | null;
                      language?: string | null;
                      description?: string | null;
                      descriptions?: Array<string> | null;
                      abstract?: string | null;
                      paragraphs?: Array<string> | null;
                      gfa?: number | null;
                      nResidentialUnits?: number | null;
                      categories?: Array<{
                          ranking?: number | null;
                          name: string;
                          confidenceScore?: number | null;
                          isPrecise?: boolean | null;
                      }> | null;
                      constructionPhases?: Array<{
                          ranking?: number | null;
                          subPhases?: Array<string> | null;
                          name: string;
                      }> | null;
                      sourcePublishedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      images?: Array<{
                          url: string;
                          sourceUrl: string;
                          sourceDomain: string;
                          sourceTitle?: string | null;
                          qualityScore?: number | null;
                      }> | null;
                      contacts?: Array<{
                          associatedItemId?: string | null;
                          companyName?: string | null;
                          companyRoles?: Array<string> | null;
                          email?: string | null;
                          fax?: string | null;
                          homepage?: string | null;
                          name?: string | null;
                          personRoles?: Array<string> | null;
                          phoneLandline?: string | null;
                          phoneMobile?: string | null;
                          title?: string | null;
                          address?: {
                              city?: string | null;
                              continent?: string | null;
                              country?: string | null;
                              countryCode?: string | null;
                              houseNumber?: string | null;
                              lat?: number | null;
                              lng?: number | null;
                              postCode?: string | null;
                              postCodeEnd?: string | null;
                              postCodeIsExact?: boolean | null;
                              postCodeStart?: string | null;
                              precisionAccuracy?: number | null;
                              precisionLevel?: string | null;
                              precisionType?: string | null;
                              rank?: number | null;
                              road?: string | null;
                              state?: string | null;
                          } | null;
                      }> | null;
                      address?: {
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                      constructionStartDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      constructionEndDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                  }
                | {
                      itemId: string;
                      title: string;
                      titleAlternative?: string | null;
                      titleEnglish?: string | null;
                      titleAlternativeEnglish?: string | null;
                      volumeReference?: string | null;
                      sourceUrls?: Array<string> | null;
                      sourceDomains?: Array<string> | null;
                      language?: string | null;
                      description?: string | null;
                      descriptions?: Array<string> | null;
                      allLotsAwarded?: boolean | null;
                      awardCriteria?: string | null;
                      competitionDeadlineDateEst?: boolean | null;
                      frameworkAgreement?: string | null;
                      competitionPhase?: string | null;
                      documentFamilyId?: string | null;
                      documentFamilyRefs?: Array<string> | null;
                      contractType?: string | null;
                      directive?: string | null;
                      documentType?: string | null;
                      applicationUrl?: string | null;
                      procedureType?: string | null;
                      quantityScope?: string | null;
                      referenceNumber?: string | null;
                      tenderType?: string | null;
                      cpvCodes?: Array<string> | null;
                      naicsCodes?: Array<string> | null;
                      unspscCodes?: Array<string> | null;
                      rawDataString?: string | null;
                      categories?: Array<{
                          ranking?: number | null;
                          name: string;
                          confidenceScore?: number | null;
                          isPrecise?: boolean | null;
                      }> | null;
                      constructionPhases?: Array<{
                          ranking?: number | null;
                          subPhases?: Array<string> | null;
                          name: string;
                      }> | null;
                      sourcePublishedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      images?: Array<{
                          url: string;
                          sourceUrl: string;
                          sourceDomain: string;
                          sourceTitle?: string | null;
                          qualityScore?: number | null;
                      }> | null;
                      contacts?: Array<{
                          associatedItemId?: string | null;
                          companyName?: string | null;
                          companyRoles?: Array<string> | null;
                          email?: string | null;
                          fax?: string | null;
                          homepage?: string | null;
                          name?: string | null;
                          personRoles?: Array<string> | null;
                          phoneLandline?: string | null;
                          phoneMobile?: string | null;
                          title?: string | null;
                          address?: {
                              city?: string | null;
                              continent?: string | null;
                              country?: string | null;
                              countryCode?: string | null;
                              houseNumber?: string | null;
                              lat?: number | null;
                              lng?: number | null;
                              postCode?: string | null;
                              postCodeEnd?: string | null;
                              postCodeIsExact?: boolean | null;
                              postCodeStart?: string | null;
                              precisionAccuracy?: number | null;
                              precisionLevel?: string | null;
                              precisionType?: string | null;
                              rank?: number | null;
                              road?: string | null;
                              state?: string | null;
                          } | null;
                      }> | null;
                      announcedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractEndDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractStartDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      competitionDeadlineDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      openingDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractAddress?: {
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                      awardedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                  }
                | {}
            >;
            mergedDocument:
                | {
                      itemId: string;
                      title: string;
                      titleAlternative?: string | null;
                      titleEnglish?: string | null;
                      titleAlternativeEnglish?: string | null;
                      volumeReference?: string | null;
                      sourceUrls?: Array<string> | null;
                      sourceDomains?: Array<string> | null;
                      language?: string | null;
                      description?: string | null;
                      descriptions?: Array<string> | null;
                      abstract?: string | null;
                      paragraphs?: Array<string> | null;
                      gfa?: number | null;
                      nResidentialUnits?: number | null;
                      categories?: Array<{
                          ranking?: number | null;
                          name: string;
                          confidenceScore?: number | null;
                          isPrecise?: boolean | null;
                      }> | null;
                      constructionPhases?: Array<{
                          ranking?: number | null;
                          subPhases?: Array<string> | null;
                          name: string;
                      }> | null;
                      sourcePublishedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      images?: Array<{
                          url: string;
                          sourceUrl: string;
                          sourceDomain: string;
                          sourceTitle?: string | null;
                          qualityScore?: number | null;
                      }> | null;
                      contacts?: Array<{
                          associatedItemId?: string | null;
                          companyName?: string | null;
                          companyRoles?: Array<string> | null;
                          email?: string | null;
                          fax?: string | null;
                          homepage?: string | null;
                          name?: string | null;
                          personRoles?: Array<string> | null;
                          phoneLandline?: string | null;
                          phoneMobile?: string | null;
                          title?: string | null;
                          address?: {
                              city?: string | null;
                              continent?: string | null;
                              country?: string | null;
                              countryCode?: string | null;
                              houseNumber?: string | null;
                              lat?: number | null;
                              lng?: number | null;
                              postCode?: string | null;
                              postCodeEnd?: string | null;
                              postCodeIsExact?: boolean | null;
                              postCodeStart?: string | null;
                              precisionAccuracy?: number | null;
                              precisionLevel?: string | null;
                              precisionType?: string | null;
                              rank?: number | null;
                              road?: string | null;
                              state?: string | null;
                          } | null;
                      }> | null;
                      address?: {
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                      constructionStartDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      constructionEndDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                  }
                | {
                      itemId: string;
                      title: string;
                      titleAlternative?: string | null;
                      titleEnglish?: string | null;
                      titleAlternativeEnglish?: string | null;
                      volumeReference?: string | null;
                      sourceUrls?: Array<string> | null;
                      sourceDomains?: Array<string> | null;
                      language?: string | null;
                      description?: string | null;
                      descriptions?: Array<string> | null;
                      allLotsAwarded?: boolean | null;
                      awardCriteria?: string | null;
                      competitionDeadlineDateEst?: boolean | null;
                      frameworkAgreement?: string | null;
                      competitionPhase?: string | null;
                      documentFamilyId?: string | null;
                      documentFamilyRefs?: Array<string> | null;
                      contractType?: string | null;
                      directive?: string | null;
                      documentType?: string | null;
                      applicationUrl?: string | null;
                      procedureType?: string | null;
                      quantityScope?: string | null;
                      referenceNumber?: string | null;
                      tenderType?: string | null;
                      cpvCodes?: Array<string> | null;
                      naicsCodes?: Array<string> | null;
                      unspscCodes?: Array<string> | null;
                      rawDataString?: string | null;
                      categories?: Array<{
                          ranking?: number | null;
                          name: string;
                          confidenceScore?: number | null;
                          isPrecise?: boolean | null;
                      }> | null;
                      constructionPhases?: Array<{
                          ranking?: number | null;
                          subPhases?: Array<string> | null;
                          name: string;
                      }> | null;
                      sourcePublishedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      images?: Array<{
                          url: string;
                          sourceUrl: string;
                          sourceDomain: string;
                          sourceTitle?: string | null;
                          qualityScore?: number | null;
                      }> | null;
                      contacts?: Array<{
                          associatedItemId?: string | null;
                          companyName?: string | null;
                          companyRoles?: Array<string> | null;
                          email?: string | null;
                          fax?: string | null;
                          homepage?: string | null;
                          name?: string | null;
                          personRoles?: Array<string> | null;
                          phoneLandline?: string | null;
                          phoneMobile?: string | null;
                          title?: string | null;
                          address?: {
                              city?: string | null;
                              continent?: string | null;
                              country?: string | null;
                              countryCode?: string | null;
                              houseNumber?: string | null;
                              lat?: number | null;
                              lng?: number | null;
                              postCode?: string | null;
                              postCodeEnd?: string | null;
                              postCodeIsExact?: boolean | null;
                              postCodeStart?: string | null;
                              precisionAccuracy?: number | null;
                              precisionLevel?: string | null;
                              precisionType?: string | null;
                              rank?: number | null;
                              road?: string | null;
                              state?: string | null;
                          } | null;
                      }> | null;
                      announcedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractEndDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractStartDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      competitionDeadlineDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      openingDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                      contractAddress?: {
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                      awardedDate?: {
                          day?: number | null;
                          hour?: number | null;
                          microsecond?: number | null;
                          minute?: number | null;
                          month?: number | null;
                          precision: BrTimePrecision;
                          second?: number | null;
                          year: number;
                      } | null;
                  }
                | {};
        }>;
    };
};

export type GetProjectByIdQueryVariables = Exact<{
    projectId: Scalars['Int']['input'];
}>;

export type GetProjectByIdQuery = {
    getProject?: {
        id: number;
        searchIds: Array<number>;
        created: any;
        updated: any;
        folderUpdated: any;
        folderTag: FolderTag;
        docGroupId: string;
        documents: Array<
            | {
                  itemId: string;
                  title: string;
                  titleAlternative?: string | null;
                  titleEnglish?: string | null;
                  titleAlternativeEnglish?: string | null;
                  volumeReference?: string | null;
                  sourceUrls?: Array<string> | null;
                  sourceDomains?: Array<string> | null;
                  language?: string | null;
                  description?: string | null;
                  descriptions?: Array<string> | null;
                  abstract?: string | null;
                  paragraphs?: Array<string> | null;
                  gfa?: number | null;
                  nResidentialUnits?: number | null;
                  categories?: Array<{
                      ranking?: number | null;
                      name: string;
                      confidenceScore?: number | null;
                      isPrecise?: boolean | null;
                  }> | null;
                  constructionPhases?: Array<{
                      ranking?: number | null;
                      subPhases?: Array<string> | null;
                      name: string;
                  }> | null;
                  sourcePublishedDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  images?: Array<{
                      url: string;
                      sourceUrl: string;
                      sourceDomain: string;
                      sourceTitle?: string | null;
                      qualityScore?: number | null;
                  }> | null;
                  contacts?: Array<{
                      associatedItemId?: string | null;
                      companyName?: string | null;
                      companyRoles?: Array<string> | null;
                      email?: string | null;
                      fax?: string | null;
                      homepage?: string | null;
                      name?: string | null;
                      personRoles?: Array<string> | null;
                      phoneLandline?: string | null;
                      phoneMobile?: string | null;
                      title?: string | null;
                      address?: {
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                  }> | null;
                  address?: {
                      city?: string | null;
                      continent?: string | null;
                      country?: string | null;
                      countryCode?: string | null;
                      houseNumber?: string | null;
                      lat?: number | null;
                      lng?: number | null;
                      postCode?: string | null;
                      postCodeEnd?: string | null;
                      postCodeIsExact?: boolean | null;
                      postCodeStart?: string | null;
                      precisionAccuracy?: number | null;
                      precisionLevel?: string | null;
                      precisionType?: string | null;
                      rank?: number | null;
                      road?: string | null;
                      state?: string | null;
                  } | null;
                  constructionStartDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  constructionEndDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
              }
            | {
                  itemId: string;
                  title: string;
                  titleAlternative?: string | null;
                  titleEnglish?: string | null;
                  titleAlternativeEnglish?: string | null;
                  volumeReference?: string | null;
                  sourceUrls?: Array<string> | null;
                  sourceDomains?: Array<string> | null;
                  language?: string | null;
                  description?: string | null;
                  descriptions?: Array<string> | null;
                  allLotsAwarded?: boolean | null;
                  awardCriteria?: string | null;
                  competitionDeadlineDateEst?: boolean | null;
                  frameworkAgreement?: string | null;
                  competitionPhase?: string | null;
                  documentFamilyId?: string | null;
                  documentFamilyRefs?: Array<string> | null;
                  contractType?: string | null;
                  directive?: string | null;
                  documentType?: string | null;
                  applicationUrl?: string | null;
                  procedureType?: string | null;
                  quantityScope?: string | null;
                  referenceNumber?: string | null;
                  tenderType?: string | null;
                  cpvCodes?: Array<string> | null;
                  naicsCodes?: Array<string> | null;
                  unspscCodes?: Array<string> | null;
                  rawDataString?: string | null;
                  categories?: Array<{
                      ranking?: number | null;
                      name: string;
                      confidenceScore?: number | null;
                      isPrecise?: boolean | null;
                  }> | null;
                  constructionPhases?: Array<{
                      ranking?: number | null;
                      subPhases?: Array<string> | null;
                      name: string;
                  }> | null;
                  sourcePublishedDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  images?: Array<{
                      url: string;
                      sourceUrl: string;
                      sourceDomain: string;
                      sourceTitle?: string | null;
                      qualityScore?: number | null;
                  }> | null;
                  contacts?: Array<{
                      associatedItemId?: string | null;
                      companyName?: string | null;
                      companyRoles?: Array<string> | null;
                      email?: string | null;
                      fax?: string | null;
                      homepage?: string | null;
                      name?: string | null;
                      personRoles?: Array<string> | null;
                      phoneLandline?: string | null;
                      phoneMobile?: string | null;
                      title?: string | null;
                      address?: {
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                  }> | null;
                  announcedDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  contractEndDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  contractStartDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  competitionDeadlineDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  openingDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  contractAddress?: {
                      city?: string | null;
                      continent?: string | null;
                      country?: string | null;
                      countryCode?: string | null;
                      houseNumber?: string | null;
                      lat?: number | null;
                      lng?: number | null;
                      postCode?: string | null;
                      postCodeEnd?: string | null;
                      postCodeIsExact?: boolean | null;
                      postCodeStart?: string | null;
                      precisionAccuracy?: number | null;
                      precisionLevel?: string | null;
                      precisionType?: string | null;
                      rank?: number | null;
                      road?: string | null;
                      state?: string | null;
                  } | null;
                  awardedDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
              }
            | {}
        >;
        mergedDocument:
            | {
                  itemId: string;
                  title: string;
                  titleAlternative?: string | null;
                  titleEnglish?: string | null;
                  titleAlternativeEnglish?: string | null;
                  volumeReference?: string | null;
                  sourceUrls?: Array<string> | null;
                  sourceDomains?: Array<string> | null;
                  language?: string | null;
                  description?: string | null;
                  descriptions?: Array<string> | null;
                  abstract?: string | null;
                  paragraphs?: Array<string> | null;
                  gfa?: number | null;
                  nResidentialUnits?: number | null;
                  categories?: Array<{
                      ranking?: number | null;
                      name: string;
                      confidenceScore?: number | null;
                      isPrecise?: boolean | null;
                  }> | null;
                  constructionPhases?: Array<{
                      ranking?: number | null;
                      subPhases?: Array<string> | null;
                      name: string;
                  }> | null;
                  sourcePublishedDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  images?: Array<{
                      url: string;
                      sourceUrl: string;
                      sourceDomain: string;
                      sourceTitle?: string | null;
                      qualityScore?: number | null;
                  }> | null;
                  contacts?: Array<{
                      associatedItemId?: string | null;
                      companyName?: string | null;
                      companyRoles?: Array<string> | null;
                      email?: string | null;
                      fax?: string | null;
                      homepage?: string | null;
                      name?: string | null;
                      personRoles?: Array<string> | null;
                      phoneLandline?: string | null;
                      phoneMobile?: string | null;
                      title?: string | null;
                      address?: {
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                  }> | null;
                  address?: {
                      city?: string | null;
                      continent?: string | null;
                      country?: string | null;
                      countryCode?: string | null;
                      houseNumber?: string | null;
                      lat?: number | null;
                      lng?: number | null;
                      postCode?: string | null;
                      postCodeEnd?: string | null;
                      postCodeIsExact?: boolean | null;
                      postCodeStart?: string | null;
                      precisionAccuracy?: number | null;
                      precisionLevel?: string | null;
                      precisionType?: string | null;
                      rank?: number | null;
                      road?: string | null;
                      state?: string | null;
                  } | null;
                  constructionStartDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  constructionEndDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
              }
            | {
                  itemId: string;
                  title: string;
                  titleAlternative?: string | null;
                  titleEnglish?: string | null;
                  titleAlternativeEnglish?: string | null;
                  volumeReference?: string | null;
                  sourceUrls?: Array<string> | null;
                  sourceDomains?: Array<string> | null;
                  language?: string | null;
                  description?: string | null;
                  descriptions?: Array<string> | null;
                  allLotsAwarded?: boolean | null;
                  awardCriteria?: string | null;
                  competitionDeadlineDateEst?: boolean | null;
                  frameworkAgreement?: string | null;
                  competitionPhase?: string | null;
                  documentFamilyId?: string | null;
                  documentFamilyRefs?: Array<string> | null;
                  contractType?: string | null;
                  directive?: string | null;
                  documentType?: string | null;
                  applicationUrl?: string | null;
                  procedureType?: string | null;
                  quantityScope?: string | null;
                  referenceNumber?: string | null;
                  tenderType?: string | null;
                  cpvCodes?: Array<string> | null;
                  naicsCodes?: Array<string> | null;
                  unspscCodes?: Array<string> | null;
                  rawDataString?: string | null;
                  categories?: Array<{
                      ranking?: number | null;
                      name: string;
                      confidenceScore?: number | null;
                      isPrecise?: boolean | null;
                  }> | null;
                  constructionPhases?: Array<{
                      ranking?: number | null;
                      subPhases?: Array<string> | null;
                      name: string;
                  }> | null;
                  sourcePublishedDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  images?: Array<{
                      url: string;
                      sourceUrl: string;
                      sourceDomain: string;
                      sourceTitle?: string | null;
                      qualityScore?: number | null;
                  }> | null;
                  contacts?: Array<{
                      associatedItemId?: string | null;
                      companyName?: string | null;
                      companyRoles?: Array<string> | null;
                      email?: string | null;
                      fax?: string | null;
                      homepage?: string | null;
                      name?: string | null;
                      personRoles?: Array<string> | null;
                      phoneLandline?: string | null;
                      phoneMobile?: string | null;
                      title?: string | null;
                      address?: {
                          city?: string | null;
                          continent?: string | null;
                          country?: string | null;
                          countryCode?: string | null;
                          houseNumber?: string | null;
                          lat?: number | null;
                          lng?: number | null;
                          postCode?: string | null;
                          postCodeEnd?: string | null;
                          postCodeIsExact?: boolean | null;
                          postCodeStart?: string | null;
                          precisionAccuracy?: number | null;
                          precisionLevel?: string | null;
                          precisionType?: string | null;
                          rank?: number | null;
                          road?: string | null;
                          state?: string | null;
                      } | null;
                  }> | null;
                  announcedDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  contractEndDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  contractStartDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  competitionDeadlineDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  openingDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
                  contractAddress?: {
                      city?: string | null;
                      continent?: string | null;
                      country?: string | null;
                      countryCode?: string | null;
                      houseNumber?: string | null;
                      lat?: number | null;
                      lng?: number | null;
                      postCode?: string | null;
                      postCodeEnd?: string | null;
                      postCodeIsExact?: boolean | null;
                      postCodeStart?: string | null;
                      precisionAccuracy?: number | null;
                      precisionLevel?: string | null;
                      precisionType?: string | null;
                      rank?: number | null;
                      road?: string | null;
                      state?: string | null;
                  } | null;
                  awardedDate?: {
                      day?: number | null;
                      hour?: number | null;
                      microsecond?: number | null;
                      minute?: number | null;
                      month?: number | null;
                      precision: BrTimePrecision;
                      second?: number | null;
                      year: number;
                  } | null;
              }
            | {};
    } | null;
};

export type GetCustomTitlesOfProjectsQueryVariables = Exact<{
    projectIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type GetCustomTitlesOfProjectsQuery = {
    getCustomTitlesOfProjects: Array<{ projectId: number; title: string }>;
};

export type UpdateProjectCustomTitleMutationVariables = Exact<{
    projectId: Scalars['Int']['input'];
    title: Scalars['String']['input'];
}>;

export type UpdateProjectCustomTitleMutation = {
    updateProjectCustomTitle: { ok: boolean };
};

export type GetAllFoldersCounterQueryVariables = Exact<{
    searchTags: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type GetAllFoldersCounterQuery = {
    getAllFolderCounts: { folderCounts: any };
};

export type MoveProjectToFolderMutationVariables = Exact<{
    projectId: Scalars['Int']['input'];
    folder: FolderTag;
    disqualificationReason?: InputMaybe<Scalars['String']['input']>;
    disqualificationExtraInfo?: InputMaybe<Scalars['String']['input']>;
}>;

export type MoveProjectToFolderMutation = { moveProject: { ok: boolean } };

export type GetProjectDataForOutreachQueryVariables = Exact<{
    projectId: Scalars['Int']['input'];
}>;

export type GetProjectDataForOutreachQuery = {
    getProject?: {
        mergedDocument:
            | {
                  images?: Array<{
                      url: string;
                      sourceUrl: string;
                      sourceDomain: string;
                      sourceTitle?: string | null;
                      qualityScore?: number | null;
                  }> | null;
              }
            | {
                  images?: Array<{
                      url: string;
                      sourceUrl: string;
                      sourceDomain: string;
                      sourceTitle?: string | null;
                      qualityScore?: number | null;
                  }> | null;
              }
            | {};
    } | null;
};

export type GetProjectsGeneratedDataQueryVariables = Exact<{
    dgrpIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
    language: Language;
}>;

export type GetProjectsGeneratedDataQuery = {
    getGeneratedDataOfDgrps: Array<{
        dgrpId: string;
        language: string;
        summary: string;
        title: string;
    }>;
};

export type WipeInboxedSearchProjectsMutationVariables = Exact<{
    searchId: Scalars['Int']['input'];
}>;

export type WipeInboxedSearchProjectsMutation = {
    wipeInboxedProjectsOfSearch: { ok: boolean };
};

export const BrDateTimePartFragmentDoc = gql`
    fragment BrDateTimePart on BRTimeQL {
        day
        hour
        microsecond
        minute
        month
        precision
        second
        year
    }
`;
export const AddressPartFragmentDoc = gql`
    fragment AddressPart on AddressQL {
        city
        continent
        country
        countryCode
        houseNumber
        lat
        lng
        postCode
        postCodeEnd
        postCodeIsExact
        postCodeStart
        precisionAccuracy
        precisionLevel
        precisionType
        rank
        road
        state
    }
`;
export const ContactPartFragmentDoc = gql`
    fragment ContactPart on ContactQL {
        address {
            ... on AddressQL {
                ...AddressPart
            }
        }
        associatedItemId
        companyName
        companyRoles
        email
        fax
        homepage
        name
        personRoles
        phoneLandline
        phoneMobile
        title
    }
    ${AddressPartFragmentDoc}
`;
export const ArticleDocumentPartFragmentDoc = gql`
    fragment ArticleDocumentPart on ArticleDocumentQL {
        itemId
        title
        titleAlternative
        titleEnglish
        titleAlternativeEnglish
        categories {
            ranking
            name
            confidenceScore
            isPrecise
        }
        constructionPhases {
            ranking
            subPhases
            name
        }
        volumeReference
        sourceUrls
        sourceDomains
        language
        description
        descriptions
        sourcePublishedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        images {
            url
            sourceUrl
            sourceDomain
            sourceTitle
            qualityScore
        }
        contacts {
            ... on ContactQL {
                ...ContactPart
            }
        }
        address {
            ... on AddressQL {
                ...AddressPart
            }
        }
        abstract
        paragraphs
        constructionStartDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        constructionEndDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        gfa
        nResidentialUnits
    }
    ${BrDateTimePartFragmentDoc}
    ${ContactPartFragmentDoc}
    ${AddressPartFragmentDoc}
`;
export const TenderDocumentPartFragmentDoc = gql`
    fragment TenderDocumentPart on TenderDocumentQL {
        itemId
        title
        titleAlternative
        titleEnglish
        titleAlternativeEnglish
        categories {
            ranking
            name
            confidenceScore
            isPrecise
        }
        constructionPhases {
            ranking
            subPhases
            name
        }
        volumeReference
        sourceUrls
        sourceDomains
        language
        description
        descriptions
        sourcePublishedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        images {
            url
            sourceUrl
            sourceDomain
            sourceTitle
            qualityScore
        }
        contacts {
            ... on ContactQL {
                ...ContactPart
            }
        }
        allLotsAwarded
        announcedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        awardCriteria
        contractEndDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        contractStartDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        competitionDeadlineDateEst
        competitionDeadlineDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        frameworkAgreement
        openingDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        competitionPhase
        documentFamilyId
        documentFamilyRefs
        contractType
        directive
        documentType
        applicationUrl
        contractAddress {
            ... on AddressQL {
                ...AddressPart
            }
        }
        procedureType
        quantityScope
        referenceNumber
        tenderType
        awardedDate {
            ... on BRTimeQL {
                ...BrDateTimePart
            }
        }
        cpvCodes
        naicsCodes
        unspscCodes
        rawDataString
    }
    ${BrDateTimePartFragmentDoc}
    ${ContactPartFragmentDoc}
    ${AddressPartFragmentDoc}
`;
export const ProjectPartFragmentDoc = gql`
    fragment ProjectPart on RDBProjectQL {
        id
        searchIds
        created
        updated
        folderUpdated
        folderTag
        docGroupId
        documents {
            ... on ArticleDocumentQL {
                ...ArticleDocumentPart
            }
            ... on TenderDocumentQL {
                ...TenderDocumentPart
            }
        }
        mergedDocument {
            ... on ArticleDocumentQL {
                ...ArticleDocumentPart
            }
            ... on TenderDocumentQL {
                ...TenderDocumentPart
            }
        }
    }
    ${ArticleDocumentPartFragmentDoc}
    ${TenderDocumentPartFragmentDoc}
`;
export const CreateProjectFromDocGroupDocument = gql`
    mutation CreateProjectFromDocGroup(
        $folderTag: FolderTag!
        $docGroupsInfo: [DocGroupInfoInputQL!]!
        $searchId: Int
    ) {
        createProjectFromDocGroup(
            folderTag: $folderTag
            docGroupsInfo: $docGroupsInfo
            searchId: $searchId
        ) {
            projectIds
        }
    }
`;
export const GetProjectsOfFolderDocument = gql`
    query GetProjectsOfFolder(
        $folderName: FolderTag!
        $includeUnassigned: Boolean!
        $searchTags: [Int!]!
        $count: Int!
        $cursorDate: DateTime!
        $cursorProjectId: Int = null
    ) {
        getProjectsOfFolder(
            folderName: $folderName
            includeUnassigned: $includeUnassigned
            searchTags: $searchTags
            count: $count
            cursorDate: $cursorDate
            cursorProjectId: $cursorProjectId
        ) {
            projects {
                ... on RDBProjectQL {
                    ...ProjectPart
                }
            }
        }
    }
    ${ProjectPartFragmentDoc}
`;
export const GetProjectByIdDocument = gql`
    query GetProjectById($projectId: Int!) {
        getProject(projectId: $projectId) {
            ... on RDBProjectQL {
                ...ProjectPart
            }
        }
    }
    ${ProjectPartFragmentDoc}
`;
export const GetCustomTitlesOfProjectsDocument = gql`
    query GetCustomTitlesOfProjects($projectIds: [Int!]!) {
        getCustomTitlesOfProjects(projectIds: $projectIds) {
            projectId
            title
        }
    }
`;
export const UpdateProjectCustomTitleDocument = gql`
    mutation UpdateProjectCustomTitle($projectId: Int!, $title: String!) {
        updateProjectCustomTitle(projectId: $projectId, title: $title) {
            ok
        }
    }
`;
export const GetAllFoldersCounterDocument = gql`
    query GetAllFoldersCounter($searchTags: [Int!]!) {
        getAllFolderCounts(searchTags: $searchTags) {
            folderCounts
        }
    }
`;
export const MoveProjectToFolderDocument = gql`
    mutation MoveProjectToFolder(
        $projectId: Int!
        $folder: FolderTag!
        $disqualificationReason: String
        $disqualificationExtraInfo: String
    ) {
        moveProject(
            projectId: $projectId
            folder: $folder
            disqualificationReason: $disqualificationReason
            disqualificationExtraInfo: $disqualificationExtraInfo
        ) {
            ok
        }
    }
`;
export const GetProjectDataForOutreachDocument = gql`
    query GetProjectDataForOutreach($projectId: Int!) {
        getProject(projectId: $projectId) {
            mergedDocument {
                ... on ArticleDocumentQL {
                    images {
                        url
                        sourceUrl
                        sourceDomain
                        sourceTitle
                        qualityScore
                    }
                }
                ... on TenderDocumentQL {
                    images {
                        url
                        sourceUrl
                        sourceDomain
                        sourceTitle
                        qualityScore
                    }
                }
            }
        }
    }
`;
export const GetProjectsGeneratedDataDocument = gql`
    query GetProjectsGeneratedData($dgrpIds: [String!]!, $language: Language!) {
        getGeneratedDataOfDgrps(dgrpIds: $dgrpIds, language: $language) {
            ... on GeneratedDGRPDataQL {
                dgrpId
                language
                summary
                title
            }
        }
    }
`;
export const WipeInboxedSearchProjectsDocument = gql`
    mutation WipeInboxedSearchProjects($searchId: Int!) {
        wipeInboxedProjectsOfSearch(searchId: $searchId) {
            ok
        }
    }
`;
export type Requester<C = {}> = <R, V>(
    doc: DocumentNode,
    vars?: V,
    options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        CreateProjectFromDocGroup(
            variables: CreateProjectFromDocGroupMutationVariables,
            options?: C,
        ): Promise<CreateProjectFromDocGroupMutation> {
            return requester<
                CreateProjectFromDocGroupMutation,
                CreateProjectFromDocGroupMutationVariables
            >(
                CreateProjectFromDocGroupDocument,
                variables,
                options,
            ) as Promise<CreateProjectFromDocGroupMutation>;
        },
        GetProjectsOfFolder(
            variables: GetProjectsOfFolderQueryVariables,
            options?: C,
        ): Promise<GetProjectsOfFolderQuery> {
            return requester<
                GetProjectsOfFolderQuery,
                GetProjectsOfFolderQueryVariables
            >(
                GetProjectsOfFolderDocument,
                variables,
                options,
            ) as Promise<GetProjectsOfFolderQuery>;
        },
        GetProjectById(
            variables: GetProjectByIdQueryVariables,
            options?: C,
        ): Promise<GetProjectByIdQuery> {
            return requester<GetProjectByIdQuery, GetProjectByIdQueryVariables>(
                GetProjectByIdDocument,
                variables,
                options,
            ) as Promise<GetProjectByIdQuery>;
        },
        GetCustomTitlesOfProjects(
            variables: GetCustomTitlesOfProjectsQueryVariables,
            options?: C,
        ): Promise<GetCustomTitlesOfProjectsQuery> {
            return requester<
                GetCustomTitlesOfProjectsQuery,
                GetCustomTitlesOfProjectsQueryVariables
            >(
                GetCustomTitlesOfProjectsDocument,
                variables,
                options,
            ) as Promise<GetCustomTitlesOfProjectsQuery>;
        },
        UpdateProjectCustomTitle(
            variables: UpdateProjectCustomTitleMutationVariables,
            options?: C,
        ): Promise<UpdateProjectCustomTitleMutation> {
            return requester<
                UpdateProjectCustomTitleMutation,
                UpdateProjectCustomTitleMutationVariables
            >(
                UpdateProjectCustomTitleDocument,
                variables,
                options,
            ) as Promise<UpdateProjectCustomTitleMutation>;
        },
        GetAllFoldersCounter(
            variables: GetAllFoldersCounterQueryVariables,
            options?: C,
        ): Promise<GetAllFoldersCounterQuery> {
            return requester<
                GetAllFoldersCounterQuery,
                GetAllFoldersCounterQueryVariables
            >(
                GetAllFoldersCounterDocument,
                variables,
                options,
            ) as Promise<GetAllFoldersCounterQuery>;
        },
        MoveProjectToFolder(
            variables: MoveProjectToFolderMutationVariables,
            options?: C,
        ): Promise<MoveProjectToFolderMutation> {
            return requester<
                MoveProjectToFolderMutation,
                MoveProjectToFolderMutationVariables
            >(
                MoveProjectToFolderDocument,
                variables,
                options,
            ) as Promise<MoveProjectToFolderMutation>;
        },
        GetProjectDataForOutreach(
            variables: GetProjectDataForOutreachQueryVariables,
            options?: C,
        ): Promise<GetProjectDataForOutreachQuery> {
            return requester<
                GetProjectDataForOutreachQuery,
                GetProjectDataForOutreachQueryVariables
            >(
                GetProjectDataForOutreachDocument,
                variables,
                options,
            ) as Promise<GetProjectDataForOutreachQuery>;
        },
        GetProjectsGeneratedData(
            variables: GetProjectsGeneratedDataQueryVariables,
            options?: C,
        ): Promise<GetProjectsGeneratedDataQuery> {
            return requester<
                GetProjectsGeneratedDataQuery,
                GetProjectsGeneratedDataQueryVariables
            >(
                GetProjectsGeneratedDataDocument,
                variables,
                options,
            ) as Promise<GetProjectsGeneratedDataQuery>;
        },
        WipeInboxedSearchProjects(
            variables: WipeInboxedSearchProjectsMutationVariables,
            options?: C,
        ): Promise<WipeInboxedSearchProjectsMutation> {
            return requester<
                WipeInboxedSearchProjectsMutation,
                WipeInboxedSearchProjectsMutationVariables
            >(
                WipeInboxedSearchProjectsDocument,
                variables,
                options,
            ) as Promise<WipeInboxedSearchProjectsMutation>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
