import { DeveloperApiKey } from 'src/app-features/account-developer-tools/domain/developer-api-key.model';
import { AbortParams } from 'src/data/api/api-client';
import { ApiPermissionLevel } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { UserGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

export interface IDeveloperApiKeysApi {
    myKeys: () => Promise<DeveloperApiKey[]>;
    createNew: (params: CreateNewApiKey) => Promise<DeveloperApiKey>;
    deleteKey: (params: DeleteApiKey) => Promise<boolean>;
}

export const createDeveloperApiKeysApi = (
    userGqlSdk: UserGqlSdk,
): IDeveloperApiKeysApi => {
    const myKeys = async (): Promise<DeveloperApiKey[]> => {
        const response = await userGqlSdk.GetUserApiTokens();
        const apiKeys: DeveloperApiKey[] = response.userApiTokens.map(
            (key) => new DeveloperApiKey(key),
        );

        return apiKeys;
    };

    const createNew = async (
        params: CreateNewApiKey,
    ): Promise<DeveloperApiKey> => {
        const response = await userGqlSdk.CreateUserApiToken({
            name: params.keyName,
            apiPermissionLevel: params.writeAccess
                ? ApiPermissionLevel.Write
                : ApiPermissionLevel.Read,
        });

        return new DeveloperApiKey(response.createUserApiToken);
    };

    const deleteKey = async (params: DeleteApiKey): Promise<boolean> => {
        const response = await userGqlSdk.DeleteUserApiToken({ id: params.id });

        return response.deleteUserApiToken.ok;
    };

    return {
        myKeys,
        createNew,
        deleteKey,
    };
};

interface CreateNewApiKey extends AbortParams {
    keyName: string;
    writeAccess: boolean;
}

interface DeleteApiKey extends AbortParams {
    id: number;
}
