import { AnimatePresence, motion } from 'motion/react';
import { type FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { multiple } from 'src/utils/array.utils';
import { type Mention } from 'src/domain/models/mention/mention.model';
import { type User, getUserFullName } from 'src/domain/models/user/user.model';
import { EmptyNotificationsList } from '../shared/empty-notifications-list/empty-notifications-list.component';
import { NotificationPlaceholderComponent } from '../shared/notification-placeholder/notification-placeholder.component';
import { MentionContainer } from './mention/mention.container';

interface MentionsDashboardProps {
    mentions: Mention[];
    isLoading: boolean;
    user?: User;
}

export const MentionsDashboard: FC<MentionsDashboardProps> = observer(
    ({ mentions, isLoading, user }) => {
        const myName = getUserFullName(user);

        const { t } = useTranslation();

        const tip = useMemo(
            () => ({
                tipLabel: t(
                    'notification_flyout.mentions_tab.empty_mentions.tip_label',
                ),
                tipText: t(
                    'notification_flyout.mentions_tab.empty_mentions.tip_text',
                    {
                        current_user_name: myName,
                    },
                ),
            }),
            [myName, t],
        );

        const shouldDisplayEmptyMentionsComponent =
            !mentions.length && !isLoading;
        const shouldDisplaySectionTitle =
            isLoading || (!isLoading && !!mentions.length);

        return (
            <div className="flex flex-col gap-2">
                {shouldDisplayEmptyMentionsComponent ? (
                    <EmptyNotificationsList
                        title={t(
                            'notification_flyout.mentions_tab.empty_mentions.title',
                        )}
                        subtitle={t(
                            'notification_flyout.mentions_tab.empty_mentions.subtitle',
                        )}
                        tip={tip}
                    />
                ) : (
                    <>
                        {shouldDisplaySectionTitle && (
                            <h3 className="text-sm font-bold tracking-wider text-gray-500">
                                {t('notification_flyout.mentions_tab.title')}
                            </h3>
                        )}
                        <ul className="flex list-none flex-col gap-2">
                            <AnimatePresence>
                                {isLoading
                                    ? multiple(
                                          (index: number) => (
                                              <NotificationPlaceholderComponent
                                                  key={index}
                                              />
                                          ),
                                          3,
                                      )
                                    : mentions.map((m) => (
                                          <motion.li
                                              key={m.commentId}
                                              initial={{ opacity: 0 }}
                                              animate={{ opacity: 1 }}
                                              exit={{ opacity: 0 }}
                                              layout="position"
                                          >
                                              <MentionContainer mention={m} />
                                          </motion.li>
                                      ))}
                            </AnimatePresence>
                        </ul>
                    </>
                )}
            </div>
        );
    },
);
