import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    BigInt: { input: any; output: any };
    /** Date (isoformat) */
    Date: { input: any; output: any };
    /** Date with time (isoformat) */
    DateTime: { input: any; output: any };
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](https://ecma-international.org/wp-content/uploads/ECMA-404_2nd_edition_december_2017.pdf). */
    JSON: { input: any; output: any };
};

export enum AccountType {
    Dummy = 'DUMMY',
    Production = 'PRODUCTION',
    Template = 'TEMPLATE',
}

export type ActionFeaturesConfigQl = {
    active: Scalars['Boolean']['output'];
    companyOutreachStages?: Maybe<Array<CompanyOutreachStage>>;
    contactOutreachStages?: Maybe<Array<ContactOutreachStage>>;
    name: Scalars['String']['output'];
    order: Scalars['Int']['output'];
    settings?: Maybe<Scalars['JSON']['output']>;
};

export type ActivityLogQl = {
    companyBid: Scalars['Int']['output'];
    contactId?: Maybe<Scalars['String']['output']>;
    createdDate: Scalars['DateTime']['output'];
    customType: Scalars['String']['output'];
    deal?: Maybe<DealQl>;
    dealId?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isParentVisible: Scalars['Boolean']['output'];
    notes: Scalars['String']['output'];
    relevantCompanyId?: Maybe<Scalars['String']['output']>;
    subType?: Maybe<ActivityLogSubType>;
    type: ActivityLogType;
    updatedDate: Scalars['DateTime']['output'];
    userId: Scalars['Int']['output'];
};

export type ActivityLogStatsQl = {
    count: Scalars['Int']['output'];
    lastActivityDate?: Maybe<Scalars['DateTime']['output']>;
};

export enum ActivityLogSubType {
    DealAttachmentUpload = 'DEAL_ATTACHMENT_UPLOAD',
    DealOwnerChange = 'DEAL_OWNER_CHANGE',
    Email = 'EMAIL',
    Other = 'OTHER',
    PhoneCall = 'PHONE_CALL',
}

export enum ActivityLogType {
    History = 'HISTORY',
    OutreachAttempt = 'OUTREACH_ATTEMPT',
}

export type AddressDetailQl = {
    city?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    countryCode?: Maybe<Scalars['String']['output']>;
    postalCode?: Maybe<Scalars['String']['output']>;
    street?: Maybe<Scalars['String']['output']>;
    streetNumber?: Maybe<Scalars['String']['output']>;
};

export type AnnualSalesPeriodQl = {
    frequency: SalesPeriodFrequency;
    year: Scalars['Int']['output'];
};

export type AnnualSalesPeriodQlQuarterlySalesPeriodQl =
    | AnnualSalesPeriodQl
    | QuarterlySalesPeriodQl;

export type AppInfoQl = {
    branchHint: Scalars['String']['output'];
    version: Scalars['String']['output'];
};

export type BulkMoveResultQl = {
    ok: Scalars['Boolean']['output'];
    updatedDealIds: Array<Scalars['String']['output']>;
};

export type BulkUpdateAttributesResultQl = {
    ok: Scalars['Boolean']['output'];
    updatedDealIds: Array<Scalars['String']['output']>;
};

export type BulkUpdateCustomFieldsResultQl = {
    ok: Scalars['Boolean']['output'];
    updatedDealIds: Array<Scalars['String']['output']>;
};

export enum CategoryNames {
    Agriculture = 'AGRICULTURE',
    Commercial = 'COMMERCIAL',
    CulturalEvent = 'CULTURAL_EVENT',
    EducationScience = 'EDUCATION_SCIENCE',
    Health = 'HEALTH',
    HistoricalReligious = 'HISTORICAL_RELIGIOUS',
    Hospitality = 'HOSPITALITY',
    Industrial = 'INDUSTRIAL',
    Infrastructure = 'INFRASTRUCTURE',
    Landscaping = 'LANDSCAPING',
    Office = 'OFFICE',
    PublicServiceAdministration = 'PUBLIC_SERVICE_ADMINISTRATION',
    Residential = 'RESIDENTIAL',
    Sport = 'SPORT',
}

export type ColleagueAchievementsQl = {
    achievements: UserAchievementsDetailsQl;
    defaultPipelineId: Scalars['String']['output'];
    optInAchievements: Scalars['Boolean']['output'];
    userId: Scalars['Int']['output'];
};

export type CombinedContactExtractionResultQl = {
    projectContextResult?: Maybe<ContactExtractionResultQl>;
    webSearchResult?: Maybe<ContactExtractionResultQl>;
};

export type CommentCreateQl = {
    content: Scalars['String']['input'];
    /** @deprecated User identifiers are now parsed from the content. Stop sending them. */
    mentionedUserBids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CommentQl = {
    content: Scalars['String']['output'];
    createdDate: Scalars['DateTime']['output'];
    creatorBid: Scalars['Int']['output'];
    id: Scalars['String']['output'];
    parentId: Scalars['String']['output'];
    updatedDate: Scalars['DateTime']['output'];
};

export type CommentUpdateQl = {
    content: Scalars['String']['input'];
    /** @deprecated User identifiers are now parsed from the content. Stop sending them. */
    mentionedUserBids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CompanyConfigQl = {
    actionFeatures: Array<ActionFeaturesConfigQl>;
    configurationUpdatedDate: Scalars['DateTime']['output'];
    customFields: CustomFieldsConfig;
    dealValueVisible: Scalars['Boolean']['output'];
    exportMappings: ExportMappingsConfig;
    roles: RolesConfig;
    salesPeriod?: Maybe<SalesPeriodConfigurationQl>;
};

export enum CompanyOutreachStage {
    FindPeople = 'FIND_PEOPLE',
    Research = 'RESEARCH',
}

export type CompanyQl = {
    accountType: AccountType;
    config?: Maybe<CompanyConfigQl>;
    id: Scalars['Int']['output'];
    licenseType: LicenseType;
    name: Scalars['String']['output'];
};

export type ContactEnrichmentResultQl = {
    emails: Array<EnrichmentDataValueQl>;
    linkedins: Array<EnrichmentDataValueQl>;
    phones: Array<EnrichmentDataValueQl>;
    positions: Array<EnrichmentDataValueQl>;
};

export type ContactExtractionResultQl = {
    companyBid: Scalars['Int']['output'];
    createdDate: Scalars['DateTime']['output'];
    dealId: Scalars['String']['output'];
    extractedCompanies: Array<ExtractedCompanyQl>;
    extractedContacts: Array<ExtractedContactQl>;
    extractionType: ExtractionType;
    id: Scalars['String']['output'];
    status: ContactExtractionStatus;
    updatedDate: Scalars['DateTime']['output'];
};

export enum ContactExtractionStatus {
    Failed = 'FAILED',
    InProgress = 'IN_PROGRESS',
    Success = 'SUCCESS',
}

export type ContactMutationQl = {
    customInput?: InputMaybe<ContactUpdateCustomInputQl>;
    extracted?: InputMaybe<Scalars['Boolean']['input']>;
    firstName?: InputMaybe<Scalars['String']['input']>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
};

export enum ContactOutreachStage {
    Identified = 'IDENTIFIED',
    InContact = 'IN_CONTACT',
    Outreach = 'OUTREACH',
    Research = 'RESEARCH',
}

export type ContactQl = NodeQl & {
    createdDate: Scalars['DateTime']['output'];
    customFields?: Maybe<Array<CustomFieldQl>>;
    extracted?: Maybe<Scalars['Boolean']['output']>;
    firstName?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isKey?: Maybe<Scalars['Boolean']['output']>;
    lastName?: Maybe<Scalars['String']['output']>;
    notes?: Maybe<Scalars['String']['output']>;
    parentDeals?: Maybe<Array<DealQl>>;
    relevantCompany?: Maybe<RelevantCompanyQl>;
    role?: Maybe<Scalars['String']['output']>;
};

export type ContactUpdateCustomInputQl = {
    customValues: Array<CustomValueInputQl>;
};

export type ContactUpdateQl = {
    customInput?: InputMaybe<ContactUpdateCustomInputQl>;
    extracted?: InputMaybe<Scalars['Boolean']['input']>;
    firstName?: InputMaybe<Scalars['String']['input']>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactsFromExtractedContactsResultQl = {
    contacts: Array<ContactQl>;
    ok: Scalars['Boolean']['output'];
    relevantCompanies: Array<RelevantCompanyQl>;
};

export type CreateDealResultQl = {
    dealId: Scalars['String']['output'];
    ok: Scalars['Boolean']['output'];
};

export type CrmQl = {
    exportedDate?: Maybe<Scalars['DateTime']['output']>;
    remoteUrl?: Maybe<Scalars['String']['output']>;
    state?: Maybe<EntitySyncState>;
};

export type CsvQl = {
    header: Scalars['String']['output'];
    row: Scalars['String']['output'];
};

export type CustomFieldConfigQl = {
    acceptedValues?: Maybe<Array<Scalars['String']['output']>>;
    defaultValue?: Maybe<Scalars['String']['output']>;
    isTag: Scalars['Boolean']['output'];
    isVisibleInDealPage: Scalars['Boolean']['output'];
    label: Scalars['String']['output'];
    name: Scalars['String']['output'];
    order: Scalars['Int']['output'];
    type: CustomFieldType;
};

export type CustomFieldQl = {
    acceptedValues?: Maybe<Array<Scalars['String']['output']>>;
    defaultValue?: Maybe<Scalars['String']['output']>;
    enrichmentDetail?: Maybe<EnrichmentDetailQl>;
    isTag: Scalars['Boolean']['output'];
    isVisibleInDealPage: Scalars['Boolean']['output'];
    label: Scalars['String']['output'];
    name: Scalars['String']['output'];
    order: Scalars['Int']['output'];
    type: CustomFieldType;
    value?: Maybe<Scalars['String']['output']>;
};

export enum CustomFieldType {
    Decision = 'DECISION',
    DecisionCompanies = 'DECISION_COMPANIES',
    DecisionContacts = 'DECISION_CONTACTS',
    Float = 'FLOAT',
    Integer = 'INTEGER',
    Isodate = 'ISODATE',
    String = 'STRING',
}

export type CustomFieldValueInputQl = {
    enrichmentDetail?: InputMaybe<EnrichmentDetailInputQl>;
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type CustomFieldsConfig = {
    contacts?: Maybe<Array<CustomFieldConfigQl>>;
    deals?: Maybe<Array<CustomFieldConfigQl>>;
    relevantCompanies?: Maybe<Array<CustomFieldConfigQl>>;
};

export type CustomValueInputQl = {
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type DealAlertQl = {
    /** Set to True if deal owner or reminder assignee is current user. */
    currentUserRelated: Scalars['Boolean']['output'];
    type: DealAlertType;
};

export enum DealAlertType {
    FailedSo = 'FAILED_SO',
    Healthy = 'HEALTHY',
    OverdueReminder = 'OVERDUE_REMINDER',
    Paused = 'PAUSED',
    ReadyToReactivate = 'READY_TO_REACTIVATE',
    RecentlyAddedToStage = 'RECENTLY_ADDED_TO_STAGE',
    SlowMoving = 'SLOW_MOVING',
    Unassigned = 'UNASSIGNED',
    UpcomingReminder = 'UPCOMING_REMINDER',
}

export enum DealAttribute {
    Assignee = 'ASSIGNEE',
    DealValue = 'DEAL_VALUE',
    Notes = 'NOTES',
}

export type DealCollectionEdgeQl = {
    cursor: Scalars['String']['output'];
    node: DealQl;
};

export type DealCollectionInfoQl = {
    edges?: Maybe<Array<DealCollectionEdgeQl>>;
    pageInfo: PageInfoQl;
    /** Total number of elements in the object collection which match the filter criteria. */
    totalCount: Scalars['Int']['output'];
};

export enum DealContentCreationSource {
    AdditionalSourcesLlm = 'ADDITIONAL_SOURCES_LLM',
    DealImport = 'DEAL_IMPORT',
    UserCreated = 'USER_CREATED',
}

export type DealContentMetadataQl = {
    creationSource: DealContentCreationSource;
    hasContent: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    name?: Maybe<Scalars['String']['output']>;
    publishedDate?: Maybe<Scalars['DateTime']['output']>;
    source?: Maybe<Scalars['String']['output']>;
    type: DealContentType;
};

export enum DealContentType {
    File = 'FILE',
    Text = 'TEXT',
    Url = 'URL',
}

export enum DealCreationSource {
    ByopFileAttachment = 'BYOP_FILE_ATTACHMENT',
    ByopPastedContext = 'BYOP_PASTED_CONTEXT',
    ByopUrlAttachment = 'BYOP_URL_ATTACHMENT',
    ExternalApi = 'EXTERNAL_API',
    Manual = 'MANUAL',
    NeuralSearch = 'NEURAL_SEARCH',
    ProjectLookup = 'PROJECT_LOOKUP',
    Screening = 'SCREENING',
}

export type DealExportQl = NodeQl & {
    crm: CrmQl;
    csv: CsvQl;
    id: Scalars['String']['output'];
};

export type DealExportQlCrmArgs = {
    integrationId: Scalars['String']['input'];
};

export type DealExportQlCsvArgs = {
    delimiter?: Scalars['String']['input'];
};

/** If the deal assignee equals 0 means it is unassigned */
export type DealQl = NodeQl & {
    activityLog: Array<ActivityLogQl>;
    alert: DealAlertQl;
    assignee: Scalars['Int']['output'];
    closestNextStepDate?: Maybe<Scalars['DateTime']['output']>;
    comments?: Maybe<Array<CommentQl>>;
    completedDate?: Maybe<Scalars['DateTime']['output']>;
    contacts?: Maybe<Array<ContactQl>>;
    contents: Array<DealContentMetadataQl>;
    createdDate: Scalars['DateTime']['output'];
    currentObjectives: Array<ObjectiveQl>;
    currentStage: DealStageQl;
    customFields?: Maybe<Array<CustomFieldQl>>;
    expectedCurrentStageConversionDate: Scalars['DateTime']['output'];
    expectedFinalConversionDate: Scalars['DateTime']['output'];
    expectedSalesPeriod?: Maybe<AnnualSalesPeriodQlQuarterlySalesPeriodQl>;
    export: DealExportQl;
    exportedDate?: Maybe<ExportedDateQl>;
    hasComments: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    importInfo?: Maybe<ImportInfoQl>;
    inlineReminders: Array<InlineReminderQl>;
    notes: Scalars['String']['output'];
    outreachLogStats: ActivityLogStatsQl;
    pausedDate?: Maybe<Scalars['DateTime']['output']>;
    pausedUntil?: Maybe<Scalars['DateTime']['output']>;
    pipeline: PipelineQl;
    project?: Maybe<ProjectQl>;
    relevantCompanies?: Maybe<Array<RelevantCompanyQl>>;
    reminders: Array<ReminderQl>;
    stageUpdatedDate: Scalars['DateTime']['output'];
    state: DealState;
    summary?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    unpausedDate?: Maybe<Scalars['DateTime']['output']>;
    value: ValueCurrencyQl;
};

export type DealStageQl = {
    id: Scalars['String']['output'];
};

export enum DealState {
    Disqualified = 'DISQUALIFIED',
    Done = 'DONE',
    InPlay = 'IN_PLAY',
}

export type DealUpdateCustomInputQl = {
    customValues: Array<CustomValueInputQl>;
};

export type DealUpdateInputQl = {
    assignee?: InputMaybe<Scalars['Int']['input']>;
    dealValue?: InputMaybe<ValueCurrencyInput>;
    exported?: InputMaybe<ExportedType>;
    notes?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type DebugEmailInputQl = {
    body: Scalars['String']['input'];
    senderEmail: Scalars['String']['input'];
    senderName: Scalars['String']['input'];
    subject: Scalars['String']['input'];
    toEmail: Scalars['String']['input'];
    toName: Scalars['String']['input'];
};

export type DebugQl = {
    request: DebugRequestQl;
};

export type DebugRequestQl = {
    tracing: DebugRequestTracingQl;
};

export type DebugRequestTracingQl = {
    traceId?: Maybe<Scalars['String']['output']>;
};

export type DomainEnrichmentDataValueQl = {
    domain: Scalars['String']['output'];
    name: Scalars['String']['output'];
    providers: Array<EnrichmentDataProviderSource>;
};

export enum DynamicFilter {
    DfFailedSo = 'DF_FAILED_SO',
    DfNoReminderSet = 'DF_NO_REMINDER_SET',
    DfOverdueReminder = 'DF_OVERDUE_REMINDER',
    DfReadyToReactivate = 'DF_READY_TO_REACTIVATE',
    DfSlowMoving = 'DF_SLOW_MOVING',
    DfUnassigned = 'DF_UNASSIGNED',
}

export type DynamicFilterMetricsQl = {
    count: Scalars['Int']['output'];
    dynamicFilter: DynamicFilter;
};

export type EmailAddressHeaderQl = {
    bccReceivers?: Maybe<Array<EmailAddressQl>>;
    ccReceivers?: Maybe<Array<EmailAddressQl>>;
    fromSender: EmailAddressQl;
    replyTo?: Maybe<EmailAddressQl>;
    toReceivers: Array<EmailAddressQl>;
};

export type EmailAddressQl = {
    email: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export type EmailTemplateParamQl = {
    key: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type EmailTemplateParamsQl = {
    params?: Maybe<Array<EmailTemplateParamQl>>;
    templateId: Scalars['String']['output'];
};

export enum EnrichmentDataProviderSource {
    Clearbit = 'CLEARBIT',
    ContactOut = 'CONTACT_OUT',
    DealFront = 'DEAL_FRONT',
    GooglePlaces = 'GOOGLE_PLACES',
    Hunter = 'HUNTER',
    Mock = 'MOCK',
    RocketReach = 'ROCKET_REACH',
}

export type EnrichmentDataValueQl = {
    providers: Array<EnrichmentDataProviderSource>;
    value: Scalars['String']['output'];
};

export type EnrichmentDetailInputQl = {
    id: Scalars['String']['input'];
    source: Scalars['String']['input'];
};

export type EnrichmentDetailQl = {
    id: Scalars['String']['output'];
    source: Scalars['String']['output'];
};

export enum EntitySyncObjectTypes {
    Deal = 'DEAL',
}

export type EntitySyncRequestQl = NodeQl & {
    createdDate: Scalars['DateTime']['output'];
    entityId: Scalars['String']['output'];
    entityType: EntitySyncObjectTypes;
    id: Scalars['String']['output'];
    integrationId: Scalars['String']['output'];
    state: EntitySyncState;
};

export type EntitySyncRequestResultQl = {
    entitySyncRequest: EntitySyncRequestQl;
    ok: Scalars['Boolean']['output'];
};

export enum EntitySyncState {
    Created = 'CREATED',
    Error = 'ERROR',
    Exported = 'EXPORTED',
}

export type ExportDealEmailInputQl = {
    dealId: Scalars['String']['input'];
    dealOwner: Scalars['String']['input'];
    dealOwnerEmail: Scalars['String']['input'];
    dealUrl: Scalars['String']['input'];
    language: UserLanguage;
    senderName: Scalars['String']['input'];
    toEmail: Scalars['String']['input'];
    toName: Scalars['String']['input'];
};

export enum ExportMappingCodeVersion {
    Draft = 'DRAFT',
    Live = 'LIVE',
    Prev = 'PREV',
}

export type ExportMappingQl = NodeQl & {
    codeVersions: Array<ExportMappingVersionedCodeQl>;
    createdDate: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
    updatedDate: Scalars['DateTime']['output'];
};

export type ExportMappingVersionedCodeQl = {
    code?: Maybe<Scalars['String']['output']>;
    version: ExportMappingCodeVersion;
};

export type ExportMappingsConfig = {
    defaultExportMappingId?: Maybe<Scalars['String']['output']>;
};

export type ExportedDateQl = {
    csv?: Maybe<Scalars['DateTime']['output']>;
    email?: Maybe<Scalars['DateTime']['output']>;
};

export enum ExportedType {
    Csv = 'CSV',
    Email = 'EMAIL',
}

export type ExtractedCompanyQl = {
    legitimateInterestText: Scalars['String']['output'];
    name: Scalars['String']['output'];
    role: Scalars['String']['output'];
    sourceUrls: Array<Scalars['String']['output']>;
};

export type ExtractedContactQl = {
    companyName: Scalars['String']['output'];
    firstName: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    legitimateInterestText: Scalars['String']['output'];
    role: Scalars['String']['output'];
    sourceUrls: Array<Scalars['String']['output']>;
};

export enum ExtractionType {
    ProjectContext = 'PROJECT_CONTEXT',
    WebSearch = 'WEB_SEARCH',
}

export enum FilterKeys {
    FkActivityLogContactId = 'FK_ACTIVITY_LOG_CONTACT_ID',
    FkActivityLogDealId = 'FK_ACTIVITY_LOG_DEAL_ID',
    FkActivityLogSubType = 'FK_ACTIVITY_LOG_SUB_TYPE',
    FkActivityLogType = 'FK_ACTIVITY_LOG_TYPE',
    FkContactCreatedDate = 'FK_CONTACT_CREATED_DATE',
    FkContactPipelineId = 'FK_CONTACT_PIPELINE_ID',
    FkDealAssigneeBid = 'FK_DEAL_ASSIGNEE_BID',
    FkDealCompletedDate = 'FK_DEAL_COMPLETED_DATE',
    FkDealContacts = 'FK_DEAL_CONTACTS',
    FkDealCreatedDate = 'FK_DEAL_CREATED_DATE',
    FkDealCsvExportedDate = 'FK_DEAL_CSV_EXPORTED_DATE',
    FkDealCurrentStageId = 'FK_DEAL_CURRENT_STAGE_ID',
    FkDealCustomValue = 'FK_DEAL_CUSTOM_VALUE',
    FkDealExpectedCurrentStageConversionDate = 'FK_DEAL_EXPECTED_CURRENT_STAGE_CONVERSION_DATE',
    FkDealExpectedFinalConversionDate = 'FK_DEAL_EXPECTED_FINAL_CONVERSION_DATE',
    FkDealExpectedSalesPeriod = 'FK_DEAL_EXPECTED_SALES_PERIOD',
    FkDealHidePaused = 'FK_DEAL_HIDE_PAUSED',
    FkDealId = 'FK_DEAL_ID',
    FkDealPausedUntil = 'FK_DEAL_PAUSED_UNTIL',
    FkDealPipelineId = 'FK_DEAL_PIPELINE_ID',
    FkDealStageUpdatedDate = 'FK_DEAL_STAGE_UPDATED_DATE',
    FkDealState = 'FK_DEAL_STATE',
    FkDealTitle = 'FK_DEAL_TITLE',
    FkDealValueCurrencyNaive = 'FK_DEAL_VALUE_CURRENCY_NAIVE',
    FkDealVisible = 'FK_DEAL_VISIBLE',
    FkEsrControllerRetryAvailableDate = 'FK_ESR_CONTROLLER_RETRY_AVAILABLE_DATE',
    FkEsrControllerState = 'FK_ESR_CONTROLLER_STATE',
    FkEsrEntityId = 'FK_ESR_ENTITY_ID',
    FkEsrIntegrationId = 'FK_ESR_INTEGRATION_ID',
    FkObjectiveCriteria = 'FK_OBJECTIVE_CRITERIA',
    FkObjectiveLinkedFieldName = 'FK_OBJECTIVE_LINKED_FIELD_NAME',
    FkObjectiveLinkedFieldType = 'FK_OBJECTIVE_LINKED_FIELD_TYPE',
    FkObjectivePipelineId = 'FK_OBJECTIVE_PIPELINE_ID',
    FkObjectiveStageId = 'FK_OBJECTIVE_STAGE_ID',
    FkPipeVisible = 'FK_PIPE_VISIBLE',
    FkRelevantCompanyRoleUpdatedDate = 'FK_RELEVANT_COMPANY_ROLE_UPDATED_DATE',
}

export type FilterOpQl = {
    field: FilterKeys;
    fop: FilterOperators;
    value?: InputMaybe<Scalars['JSON']['input']>;
    values?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export enum FilterOperators {
    FopEq = 'FOP_EQ',
    FopGt = 'FOP_GT',
    FopGte = 'FOP_GTE',
    FopIn = 'FOP_IN',
    FopLt = 'FOP_LT',
    FopLte = 'FOP_LTE',
    FopNe = 'FOP_NE',
    FopNin = 'FOP_NIN',
    FopRegex = 'FOP_REGEX',
}

export type ImportInfoQl = {
    creationSource: DealCreationSource;
    sourceName: Scalars['String']['output'];
    status: ImportStatus;
};

export enum ImportStatus {
    Failed = 'FAILED',
    InProgress = 'IN_PROGRESS',
    Success = 'SUCCESS',
}

export type InlineReminderQl = {
    /** User ID of the user who is assigned to the reminder */
    assignee: Scalars['Int']['output'];
    /** Date when the reminder was completed */
    completedDate?: Maybe<Scalars['DateTime']['output']>;
    /** Reminder due date (not a datetime) */
    dueDate: Scalars['Date']['output'];
    /** Reference to the reminder in the 'reminders' collection */
    id: Scalars['String']['output'];
};

export type InstantMessageQl = {
    content: InstantMessageUserDealImportCompletedQl;
    type: InstantUserMessageType;
    userSourceId: Scalars['Int']['output'];
    userTargetId: Scalars['Int']['output'];
};

export type InstantMessageUserDealImportCompletedQl = {
    dealId: Scalars['String']['output'];
    title: Scalars['String']['output'];
};

export enum InstantUserMessageType {
    UserDealImportCompleted = 'USER_DEAL_IMPORT_COMPLETED',
}

export enum IntegrationCodeSlots {
    CodeSlotDraft = 'CODE_SLOT_DRAFT',
    CodeSlotLive = 'CODE_SLOT_LIVE',
    CodeSlotPrev = 'CODE_SLOT_PREV',
}

export enum IntegrationDriver {
    CrmDynamicsDefault = 'CRM_DYNAMICS_DEFAULT',
    CrmFakeDefault = 'CRM_FAKE_DEFAULT',
    CrmSalesforceDefault = 'CRM_SALESFORCE_DEFAULT',
    ExternalUrlDefault = 'EXTERNAL_URL_DEFAULT',
}

export enum IntegrationMapper {
    MapperDynamicsBasic = 'MAPPER_DYNAMICS_BASIC',
    MapperFake = 'MAPPER_FAKE',
    MapperObgDynamics = 'MAPPER_OBG_DYNAMICS',
    MapperPyscriptV2 = 'MAPPER_PYSCRIPT_V2',
    MapperSalesforceBasic = 'MAPPER_SALESFORCE_BASIC',
}

export type IntegrationMappingDebugApplyResultQl = {
    logOutput: Scalars['String']['output'];
};

export type IntegrationMappingDebugQl = {
    applyCode: IntegrationMappingDebugApplyResultQl;
};

export type IntegrationMappingDebugQlApplyCodeArgs = {
    code: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
};

export type IntegrationMappingQl = {
    code?: Maybe<Scalars['String']['output']>;
    debug: IntegrationMappingDebugQl;
};

export type IntegrationMappingQlCodeArgs = {
    slot?: IntegrationCodeSlots;
};

export enum IntegrationProvider {
    CrmDynamics = 'CRM_DYNAMICS',
    CrmFake = 'CRM_FAKE',
    CrmSalesforce = 'CRM_SALESFORCE',
    ExternalUrl = 'EXTERNAL_URL',
}

export type IntegrationQl = NodeQl & {
    clientConfigUrl?: Maybe<Scalars['String']['output']>;
    createdDate: Scalars['DateTime']['output'];
    driver: IntegrationDriver;
    id: Scalars['String']['output'];
    /** @deprecated It's always 'CRM' */
    integrationType: IntegrationType;
    mapper: IntegrationMapper;
    mapping: IntegrationMappingQl;
    name: Scalars['String']['output'];
    provider: IntegrationProvider;
    rawMappingConfig?: Maybe<Scalars['JSON']['output']>;
    updatedDate: Scalars['DateTime']['output'];
    verifySecureConnection: Scalars['Boolean']['output'];
};

export enum IntegrationType {
    Crm = 'CRM',
}

export enum LicenseType {
    Enterprise = 'ENTERPRISE',
    Medium = 'MEDIUM',
    Other = 'OTHER',
    PaidTrial = 'PAID_TRIAL',
    Small = 'SMALL',
}

export type LinkedFieldInputQl = {
    name: Scalars['String']['input'];
    type: LinkedFieldType;
};

export type LinkedFieldQl = {
    name: Scalars['String']['output'];
    type: LinkedFieldType;
};

export enum LinkedFieldType {
    CompanyRole = 'COMPANY_ROLE',
    ContactRole = 'CONTACT_ROLE',
    DealCustom = 'DEAL_CUSTOM',
    DealNative = 'DEAL_NATIVE',
}

export type LinkedInProfileContactInformationQl = {
    personalEmail: Array<Scalars['String']['output']>;
    phone: Array<Scalars['String']['output']>;
    workEmail: Array<Scalars['String']['output']>;
};

export type LinkedInProfileQl = {
    companyCountry: Scalars['String']['output'];
    companyName: Scalars['String']['output'];
    country: Scalars['String']['output'];
    fullName: Scalars['String']['output'];
    hasEmail: Scalars['Boolean']['output'];
    hasPhone: Scalars['Boolean']['output'];
    headline: Scalars['String']['output'];
    latestExperience: Scalars['String']['output'];
    linkedinUsername: Scalars['String']['output'];
    location: Scalars['String']['output'];
    profilePictureUrl: Scalars['String']['output'];
    title: Scalars['String']['output'];
};

export type LinkedInSearchResultQl = {
    metaData: SearchMetaDataQl;
    profiles: Array<LinkedInProfileQl>;
};

export type MentionQl = {
    commentId: Scalars['String']['output'];
    creatorBid: Scalars['Int']['output'];
    deal: DealQl;
    isRead: Scalars['Boolean']['output'];
    updatedDate: Scalars['DateTime']['output'];
};

export type Mutation = {
    addDealComment: CommentQl;
    autoEnrichCompany: RelevantCompanyQl;
    bulkMove: BulkMoveResultQl;
    bulkUpdateDealAttributes: BulkUpdateAttributesResultQl;
    bulkUpdateDealCustomValues: BulkUpdateCustomFieldsResultQl;
    completeReminder: ReminderQl;
    createActivityLog: ActivityLogQl;
    createContact: ContactQl;
    createContactsFromExtractedContacts: CreateContactsFromExtractedContactsResultQl;
    createDeal: CreateDealResultQl;
    createOutreachBlock: OutreachBlockQl;
    createRelevantCompany: RelevantCompanyQl;
    createReminder: ReminderQl;
    debugSendEmail: OutboundEmailResultQl;
    deleteActivityLog: ResultQl;
    deleteComment: ResultQl;
    deleteContact: ResultQl;
    deleteOutreachBlock: ResultQl;
    deleteRelevantCompany: ResultQl;
    deleteReminder: ResultQl;
    dismissCommentMention: ResultQl;
    disqualifyDeal: DealQl;
    exportDealEmail: OutboundEmailResultQl;
    exportToCrm: EntitySyncRequestResultQl;
    markDealAsDone: DealQl;
    moveToStage: ResultQl;
    overrideCompanyInfoWithPlace: RelevantCompanyQl;
    pauseDeal: ResultQl;
    readCommentMention: ResultQl;
    removeDealContent: ResultQl;
    removePlaceFromCompany: RelevantCompanyQl;
    restoreDeal: DealQl;
    runContactExtractionForDeal: ResultQl;
    uncompleteReminder: ReminderQl;
    unpauseDeal: ResultQl;
    updateActivityLog: ActivityLogQl;
    updateComment: CommentQl;
    updateContact: ContactQl;
    updateDeal: ResultQl;
    updateDealCustomValues: ResultQl;
    updateObjective: ResultQl;
    updateOutreachBlock: ResultQl;
    updateRelevantCompany: RelevantCompanyQl;
    updateReminder: ReminderQl;
    updateUserAchievementState: ResultQl;
    updateUserAchievementsEnrolmentOptIn: ResultQl;
};

export type MutationAddDealCommentArgs = {
    data: CommentCreateQl;
    dealId: Scalars['String']['input'];
};

export type MutationAutoEnrichCompanyArgs = {
    dealId: Scalars['String']['input'];
    relevantCompanyId: Scalars['String']['input'];
};

export type MutationBulkMoveArgs = {
    dealIds: Array<Scalars['String']['input']>;
    targetDealState: DealState;
    targetPipelineId: Scalars['String']['input'];
    targetStageId: Scalars['String']['input'];
    unpauseDeals?: Scalars['Boolean']['input'];
};

export type MutationBulkUpdateDealAttributesArgs = {
    attribute: DealAttribute;
    dealIds: Array<Scalars['String']['input']>;
    value?: InputMaybe<Scalars['JSON']['input']>;
};

export type MutationBulkUpdateDealCustomValuesArgs = {
    dealIds: Array<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type MutationCompleteReminderArgs = {
    reminderId: Scalars['String']['input'];
};

export type MutationCreateActivityLogArgs = {
    activityType: ActivityLogType;
    contactId?: InputMaybe<Scalars['String']['input']>;
    customType?: Scalars['String']['input'];
    dealId?: InputMaybe<Scalars['String']['input']>;
    notes?: Scalars['String']['input'];
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    subType?: InputMaybe<ActivityLogSubType>;
};

export type MutationCreateContactArgs = {
    createdFrom?: InputMaybe<Scalars['String']['input']>;
    data: ContactMutationQl;
    dealId: Scalars['String']['input'];
    enrichmentProviders?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationCreateContactsFromExtractedContactsArgs = {
    dealId: Scalars['String']['input'];
    extractedCompanies: Array<ReformattedExtractedCompanyInputQl>;
    extractedContacts: Array<ReformattedExtractedContactInputQl>;
};

export type MutationCreateDealArgs = {
    creationSource?: InputMaybe<DealCreationSource>;
    currentSearchName?: InputMaybe<Scalars['String']['input']>;
    pipelineId: Scalars['String']['input'];
    projectDataFromCustomText?: InputMaybe<ProjectDataFromCustomTextQl>;
    projectDataFromSearchResult?: InputMaybe<ProjectDataFromSearchResultQl>;
    projectId?: InputMaybe<Scalars['String']['input']>;
    searchNames?: InputMaybe<Array<Scalars['String']['input']>>;
    title: Scalars['String']['input'];
};

export type MutationCreateOutreachBlockArgs = {
    dealId: Scalars['String']['input'];
    outreachBlock: OutreachBlockCreateMutationQl;
    vpoSessionId: Scalars['String']['input'];
};

export type MutationCreateRelevantCompanyArgs = {
    createdFrom?: InputMaybe<Scalars['String']['input']>;
    data: RelevantCompanyCreateQl;
    dealId: Scalars['String']['input'];
    enrichmentProviders?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationCreateReminderArgs = {
    data: ReminderCreateInputQl;
    launchSource?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDebugSendEmailArgs = {
    inEmail: DebugEmailInputQl;
};

export type MutationDeleteActivityLogArgs = {
    activityLogId: Scalars['String']['input'];
};

export type MutationDeleteCommentArgs = {
    commentId: Scalars['String']['input'];
};

export type MutationDeleteContactArgs = {
    contactId: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
};

export type MutationDeleteOutreachBlockArgs = {
    dealId: Scalars['String']['input'];
    outreachBlockId: Scalars['String']['input'];
    vpoSessionId: Scalars['String']['input'];
};

export type MutationDeleteRelevantCompanyArgs = {
    dealId: Scalars['String']['input'];
    deleteAssociatedContacts?: Scalars['Boolean']['input'];
    relevantCompanyId: Scalars['String']['input'];
};

export type MutationDeleteReminderArgs = {
    reminderId: Scalars['String']['input'];
};

export type MutationDismissCommentMentionArgs = {
    commentId: Scalars['String']['input'];
};

export type MutationDisqualifyDealArgs = {
    dealId: Scalars['String']['input'];
    reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationExportDealEmailArgs = {
    inEmail: ExportDealEmailInputQl;
};

export type MutationExportToCrmArgs = {
    dealId: Scalars['String']['input'];
    integrationId: Scalars['String']['input'];
};

export type MutationMarkDealAsDoneArgs = {
    dealId: Scalars['String']['input'];
};

export type MutationMoveToStageArgs = {
    dealId: Scalars['String']['input'];
    stageId: Scalars['String']['input'];
};

export type MutationOverrideCompanyInfoWithPlaceArgs = {
    dealId: Scalars['String']['input'];
    fields?: InputMaybe<Array<Scalars['String']['input']>>;
    placeId: Scalars['String']['input'];
    relevantCompanyId: Scalars['String']['input'];
};

export type MutationPauseDealArgs = {
    dealId: Scalars['String']['input'];
    pausedUntil: Scalars['DateTime']['input'];
};

export type MutationReadCommentMentionArgs = {
    commentId: Scalars['String']['input'];
};

export type MutationRemoveDealContentArgs = {
    contentId: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
};

export type MutationRemovePlaceFromCompanyArgs = {
    field?: InputMaybe<Scalars['String']['input']>;
    placeId: Scalars['String']['input'];
    relevantCompanyId: Scalars['String']['input'];
};

export type MutationRestoreDealArgs = {
    dealId: Scalars['String']['input'];
};

export type MutationRunContactExtractionForDealArgs = {
    dealId: Scalars['String']['input'];
    language?: UserLanguage;
};

export type MutationUncompleteReminderArgs = {
    reminderId: Scalars['String']['input'];
};

export type MutationUnpauseDealArgs = {
    dealId: Scalars['String']['input'];
};

export type MutationUpdateActivityLogArgs = {
    activityLogId: Scalars['String']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    customType?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateCommentArgs = {
    commentId: Scalars['String']['input'];
    update: CommentUpdateQl;
};

export type MutationUpdateContactArgs = {
    contactId: Scalars['String']['input'];
    update: ContactUpdateQl;
};

export type MutationUpdateDealArgs = {
    dealId: Scalars['String']['input'];
    update: DealUpdateInputQl;
};

export type MutationUpdateDealCustomValuesArgs = {
    dealId: Scalars['String']['input'];
    update: DealUpdateCustomInputQl;
};

export type MutationUpdateObjectiveArgs = {
    objectiveId: Scalars['String']['input'];
    update: ObjectiveUpdateInputQl;
};

export type MutationUpdateOutreachBlockArgs = {
    dealId: Scalars['String']['input'];
    outreachBlock: OutreachBlockUpdateMutationQl;
    vpoSessionId: Scalars['String']['input'];
};

export type MutationUpdateRelevantCompanyArgs = {
    relevantCompanyId: Scalars['String']['input'];
    update: RelevantCompanyUpdateQl;
};

export type MutationUpdateReminderArgs = {
    reminderId: Scalars['String']['input'];
    update: ReminderUpdateInputQl;
};

export type MutationUpdateUserAchievementStateArgs = {
    achievementName: UserAchievementName;
    status: UserAchievementState;
};

export type MutationUpdateUserAchievementsEnrolmentOptInArgs = {
    optIn: Scalars['Boolean']['input'];
};

export type NodeQl = {
    id: Scalars['String']['output'];
};

export type ObjectiveConfigQl = {
    criteria: Scalars['String']['output'];
    id?: Maybe<Scalars['String']['output']>;
    linkedField?: Maybe<LinkedFieldQl>;
    order: Scalars['Int']['output'];
    type: ObjectiveType;
};

export type ObjectiveQl = NodeQl & {
    criteria: Scalars['String']['output'];
    id: Scalars['String']['output'];
    linkedField?: Maybe<LinkedFieldQl>;
    objectiveTemplate?: Maybe<Scalars['String']['output']>;
    order?: Maybe<Scalars['Int']['output']>;
    state: Tristate;
    status: Scalars['String']['output'];
    type: ObjectiveType;
};

export enum ObjectiveType {
    Confirm = 'CONFIRM',
    Question = 'QUESTION',
}

export type ObjectiveUpdateInputQl = {
    criteria?: InputMaybe<Scalars['String']['input']>;
    linkedField?: InputMaybe<LinkedFieldInputQl>;
    state?: InputMaybe<Tristate>;
    status?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<ObjectiveType>;
};

export type OmniSearchResultQl = {
    activityLogs: Array<ActivityLogQl>;
    comments: Array<CommentQl>;
    companies: Array<RelevantCompanyQl>;
    contacts: Array<ContactQl>;
    deals: Array<DealQl>;
    nextSteps: Array<ReminderQl>;
};

export type OutboundEmailQl = NodeQl & {
    addresses: EmailAddressHeaderQl;
    enqueuedDate?: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['String']['output'];
    mtaMessageId?: Maybe<Scalars['String']['output']>;
    senderBid: Scalars['Int']['output'];
    sentDate?: Maybe<Scalars['DateTime']['output']>;
    state: OutboundEmailState;
    template: EmailTemplateParamsQl;
};

export type OutboundEmailResultQl = {
    email: OutboundEmailQl;
    ok: Scalars['Boolean']['output'];
};

export enum OutboundEmailState {
    Draft = 'DRAFT',
    Enqueued = 'ENQUEUED',
    Failed = 'FAILED',
    Sending = 'SENDING',
    Sent = 'SENT',
}

export type OutreachBlockCreateMutationQl = {
    text: Scalars['String']['input'];
    title: Scalars['String']['input'];
    type: OutreachBlockType;
};

export type OutreachBlockQl = {
    companyBid: Scalars['Int']['output'];
    createdDate: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    outreachType: OutreachType;
    text: Scalars['String']['output'];
    title: Scalars['String']['output'];
    type: OutreachBlockType;
    updatedDate: Scalars['DateTime']['output'];
};

export enum OutreachBlockType {
    DesiredOutcome = 'DESIRED_OUTCOME',
    ValueProposition = 'VALUE_PROPOSITION',
    WritingStyle = 'WRITING_STYLE',
}

export type OutreachBlockUpdateMutationQl = {
    id: Scalars['String']['input'];
    text?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export enum OutreachType {
    All = 'ALL',
    Call = 'CALL',
    Email = 'EMAIL',
}

export type PageInfoQl = {
    count: Scalars['Int']['output'];
    endCursor?: Maybe<Scalars['String']['output']>;
    hasNextPage: Scalars['Boolean']['output'];
    startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaginationConfQl = {
    after?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PipelineConfigQl = {
    averageDealValue: ValueCurrencyQl;
    goalsEnabled: Scalars['Boolean']['output'];
    revenueGoal: ValueCurrencyQl;
    stages: PipelineStagesConfigQl;
    timePeriodStart: Scalars['DateTime']['output'];
};

export type PipelineQl = NodeQl & {
    config: PipelineConfigQl;
    deals: Array<DealQl>;
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
    statistics: PipelineStatsQl;
    visible: Scalars['Boolean']['output'];
};

export type PipelineQlStatisticsArgs = {
    queryParams?: InputMaybe<QueryParamsQl>;
};

export type PipelineStageConfigQl = {
    defaultObjectives?: Maybe<Array<ObjectiveConfigQl>>;
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
    order: Scalars['Int']['output'];
    stageConversionTime: Scalars['Float']['output'];
    targetConversionRate?: Maybe<Scalars['Float']['output']>;
};

export type PipelineStageStatsDealQl = {
    numberOfDeals: Scalars['Int']['output'];
    /** @deprecated Calculation no longer needed. */
    totalDealValue: Scalars['BigInt']['output'];
};

export type PipelineStageStatsQl = {
    atRiskDeals: PipelineStageStatsDealQl;
    disqualifiedDeals: PipelineStageStatsDealQl;
    goal: Scalars['Int']['output'];
    healthyDeals: PipelineStageStatsDealQl;
    id: Scalars['String']['output'];
    inStageDeals: PipelineStageStatsDealQl;
    markedDoneDeals: PipelineStageStatsDealQl;
    observedConversionRate: Scalars['Float']['output'];
    progressedDeals: PipelineStageStatsDealQl;
    targetConversionRate: Scalars['Float']['output'];
};

export type PipelineStagesConfigQl = {
    initialStage: Scalars['String']['output'];
    lastStage: Scalars['String']['output'];
    stages: Array<PipelineStageConfigQl>;
};

export type PipelineStatisticsQl = {
    companyBid: Scalars['Int']['output'];
    countContacts: Scalars['Int']['output'];
    countDeals: Scalars['Int']['output'];
    countDealsWon: Scalars['Int']['output'];
    countLeadsQualified: Scalars['Int']['output'];
    countMeetingsBooked: Scalars['Int']['output'];
    createdDate: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    pipelineId: Scalars['String']['output'];
    updatedDate: Scalars['DateTime']['output'];
    userId?: Maybe<Scalars['Int']['output']>;
};

export type PipelineStatsQl = {
    dynamicFiltersMetrics: Array<DynamicFilterMetricsQl>;
    stages: Array<PipelineStageStatsQl>;
};

export type PlaceDetailsQl = {
    addressDetail?: Maybe<AddressDetailQl>;
    email?: Maybe<Scalars['String']['output']>;
    formattedAddress?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    internationalPhoneNumber?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    source: PlaceSource;
    type?: Maybe<Scalars['String']['output']>;
    website?: Maybe<Scalars['String']['output']>;
};

export type PlaceQl = NodeQl & {
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export enum PlaceSource {
    GooglePlaces = 'GOOGLE_PLACES',
    WebSearch = 'WEB_SEARCH',
}

export type ProjectAddressQl = {
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    state: Scalars['String']['output'];
};

export type ProjectDataFromCustomTextQl = {
    body?: InputMaybe<Scalars['String']['input']>;
    publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
    sourceUrl?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectDataFromSearchResultQl = {
    articleId: Scalars['String']['input'];
    city: Scalars['String']['input'];
    country: Scalars['String']['input'];
    projectTypes: Array<CategoryNames>;
    publishedDate: Scalars['DateTime']['input'];
    sourceUrls: Array<Scalars['String']['input']>;
    state: Scalars['String']['input'];
    summary: Scalars['String']['input'];
};

export type ProjectDetailsQl = {
    language: UserLanguage;
    paragraphs: Array<Scalars['String']['output']>;
    projectAddress?: Maybe<ProjectAddressQl>;
    projectTypes: Array<CategoryNames>;
    publishedDate?: Maybe<Scalars['DateTime']['output']>;
    sourceUrl?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
};

export type ProjectQl = {
    projectDetails?: Maybe<ProjectDetailsQl>;
    projectId: Scalars['String']['output'];
    type: ProjectType;
};

export enum ProjectType {
    ArticleFreeText = 'ARTICLE_FREE_TEXT',
    ArticleItemId = 'ARTICLE_ITEM_ID',
    Br = 'BR',
    Unknown = 'UNKNOWN',
}

export type QuarterlySalesPeriodQl = {
    frequency: SalesPeriodFrequency;
    quarter: Scalars['Int']['output'];
    year: Scalars['Int']['output'];
};

export type Query = {
    actionFeatureContactEnrichment: ContactEnrichmentResultQl;
    actionFeatureContactEnrichmentCompanyDomain: Array<DomainEnrichmentDataValueQl>;
    actionFeatureGoogleEnrichmentPlaceDetails: PlaceDetailsQl;
    actionFeatureGoogleEnrichmentPlaces: Array<PlaceQl>;
    actionFeatureValuePropOutreachCallScript: QuestionResponseQl;
    actionFeatureValuePropOutreachEmail: QuestionResponseQl;
    actionFeatureValuePropOutreachFollowUp: QuestionResponseQl;
    actionFeatureXray: QuestionResponseQl;
    allDeals: Array<DealQl>;
    allEntitySyncRequests: Array<EntitySyncRequestQl>;
    allExportMappings: Array<ExportMappingQl>;
    allIntegrations: Array<IntegrationQl>;
    allOutboundemails: Array<OutboundEmailQl>;
    /** Returns all pipelines */
    allPipelines: Array<PipelineQl>;
    allValueMilestones: Array<ValueMilestoneQl>;
    appInfo: AppInfoQl;
    colleaguesAchievements: Array<ColleagueAchievementsQl>;
    company: CompanyQl;
    deal: DealQl;
    dealsCollection: DealCollectionInfoQl;
    debug: DebugQl;
    entitySyncRequest: EntitySyncRequestQl;
    exportMapping: ExportMappingQl;
    getCompanyLinkedinProfiles: LinkedInSearchResultQl;
    getContactExtractionResult: CombinedContactExtractionResultQl;
    getDealAttachmentDownloadUrl: StringQl;
    getLinkedinProfileContactInformation: LinkedInProfileContactInformationQl;
    integration: IntegrationQl;
    me: UserQl;
    omniSearch: OmniSearchResultQl;
    outboundemail: OutboundEmailQl;
    pipeline: PipelineQl;
    pipelineStatistics?: Maybe<PipelineStatisticsQl>;
    timeoutTest: Scalars['String']['output'];
    translate: Scalars['String']['output'];
    userAchievements: UserAchievementsQl;
};

export type QueryActionFeatureContactEnrichmentArgs = {
    company: Scalars['String']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    contactName: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
    domain?: InputMaybe<Scalars['String']['input']>;
    linkedinUrl?: InputMaybe<Scalars['String']['input']>;
    triggeredFromSearchRefinement?: Scalars['Boolean']['input'];
};

export type QueryActionFeatureContactEnrichmentCompanyDomainArgs = {
    companyName: Scalars['String']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryActionFeatureGoogleEnrichmentPlaceDetailsArgs = {
    language: Scalars['String']['input'];
    placeId: Scalars['String']['input'];
};

export type QueryActionFeatureGoogleEnrichmentPlacesArgs = {
    dealId: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type QueryActionFeatureValuePropOutreachCallScriptArgs = {
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryActionFeatureValuePropOutreachEmailArgs = {
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    vpoSessionId: Scalars['String']['input'];
};

export type QueryActionFeatureValuePropOutreachFollowUpArgs = {
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryActionFeatureXrayArgs = {
    dealId?: InputMaybe<Scalars['String']['input']>;
    documentId?: InputMaybe<Scalars['String']['input']>;
    projectId?: InputMaybe<Scalars['Int']['input']>;
    question: Scalars['String']['input'];
};

export type QueryAllDealsArgs = {
    queryParams?: InputMaybe<QueryParamsQl>;
};

export type QueryAllEntitySyncRequestsArgs = {
    queryParams?: InputMaybe<QueryParamsQl>;
};

export type QueryAllPipelinesArgs = {
    queryParams?: InputMaybe<QueryParamsQl>;
};

export type QueryDealArgs = {
    dealId: Scalars['String']['input'];
};

export type QueryDealsCollectionArgs = {
    queryParams?: InputMaybe<QueryParamsQl>;
};

export type QueryEntitySyncRequestArgs = {
    esrId: Scalars['String']['input'];
};

export type QueryExportMappingArgs = {
    exportMappingId: Scalars['String']['input'];
};

export type QueryGetCompanyLinkedinProfilesArgs = {
    companyName: Scalars['String']['input'];
    keyword?: InputMaybe<Scalars['String']['input']>;
    location?: InputMaybe<Scalars['String']['input']>;
    page?: Scalars['Int']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetContactExtractionResultArgs = {
    dealId: Scalars['String']['input'];
};

export type QueryGetDealAttachmentDownloadUrlArgs = {
    contentId: Scalars['String']['input'];
    inline?: Scalars['Boolean']['input'];
};

export type QueryGetLinkedinProfileContactInformationArgs = {
    linkedinUsername: Scalars['String']['input'];
};

export type QueryIntegrationArgs = {
    integrationId: Scalars['String']['input'];
};

export type QueryOmniSearchArgs = {
    limit: Scalars['Int']['input'];
    query: Scalars['String']['input'];
};

export type QueryOutboundemailArgs = {
    emailId: Scalars['String']['input'];
};

export type QueryPipelineArgs = {
    pipelineId: Scalars['String']['input'];
};

export type QueryPipelineStatisticsArgs = {
    forCurrentUser?: InputMaybe<Scalars['Boolean']['input']>;
    pipelineId: Scalars['String']['input'];
};

export type QueryTimeoutTestArgs = {
    timeoutSec?: Scalars['Int']['input'];
};

export type QueryTranslateArgs = {
    language: Scalars['String']['input'];
    text: Scalars['String']['input'];
};

export type QueryParamsQl = {
    /** Optional dynamic filters to apply to the result set. Multiple dynamic filters are combined with OR and applied after the 'filters'. */
    dynamicFiltering?: InputMaybe<Array<DynamicFilter>>;
    /** Optional filters to apply to the result set. Multiple filters are combined with AND. */
    filtering?: InputMaybe<Array<FilterOpQl>>;
    pagination?: InputMaybe<PaginationConfQl>;
    /** Optional sorting to apply to the result set. */
    sorting?: InputMaybe<Array<SortingOpQl>>;
};

export type QuestionResponseQl = {
    answer: Scalars['String']['output'];
};

export type ReformattedExtractedCompanyInputQl = {
    customValues?: InputMaybe<Array<CustomFieldValueInputQl>>;
    extractionTypes: Array<ExtractionType>;
    name: Scalars['String']['input'];
    notes: Scalars['String']['input'];
};

export type ReformattedExtractedContactInputQl = {
    companyName: Scalars['String']['input'];
    customValues?: InputMaybe<Array<CustomFieldValueInputQl>>;
    extractionTypes: Array<ExtractionType>;
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    notes: Scalars['String']['input'];
};

export type RegenerationSpecQl = {
    previousText: Scalars['String']['input'];
    userRawPrompt: Scalars['String']['input'];
};

export type RelevantCompanyCreateQl = {
    customInput?: InputMaybe<RelevantCompanyUpdateCustomInputQl>;
    extracted?: InputMaybe<Scalars['Boolean']['input']>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    name: Scalars['String']['input'];
    notes?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
};

export type RelevantCompanyQl = NodeQl & {
    contacts?: Maybe<Array<ContactQl>>;
    createdDate: Scalars['DateTime']['output'];
    customFields?: Maybe<Array<CustomFieldQl>>;
    extracted?: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['String']['output'];
    isKey?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    notes?: Maybe<Scalars['String']['output']>;
    parentDeals?: Maybe<Array<DealQl>>;
    placeDetails?: Maybe<Array<PlaceDetailsQl>>;
    role?: Maybe<Scalars['String']['output']>;
};

export type RelevantCompanyUpdateCustomInputQl = {
    customValues: Array<CustomValueInputQl>;
};

export type RelevantCompanyUpdateQl = {
    customInput?: InputMaybe<RelevantCompanyUpdateCustomInputQl>;
    extracted?: InputMaybe<Scalars['Boolean']['input']>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
};

export type ReminderCreateInputQl = {
    assigneeId?: InputMaybe<Scalars['Int']['input']>;
    description: Scalars['String']['input'];
    dueDate: Scalars['Date']['input'];
    target: ReminderTargetInputQl;
};

export type ReminderQl = {
    assignee: Scalars['Int']['output'];
    completedDate?: Maybe<Scalars['DateTime']['output']>;
    createdDate: Scalars['DateTime']['output'];
    creator: Scalars['Int']['output'];
    description: Scalars['String']['output'];
    dueDate: Scalars['Date']['output'];
    id: Scalars['String']['output'];
    target: ReminderTargetQl;
    updatedDate: Scalars['DateTime']['output'];
};

export type ReminderTargetInputQl = {
    id: Scalars['String']['input'];
    type: ReminderTargetType;
};

export type ReminderTargetQl = {
    id: Scalars['String']['output'];
    title: Scalars['String']['output'];
    type: ReminderTargetType;
};

export enum ReminderTargetType {
    Deal = 'DEAL',
}

export type ReminderUpdateInputQl = {
    assigneeId?: InputMaybe<Scalars['Int']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    dueDate?: InputMaybe<Scalars['Date']['input']>;
};

export type ResultQl = {
    ok: Scalars['Boolean']['output'];
};

export type RoleConfigQl = {
    exportable?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
};

export type RolesConfig = {
    contacts?: Maybe<Array<RoleConfigQl>>;
    relevantCompanies?: Maybe<Array<RoleConfigQl>>;
};

export type SalesPeriodConfigurationQl = {
    frequency: SalesPeriodFrequency;
};

export enum SalesPeriodFrequency {
    Annually = 'ANNUALLY',
    Quarterly = 'QUARTERLY',
}

export type SearchMetaDataQl = {
    page: Scalars['Int']['output'];
    pageSize: Scalars['Int']['output'];
    total: Scalars['Int']['output'];
};

export enum SortingKeys {
    SkActivityLogCreatedDate = 'SK_ACTIVITY_LOG_CREATED_DATE',
    SkDealAssigneeBid = 'SK_DEAL_ASSIGNEE_BID',
    SkDealClosestNextStepDate = 'SK_DEAL_CLOSEST_NEXT_STEP_DATE',
    SkDealCompletedDate = 'SK_DEAL_COMPLETED_DATE',
    SkDealCreatedDate = 'SK_DEAL_CREATED_DATE',
    SkDealCsvExportedDate = 'SK_DEAL_CSV_EXPORTED_DATE',
    SkDealCurrentStageId = 'SK_DEAL_CURRENT_STAGE_ID',
    SkDealExpectedCurrentStageConversionDate = 'SK_DEAL_EXPECTED_CURRENT_STAGE_CONVERSION_DATE',
    SkDealExpectedFinalConversionDate = 'SK_DEAL_EXPECTED_FINAL_CONVERSION_DATE',
    SkDealExpectedSalesPeriod = 'SK_DEAL_EXPECTED_SALES_PERIOD',
    SkDealOutreachCount = 'SK_DEAL_OUTREACH_COUNT',
    SkDealPipelineId = 'SK_DEAL_PIPELINE_ID',
    SkDealStageUpdatedDate = 'SK_DEAL_STAGE_UPDATED_DATE',
    SkDealState = 'SK_DEAL_STATE',
    SkDealTitle = 'SK_DEAL_TITLE',
    SkDealValueCurrencyNaive = 'SK_DEAL_VALUE_CURRENCY_NAIVE',
    SkEsrCreatedDate = 'SK_ESR_CREATED_DATE',
    SkPipeCreatedDate = 'SK_PIPE_CREATED_DATE',
}

export type SortingOpQl = {
    field: SortingKeys;
    sop: SortingOperators;
};

export enum SortingOperators {
    SopAsc = 'SOP_ASC',
    SopDesc = 'SOP_DESC',
}

export type StringQl = {
    value: Scalars['String']['output'];
};

export type Subscription = {
    actionFeatureValuePropOutreachCallScript: QuestionResponseQl;
    actionFeatureValuePropOutreachEmail: QuestionResponseQl;
    actionFeatureValuePropOutreachFollowUp: QuestionResponseQl;
    actionFeatureXray: QuestionResponseQl;
    subscribeUserUpdates: InstantMessageQl;
};

export type SubscriptionActionFeatureValuePropOutreachCallScriptArgs = {
    accumulateResponse?: Scalars['Boolean']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionActionFeatureValuePropOutreachEmailArgs = {
    accumulateResponse?: Scalars['Boolean']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    vpoSessionId: Scalars['String']['input'];
};

export type SubscriptionActionFeatureValuePropOutreachFollowUpArgs = {
    accumulateResponse?: Scalars['Boolean']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    outreachBlockIds: Array<Scalars['String']['input']>;
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionActionFeatureXrayArgs = {
    accumulateResponse?: Scalars['Boolean']['input'];
    dealId?: InputMaybe<Scalars['String']['input']>;
    documentId?: InputMaybe<Scalars['String']['input']>;
    projectId?: InputMaybe<Scalars['Int']['input']>;
    question: Scalars['String']['input'];
};

export enum Tristate {
    Failure = 'FAILURE',
    NotDecided = 'NOT_DECIDED',
    Success = 'SUCCESS',
}

export enum UserAchievementName {
    AddContactsEmailOrPhone = 'ADD_CONTACTS_EMAIL_OR_PHONE',
    AskArticleWizard = 'ASK_ARTICLE_WIZARD',
    AssignContactDealRole = 'ASSIGN_CONTACT_DEAL_ROLE',
    BookedFirstMeeting = 'BOOKED_FIRST_MEETING',
    CreateYourFirstContact = 'CREATE_YOUR_FIRST_CONTACT',
    CreateYourFirstDeal = 'CREATE_YOUR_FIRST_DEAL',
    DisqualifyADeal = 'DISQUALIFY_A_DEAL',
    OutreachByEmail = 'OUTREACH_BY_EMAIL',
    OutreachByPhone = 'OUTREACH_BY_PHONE',
    PauseADeal = 'PAUSE_A_DEAL',
    PlanANextStep = 'PLAN_A_NEXT_STEP',
    ResolveANextStep = 'RESOLVE_A_NEXT_STEP',
}

export enum UserAchievementState {
    Achieved = 'ACHIEVED',
    NotAchieved = 'NOT_ACHIEVED',
    Started = 'STARTED',
}

export type UserAchievementsDetailsQl = {
    addContactsEmailOrPhone: UserSingleAchievementDetailsQl;
    askArticleWizard: UserSingleAchievementDetailsQl;
    assignContactDealRole: UserSingleAchievementDetailsQl;
    bookedFirstMeeting: UserSingleAchievementDetailsQl;
    createYourFirstContact: UserSingleAchievementDetailsQl;
    createYourFirstDeal: UserSingleAchievementDetailsQl;
    disqualifyADeal: UserSingleAchievementDetailsQl;
    outreachByEmail: UserSingleAchievementDetailsQl;
    outreachByPhone: UserSingleAchievementDetailsQl;
    pauseADeal: UserSingleAchievementDetailsQl;
    planANextStep: UserSingleAchievementDetailsQl;
    resolveANextStep: UserSingleAchievementDetailsQl;
};

export type UserAchievementsQl = {
    achievements: UserAchievementsDetailsQl;
    companyBid: Scalars['Int']['output'];
    createdDate: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    updatedDate: Scalars['DateTime']['output'];
    userId: Scalars['Int']['output'];
};

export enum UserFeatureFlag {
    CompanyInfoWeb = 'COMPANY_INFO_WEB',
    SampleFlag = 'SAMPLE_FLAG',
}

export enum UserLanguage {
    De = 'DE',
    En = 'EN',
}

export type UserQl = NodeQl & {
    companyId: Scalars['String']['output'];
    featureEnabled: Scalars['Boolean']['output'];
    hubspotToken: Scalars['String']['output'];
    id: Scalars['String']['output'];
    mentions?: Maybe<Array<MentionQl>>;
    myDeals: Array<DealQl>;
    reminders: Array<ReminderQl>;
};

export type UserQlFeatureEnabledArgs = {
    defaultValue?: Scalars['Boolean']['input'];
    featureFlag: UserFeatureFlag;
};

export type UserSingleAchievementDetailsQl = {
    achievementState: UserAchievementState;
    achievementStateUpdateDate?: Maybe<Scalars['DateTime']['output']>;
};

export type ValueCurrencyInput = {
    currency: Scalars['String']['input'];
    value: Scalars['BigInt']['input'];
};

export type ValueCurrencyQl = {
    currency: Scalars['String']['output'];
    value: Scalars['BigInt']['output'];
};

export type ValueMilestoneQl = {
    companyBid: Scalars['Int']['output'];
    createdDate: Scalars['DateTime']['output'];
    id: Scalars['String']['output'];
    pipeline: Scalars['String']['output'];
    stage: Scalars['String']['output'];
    type: ValueMilestoneType;
    updatedDate: Scalars['DateTime']['output'];
};

export enum ValueMilestoneType {
    DealClosedWon = 'DEAL_CLOSED_WON',
    LeadQualified = 'LEAD_QUALIFIED',
    MeetingBooked = 'MEETING_BOOKED',
}

export type DealAlertPartFragment = {
    type: DealAlertType;
    currentUserRelated: boolean;
};

export type ActivityLogPartFragment = {
    id: string;
    companyBid: number;
    createdDate: any;
    updatedDate: any;
    userId: number;
    type: ActivityLogType;
    notes: string;
    dealId?: string | null;
    contactId?: string | null;
    relevantCompanyId?: string | null;
    subType?: ActivityLogSubType | null;
    customType: string;
};

export type DealPartFragment = {
    id: string;
    title: string;
    assignee: number;
    state: DealState;
    notes: string;
    stageUpdatedDate: any;
    createdDate: any;
    completedDate?: any | null;
    pausedUntil?: any | null;
    summary?: string | null;
    value: { currency: string; value: any };
    project?: { projectId: string } | null;
    pipeline: { id: string; name: string };
    currentObjectives: Array<{
        criteria: string;
        id: string;
        state: Tristate;
        status: string;
        type: ObjectiveType;
        order?: number | null;
        linkedField?: { name: string; type: LinkedFieldType } | null;
    }>;
    currentStage: { id: string };
    exportedDate?: { email?: any | null; csv?: any | null } | null;
    reminders: Array<{
        id: string;
        assignee: number;
        description: string;
        dueDate: any;
        createdDate: any;
        updatedDate: any;
        completedDate?: any | null;
        creator: number;
        target: { id: string; type: ReminderTargetType; title: string };
    }>;
    alert: { type: DealAlertType; currentUserRelated: boolean };
    activityLog: Array<{
        id: string;
        companyBid: number;
        createdDate: any;
        updatedDate: any;
        userId: number;
        type: ActivityLogType;
        notes: string;
        dealId?: string | null;
        contactId?: string | null;
        relevantCompanyId?: string | null;
        subType?: ActivityLogSubType | null;
        customType: string;
    }>;
};

export type DealAnnualSalesPeriodFragment = {
    year: number;
    frequency: SalesPeriodFrequency;
};

export type DealQuarterlySalesPeriodFragment = {
    year: number;
    quarter: number;
    frequency: SalesPeriodFrequency;
};

export type PipelineStatisticsDealPartFragment = {
    id: string;
    title: string;
    assignee: number;
    createdDate: any;
    completedDate?: any | null;
    state: DealState;
    pausedUntil?: any | null;
    expectedCurrentStageConversionDate: any;
    value: { currency: string; value: any };
    currentStage: { id: string };
    exportedDate?: { email?: any | null; csv?: any | null } | null;
    expectedSalesPeriod?:
        | { year: number; frequency: SalesPeriodFrequency }
        | { year: number; quarter: number; frequency: SalesPeriodFrequency }
        | null;
    alert: { type: DealAlertType; currentUserRelated: boolean };
    project?: { projectId: string } | null;
};

export type MinimalDealPartFragment = {
    id: string;
    title: string;
    assignee: number;
    createdDate: any;
    completedDate?: any | null;
    state: DealState;
    pausedUntil?: any | null;
    expectedCurrentStageConversionDate: any;
    pipeline: { name: string; id: string };
    value: { currency: string; value: any };
    currentStage: { id: string };
    exportedDate?: { email?: any | null; csv?: any | null } | null;
    expectedSalesPeriod?:
        | { year: number; frequency: SalesPeriodFrequency }
        | { year: number; quarter: number; frequency: SalesPeriodFrequency }
        | null;
    alert: { type: DealAlertType; currentUserRelated: boolean };
    project?: { projectId: string } | null;
};

export type PipelineDealPartFragment = {
    id: string;
    pausedUntil?: any | null;
    title: string;
    stageUpdatedDate: any;
    assignee: number;
    alert: { type: DealAlertType; currentUserRelated: boolean };
    currentStage: { id: string };
    outreachLogStats: { count: number; lastActivityDate?: any | null };
};

export type ObjectivePartFragment = {
    criteria: string;
    id: string;
    state: Tristate;
    status: string;
    type: ObjectiveType;
    order?: number | null;
    linkedField?: { name: string; type: LinkedFieldType } | null;
};

export type ProjectPartFragment = { projectId: string };

export type DealContactPartFragment = {
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    role?: string | null;
    notes?: string | null;
    extracted?: boolean | null;
    isKey?: boolean | null;
    createdDate: any;
    customFields?: Array<{
        acceptedValues?: Array<string> | null;
        defaultValue?: string | null;
        label: string;
        name: string;
        type: CustomFieldType;
        value?: string | null;
        order: number;
        isTag: boolean;
        isVisibleInDealPage: boolean;
        enrichmentDetail?: { id: string; source: string } | null;
    }> | null;
    relevantCompany?: { id: string; name: string } | null;
    parentDeals?: Array<{ id: string }> | null;
};

export type DealCompanyPartFragment = {
    id: string;
    name: string;
    role?: string | null;
    notes?: string | null;
    extracted?: boolean | null;
    isKey?: boolean | null;
    createdDate: any;
    customFields?: Array<{
        acceptedValues?: Array<string> | null;
        defaultValue?: string | null;
        label: string;
        name: string;
        type: CustomFieldType;
        value?: string | null;
        order: number;
        isTag: boolean;
        isVisibleInDealPage: boolean;
        enrichmentDetail?: { id: string; source: string } | null;
    }> | null;
    contacts?: Array<{
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        relevantCompany?: { id: string; name: string } | null;
        parentDeals?: Array<{ id: string }> | null;
    }> | null;
    parentDeals?: Array<{ id: string }> | null;
    placeDetails?: Array<{
        name?: string | null;
        type?: string | null;
        formattedAddress?: string | null;
        id: string;
        internationalPhoneNumber?: string | null;
        website?: string | null;
        email?: string | null;
        addressDetail?: {
            city?: string | null;
            country?: string | null;
            countryCode?: string | null;
            postalCode?: string | null;
            street?: string | null;
            streetNumber?: string | null;
        } | null;
    }> | null;
};

export type PipelinePartFragment = {
    id: string;
    name: string;
    config: {
        timePeriodStart: any;
        goalsEnabled: boolean;
        averageDealValue: { currency: string; value: any };
        revenueGoal: { currency: string; value: any };
        stages: {
            initialStage: string;
            lastStage: string;
            stages: Array<{
                id: string;
                name: string;
                order: number;
                targetConversionRate?: number | null;
                defaultObjectives?: Array<{
                    criteria: string;
                    linkedField?: {
                        name: string;
                        type: LinkedFieldType;
                    } | null;
                }> | null;
            }>;
        };
    };
    statistics: {
        stages: Array<{
            id: string;
            observedConversionRate: number;
            goal: number;
            targetConversionRate: number;
            atRiskDeals: { numberOfDeals: number; totalDealValue: any };
            healthyDeals: { numberOfDeals: number; totalDealValue: any };
            inStageDeals: { numberOfDeals: number; totalDealValue: any };
            markedDoneDeals: { numberOfDeals: number; totalDealValue: any };
            disqualifiedDeals: { numberOfDeals: number; totalDealValue: any };
            progressedDeals: { numberOfDeals: number; totalDealValue: any };
        }>;
    };
};

export type PipelineUpdateNamePartFragment = { id: string; name: string };

export type PipelineStageStatisticsPartFragment = {
    id: string;
    observedConversionRate: number;
    goal: number;
    targetConversionRate: number;
    atRiskDeals: { numberOfDeals: number; totalDealValue: any };
    healthyDeals: { numberOfDeals: number; totalDealValue: any };
    inStageDeals: { numberOfDeals: number; totalDealValue: any };
    markedDoneDeals: { numberOfDeals: number; totalDealValue: any };
    disqualifiedDeals: { numberOfDeals: number; totalDealValue: any };
    progressedDeals: { numberOfDeals: number; totalDealValue: any };
};

export type PipelineStatisticsPartFragment = {
    stages: Array<{
        id: string;
        observedConversionRate: number;
        goal: number;
        targetConversionRate: number;
        atRiskDeals: { numberOfDeals: number; totalDealValue: any };
        healthyDeals: { numberOfDeals: number; totalDealValue: any };
        inStageDeals: { numberOfDeals: number; totalDealValue: any };
        markedDoneDeals: { numberOfDeals: number; totalDealValue: any };
        disqualifiedDeals: { numberOfDeals: number; totalDealValue: any };
        progressedDeals: { numberOfDeals: number; totalDealValue: any };
    }>;
};

export type PipelineConfigPartFragment = {
    timePeriodStart: any;
    goalsEnabled: boolean;
    averageDealValue: { currency: string; value: any };
    revenueGoal: { currency: string; value: any };
    stages: {
        initialStage: string;
        lastStage: string;
        stages: Array<{
            id: string;
            name: string;
            order: number;
            targetConversionRate?: number | null;
            defaultObjectives?: Array<{
                criteria: string;
                linkedField?: { name: string; type: LinkedFieldType } | null;
            }> | null;
        }>;
    };
};

export type PipelineStagesConfigFragment = {
    initialStage: string;
    lastStage: string;
    stages: Array<{
        id: string;
        name: string;
        order: number;
        targetConversionRate?: number | null;
        defaultObjectives?: Array<{
            criteria: string;
            linkedField?: { name: string; type: LinkedFieldType } | null;
        }> | null;
    }>;
};

export type PipelineStagesDataConfigFragment = {
    id: string;
    name: string;
    order: number;
    targetConversionRate?: number | null;
    defaultObjectives?: Array<{
        criteria: string;
        linkedField?: { name: string; type: LinkedFieldType } | null;
    }> | null;
};

export type ValueCurrencyPartFragment = { currency: string; value: any };

export type IntegrationPartFragment = {
    id: string;
    name: string;
    integrationType: IntegrationType;
    provider: IntegrationProvider;
    driver: IntegrationDriver;
    mapper: IntegrationMapper;
    createdDate: any;
    updatedDate: any;
};

export type CrmStatePartFragment = {
    state?: EntitySyncState | null;
    remoteUrl?: string | null;
    exportedDate?: any | null;
};

export type DealCommentFragment = {
    id: string;
    creatorBid: number;
    content: string;
    createdDate: any;
    updatedDate: any;
    parentId: string;
};

export type CustomFieldFragment = {
    acceptedValues?: Array<string> | null;
    defaultValue?: string | null;
    label: string;
    name: string;
    type: CustomFieldType;
    value?: string | null;
    order: number;
    isTag: boolean;
    isVisibleInDealPage: boolean;
    enrichmentDetail?: { id: string; source: string } | null;
};

export type CustomFieldConfigFragment = {
    acceptedValues?: Array<string> | null;
    defaultValue?: string | null;
    label: string;
    name: string;
    type: CustomFieldType;
    order: number;
    isTag: boolean;
    isVisibleInDealPage: boolean;
};

export type MentionPartFragment = {
    commentId: string;
    creatorBid: number;
    updatedDate: any;
    isRead: boolean;
    deal: { id: string; title: string };
};

export type MentionDealPartFragment = { id: string; title: string };

export type RolesPartFragment = {
    contacts?: Array<{ name: string }> | null;
    relevantCompanies?: Array<{ name: string }> | null;
};

export type LinkedFieldPartFragment = { name: string; type: LinkedFieldType };

export type GoogleEnrichmentPartFragment = {
    name?: string | null;
    type?: string | null;
    formattedAddress?: string | null;
    id: string;
    internationalPhoneNumber?: string | null;
    website?: string | null;
    email?: string | null;
    addressDetail?: {
        city?: string | null;
        country?: string | null;
        countryCode?: string | null;
        postalCode?: string | null;
        street?: string | null;
        streetNumber?: string | null;
    } | null;
};

export type ExtractedContactPartFragment = {
    firstName: string;
    lastName: string;
    companyName: string;
    legitimateInterestText: string;
    role: string;
    sourceUrls: Array<string>;
};

export type PlacePartFragment = { id: string; name: string };

export type ReminderPartFragment = {
    id: string;
    assignee: number;
    description: string;
    dueDate: any;
    createdDate: any;
    updatedDate: any;
    completedDate?: any | null;
    creator: number;
    target: { id: string; type: ReminderTargetType; title: string };
};

export type DynamicFilterMetricsPartFragment = {
    count: number;
    dynamicFilter: DynamicFilter;
};

export type ExtractedCompanyPartFragment = {
    legitimateInterestText: string;
    name: string;
    role: string;
    sourceUrls: Array<string>;
};

export type AchievementDetailsPartFragment = {
    achievementState: UserAchievementState;
    achievementStateUpdateDate?: any | null;
};

export type UserAchievementsFragment = {
    resolveANextStep: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    planANextStep: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    addContactsEmailOrPhone: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    askArticleWizard: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    assignContactDealRole: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    bookedFirstMeeting: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    outreachByEmail: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    disqualifyADeal: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    createYourFirstContact: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    createYourFirstDeal: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    outreachByPhone: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
    pauseADeal: {
        achievementState: UserAchievementState;
        achievementStateUpdateDate?: any | null;
    };
};

export type ValueMilestoneFragment = {
    id: string;
    type: ValueMilestoneType;
    pipeline: string;
};

export type ColleagueAchievementsFragment = {
    defaultPipelineId: string;
    userId: number;
    optInAchievements: boolean;
    achievements: {
        resolveANextStep: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        planANextStep: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        addContactsEmailOrPhone: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        askArticleWizard: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        assignContactDealRole: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        bookedFirstMeeting: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        outreachByEmail: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        disqualifyADeal: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        createYourFirstContact: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        createYourFirstDeal: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        outreachByPhone: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
        pauseADeal: {
            achievementState: UserAchievementState;
            achievementStateUpdateDate?: any | null;
        };
    };
};

export type PipelineStatisticsFragment = {
    countContacts: number;
    countDeals: number;
    countDealsWon: number;
    countLeadsQualified: number;
    countMeetingsBooked: number;
};

export type ContactExtractionPartFragment = {
    status: ContactExtractionStatus;
    extractionType: ExtractionType;
    extractedContacts: Array<{
        firstName: string;
        lastName: string;
        companyName: string;
        legitimateInterestText: string;
        role: string;
        sourceUrls: Array<string>;
    }>;
    extractedCompanies: Array<{
        legitimateInterestText: string;
        name: string;
        role: string;
        sourceUrls: Array<string>;
    }>;
};

export type ContactExtractionResultFragment = {
    projectContextResult?: {
        status: ContactExtractionStatus;
        extractionType: ExtractionType;
        extractedContacts: Array<{
            firstName: string;
            lastName: string;
            companyName: string;
            legitimateInterestText: string;
            role: string;
            sourceUrls: Array<string>;
        }>;
        extractedCompanies: Array<{
            legitimateInterestText: string;
            name: string;
            role: string;
            sourceUrls: Array<string>;
        }>;
    } | null;
    webSearchResult?: {
        status: ContactExtractionStatus;
        extractionType: ExtractionType;
        extractedContacts: Array<{
            firstName: string;
            lastName: string;
            companyName: string;
            legitimateInterestText: string;
            role: string;
            sourceUrls: Array<string>;
        }>;
        extractedCompanies: Array<{
            legitimateInterestText: string;
            name: string;
            role: string;
            sourceUrls: Array<string>;
        }>;
    } | null;
};

export type LinkedinProfileFragment = {
    title: string;
    location: string;
    linkedinUsername: string;
    headline: string;
    hasPhone: boolean;
    hasEmail: boolean;
    fullName: string;
    companyCountry: string;
    companyName: string;
};

export type LinkedinSearchMetaFragment = {
    page: number;
    pageSize: number;
    total: number;
};

export type LinkedinProfileContactInfoFragment = {
    personalEmail: Array<string>;
    phone: Array<string>;
    workEmail: Array<string>;
};

export type DealContentMetadataFragment = {
    id: string;
    type: DealContentType;
    source?: string | null;
    name?: string | null;
    publishedDate?: any | null;
    hasContent: boolean;
    creationSource: DealContentCreationSource;
};

export type DealFileAttachmentDownloadUrlFragment = { value: string };

export type InstantMessageFragment = {
    userTargetId: number;
    userSourceId: number;
    type: InstantUserMessageType;
    content: { dealId: string; title: string };
};

export type MoveDealToStageMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    stageId: Scalars['String']['input'];
}>;

export type MoveDealToStageMutation = { moveToStage: { ok: boolean } };

export type AssignUserToDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    update: DealUpdateInputQl;
}>;

export type AssignUserToDealMutation = { updateDeal: { ok: boolean } };

export type DisqualifyDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    reason?: InputMaybe<Scalars['String']['input']>;
}>;

export type DisqualifyDealMutation = {
    disqualifyDeal: {
        id: string;
        title: string;
        assignee: number;
        state: DealState;
        notes: string;
        stageUpdatedDate: any;
        createdDate: any;
        completedDate?: any | null;
        pausedUntil?: any | null;
        summary?: string | null;
        value: { currency: string; value: any };
        project?: { projectId: string } | null;
        pipeline: { id: string; name: string };
        currentObjectives: Array<{
            criteria: string;
            id: string;
            state: Tristate;
            status: string;
            type: ObjectiveType;
            order?: number | null;
            linkedField?: { name: string; type: LinkedFieldType } | null;
        }>;
        currentStage: { id: string };
        exportedDate?: { email?: any | null; csv?: any | null } | null;
        reminders: Array<{
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: { id: string; type: ReminderTargetType; title: string };
        }>;
        alert: { type: DealAlertType; currentUserRelated: boolean };
        activityLog: Array<{
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type RestoreDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type RestoreDealMutation = {
    restoreDeal: {
        id: string;
        title: string;
        assignee: number;
        state: DealState;
        notes: string;
        stageUpdatedDate: any;
        createdDate: any;
        completedDate?: any | null;
        pausedUntil?: any | null;
        summary?: string | null;
        value: { currency: string; value: any };
        project?: { projectId: string } | null;
        pipeline: { id: string; name: string };
        currentObjectives: Array<{
            criteria: string;
            id: string;
            state: Tristate;
            status: string;
            type: ObjectiveType;
            order?: number | null;
            linkedField?: { name: string; type: LinkedFieldType } | null;
        }>;
        currentStage: { id: string };
        exportedDate?: { email?: any | null; csv?: any | null } | null;
        reminders: Array<{
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: { id: string; type: ReminderTargetType; title: string };
        }>;
        alert: { type: DealAlertType; currentUserRelated: boolean };
        activityLog: Array<{
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type PauseDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    pausedUntil: Scalars['DateTime']['input'];
}>;

export type PauseDealMutation = { pauseDeal: { ok: boolean } };

export type UnPauseDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type UnPauseDealMutation = { unpauseDeal: { ok: boolean } };

export type MarkDealAsDoneMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type MarkDealAsDoneMutation = {
    markDealAsDone: {
        id: string;
        title: string;
        assignee: number;
        state: DealState;
        notes: string;
        stageUpdatedDate: any;
        createdDate: any;
        completedDate?: any | null;
        pausedUntil?: any | null;
        summary?: string | null;
        value: { currency: string; value: any };
        project?: { projectId: string } | null;
        pipeline: { id: string; name: string };
        currentObjectives: Array<{
            criteria: string;
            id: string;
            state: Tristate;
            status: string;
            type: ObjectiveType;
            order?: number | null;
            linkedField?: { name: string; type: LinkedFieldType } | null;
        }>;
        currentStage: { id: string };
        exportedDate?: { email?: any | null; csv?: any | null } | null;
        reminders: Array<{
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: { id: string; type: ReminderTargetType; title: string };
        }>;
        alert: { type: DealAlertType; currentUserRelated: boolean };
        activityLog: Array<{
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type CreateNewDealMutationMutationVariables = Exact<{
    pipelineId: Scalars['String']['input'];
    projectId?: InputMaybe<Scalars['String']['input']>;
    title: Scalars['String']['input'];
    creationSource: DealCreationSource;
    searchNames?: InputMaybe<
        Array<Scalars['String']['input']> | Scalars['String']['input']
    >;
    currentSearchName?: InputMaybe<Scalars['String']['input']>;
    projectDataFromCustomText?: InputMaybe<ProjectDataFromCustomTextQl>;
    projectDataFromSearchResult?: InputMaybe<ProjectDataFromSearchResultQl>;
}>;

export type CreateNewDealMutationMutation = {
    createDeal: { dealId: string; ok: boolean };
};

export type UpdateDealInformationMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    updateDeal: DealUpdateInputQl;
}>;

export type UpdateDealInformationMutation = { updateDeal: { ok: boolean } };

export type UpdateObjectiveMutationMutationVariables = Exact<{
    objectiveId: Scalars['String']['input'];
    updateObjective: ObjectiveUpdateInputQl;
}>;

export type UpdateObjectiveMutationMutation = {
    updateObjective: { ok: boolean };
};

export type GetAllDealsQueryVariables = Exact<{
    queryParams: QueryParamsQl;
}>;

export type GetAllDealsQuery = {
    allDeals: Array<{
        id: string;
        title: string;
        assignee: number;
        createdDate: any;
        completedDate?: any | null;
        state: DealState;
        pausedUntil?: any | null;
        expectedCurrentStageConversionDate: any;
        pipeline: { name: string; id: string };
        value: { currency: string; value: any };
        currentStage: { id: string };
        exportedDate?: { email?: any | null; csv?: any | null } | null;
        expectedSalesPeriod?:
            | { year: number; frequency: SalesPeriodFrequency }
            | { year: number; quarter: number; frequency: SalesPeriodFrequency }
            | null;
        alert: { type: DealAlertType; currentUserRelated: boolean };
        project?: { projectId: string } | null;
    }>;
};

export type GetDealQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealQuery = {
    deal: {
        id: string;
        title: string;
        assignee: number;
        state: DealState;
        notes: string;
        stageUpdatedDate: any;
        createdDate: any;
        completedDate?: any | null;
        pausedUntil?: any | null;
        summary?: string | null;
        value: { currency: string; value: any };
        project?: { projectId: string } | null;
        pipeline: { id: string; name: string };
        currentObjectives: Array<{
            criteria: string;
            id: string;
            state: Tristate;
            status: string;
            type: ObjectiveType;
            order?: number | null;
            linkedField?: { name: string; type: LinkedFieldType } | null;
        }>;
        currentStage: { id: string };
        exportedDate?: { email?: any | null; csv?: any | null } | null;
        reminders: Array<{
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: { id: string; type: ReminderTargetType; title: string };
        }>;
        alert: { type: DealAlertType; currentUserRelated: boolean };
        activityLog: Array<{
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type ExportCsvQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
    delimiter: Scalars['String']['input'];
}>;

export type ExportCsvQuery = {
    deal: { export: { csv: { row: string; header: string } } };
};

export type ExportBatchCsvQueryVariables = Exact<{
    queryParams: QueryParamsQl;
    delimiter: Scalars['String']['input'];
}>;

export type ExportBatchCsvQuery = {
    allDeals: Array<{
        id: string;
        export: { csv: { row: string; header: string } };
    }>;
};

export type GetDealObjectivesQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealObjectivesQuery = {
    deal: {
        currentObjectives: Array<{
            criteria: string;
            id: string;
            state: Tristate;
            status: string;
            type: ObjectiveType;
            order?: number | null;
            linkedField?: { name: string; type: LinkedFieldType } | null;
        }>;
    };
};

export type GetDealStageUpdateDateQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealStageUpdateDateQuery = { deal: { stageUpdatedDate: any } };

export type GetDealContactsQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealContactsQuery = {
    deal: {
        relevantCompanies?: Array<{
            id: string;
            name: string;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            contacts?: Array<{
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                role?: string | null;
                notes?: string | null;
                extracted?: boolean | null;
                isKey?: boolean | null;
                createdDate: any;
                customFields?: Array<{
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    value?: string | null;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                    enrichmentDetail?: { id: string; source: string } | null;
                }> | null;
                relevantCompany?: { id: string; name: string } | null;
                parentDeals?: Array<{ id: string }> | null;
            }> | null;
            parentDeals?: Array<{ id: string }> | null;
            placeDetails?: Array<{
                name?: string | null;
                type?: string | null;
                formattedAddress?: string | null;
                id: string;
                internationalPhoneNumber?: string | null;
                website?: string | null;
                email?: string | null;
                addressDetail?: {
                    city?: string | null;
                    country?: string | null;
                    countryCode?: string | null;
                    postalCode?: string | null;
                    street?: string | null;
                    streetNumber?: string | null;
                } | null;
            }> | null;
        }> | null;
        contacts?: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }> | null;
    };
};

export type CreateCompanyMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    name: Scalars['String']['input'];
    role?: InputMaybe<Scalars['String']['input']>;
    customInput?: InputMaybe<RelevantCompanyUpdateCustomInputQl>;
    notes?: InputMaybe<Scalars['String']['input']>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    createdFrom?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateCompanyMutation = {
    createRelevantCompany: {
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        contacts?: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }> | null;
        parentDeals?: Array<{ id: string }> | null;
        placeDetails?: Array<{
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type UpdateCompanyMutationVariables = Exact<{
    companyId: Scalars['String']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateCompanyMutation = {
    updateRelevantCompany: {
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        contacts?: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }> | null;
        parentDeals?: Array<{ id: string }> | null;
        placeDetails?: Array<{
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type UpdateCompanyNoteMutationVariables = Exact<{
    relevantCompanyId: Scalars['String']['input'];
    notes: Scalars['String']['input'];
}>;

export type UpdateCompanyNoteMutation = {
    updateRelevantCompany: {
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        contacts?: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }> | null;
        parentDeals?: Array<{ id: string }> | null;
        placeDetails?: Array<{
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type UpdateCompanyRoleMutationVariables = Exact<{
    relevantCompanyId: Scalars['String']['input'];
    role: Scalars['String']['input'];
    isKey: Scalars['Boolean']['input'];
}>;

export type UpdateCompanyRoleMutation = {
    updateRelevantCompany: {
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        contacts?: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }> | null;
        parentDeals?: Array<{ id: string }> | null;
        placeDetails?: Array<{
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type DeleteCompanyMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    relevantCompanyId: Scalars['String']['input'];
    deleteAssociatedContacts: Scalars['Boolean']['input'];
}>;

export type DeleteCompanyMutation = { deleteRelevantCompany: { ok: boolean } };

export type ExportEmailMutationVariables = Exact<{
    senderName: Scalars['String']['input'];
    toName: Scalars['String']['input'];
    toEmail: Scalars['String']['input'];
    language: UserLanguage;
    dealId: Scalars['String']['input'];
    dealOwner: Scalars['String']['input'];
    dealOwnerEmail: Scalars['String']['input'];
    dealUrl: Scalars['String']['input'];
}>;

export type ExportEmailMutation = {
    exportDealEmail: {
        ok: boolean;
        email: {
            enqueuedDate?: any | null;
            id: string;
            mtaMessageId?: string | null;
            senderBid: number;
            sentDate?: any | null;
            state: OutboundEmailState;
            template: {
                templateId: string;
                params?: Array<{ key: string; value: string }> | null;
            };
            addresses: {
                toReceivers: Array<{ email: string; name: string }>;
                replyTo?: { email: string; name: string } | null;
                fromSender: { email: string; name: string };
                ccReceivers?: Array<{ email: string; name: string }> | null;
                bccReceivers?: Array<{ email: string; name: string }> | null;
            };
        };
    };
};

export type CreateContactMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    customInput?: InputMaybe<ContactUpdateCustomInputQl>;
    isKey?: InputMaybe<Scalars['Boolean']['input']>;
    createdFrom?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateContactMutation = {
    createContact: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        relevantCompany?: { id: string; name: string } | null;
        parentDeals?: Array<{ id: string }> | null;
    };
};

export type UpdateContactMutationVariables = Exact<{
    contactId: Scalars['String']['input'];
    firstName?: InputMaybe<Scalars['String']['input']>;
    lastName?: InputMaybe<Scalars['String']['input']>;
    role?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateContactMutation = {
    updateContact: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        relevantCompany?: { id: string; name: string } | null;
        parentDeals?: Array<{ id: string }> | null;
    };
};

export type UpdateContactNoteMutationVariables = Exact<{
    contactId: Scalars['String']['input'];
    notes: Scalars['String']['input'];
}>;

export type UpdateContactNoteMutation = {
    updateContact: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        relevantCompany?: { id: string; name: string } | null;
        parentDeals?: Array<{ id: string }> | null;
    };
};

export type UpdateContactRoleMutationVariables = Exact<{
    contactId: Scalars['String']['input'];
    role: Scalars['String']['input'];
    isKey: Scalars['Boolean']['input'];
}>;

export type UpdateContactRoleMutation = {
    updateContact: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        relevantCompany?: { id: string; name: string } | null;
        parentDeals?: Array<{ id: string }> | null;
    };
};

export type DeleteContactMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    contactId: Scalars['String']['input'];
}>;

export type DeleteContactMutation = { deleteContact: { ok: boolean } };

export type AllPipelinesQueryQueryVariables = Exact<{
    queryParams?: InputMaybe<QueryParamsQl>;
}>;

export type AllPipelinesQueryQuery = {
    allPipelines: Array<{
        id: string;
        name: string;
        config: {
            timePeriodStart: any;
            goalsEnabled: boolean;
            averageDealValue: { currency: string; value: any };
            revenueGoal: { currency: string; value: any };
            stages: {
                initialStage: string;
                lastStage: string;
                stages: Array<{
                    id: string;
                    name: string;
                    order: number;
                    targetConversionRate?: number | null;
                    defaultObjectives?: Array<{
                        criteria: string;
                        linkedField?: {
                            name: string;
                            type: LinkedFieldType;
                        } | null;
                    }> | null;
                }>;
            };
        };
        statistics: {
            stages: Array<{
                id: string;
                observedConversionRate: number;
                goal: number;
                targetConversionRate: number;
                atRiskDeals: { numberOfDeals: number; totalDealValue: any };
                healthyDeals: { numberOfDeals: number; totalDealValue: any };
                inStageDeals: { numberOfDeals: number; totalDealValue: any };
                markedDoneDeals: { numberOfDeals: number; totalDealValue: any };
                disqualifiedDeals: {
                    numberOfDeals: number;
                    totalDealValue: any;
                };
                progressedDeals: { numberOfDeals: number; totalDealValue: any };
            }>;
        };
    }>;
};

export type BulkMoveMutationVariables = Exact<{
    dealIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
    targetPipelineId: Scalars['String']['input'];
    targetStageId: Scalars['String']['input'];
    targetDealState: DealState;
    unpauseDeals?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BulkMoveMutation = {
    bulkMove: { ok: boolean; updatedDealIds: Array<string> };
};

export type BulkUpdateDealCustomParametersMutationVariables = Exact<{
    dealIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
}>;

export type BulkUpdateDealCustomParametersMutation = {
    bulkUpdateDealCustomValues: { updatedDealIds: Array<string> };
};

export type BulkUpdateDealAttributesMutationVariables = Exact<{
    attribute: DealAttribute;
    dealIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
    value?: InputMaybe<Scalars['JSON']['input']>;
}>;

export type BulkUpdateDealAttributesMutation = {
    bulkUpdateDealAttributes: { updatedDealIds: Array<string> };
};

export type GetDealsCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetDealsCountQuery = { dealsCollection: { totalCount: number } };

export type GetAllDealsCollectionQueryVariables = Exact<{
    queryParams: QueryParamsQl;
}>;

export type GetAllDealsCollectionQuery = {
    dealsCollection: {
        totalCount: number;
        pageInfo: { count: number; hasNextPage: boolean };
        edges?: Array<{
            node: {
                id: string;
                title: string;
                assignee: number;
                createdDate: any;
                completedDate?: any | null;
                state: DealState;
                pausedUntil?: any | null;
                expectedCurrentStageConversionDate: any;
                pipeline: { name: string; id: string };
                value: { currency: string; value: any };
                currentStage: { id: string };
                exportedDate?: { email?: any | null; csv?: any | null } | null;
                expectedSalesPeriod?:
                    | { year: number; frequency: SalesPeriodFrequency }
                    | {
                          year: number;
                          quarter: number;
                          frequency: SalesPeriodFrequency;
                      }
                    | null;
                alert: { type: DealAlertType; currentUserRelated: boolean };
                project?: { projectId: string } | null;
            };
        }> | null;
    };
};

export type GetDealCustomParametersQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealCustomParametersQuery = {
    deal: {
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
    };
};

export type UpdateDealCustomParametersMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    update: DealUpdateCustomInputQl;
}>;

export type UpdateDealCustomParametersMutation = {
    updateDealCustomValues: { ok: boolean };
};

export type GetAllIntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllIntegrationsQuery = {
    allIntegrations: Array<{
        id: string;
        name: string;
        integrationType: IntegrationType;
        provider: IntegrationProvider;
        driver: IntegrationDriver;
        mapper: IntegrationMapper;
        createdDate: any;
        updatedDate: any;
    }>;
};

export type GetDealCommentsQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealCommentsQuery = {
    deal: {
        comments?: Array<{
            id: string;
            creatorBid: number;
            content: string;
            createdDate: any;
            updatedDate: any;
            parentId: string;
        }> | null;
    };
};

export type AddDealCommentMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    data: CommentCreateQl;
}>;

export type AddDealCommentMutation = {
    addDealComment: {
        id: string;
        creatorBid: number;
        content: string;
        createdDate: any;
        updatedDate: any;
        parentId: string;
    };
};

export type UpdateCommentMutationVariables = Exact<{
    commentId: Scalars['String']['input'];
    data: CommentUpdateQl;
}>;

export type UpdateCommentMutation = {
    updateComment: {
        id: string;
        creatorBid: number;
        content: string;
        createdDate: any;
        updatedDate: any;
        parentId: string;
    };
};

export type DeleteCommentMutationVariables = Exact<{
    commentId: Scalars['String']['input'];
}>;

export type DeleteCommentMutation = { deleteComment: { ok: boolean } };

export type ExportToCrmMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    integrationId: Scalars['String']['input'];
}>;

export type ExportToCrmMutation = { exportToCrm: { ok: boolean } };

export type GetCrmStateQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
    integrationId: Scalars['String']['input'];
}>;

export type GetCrmStateQuery = {
    deal: {
        export: {
            crm: {
                state?: EntitySyncState | null;
                remoteUrl?: string | null;
                exportedDate?: any | null;
            };
        };
    };
};

export type GetMyHubSpotTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyHubSpotTokenQuery = { me: { hubspotToken: string } };

export type UpdatePersonMutationVariables = Exact<{
    contactId: Scalars['String']['input'];
    update: ContactUpdateQl;
}>;

export type UpdatePersonMutation = {
    updateContact: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        relevantCompany?: { id: string; name: string } | null;
        parentDeals?: Array<{ id: string }> | null;
    };
};

export type UpdateRelevantCompanyMutationVariables = Exact<{
    relevantCompanyId: Scalars['String']['input'];
    update: RelevantCompanyUpdateQl;
}>;

export type UpdateRelevantCompanyMutation = {
    updateRelevantCompany: {
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        contacts?: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }> | null;
        parentDeals?: Array<{ id: string }> | null;
        placeDetails?: Array<{
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type GetAccountConfigurationQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetAccountConfigurationQuery = {
    company: {
        config?: {
            dealValueVisible: boolean;
            actionFeatures: Array<{
                active: boolean;
                name: string;
                order: number;
                settings?: any | null;
                companyOutreachStages?: Array<CompanyOutreachStage> | null;
                contactOutreachStages?: Array<ContactOutreachStage> | null;
            }>;
            salesPeriod?: { frequency: SalesPeriodFrequency } | null;
            customFields: {
                deals?: Array<{
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                }> | null;
                contacts?: Array<{
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                }> | null;
                relevantCompanies?: Array<{
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                }> | null;
            };
            roles: {
                contacts?: Array<{ name: string }> | null;
                relevantCompanies?: Array<{ name: string }> | null;
            };
        } | null;
    };
};

export type GetDealLocationDataQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
    name: Scalars['String']['input'];
}>;

export type GetDealLocationDataQuery = {
    actionFeatureGoogleEnrichmentPlaces: Array<{ id: string; name: string }>;
};

export type GetGoogleEnrichmentDataQueryVariables = Exact<{
    language: Scalars['String']['input'];
    placeId: Scalars['String']['input'];
}>;

export type GetGoogleEnrichmentDataQuery = {
    actionFeatureGoogleEnrichmentPlaceDetails: {
        name?: string | null;
        type?: string | null;
        formattedAddress?: string | null;
        id: string;
        internationalPhoneNumber?: string | null;
        website?: string | null;
        email?: string | null;
        addressDetail?: {
            city?: string | null;
            country?: string | null;
            countryCode?: string | null;
            postalCode?: string | null;
            street?: string | null;
            streetNumber?: string | null;
        } | null;
    };
};

export type CreateContactsFromExtractedContactsMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    extractedContacts:
        | Array<ReformattedExtractedContactInputQl>
        | ReformattedExtractedContactInputQl;
    extractedCompanies:
        | Array<ReformattedExtractedCompanyInputQl>
        | ReformattedExtractedCompanyInputQl;
}>;

export type CreateContactsFromExtractedContactsMutation = {
    createContactsFromExtractedContacts: {
        contacts: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }>;
        relevantCompanies: Array<{
            id: string;
            name: string;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            contacts?: Array<{
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                role?: string | null;
                notes?: string | null;
                extracted?: boolean | null;
                isKey?: boolean | null;
                createdDate: any;
                customFields?: Array<{
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    value?: string | null;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                    enrichmentDetail?: { id: string; source: string } | null;
                }> | null;
                relevantCompany?: { id: string; name: string } | null;
                parentDeals?: Array<{ id: string }> | null;
            }> | null;
            parentDeals?: Array<{ id: string }> | null;
            placeDetails?: Array<{
                name?: string | null;
                type?: string | null;
                formattedAddress?: string | null;
                id: string;
                internationalPhoneNumber?: string | null;
                website?: string | null;
                email?: string | null;
                addressDetail?: {
                    city?: string | null;
                    country?: string | null;
                    countryCode?: string | null;
                    postalCode?: string | null;
                    street?: string | null;
                    streetNumber?: string | null;
                } | null;
            }> | null;
        }>;
    };
};

export type CreateReminderMutationVariables = Exact<{
    data: ReminderCreateInputQl;
    launchSource?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateReminderMutation = {
    createReminder: {
        id: string;
        assignee: number;
        description: string;
        dueDate: any;
        createdDate: any;
        updatedDate: any;
        completedDate?: any | null;
        creator: number;
        target: { id: string; type: ReminderTargetType; title: string };
    };
};

export type UpdateReminderMutationVariables = Exact<{
    reminderId: Scalars['String']['input'];
    update: ReminderUpdateInputQl;
}>;

export type UpdateReminderMutation = {
    updateReminder: {
        id: string;
        assignee: number;
        description: string;
        dueDate: any;
        createdDate: any;
        updatedDate: any;
        completedDate?: any | null;
        creator: number;
        target: { id: string; type: ReminderTargetType; title: string };
    };
};

export type GetMyRemindersAndMentionsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetMyRemindersAndMentionsQuery = {
    me: {
        mentions?: Array<{
            commentId: string;
            creatorBid: number;
            updatedDate: any;
            isRead: boolean;
            deal: { id: string; title: string };
        }> | null;
        reminders: Array<{
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: { id: string; type: ReminderTargetType; title: string };
        }>;
    };
};

export type DismissReminderMutationVariables = Exact<{
    reminderId: Scalars['String']['input'];
}>;

export type DismissReminderMutation = {
    completeReminder: {
        id: string;
        assignee: number;
        description: string;
        dueDate: any;
        createdDate: any;
        updatedDate: any;
        completedDate?: any | null;
        creator: number;
        target: { id: string; type: ReminderTargetType; title: string };
    };
};

export type MarkMentionAsReadMutationVariables = Exact<{
    mentionId: Scalars['String']['input'];
}>;

export type MarkMentionAsReadMutation = { readCommentMention: { ok: boolean } };

export type DismissMentionMutationVariables = Exact<{
    mentionId: Scalars['String']['input'];
}>;

export type DismissMentionMutation = { dismissCommentMention: { ok: boolean } };

export type GetCompanyTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyTypesQuery = {
    company: { accountType: AccountType; licenseType: LicenseType };
};

export type GetDealAlertQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealAlertQuery = {
    deal: { alert: { type: DealAlertType; currentUserRelated: boolean } };
};

export type GetDynamicFilterMetricsQueryVariables = Exact<{
    pipelineId: Scalars['String']['input'];
    filters: Array<FilterOpQl> | FilterOpQl;
}>;

export type GetDynamicFilterMetricsQuery = {
    pipeline: {
        statistics: {
            dynamicFiltersMetrics: Array<{
                count: number;
                dynamicFilter: DynamicFilter;
            }>;
        };
    };
};

export type GetDealsCollectionByPipelineQueryVariables = Exact<{
    queryParams: QueryParamsQl;
}>;

export type GetDealsCollectionByPipelineQuery = {
    dealsCollection: {
        totalCount: number;
        edges?: Array<{
            node: {
                id: string;
                pausedUntil?: any | null;
                title: string;
                stageUpdatedDate: any;
                assignee: number;
                alert: { type: DealAlertType; currentUserRelated: boolean };
                currentStage: { id: string };
                outreachLogStats: {
                    count: number;
                    lastActivityDate?: any | null;
                };
            };
        }> | null;
    };
};

export type AskQuestionQueryVariables = Exact<{
    question: Scalars['String']['input'];
    projectId?: InputMaybe<Scalars['Int']['input']>;
    documentId?: InputMaybe<Scalars['String']['input']>;
    dealId?: InputMaybe<Scalars['String']['input']>;
}>;

export type AskQuestionQuery = { actionFeatureXray: { answer: string } };

export type AskQuestionRealtimeSubscriptionVariables = Exact<{
    question: Scalars['String']['input'];
    projectId?: InputMaybe<Scalars['Int']['input']>;
    documentId?: InputMaybe<Scalars['String']['input']>;
    dealId?: InputMaybe<Scalars['String']['input']>;
}>;

export type AskQuestionRealtimeSubscription = {
    actionFeatureXray: { answer: string };
};

export type LiveUserNotificationsSubscriptionVariables = Exact<{
    [key: string]: never;
}>;

export type LiveUserNotificationsSubscription = {
    subscribeUserUpdates: {
        userTargetId: number;
        userSourceId: number;
        type: InstantUserMessageType;
        content: { dealId: string; title: string };
    };
};

export type GenerateValuePropEmailQueryVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
    vpoSessionId: Scalars['String']['input'];
}>;

export type GenerateValuePropEmailQuery = {
    actionFeatureValuePropOutreachEmail: { answer: string };
};

export type GenerateValuePropEmailFollowUpQueryVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
}>;

export type GenerateValuePropEmailFollowUpQuery = {
    actionFeatureValuePropOutreachFollowUp: { answer: string };
};

export type GenerateValuePropCallScriptQueryVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
}>;

export type GenerateValuePropCallScriptQuery = {
    actionFeatureValuePropOutreachCallScript: { answer: string };
};

export type GenerateValuePropEmailStreamSubscriptionVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
    vpoSessionId: Scalars['String']['input'];
}>;

export type GenerateValuePropEmailStreamSubscription = {
    actionFeatureValuePropOutreachEmail: { answer: string };
};

export type GenerateValuePropEmailFollowUpStreamSubscriptionVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
}>;

export type GenerateValuePropEmailFollowUpStreamSubscription = {
    actionFeatureValuePropOutreachFollowUp: { answer: string };
};

export type GenerateValuePropCallScriptStreamSubscriptionVariables = Exact<{
    contactId?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['String']['input']>;
    dealId: Scalars['String']['input'];
    regenerationSpec?: InputMaybe<RegenerationSpecQl>;
    outreachBlockIds:
        | Array<Scalars['String']['input']>
        | Scalars['String']['input'];
}>;

export type GenerateValuePropCallScriptStreamSubscription = {
    actionFeatureValuePropOutreachCallScript: { answer: string };
};

export type GetDomainEnrichmentResultQueryVariables = Exact<{
    companyName: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
    relevantCompanyId: Scalars['String']['input'];
    contactId: Scalars['String']['input'];
}>;

export type GetDomainEnrichmentResultQuery = {
    actionFeatureContactEnrichmentCompanyDomain: Array<{
        domain: string;
        name: string;
        providers: Array<EnrichmentDataProviderSource>;
    }>;
};

export type GetContactEnrichmentResultQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
    contactName: Scalars['String']['input'];
    company: Scalars['String']['input'];
    domain?: InputMaybe<Scalars['String']['input']>;
    linkedinUrl?: InputMaybe<Scalars['String']['input']>;
    contactId?: InputMaybe<Scalars['String']['input']>;
    triggeredFromSearchRefinement?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetContactEnrichmentResultQuery = {
    actionFeatureContactEnrichment: {
        emails: Array<{
            value: string;
            providers: Array<EnrichmentDataProviderSource>;
        }>;
        phones: Array<{
            value: string;
            providers: Array<EnrichmentDataProviderSource>;
        }>;
        linkedins: Array<{
            value: string;
            providers: Array<EnrichmentDataProviderSource>;
        }>;
        positions: Array<{
            value: string;
            providers: Array<EnrichmentDataProviderSource>;
        }>;
    };
};

export type SetAchievementsOptInMutationVariables = Exact<{
    optIn: Scalars['Boolean']['input'];
}>;

export type SetAchievementsOptInMutation = {
    updateUserAchievementsEnrolmentOptIn: { ok: boolean };
};

export type GetUserAchievementsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserAchievementsQuery = {
    userAchievements: {
        achievements: {
            resolveANextStep: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            planANextStep: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            addContactsEmailOrPhone: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            askArticleWizard: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            assignContactDealRole: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            bookedFirstMeeting: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            outreachByEmail: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            disqualifyADeal: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            createYourFirstContact: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            createYourFirstDeal: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            outreachByPhone: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            pauseADeal: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
        };
    };
};

export type UpdateAchievementStateMutationVariables = Exact<{
    name: UserAchievementName;
    state: UserAchievementState;
}>;

export type UpdateAchievementStateMutation = {
    updateUserAchievementState: { ok: boolean };
};

export type GetValueMilestonesQueryVariables = Exact<{ [key: string]: never }>;

export type GetValueMilestonesQuery = {
    allValueMilestones: Array<{
        id: string;
        type: ValueMilestoneType;
        pipeline: string;
    }>;
};

export type GetTeamsAchievementsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetTeamsAchievementsQuery = {
    colleaguesAchievements: Array<{
        defaultPipelineId: string;
        userId: number;
        optInAchievements: boolean;
        achievements: {
            resolveANextStep: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            planANextStep: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            addContactsEmailOrPhone: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            askArticleWizard: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            assignContactDealRole: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            bookedFirstMeeting: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            outreachByEmail: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            disqualifyADeal: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            createYourFirstContact: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            createYourFirstDeal: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            outreachByPhone: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
            pauseADeal: {
                achievementState: UserAchievementState;
                achievementStateUpdateDate?: any | null;
            };
        };
    }>;
};

export type GetPipelineStatisticsQueryVariables = Exact<{
    forCurrentUser: Scalars['Boolean']['input'];
    pipelineId: Scalars['String']['input'];
}>;

export type GetPipelineStatisticsQuery = {
    pipelineStatistics?: {
        countContacts: number;
        countDeals: number;
        countDealsWon: number;
        countLeadsQualified: number;
        countMeetingsBooked: number;
    } | null;
};

export type GetCustomFieldsForDealsQueryVariables = Exact<{
    dealIds: Array<Scalars['JSON']['input']> | Scalars['JSON']['input'];
}>;

export type GetCustomFieldsForDealsQuery = {
    dealsCollection: {
        edges?: Array<{
            node: {
                id: string;
                customFields?: Array<{
                    isTag: boolean;
                    label: string;
                    name: string;
                    value?: string | null;
                }> | null;
            };
        }> | null;
    };
};

export type GetRemindersForDealsQueryVariables = Exact<{
    dealIds: Array<Scalars['JSON']['input']> | Scalars['JSON']['input'];
}>;

export type GetRemindersForDealsQuery = {
    dealsCollection: {
        edges?: Array<{
            node: {
                id: string;
                reminders: Array<{
                    id: string;
                    assignee: number;
                    description: string;
                    dueDate: any;
                    createdDate: any;
                    updatedDate: any;
                    completedDate?: any | null;
                    creator: number;
                    target: {
                        id: string;
                        type: ReminderTargetType;
                        title: string;
                    };
                }>;
            };
        }> | null;
    };
};

export type GetCommentsForDealsQueryVariables = Exact<{
    dealIds: Array<Scalars['JSON']['input']> | Scalars['JSON']['input'];
}>;

export type GetCommentsForDealsQuery = {
    dealsCollection: {
        edges?: Array<{
            node: {
                id: string;
                comments?: Array<{
                    id: string;
                    creatorBid: number;
                    content: string;
                    createdDate: any;
                    updatedDate: any;
                    parentId: string;
                }> | null;
            };
        }> | null;
    };
};

export type DeleteOutreachBlockMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    outreachBlockId: Scalars['String']['input'];
    vpoSessionId: Scalars['String']['input'];
}>;

export type DeleteOutreachBlockMutation = {
    deleteOutreachBlock: { ok: boolean };
};

export type CreateOutreachBlockMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    outreachBlock: OutreachBlockCreateMutationQl;
    vpoSessionId: Scalars['String']['input'];
}>;

export type CreateOutreachBlockMutation = {
    createOutreachBlock: { id: string };
};

export type UpdateOutreachBlockMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    outreachBlock: OutreachBlockUpdateMutationQl;
    vpoSessionId: Scalars['String']['input'];
}>;

export type UpdateOutreachBlockMutation = {
    updateOutreachBlock: { ok: boolean };
};

export type TranslateQueryVariables = Exact<{
    text: Scalars['String']['input'];
    language: Scalars['String']['input'];
}>;

export type TranslateQuery = { translate: string };

export type CreateDealActivityMutationVariables = Exact<{
    activityType: ActivityLogType;
    subType?: InputMaybe<ActivityLogSubType>;
    dealId?: InputMaybe<Scalars['String']['input']>;
    contactId?: InputMaybe<Scalars['String']['input']>;
    relevantCompanyId?: InputMaybe<Scalars['String']['input']>;
    notes?: Scalars['String']['input'];
    customType?: Scalars['String']['input'];
}>;

export type CreateDealActivityMutation = {
    createActivityLog: {
        id: string;
        companyBid: number;
        createdDate: any;
        updatedDate: any;
        userId: number;
        type: ActivityLogType;
        notes: string;
        dealId?: string | null;
        contactId?: string | null;
        relevantCompanyId?: string | null;
        subType?: ActivityLogSubType | null;
        customType: string;
    };
};

export type UpdateDealActivityMutationVariables = Exact<{
    activityLogId: Scalars['String']['input'];
    contactId?: InputMaybe<Scalars['String']['input']>;
    notes?: InputMaybe<Scalars['String']['input']>;
    customType?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateDealActivityMutation = {
    updateActivityLog: {
        id: string;
        companyBid: number;
        createdDate: any;
        updatedDate: any;
        userId: number;
        type: ActivityLogType;
        notes: string;
        dealId?: string | null;
        contactId?: string | null;
        relevantCompanyId?: string | null;
        subType?: ActivityLogSubType | null;
        customType: string;
    };
};

export type DeleteDealActivityMutationVariables = Exact<{
    activityLogId: Scalars['String']['input'];
}>;

export type DeleteDealActivityMutation = { deleteActivityLog: { ok: boolean } };

export type OmniSearchQueryVariables = Exact<{
    searchTerm: Scalars['String']['input'];
    limit: Scalars['Int']['input'];
}>;

export type OmniSearchQuery = {
    omniSearch: {
        comments: Array<{
            id: string;
            creatorBid: number;
            content: string;
            createdDate: any;
            updatedDate: any;
            parentId: string;
        }>;
        companies: Array<{
            id: string;
            name: string;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            contacts?: Array<{
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                role?: string | null;
                notes?: string | null;
                extracted?: boolean | null;
                isKey?: boolean | null;
                createdDate: any;
                customFields?: Array<{
                    acceptedValues?: Array<string> | null;
                    defaultValue?: string | null;
                    label: string;
                    name: string;
                    type: CustomFieldType;
                    value?: string | null;
                    order: number;
                    isTag: boolean;
                    isVisibleInDealPage: boolean;
                    enrichmentDetail?: { id: string; source: string } | null;
                }> | null;
                relevantCompany?: { id: string; name: string } | null;
                parentDeals?: Array<{ id: string }> | null;
            }> | null;
            parentDeals?: Array<{ id: string }> | null;
            placeDetails?: Array<{
                name?: string | null;
                type?: string | null;
                formattedAddress?: string | null;
                id: string;
                internationalPhoneNumber?: string | null;
                website?: string | null;
                email?: string | null;
                addressDetail?: {
                    city?: string | null;
                    country?: string | null;
                    countryCode?: string | null;
                    postalCode?: string | null;
                    street?: string | null;
                    streetNumber?: string | null;
                } | null;
            }> | null;
        }>;
        contacts: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }>;
        deals: Array<{
            id: string;
            title: string;
            assignee: number;
            state: DealState;
            notes: string;
            stageUpdatedDate: any;
            createdDate: any;
            completedDate?: any | null;
            pausedUntil?: any | null;
            summary?: string | null;
            value: { currency: string; value: any };
            project?: { projectId: string } | null;
            pipeline: { id: string; name: string };
            currentObjectives: Array<{
                criteria: string;
                id: string;
                state: Tristate;
                status: string;
                type: ObjectiveType;
                order?: number | null;
                linkedField?: { name: string; type: LinkedFieldType } | null;
            }>;
            currentStage: { id: string };
            exportedDate?: { email?: any | null; csv?: any | null } | null;
            reminders: Array<{
                id: string;
                assignee: number;
                description: string;
                dueDate: any;
                createdDate: any;
                updatedDate: any;
                completedDate?: any | null;
                creator: number;
                target: { id: string; type: ReminderTargetType; title: string };
            }>;
            alert: { type: DealAlertType; currentUserRelated: boolean };
            activityLog: Array<{
                id: string;
                companyBid: number;
                createdDate: any;
                updatedDate: any;
                userId: number;
                type: ActivityLogType;
                notes: string;
                dealId?: string | null;
                contactId?: string | null;
                relevantCompanyId?: string | null;
                subType?: ActivityLogSubType | null;
                customType: string;
            }>;
        }>;
        nextSteps: Array<{
            id: string;
            assignee: number;
            description: string;
            dueDate: any;
            createdDate: any;
            updatedDate: any;
            completedDate?: any | null;
            creator: number;
            target: { id: string; type: ReminderTargetType; title: string };
        }>;
        activityLogs: Array<{
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type GetContactExtractionResultQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetContactExtractionResultQuery = {
    getContactExtractionResult: {
        projectContextResult?: {
            status: ContactExtractionStatus;
            extractionType: ExtractionType;
            extractedContacts: Array<{
                firstName: string;
                lastName: string;
                companyName: string;
                legitimateInterestText: string;
                role: string;
                sourceUrls: Array<string>;
            }>;
            extractedCompanies: Array<{
                legitimateInterestText: string;
                name: string;
                role: string;
                sourceUrls: Array<string>;
            }>;
        } | null;
        webSearchResult?: {
            status: ContactExtractionStatus;
            extractionType: ExtractionType;
            extractedContacts: Array<{
                firstName: string;
                lastName: string;
                companyName: string;
                legitimateInterestText: string;
                role: string;
                sourceUrls: Array<string>;
            }>;
            extractedCompanies: Array<{
                legitimateInterestText: string;
                name: string;
                role: string;
                sourceUrls: Array<string>;
            }>;
        } | null;
    };
};

export type RunContactExtractionForDealMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    language: UserLanguage;
}>;

export type RunContactExtractionForDealMutation = {
    runContactExtractionForDeal: { ok: boolean };
};

export type GetActivityLogByDealQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetActivityLogByDealQuery = {
    deal: {
        activityLog: Array<{
            id: string;
            companyBid: number;
            createdDate: any;
            updatedDate: any;
            userId: number;
            type: ActivityLogType;
            notes: string;
            dealId?: string | null;
            contactId?: string | null;
            relevantCompanyId?: string | null;
            subType?: ActivityLogSubType | null;
            customType: string;
        }>;
    };
};

export type RunAutoEnrichmentForCompanyMutationVariables = Exact<{
    companyId: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
}>;

export type RunAutoEnrichmentForCompanyMutation = {
    autoEnrichCompany: {
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        contacts?: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }> | null;
        parentDeals?: Array<{ id: string }> | null;
        placeDetails?: Array<{
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type RemoveEnrichmentItemFromCompanyMutationVariables = Exact<{
    companyId: Scalars['String']['input'];
    placeId: Scalars['String']['input'];
    field?: InputMaybe<Scalars['String']['input']>;
}>;

export type RemoveEnrichmentItemFromCompanyMutation = {
    removePlaceFromCompany: {
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        contacts?: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }> | null;
        parentDeals?: Array<{ id: string }> | null;
        placeDetails?: Array<{
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type UpdateCompanyWithEnrichmentMutationVariables = Exact<{
    companyId: Scalars['String']['input'];
    dealId: Scalars['String']['input'];
    placeId: Scalars['String']['input'];
    fields?: InputMaybe<
        Array<Scalars['String']['input']> | Scalars['String']['input']
    >;
}>;

export type UpdateCompanyWithEnrichmentMutation = {
    overrideCompanyInfoWithPlace: {
        id: string;
        name: string;
        role?: string | null;
        notes?: string | null;
        extracted?: boolean | null;
        isKey?: boolean | null;
        createdDate: any;
        customFields?: Array<{
            acceptedValues?: Array<string> | null;
            defaultValue?: string | null;
            label: string;
            name: string;
            type: CustomFieldType;
            value?: string | null;
            order: number;
            isTag: boolean;
            isVisibleInDealPage: boolean;
            enrichmentDetail?: { id: string; source: string } | null;
        }> | null;
        contacts?: Array<{
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            role?: string | null;
            notes?: string | null;
            extracted?: boolean | null;
            isKey?: boolean | null;
            createdDate: any;
            customFields?: Array<{
                acceptedValues?: Array<string> | null;
                defaultValue?: string | null;
                label: string;
                name: string;
                type: CustomFieldType;
                value?: string | null;
                order: number;
                isTag: boolean;
                isVisibleInDealPage: boolean;
                enrichmentDetail?: { id: string; source: string } | null;
            }> | null;
            relevantCompany?: { id: string; name: string } | null;
            parentDeals?: Array<{ id: string }> | null;
        }> | null;
        parentDeals?: Array<{ id: string }> | null;
        placeDetails?: Array<{
            name?: string | null;
            type?: string | null;
            formattedAddress?: string | null;
            id: string;
            internationalPhoneNumber?: string | null;
            website?: string | null;
            email?: string | null;
            addressDetail?: {
                city?: string | null;
                country?: string | null;
                countryCode?: string | null;
                postalCode?: string | null;
                street?: string | null;
                streetNumber?: string | null;
            } | null;
        }> | null;
    };
};

export type FindContactsForCompanyQueryVariables = Exact<{
    companyName: Scalars['String']['input'];
    keyword?: InputMaybe<Scalars['String']['input']>;
    page: Scalars['Int']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
    location?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindContactsForCompanyQuery = {
    getCompanyLinkedinProfiles: {
        profiles: Array<{
            title: string;
            location: string;
            linkedinUsername: string;
            headline: string;
            hasPhone: boolean;
            hasEmail: boolean;
            fullName: string;
            companyCountry: string;
            companyName: string;
        }>;
        metaData: { page: number; pageSize: number; total: number };
    };
};

export type GetLinkedinProfileContactInfoQueryVariables = Exact<{
    linkedinUserName: Scalars['String']['input'];
}>;

export type GetLinkedinProfileContactInfoQuery = {
    getLinkedinProfileContactInformation: {
        personalEmail: Array<string>;
        phone: Array<string>;
        workEmail: Array<string>;
    };
};

export type GetDealContentMetadataQueryVariables = Exact<{
    dealId: Scalars['String']['input'];
}>;

export type GetDealContentMetadataQuery = {
    deal: {
        contents: Array<{
            id: string;
            type: DealContentType;
            source?: string | null;
            name?: string | null;
            publishedDate?: any | null;
            hasContent: boolean;
            creationSource: DealContentCreationSource;
        }>;
    };
};

export type GetDealContentAttachmentDownloadUrlQueryVariables = Exact<{
    contentId: Scalars['String']['input'];
    inline: Scalars['Boolean']['input'];
}>;

export type GetDealContentAttachmentDownloadUrlQuery = {
    getDealAttachmentDownloadUrl: { value: string };
};

export type DeleteDealContentMutationVariables = Exact<{
    dealId: Scalars['String']['input'];
    contentId: Scalars['String']['input'];
}>;

export type DeleteDealContentMutation = { removeDealContent: { ok: boolean } };

export const ProjectPartFragmentDoc = gql`
    fragment ProjectPart on ProjectQL {
        projectId
    }
`;
export const LinkedFieldPartFragmentDoc = gql`
    fragment LinkedFieldPart on LinkedFieldQL {
        name
        type
    }
`;
export const ObjectivePartFragmentDoc = gql`
    fragment ObjectivePart on ObjectiveQL {
        criteria
        id
        state
        status
        linkedField {
            ...LinkedFieldPart
        }
        type
        order
    }
    ${LinkedFieldPartFragmentDoc}
`;
export const ReminderPartFragmentDoc = gql`
    fragment ReminderPart on ReminderQL {
        id
        assignee
        description
        dueDate
        createdDate
        updatedDate
        completedDate
        creator
        target {
            id
            type
            title
        }
    }
`;
export const DealAlertPartFragmentDoc = gql`
    fragment DealAlertPart on DealAlertQL {
        type
        currentUserRelated
    }
`;
export const ActivityLogPartFragmentDoc = gql`
    fragment ActivityLogPart on ActivityLogQL {
        id
        companyBid
        createdDate
        updatedDate
        userId
        type
        notes
        dealId
        contactId
        relevantCompanyId
        subType
        customType
    }
`;
export const DealPartFragmentDoc = gql`
    fragment DealPart on DealQL {
        id
        title
        assignee
        value {
            currency
            value
        }
        state
        project {
            ...ProjectPart
        }
        pipeline {
            id
            name
        }
        notes
        currentObjectives {
            ...ObjectivePart
        }
        currentStage {
            id
        }
        stageUpdatedDate
        createdDate
        completedDate
        exportedDate {
            email
            csv
        }
        reminders {
            ...ReminderPart
        }
        pausedUntil
        alert {
            ...DealAlertPart
        }
        activityLog {
            ...ActivityLogPart
        }
        summary
    }
    ${ProjectPartFragmentDoc}
    ${ObjectivePartFragmentDoc}
    ${ReminderPartFragmentDoc}
    ${DealAlertPartFragmentDoc}
    ${ActivityLogPartFragmentDoc}
`;
export const DealAnnualSalesPeriodFragmentDoc = gql`
    fragment DealAnnualSalesPeriod on AnnualSalesPeriodQL {
        year
        frequency
    }
`;
export const DealQuarterlySalesPeriodFragmentDoc = gql`
    fragment DealQuarterlySalesPeriod on QuarterlySalesPeriodQL {
        year
        quarter
        frequency
    }
`;
export const PipelineStatisticsDealPartFragmentDoc = gql`
    fragment PipelineStatisticsDealPart on DealQL {
        id
        title
        assignee
        value {
            currency
            value
        }
        currentStage {
            id
        }
        exportedDate {
            email
            csv
        }
        createdDate
        completedDate
        state
        pausedUntil
        expectedCurrentStageConversionDate
        expectedSalesPeriod {
            ...DealAnnualSalesPeriod
            ...DealQuarterlySalesPeriod
        }
        alert {
            ...DealAlertPart
        }
        project {
            ...ProjectPart
        }
    }
    ${DealAnnualSalesPeriodFragmentDoc}
    ${DealQuarterlySalesPeriodFragmentDoc}
    ${DealAlertPartFragmentDoc}
    ${ProjectPartFragmentDoc}
`;
export const MinimalDealPartFragmentDoc = gql`
    fragment MinimalDealPart on DealQL {
        ...PipelineStatisticsDealPart
        pipeline {
            name
            id
        }
    }
    ${PipelineStatisticsDealPartFragmentDoc}
`;
export const PipelineDealPartFragmentDoc = gql`
    fragment PipelineDealPart on DealQL {
        id
        alert {
            ...DealAlertPart
        }
        pausedUntil
        title
        currentStage {
            id
        }
        stageUpdatedDate
        assignee
        outreachLogStats {
            count
            lastActivityDate
        }
    }
    ${DealAlertPartFragmentDoc}
`;
export const CustomFieldFragmentDoc = gql`
    fragment CustomField on CustomFieldQL {
        enrichmentDetail {
            id
            source
        }
        acceptedValues
        defaultValue
        label
        name
        type
        value
        order
        isTag
        isVisibleInDealPage
    }
`;
export const DealContactPartFragmentDoc = gql`
    fragment DealContactPart on ContactQL {
        id
        firstName
        lastName
        role
        notes
        customFields {
            ...CustomField
        }
        extracted
        isKey
        relevantCompany {
            id
            name
        }
        createdDate
        parentDeals {
            id
        }
    }
    ${CustomFieldFragmentDoc}
`;
export const GoogleEnrichmentPartFragmentDoc = gql`
    fragment GoogleEnrichmentPart on PlaceDetailsQL {
        name
        type
        formattedAddress
        id
        internationalPhoneNumber
        website
        email
        addressDetail {
            city
            country
            countryCode
            postalCode
            street
            streetNumber
        }
    }
`;
export const DealCompanyPartFragmentDoc = gql`
    fragment DealCompanyPart on RelevantCompanyQL {
        id
        name
        role
        notes
        customFields {
            ...CustomField
        }
        contacts {
            ...DealContactPart
        }
        extracted
        isKey
        createdDate
        parentDeals {
            id
        }
        placeDetails {
            ...GoogleEnrichmentPart
        }
    }
    ${CustomFieldFragmentDoc}
    ${DealContactPartFragmentDoc}
    ${GoogleEnrichmentPartFragmentDoc}
`;
export const ValueCurrencyPartFragmentDoc = gql`
    fragment ValueCurrencyPart on ValueCurrencyQL {
        currency
        value
    }
`;
export const PipelineStagesDataConfigFragmentDoc = gql`
    fragment PipelineStagesDataConfig on PipelineStageConfigQL {
        id
        name
        order
        targetConversionRate
        defaultObjectives {
            criteria
            linkedField {
                name
                type
            }
        }
    }
`;
export const PipelineStagesConfigFragmentDoc = gql`
    fragment PipelineStagesConfig on PipelineStagesConfigQL {
        initialStage
        lastStage
        stages {
            ...PipelineStagesDataConfig
        }
    }
    ${PipelineStagesDataConfigFragmentDoc}
`;
export const PipelineConfigPartFragmentDoc = gql`
    fragment PipelineConfigPart on PipelineConfigQL {
        averageDealValue {
            ...ValueCurrencyPart
        }
        revenueGoal {
            ...ValueCurrencyPart
        }
        timePeriodStart
        stages {
            ...PipelineStagesConfig
        }
        goalsEnabled
    }
    ${ValueCurrencyPartFragmentDoc}
    ${PipelineStagesConfigFragmentDoc}
`;
export const PipelineStageStatisticsPartFragmentDoc = gql`
    fragment PipelineStageStatisticsPart on PipelineStageStatsQL {
        atRiskDeals {
            numberOfDeals
            totalDealValue
        }
        healthyDeals {
            numberOfDeals
            totalDealValue
        }
        inStageDeals {
            numberOfDeals
            totalDealValue
        }
        markedDoneDeals {
            numberOfDeals
            totalDealValue
        }
        disqualifiedDeals {
            numberOfDeals
            totalDealValue
        }
        progressedDeals {
            numberOfDeals
            totalDealValue
        }
        id
        observedConversionRate
        goal
        targetConversionRate
    }
`;
export const PipelineStatisticsPartFragmentDoc = gql`
    fragment PipelineStatisticsPart on PipelineStatsQL {
        stages {
            ...PipelineStageStatisticsPart
        }
    }
    ${PipelineStageStatisticsPartFragmentDoc}
`;
export const PipelinePartFragmentDoc = gql`
    fragment PipelinePart on PipelineQL {
        id
        name
        config {
            ...PipelineConfigPart
        }
        statistics {
            ...PipelineStatisticsPart
        }
    }
    ${PipelineConfigPartFragmentDoc}
    ${PipelineStatisticsPartFragmentDoc}
`;
export const PipelineUpdateNamePartFragmentDoc = gql`
    fragment PipelineUpdateNamePart on PipelineQL {
        id
        name
    }
`;
export const IntegrationPartFragmentDoc = gql`
    fragment IntegrationPart on IntegrationQL {
        id
        name
        integrationType
        provider
        driver
        mapper
        createdDate
        updatedDate
    }
`;
export const CrmStatePartFragmentDoc = gql`
    fragment CrmStatePart on CrmQL {
        state
        remoteUrl
        exportedDate
    }
`;
export const DealCommentFragmentDoc = gql`
    fragment DealComment on CommentQL {
        id
        creatorBid
        content
        createdDate
        updatedDate
        parentId
    }
`;
export const CustomFieldConfigFragmentDoc = gql`
    fragment CustomFieldConfig on CustomFieldConfigQL {
        acceptedValues
        defaultValue
        label
        name
        type
        order
        isTag
        isVisibleInDealPage
    }
`;
export const MentionDealPartFragmentDoc = gql`
    fragment MentionDealPart on DealQL {
        id
        title
    }
`;
export const MentionPartFragmentDoc = gql`
    fragment MentionPart on MentionQL {
        commentId
        creatorBid
        updatedDate
        deal {
            ...MentionDealPart
        }
        isRead
    }
    ${MentionDealPartFragmentDoc}
`;
export const RolesPartFragmentDoc = gql`
    fragment RolesPart on RolesConfig {
        contacts {
            name
        }
        relevantCompanies {
            name
        }
    }
`;
export const PlacePartFragmentDoc = gql`
    fragment PlacePart on PlaceQL {
        id
        name
    }
`;
export const DynamicFilterMetricsPartFragmentDoc = gql`
    fragment DynamicFilterMetricsPart on DynamicFilterMetricsQL {
        count
        dynamicFilter
    }
`;
export const ValueMilestoneFragmentDoc = gql`
    fragment ValueMilestone on ValueMilestoneQL {
        id
        type
        pipeline
    }
`;
export const AchievementDetailsPartFragmentDoc = gql`
    fragment AchievementDetailsPart on UserSingleAchievementDetailsQL {
        achievementState
        achievementStateUpdateDate
    }
`;
export const UserAchievementsFragmentDoc = gql`
    fragment UserAchievements on UserAchievementsDetailsQL {
        resolveANextStep {
            ...AchievementDetailsPart
        }
        planANextStep {
            ...AchievementDetailsPart
        }
        addContactsEmailOrPhone {
            ...AchievementDetailsPart
        }
        askArticleWizard {
            ...AchievementDetailsPart
        }
        assignContactDealRole {
            ...AchievementDetailsPart
        }
        bookedFirstMeeting {
            ...AchievementDetailsPart
        }
        outreachByEmail {
            ...AchievementDetailsPart
        }
        disqualifyADeal {
            ...AchievementDetailsPart
        }
        createYourFirstContact {
            ...AchievementDetailsPart
        }
        createYourFirstDeal {
            ...AchievementDetailsPart
        }
        outreachByPhone {
            ...AchievementDetailsPart
        }
        pauseADeal {
            ...AchievementDetailsPart
        }
    }
    ${AchievementDetailsPartFragmentDoc}
`;
export const ColleagueAchievementsFragmentDoc = gql`
    fragment ColleagueAchievements on ColleagueAchievementsQL {
        defaultPipelineId
        userId
        optInAchievements
        achievements {
            ...UserAchievements
        }
    }
    ${UserAchievementsFragmentDoc}
`;
export const PipelineStatisticsFragmentDoc = gql`
    fragment PipelineStatistics on PipelineStatisticsQL {
        countContacts
        countDeals
        countDealsWon
        countLeadsQualified
        countMeetingsBooked
    }
`;
export const ExtractedContactPartFragmentDoc = gql`
    fragment ExtractedContactPart on ExtractedContactQL {
        firstName
        lastName
        companyName
        legitimateInterestText
        role
        sourceUrls
    }
`;
export const ExtractedCompanyPartFragmentDoc = gql`
    fragment ExtractedCompanyPart on ExtractedCompanyQL {
        legitimateInterestText
        name
        role
        sourceUrls
    }
`;
export const ContactExtractionPartFragmentDoc = gql`
    fragment ContactExtractionPart on ContactExtractionResultQL {
        status
        extractionType
        extractedContacts {
            ...ExtractedContactPart
        }
        extractedCompanies {
            ...ExtractedCompanyPart
        }
    }
    ${ExtractedContactPartFragmentDoc}
    ${ExtractedCompanyPartFragmentDoc}
`;
export const ContactExtractionResultFragmentDoc = gql`
    fragment ContactExtractionResult on CombinedContactExtractionResultQL {
        projectContextResult {
            ...ContactExtractionPart
        }
        webSearchResult {
            ...ContactExtractionPart
        }
    }
    ${ContactExtractionPartFragmentDoc}
`;
export const LinkedinProfileFragmentDoc = gql`
    fragment LinkedinProfile on LinkedInProfileQL {
        title
        location
        linkedinUsername
        headline
        hasPhone
        hasEmail
        fullName
        companyCountry
        companyName
    }
`;
export const LinkedinSearchMetaFragmentDoc = gql`
    fragment LinkedinSearchMeta on SearchMetaDataQL {
        page
        pageSize
        total
    }
`;
export const LinkedinProfileContactInfoFragmentDoc = gql`
    fragment LinkedinProfileContactInfo on LinkedInProfileContactInformationQL {
        personalEmail
        phone
        workEmail
    }
`;
export const DealContentMetadataFragmentDoc = gql`
    fragment DealContentMetadata on DealContentMetadataQL {
        id
        type
        source
        name
        publishedDate
        hasContent
        creationSource
    }
`;
export const DealFileAttachmentDownloadUrlFragmentDoc = gql`
    fragment DealFileAttachmentDownloadUrl on StringQL {
        value
    }
`;
export const InstantMessageFragmentDoc = gql`
    fragment InstantMessage on InstantMessageQL {
        userTargetId
        userSourceId
        type
        content {
            ... on InstantMessageUserDealImportCompletedQL {
                dealId
                title
            }
        }
    }
`;
export const MoveDealToStageDocument = gql`
    mutation MoveDealToStage($dealId: String!, $stageId: String!) {
        moveToStage(dealId: $dealId, stageId: $stageId) {
            ok
        }
    }
`;
export const AssignUserToDealDocument = gql`
    mutation AssignUserToDeal($dealId: String!, $update: DealUpdateInputQL!) {
        updateDeal(dealId: $dealId, update: $update) {
            ok
        }
    }
`;
export const DisqualifyDealDocument = gql`
    mutation DisqualifyDeal($dealId: String!, $reason: String = null) {
        disqualifyDeal(dealId: $dealId, reason: $reason) {
            ...DealPart
        }
    }
    ${DealPartFragmentDoc}
`;
export const RestoreDealDocument = gql`
    mutation RestoreDeal($dealId: String!) {
        restoreDeal(dealId: $dealId) {
            ...DealPart
        }
    }
    ${DealPartFragmentDoc}
`;
export const PauseDealDocument = gql`
    mutation PauseDeal($dealId: String!, $pausedUntil: DateTime!) {
        pauseDeal(dealId: $dealId, pausedUntil: $pausedUntil) {
            ok
        }
    }
`;
export const UnPauseDealDocument = gql`
    mutation UnPauseDeal($dealId: String!) {
        unpauseDeal(dealId: $dealId) {
            ok
        }
    }
`;
export const MarkDealAsDoneDocument = gql`
    mutation MarkDealAsDone($dealId: String!) {
        markDealAsDone(dealId: $dealId) {
            ...DealPart
        }
    }
    ${DealPartFragmentDoc}
`;
export const CreateNewDealMutationDocument = gql`
    mutation CreateNewDealMutation(
        $pipelineId: String!
        $projectId: String = null
        $title: String!
        $creationSource: DealCreationSource!
        $searchNames: [String!]
        $currentSearchName: String = null
        $projectDataFromCustomText: ProjectDataFromCustomTextQL = null
        $projectDataFromSearchResult: ProjectDataFromSearchResultQL = null
    ) {
        createDeal(
            pipelineId: $pipelineId
            projectId: $projectId
            title: $title
            creationSource: $creationSource
            searchNames: $searchNames
            currentSearchName: $currentSearchName
            projectDataFromCustomText: $projectDataFromCustomText
            projectDataFromSearchResult: $projectDataFromSearchResult
        ) {
            dealId
            ok
        }
    }
`;
export const UpdateDealInformationDocument = gql`
    mutation UpdateDealInformation(
        $dealId: String!
        $updateDeal: DealUpdateInputQL!
    ) {
        updateDeal(dealId: $dealId, update: $updateDeal) {
            ok
        }
    }
`;
export const UpdateObjectiveMutationDocument = gql`
    mutation UpdateObjectiveMutation(
        $objectiveId: String!
        $updateObjective: ObjectiveUpdateInputQL!
    ) {
        updateObjective(objectiveId: $objectiveId, update: $updateObjective) {
            ok
        }
    }
`;
export const GetAllDealsDocument = gql`
    query GetAllDeals($queryParams: QueryParamsQL!) {
        allDeals(queryParams: $queryParams) {
            ...MinimalDealPart
        }
    }
    ${MinimalDealPartFragmentDoc}
`;
export const GetDealDocument = gql`
    query GetDeal($dealId: String!) {
        deal(dealId: $dealId) {
            ...DealPart
        }
    }
    ${DealPartFragmentDoc}
`;
export const ExportCsvDocument = gql`
    query ExportCSV($dealId: String!, $delimiter: String!) {
        deal(dealId: $dealId) {
            export {
                csv(delimiter: $delimiter) {
                    row
                    header
                }
            }
        }
    }
`;
export const ExportBatchCsvDocument = gql`
    query ExportBatchCSV($queryParams: QueryParamsQL!, $delimiter: String!) {
        allDeals(queryParams: $queryParams) {
            id
            export {
                csv(delimiter: $delimiter) {
                    row
                    header
                }
            }
        }
    }
`;
export const GetDealObjectivesDocument = gql`
    query GetDealObjectives($dealId: String!) {
        deal(dealId: $dealId) {
            currentObjectives {
                ...ObjectivePart
            }
        }
    }
    ${ObjectivePartFragmentDoc}
`;
export const GetDealStageUpdateDateDocument = gql`
    query GetDealStageUpdateDate($dealId: String!) {
        deal(dealId: $dealId) {
            stageUpdatedDate
        }
    }
`;
export const GetDealContactsDocument = gql`
    query GetDealContacts($dealId: String!) {
        deal(dealId: $dealId) {
            relevantCompanies {
                ...DealCompanyPart
            }
            contacts {
                ...DealContactPart
            }
        }
    }
    ${DealCompanyPartFragmentDoc}
    ${DealContactPartFragmentDoc}
`;
export const CreateCompanyDocument = gql`
    mutation CreateCompany(
        $dealId: String!
        $name: String!
        $role: String
        $customInput: RelevantCompanyUpdateCustomInputQL
        $notes: String
        $isKey: Boolean
        $createdFrom: String
    ) {
        createRelevantCompany(
            data: {
                name: $name
                role: $role
                customInput: $customInput
                notes: $notes
                isKey: $isKey
            }
            dealId: $dealId
            createdFrom: $createdFrom
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($companyId: String!, $name: String, $role: String) {
        updateRelevantCompany(
            relevantCompanyId: $companyId
            update: { name: $name, role: $role }
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export const UpdateCompanyNoteDocument = gql`
    mutation UpdateCompanyNote($relevantCompanyId: String!, $notes: String!) {
        updateRelevantCompany(
            relevantCompanyId: $relevantCompanyId
            update: { notes: $notes }
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export const UpdateCompanyRoleDocument = gql`
    mutation UpdateCompanyRole(
        $relevantCompanyId: String!
        $role: String!
        $isKey: Boolean!
    ) {
        updateRelevantCompany(
            relevantCompanyId: $relevantCompanyId
            update: { role: $role, isKey: $isKey }
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany(
        $dealId: String!
        $relevantCompanyId: String!
        $deleteAssociatedContacts: Boolean!
    ) {
        deleteRelevantCompany(
            dealId: $dealId
            relevantCompanyId: $relevantCompanyId
            deleteAssociatedContacts: $deleteAssociatedContacts
        ) {
            ok
        }
    }
`;
export const ExportEmailDocument = gql`
    mutation exportEmail(
        $senderName: String!
        $toName: String!
        $toEmail: String!
        $language: UserLanguage!
        $dealId: String!
        $dealOwner: String!
        $dealOwnerEmail: String!
        $dealUrl: String!
    ) {
        exportDealEmail(
            inEmail: {
                senderName: $senderName
                toName: $toName
                toEmail: $toEmail
                language: $language
                dealId: $dealId
                dealOwner: $dealOwner
                dealOwnerEmail: $dealOwnerEmail
                dealUrl: $dealUrl
            }
        ) {
            ok
            email {
                enqueuedDate
                id
                mtaMessageId
                senderBid
                sentDate
                state
                template {
                    templateId
                    params {
                        key
                        value
                    }
                }
                addresses {
                    toReceivers {
                        email
                        name
                    }
                    replyTo {
                        email
                        name
                    }
                    fromSender {
                        email
                        name
                    }
                    ccReceivers {
                        email
                        name
                    }
                    bccReceivers {
                        email
                        name
                    }
                }
            }
        }
    }
`;
export const CreateContactDocument = gql`
    mutation CreateContact(
        $dealId: String!
        $firstName: String
        $lastName: String
        $role: String
        $notes: String
        $relevantCompanyId: String
        $customInput: ContactUpdateCustomInputQL
        $isKey: Boolean
        $createdFrom: String
    ) {
        createContact(
            dealId: $dealId
            data: {
                firstName: $firstName
                lastName: $lastName
                role: $role
                notes: $notes
                relevantCompanyId: $relevantCompanyId
                customInput: $customInput
                isKey: $isKey
            }
            createdFrom: $createdFrom
        ) {
            ...DealContactPart
        }
    }
    ${DealContactPartFragmentDoc}
`;
export const UpdateContactDocument = gql`
    mutation UpdateContact(
        $contactId: String!
        $firstName: String
        $lastName: String
        $role: String
    ) {
        updateContact(
            update: { firstName: $firstName, lastName: $lastName, role: $role }
            contactId: $contactId
        ) {
            ...DealContactPart
        }
    }
    ${DealContactPartFragmentDoc}
`;
export const UpdateContactNoteDocument = gql`
    mutation UpdateContactNote($contactId: String!, $notes: String!) {
        updateContact(contactId: $contactId, update: { notes: $notes }) {
            ...DealContactPart
        }
    }
    ${DealContactPartFragmentDoc}
`;
export const UpdateContactRoleDocument = gql`
    mutation UpdateContactRole(
        $contactId: String!
        $role: String!
        $isKey: Boolean!
    ) {
        updateContact(
            contactId: $contactId
            update: { role: $role, isKey: $isKey }
        ) {
            ...DealContactPart
        }
    }
    ${DealContactPartFragmentDoc}
`;
export const DeleteContactDocument = gql`
    mutation DeleteContact($dealId: String!, $contactId: String!) {
        deleteContact(dealId: $dealId, contactId: $contactId) {
            ok
        }
    }
`;
export const AllPipelinesQueryDocument = gql`
    query AllPipelinesQuery($queryParams: QueryParamsQL) {
        allPipelines(queryParams: $queryParams) {
            ...PipelinePart
        }
    }
    ${PipelinePartFragmentDoc}
`;
export const BulkMoveDocument = gql`
    mutation BulkMove(
        $dealIds: [String!]!
        $targetPipelineId: String!
        $targetStageId: String!
        $targetDealState: DealState!
        $unpauseDeals: Boolean
    ) {
        bulkMove(
            dealIds: $dealIds
            targetDealState: $targetDealState
            targetPipelineId: $targetPipelineId
            targetStageId: $targetStageId
            unpauseDeals: $unpauseDeals
        ) {
            ok
            updatedDealIds
        }
    }
`;
export const BulkUpdateDealCustomParametersDocument = gql`
    mutation BulkUpdateDealCustomParameters(
        $dealIds: [String!]!
        $name: String!
        $value: String!
    ) {
        bulkUpdateDealCustomValues(
            dealIds: $dealIds
            name: $name
            value: $value
        ) {
            updatedDealIds
        }
    }
`;
export const BulkUpdateDealAttributesDocument = gql`
    mutation BulkUpdateDealAttributes(
        $attribute: DealAttribute!
        $dealIds: [String!]!
        $value: JSON
    ) {
        bulkUpdateDealAttributes(
            attribute: $attribute
            dealIds: $dealIds
            value: $value
        ) {
            updatedDealIds
        }
    }
`;
export const GetDealsCountDocument = gql`
    query GetDealsCount {
        dealsCollection(
            queryParams: {
                filtering: {
                    field: FK_DEAL_STATE
                    fop: FOP_EQ
                    value: "IN_PLAY"
                }
            }
        ) {
            totalCount
        }
    }
`;
export const GetAllDealsCollectionDocument = gql`
    query GetAllDealsCollection($queryParams: QueryParamsQL!) {
        dealsCollection(queryParams: $queryParams) {
            pageInfo {
                count
                hasNextPage
            }
            edges {
                node {
                    ...MinimalDealPart
                }
            }
            totalCount
        }
    }
    ${MinimalDealPartFragmentDoc}
`;
export const GetDealCustomParametersDocument = gql`
    query GetDealCustomParameters($dealId: String!) {
        deal(dealId: $dealId) {
            customFields {
                ...CustomField
            }
        }
    }
    ${CustomFieldFragmentDoc}
`;
export const UpdateDealCustomParametersDocument = gql`
    mutation UpdateDealCustomParameters(
        $dealId: String!
        $update: DealUpdateCustomInputQL!
    ) {
        updateDealCustomValues(dealId: $dealId, update: $update) {
            ok
        }
    }
`;
export const GetAllIntegrationsDocument = gql`
    query GetAllIntegrations {
        allIntegrations {
            ...IntegrationPart
        }
    }
    ${IntegrationPartFragmentDoc}
`;
export const GetDealCommentsDocument = gql`
    query GetDealComments($dealId: String!) {
        deal(dealId: $dealId) {
            comments {
                ...DealComment
            }
        }
    }
    ${DealCommentFragmentDoc}
`;
export const AddDealCommentDocument = gql`
    mutation AddDealComment($dealId: String!, $data: CommentCreateQL!) {
        addDealComment(dealId: $dealId, data: $data) {
            ...DealComment
        }
    }
    ${DealCommentFragmentDoc}
`;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($commentId: String!, $data: CommentUpdateQL!) {
        updateComment(commentId: $commentId, update: $data) {
            ...DealComment
        }
    }
    ${DealCommentFragmentDoc}
`;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($commentId: String!) {
        deleteComment(commentId: $commentId) {
            ok
        }
    }
`;
export const ExportToCrmDocument = gql`
    mutation ExportToCrm($dealId: String!, $integrationId: String!) {
        exportToCrm(dealId: $dealId, integrationId: $integrationId) {
            ok
        }
    }
`;
export const GetCrmStateDocument = gql`
    query GetCrmState($dealId: String!, $integrationId: String!) {
        deal(dealId: $dealId) {
            export {
                crm(integrationId: $integrationId) {
                    ...CrmStatePart
                }
            }
        }
    }
    ${CrmStatePartFragmentDoc}
`;
export const GetMyHubSpotTokenDocument = gql`
    query GetMyHubSpotToken {
        me {
            hubspotToken
        }
    }
`;
export const UpdatePersonDocument = gql`
    mutation UpdatePerson($contactId: String!, $update: ContactUpdateQL!) {
        updateContact(contactId: $contactId, update: $update) {
            ...DealContactPart
        }
    }
    ${DealContactPartFragmentDoc}
`;
export const UpdateRelevantCompanyDocument = gql`
    mutation UpdateRelevantCompany(
        $relevantCompanyId: String!
        $update: RelevantCompanyUpdateQL!
    ) {
        updateRelevantCompany(
            relevantCompanyId: $relevantCompanyId
            update: $update
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export const GetAccountConfigurationDocument = gql`
    query GetAccountConfiguration {
        company {
            config {
                actionFeatures {
                    active
                    name
                    order
                    settings
                    companyOutreachStages
                    contactOutreachStages
                }
                salesPeriod {
                    frequency
                }
                customFields {
                    deals {
                        ...CustomFieldConfig
                    }
                    contacts {
                        ...CustomFieldConfig
                    }
                    relevantCompanies {
                        ...CustomFieldConfig
                    }
                }
                dealValueVisible
                roles {
                    ...RolesPart
                }
            }
        }
    }
    ${CustomFieldConfigFragmentDoc}
    ${RolesPartFragmentDoc}
`;
export const GetDealLocationDataDocument = gql`
    query GetDealLocationData($dealId: String!, $name: String!) {
        actionFeatureGoogleEnrichmentPlaces(dealId: $dealId, name: $name) {
            ...PlacePart
        }
    }
    ${PlacePartFragmentDoc}
`;
export const GetGoogleEnrichmentDataDocument = gql`
    query GetGoogleEnrichmentData($language: String!, $placeId: String!) {
        actionFeatureGoogleEnrichmentPlaceDetails(
            language: $language
            placeId: $placeId
        ) {
            ...GoogleEnrichmentPart
        }
    }
    ${GoogleEnrichmentPartFragmentDoc}
`;
export const CreateContactsFromExtractedContactsDocument = gql`
    mutation CreateContactsFromExtractedContacts(
        $dealId: String!
        $extractedContacts: [ReformattedExtractedContactInputQL!]!
        $extractedCompanies: [ReformattedExtractedCompanyInputQL!]!
    ) {
        createContactsFromExtractedContacts(
            extractedContacts: $extractedContacts
            dealId: $dealId
            extractedCompanies: $extractedCompanies
        ) {
            contacts {
                ...DealContactPart
            }
            relevantCompanies {
                ...DealCompanyPart
            }
        }
    }
    ${DealContactPartFragmentDoc}
    ${DealCompanyPartFragmentDoc}
`;
export const CreateReminderDocument = gql`
    mutation CreateReminder(
        $data: ReminderCreateInputQL!
        $launchSource: String = null
    ) {
        createReminder(data: $data, launchSource: $launchSource) {
            ...ReminderPart
        }
    }
    ${ReminderPartFragmentDoc}
`;
export const UpdateReminderDocument = gql`
    mutation UpdateReminder(
        $reminderId: String!
        $update: ReminderUpdateInputQL!
    ) {
        updateReminder(reminderId: $reminderId, update: $update) {
            ...ReminderPart
        }
    }
    ${ReminderPartFragmentDoc}
`;
export const GetMyRemindersAndMentionsDocument = gql`
    query GetMyRemindersAndMentions {
        me {
            mentions {
                ...MentionPart
            }
            reminders {
                ...ReminderPart
            }
        }
    }
    ${MentionPartFragmentDoc}
    ${ReminderPartFragmentDoc}
`;
export const DismissReminderDocument = gql`
    mutation DismissReminder($reminderId: String!) {
        completeReminder(reminderId: $reminderId) {
            ...ReminderPart
        }
    }
    ${ReminderPartFragmentDoc}
`;
export const MarkMentionAsReadDocument = gql`
    mutation MarkMentionAsRead($mentionId: String!) {
        readCommentMention(commentId: $mentionId) {
            ok
        }
    }
`;
export const DismissMentionDocument = gql`
    mutation DismissMention($mentionId: String!) {
        dismissCommentMention(commentId: $mentionId) {
            ok
        }
    }
`;
export const GetCompanyTypesDocument = gql`
    query GetCompanyTypes {
        company {
            accountType
            licenseType
        }
    }
`;
export const GetDealAlertDocument = gql`
    query GetDealAlert($dealId: String!) {
        deal(dealId: $dealId) {
            alert {
                ...DealAlertPart
            }
        }
    }
    ${DealAlertPartFragmentDoc}
`;
export const GetDynamicFilterMetricsDocument = gql`
    query GetDynamicFilterMetrics(
        $pipelineId: String!
        $filters: [FilterOpQL!]!
    ) {
        pipeline(pipelineId: $pipelineId) {
            statistics(queryParams: { filtering: $filters }) {
                dynamicFiltersMetrics {
                    ...DynamicFilterMetricsPart
                }
            }
        }
    }
    ${DynamicFilterMetricsPartFragmentDoc}
`;
export const GetDealsCollectionByPipelineDocument = gql`
    query GetDealsCollectionByPipeline($queryParams: QueryParamsQL!) {
        dealsCollection(queryParams: $queryParams) {
            edges {
                node {
                    ...PipelineDealPart
                }
            }
            totalCount
        }
    }
    ${PipelineDealPartFragmentDoc}
`;
export const AskQuestionDocument = gql`
    query AskQuestion(
        $question: String!
        $projectId: Int
        $documentId: String
        $dealId: String
    ) {
        actionFeatureXray(
            question: $question
            projectId: $projectId
            documentId: $documentId
            dealId: $dealId
        ) {
            answer
        }
    }
`;
export const AskQuestionRealtimeDocument = gql`
    subscription AskQuestionRealtime(
        $question: String!
        $projectId: Int
        $documentId: String
        $dealId: String
    ) {
        actionFeatureXray(
            projectId: $projectId
            question: $question
            documentId: $documentId
            dealId: $dealId
        ) {
            answer
        }
    }
`;
export const LiveUserNotificationsDocument = gql`
    subscription LiveUserNotifications {
        subscribeUserUpdates {
            ...InstantMessage
        }
    }
    ${InstantMessageFragmentDoc}
`;
export const GenerateValuePropEmailDocument = gql`
    query GenerateValuePropEmail(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
        $vpoSessionId: String!
    ) {
        actionFeatureValuePropOutreachEmail(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
            vpoSessionId: $vpoSessionId
        ) {
            answer
        }
    }
`;
export const GenerateValuePropEmailFollowUpDocument = gql`
    query GenerateValuePropEmailFollowUp(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
    ) {
        actionFeatureValuePropOutreachFollowUp(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
        ) {
            answer
        }
    }
`;
export const GenerateValuePropCallScriptDocument = gql`
    query GenerateValuePropCallScript(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
    ) {
        actionFeatureValuePropOutreachCallScript(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
        ) {
            answer
        }
    }
`;
export const GenerateValuePropEmailStreamDocument = gql`
    subscription GenerateValuePropEmailStream(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
        $vpoSessionId: String!
    ) {
        actionFeatureValuePropOutreachEmail(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
            vpoSessionId: $vpoSessionId
        ) {
            answer
        }
    }
`;
export const GenerateValuePropEmailFollowUpStreamDocument = gql`
    subscription GenerateValuePropEmailFollowUpStream(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
    ) {
        actionFeatureValuePropOutreachFollowUp(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
        ) {
            answer
        }
    }
`;
export const GenerateValuePropCallScriptStreamDocument = gql`
    subscription GenerateValuePropCallScriptStream(
        $contactId: String = null
        $companyId: String = null
        $dealId: String!
        $regenerationSpec: RegenerationSpecQL
        $outreachBlockIds: [String!]!
    ) {
        actionFeatureValuePropOutreachCallScript(
            contactId: $contactId
            relevantCompanyId: $companyId
            dealId: $dealId
            regenerationSpec: $regenerationSpec
            outreachBlockIds: $outreachBlockIds
        ) {
            answer
        }
    }
`;
export const GetDomainEnrichmentResultDocument = gql`
    query GetDomainEnrichmentResult(
        $companyName: String!
        $dealId: String!
        $relevantCompanyId: String!
        $contactId: String!
    ) {
        actionFeatureContactEnrichmentCompanyDomain(
            companyName: $companyName
            dealId: $dealId
            relevantCompanyId: $relevantCompanyId
            contactId: $contactId
        ) {
            domain
            name
            providers
        }
    }
`;
export const GetContactEnrichmentResultDocument = gql`
    query GetContactEnrichmentResult(
        $dealId: String!
        $contactName: String!
        $company: String!
        $domain: String = null
        $linkedinUrl: String = null
        $contactId: String = null
        $triggeredFromSearchRefinement: Boolean = false
    ) {
        actionFeatureContactEnrichment(
            dealId: $dealId
            company: $company
            contactName: $contactName
            domain: $domain
            linkedinUrl: $linkedinUrl
            contactId: $contactId
            triggeredFromSearchRefinement: $triggeredFromSearchRefinement
        ) {
            emails {
                value
                providers
            }
            phones {
                value
                providers
            }
            linkedins {
                value
                providers
            }
            positions {
                value
                providers
            }
        }
    }
`;
export const SetAchievementsOptInDocument = gql`
    mutation SetAchievementsOptIn($optIn: Boolean!) {
        updateUserAchievementsEnrolmentOptIn(optIn: $optIn) {
            ok
        }
    }
`;
export const GetUserAchievementsDocument = gql`
    query GetUserAchievements {
        userAchievements {
            achievements {
                ...UserAchievements
            }
        }
    }
    ${UserAchievementsFragmentDoc}
`;
export const UpdateAchievementStateDocument = gql`
    mutation UpdateAchievementState(
        $name: UserAchievementName!
        $state: UserAchievementState!
    ) {
        updateUserAchievementState(achievementName: $name, status: $state) {
            ok
        }
    }
`;
export const GetValueMilestonesDocument = gql`
    query GetValueMilestones {
        allValueMilestones {
            ...ValueMilestone
        }
    }
    ${ValueMilestoneFragmentDoc}
`;
export const GetTeamsAchievementsDocument = gql`
    query GetTeamsAchievements {
        colleaguesAchievements {
            ...ColleagueAchievements
        }
    }
    ${ColleagueAchievementsFragmentDoc}
`;
export const GetPipelineStatisticsDocument = gql`
    query GetPipelineStatistics(
        $forCurrentUser: Boolean!
        $pipelineId: String!
    ) {
        pipelineStatistics(
            pipelineId: $pipelineId
            forCurrentUser: $forCurrentUser
        ) {
            ...PipelineStatistics
        }
    }
    ${PipelineStatisticsFragmentDoc}
`;
export const GetCustomFieldsForDealsDocument = gql`
    query GetCustomFieldsForDeals($dealIds: [JSON!]!) {
        dealsCollection(
            queryParams: {
                filtering: { field: FK_DEAL_ID, fop: FOP_IN, values: $dealIds }
            }
        ) {
            edges {
                node {
                    id
                    customFields {
                        isTag
                        label
                        name
                        value
                    }
                }
            }
        }
    }
`;
export const GetRemindersForDealsDocument = gql`
    query GetRemindersForDeals($dealIds: [JSON!]!) {
        dealsCollection(
            queryParams: {
                filtering: { field: FK_DEAL_ID, fop: FOP_IN, values: $dealIds }
            }
        ) {
            edges {
                node {
                    id
                    reminders {
                        ...ReminderPart
                    }
                }
            }
        }
    }
    ${ReminderPartFragmentDoc}
`;
export const GetCommentsForDealsDocument = gql`
    query GetCommentsForDeals($dealIds: [JSON!]!) {
        dealsCollection(
            queryParams: {
                filtering: { field: FK_DEAL_ID, fop: FOP_IN, values: $dealIds }
            }
        ) {
            edges {
                node {
                    id
                    comments {
                        ...DealComment
                    }
                }
            }
        }
    }
    ${DealCommentFragmentDoc}
`;
export const DeleteOutreachBlockDocument = gql`
    mutation DeleteOutreachBlock(
        $dealId: String!
        $outreachBlockId: String!
        $vpoSessionId: String!
    ) {
        deleteOutreachBlock(
            dealId: $dealId
            outreachBlockId: $outreachBlockId
            vpoSessionId: $vpoSessionId
        ) {
            ok
        }
    }
`;
export const CreateOutreachBlockDocument = gql`
    mutation CreateOutreachBlock(
        $dealId: String!
        $outreachBlock: OutreachBlockCreateMutationQl!
        $vpoSessionId: String!
    ) {
        createOutreachBlock(
            dealId: $dealId
            outreachBlock: $outreachBlock
            vpoSessionId: $vpoSessionId
        ) {
            id
        }
    }
`;
export const UpdateOutreachBlockDocument = gql`
    mutation UpdateOutreachBlock(
        $dealId: String!
        $outreachBlock: OutreachBlockUpdateMutationQl!
        $vpoSessionId: String!
    ) {
        updateOutreachBlock(
            dealId: $dealId
            outreachBlock: $outreachBlock
            vpoSessionId: $vpoSessionId
        ) {
            ok
        }
    }
`;
export const TranslateDocument = gql`
    query Translate($text: String!, $language: String!) {
        translate(text: $text, language: $language)
    }
`;
export const CreateDealActivityDocument = gql`
    mutation CreateDealActivity(
        $activityType: ActivityLogType!
        $subType: ActivityLogSubType = null
        $dealId: String = null
        $contactId: String = null
        $relevantCompanyId: String = null
        $notes: String! = ""
        $customType: String! = ""
    ) {
        createActivityLog(
            activityType: $activityType
            subType: $subType
            dealId: $dealId
            contactId: $contactId
            relevantCompanyId: $relevantCompanyId
            notes: $notes
            customType: $customType
        ) {
            ...ActivityLogPart
        }
    }
    ${ActivityLogPartFragmentDoc}
`;
export const UpdateDealActivityDocument = gql`
    mutation UpdateDealActivity(
        $activityLogId: String!
        $contactId: String = null
        $notes: String = ""
        $customType: String = ""
    ) {
        updateActivityLog(
            activityLogId: $activityLogId
            contactId: $contactId
            notes: $notes
            customType: $customType
        ) {
            ...ActivityLogPart
        }
    }
    ${ActivityLogPartFragmentDoc}
`;
export const DeleteDealActivityDocument = gql`
    mutation DeleteDealActivity($activityLogId: String!) {
        deleteActivityLog(activityLogId: $activityLogId) {
            ok
        }
    }
`;
export const OmniSearchDocument = gql`
    query OmniSearch($searchTerm: String!, $limit: Int!) {
        omniSearch(query: $searchTerm, limit: $limit) {
            comments {
                ...DealComment
            }
            companies {
                ...DealCompanyPart
            }
            contacts {
                ...DealContactPart
            }
            deals {
                ...DealPart
            }
            nextSteps {
                ...ReminderPart
            }
            activityLogs {
                ...ActivityLogPart
            }
        }
    }
    ${DealCommentFragmentDoc}
    ${DealCompanyPartFragmentDoc}
    ${DealContactPartFragmentDoc}
    ${DealPartFragmentDoc}
    ${ReminderPartFragmentDoc}
    ${ActivityLogPartFragmentDoc}
`;
export const GetContactExtractionResultDocument = gql`
    query GetContactExtractionResult($dealId: String!) {
        getContactExtractionResult(dealId: $dealId) {
            ...ContactExtractionResult
        }
    }
    ${ContactExtractionResultFragmentDoc}
`;
export const RunContactExtractionForDealDocument = gql`
    mutation RunContactExtractionForDeal(
        $dealId: String!
        $language: UserLanguage!
    ) {
        runContactExtractionForDeal(dealId: $dealId, language: $language) {
            ok
        }
    }
`;
export const GetActivityLogByDealDocument = gql`
    query GetActivityLogByDeal($dealId: String!) {
        deal(dealId: $dealId) {
            activityLog {
                ...ActivityLogPart
            }
        }
    }
    ${ActivityLogPartFragmentDoc}
`;
export const RunAutoEnrichmentForCompanyDocument = gql`
    mutation RunAutoEnrichmentForCompany(
        $companyId: String!
        $dealId: String!
    ) {
        autoEnrichCompany(relevantCompanyId: $companyId, dealId: $dealId) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export const RemoveEnrichmentItemFromCompanyDocument = gql`
    mutation RemoveEnrichmentItemFromCompany(
        $companyId: String!
        $placeId: String!
        $field: String = null
    ) {
        removePlaceFromCompany(
            relevantCompanyId: $companyId
            placeId: $placeId
            field: $field
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export const UpdateCompanyWithEnrichmentDocument = gql`
    mutation UpdateCompanyWithEnrichment(
        $companyId: String!
        $dealId: String!
        $placeId: String!
        $fields: [String!] = null
    ) {
        overrideCompanyInfoWithPlace(
            relevantCompanyId: $companyId
            dealId: $dealId
            placeId: $placeId
            fields: $fields
        ) {
            ...DealCompanyPart
        }
    }
    ${DealCompanyPartFragmentDoc}
`;
export const FindContactsForCompanyDocument = gql`
    query FindContactsForCompany(
        $companyName: String!
        $keyword: String
        $page: Int!
        $title: String
        $location: String
    ) {
        getCompanyLinkedinProfiles(
            companyName: $companyName
            page: $page
            keyword: $keyword
            title: $title
            location: $location
        ) {
            profiles {
                ...LinkedinProfile
            }
            metaData {
                ...LinkedinSearchMeta
            }
        }
    }
    ${LinkedinProfileFragmentDoc}
    ${LinkedinSearchMetaFragmentDoc}
`;
export const GetLinkedinProfileContactInfoDocument = gql`
    query GetLinkedinProfileContactInfo($linkedinUserName: String!) {
        getLinkedinProfileContactInformation(
            linkedinUsername: $linkedinUserName
        ) {
            ...LinkedinProfileContactInfo
        }
    }
    ${LinkedinProfileContactInfoFragmentDoc}
`;
export const GetDealContentMetadataDocument = gql`
    query GetDealContentMetadata($dealId: String!) {
        deal(dealId: $dealId) {
            contents {
                ...DealContentMetadata
            }
        }
    }
    ${DealContentMetadataFragmentDoc}
`;
export const GetDealContentAttachmentDownloadUrlDocument = gql`
    query GetDealContentAttachmentDownloadUrl(
        $contentId: String!
        $inline: Boolean!
    ) {
        getDealAttachmentDownloadUrl(contentId: $contentId, inline: $inline) {
            ...DealFileAttachmentDownloadUrl
        }
    }
    ${DealFileAttachmentDownloadUrlFragmentDoc}
`;
export const DeleteDealContentDocument = gql`
    mutation DeleteDealContent($dealId: String!, $contentId: String!) {
        removeDealContent(dealId: $dealId, contentId: $contentId) {
            ok
        }
    }
`;
export type Requester<C = {}> = <R, V>(
    doc: DocumentNode,
    vars?: V,
    options?: C,
) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        MoveDealToStage(
            variables: MoveDealToStageMutationVariables,
            options?: C,
        ): Promise<MoveDealToStageMutation> {
            return requester<
                MoveDealToStageMutation,
                MoveDealToStageMutationVariables
            >(
                MoveDealToStageDocument,
                variables,
                options,
            ) as Promise<MoveDealToStageMutation>;
        },
        AssignUserToDeal(
            variables: AssignUserToDealMutationVariables,
            options?: C,
        ): Promise<AssignUserToDealMutation> {
            return requester<
                AssignUserToDealMutation,
                AssignUserToDealMutationVariables
            >(
                AssignUserToDealDocument,
                variables,
                options,
            ) as Promise<AssignUserToDealMutation>;
        },
        DisqualifyDeal(
            variables: DisqualifyDealMutationVariables,
            options?: C,
        ): Promise<DisqualifyDealMutation> {
            return requester<
                DisqualifyDealMutation,
                DisqualifyDealMutationVariables
            >(
                DisqualifyDealDocument,
                variables,
                options,
            ) as Promise<DisqualifyDealMutation>;
        },
        RestoreDeal(
            variables: RestoreDealMutationVariables,
            options?: C,
        ): Promise<RestoreDealMutation> {
            return requester<RestoreDealMutation, RestoreDealMutationVariables>(
                RestoreDealDocument,
                variables,
                options,
            ) as Promise<RestoreDealMutation>;
        },
        PauseDeal(
            variables: PauseDealMutationVariables,
            options?: C,
        ): Promise<PauseDealMutation> {
            return requester<PauseDealMutation, PauseDealMutationVariables>(
                PauseDealDocument,
                variables,
                options,
            ) as Promise<PauseDealMutation>;
        },
        UnPauseDeal(
            variables: UnPauseDealMutationVariables,
            options?: C,
        ): Promise<UnPauseDealMutation> {
            return requester<UnPauseDealMutation, UnPauseDealMutationVariables>(
                UnPauseDealDocument,
                variables,
                options,
            ) as Promise<UnPauseDealMutation>;
        },
        MarkDealAsDone(
            variables: MarkDealAsDoneMutationVariables,
            options?: C,
        ): Promise<MarkDealAsDoneMutation> {
            return requester<
                MarkDealAsDoneMutation,
                MarkDealAsDoneMutationVariables
            >(
                MarkDealAsDoneDocument,
                variables,
                options,
            ) as Promise<MarkDealAsDoneMutation>;
        },
        CreateNewDealMutation(
            variables: CreateNewDealMutationMutationVariables,
            options?: C,
        ): Promise<CreateNewDealMutationMutation> {
            return requester<
                CreateNewDealMutationMutation,
                CreateNewDealMutationMutationVariables
            >(
                CreateNewDealMutationDocument,
                variables,
                options,
            ) as Promise<CreateNewDealMutationMutation>;
        },
        UpdateDealInformation(
            variables: UpdateDealInformationMutationVariables,
            options?: C,
        ): Promise<UpdateDealInformationMutation> {
            return requester<
                UpdateDealInformationMutation,
                UpdateDealInformationMutationVariables
            >(
                UpdateDealInformationDocument,
                variables,
                options,
            ) as Promise<UpdateDealInformationMutation>;
        },
        UpdateObjectiveMutation(
            variables: UpdateObjectiveMutationMutationVariables,
            options?: C,
        ): Promise<UpdateObjectiveMutationMutation> {
            return requester<
                UpdateObjectiveMutationMutation,
                UpdateObjectiveMutationMutationVariables
            >(
                UpdateObjectiveMutationDocument,
                variables,
                options,
            ) as Promise<UpdateObjectiveMutationMutation>;
        },
        GetAllDeals(
            variables: GetAllDealsQueryVariables,
            options?: C,
        ): Promise<GetAllDealsQuery> {
            return requester<GetAllDealsQuery, GetAllDealsQueryVariables>(
                GetAllDealsDocument,
                variables,
                options,
            ) as Promise<GetAllDealsQuery>;
        },
        GetDeal(
            variables: GetDealQueryVariables,
            options?: C,
        ): Promise<GetDealQuery> {
            return requester<GetDealQuery, GetDealQueryVariables>(
                GetDealDocument,
                variables,
                options,
            ) as Promise<GetDealQuery>;
        },
        ExportCSV(
            variables: ExportCsvQueryVariables,
            options?: C,
        ): Promise<ExportCsvQuery> {
            return requester<ExportCsvQuery, ExportCsvQueryVariables>(
                ExportCsvDocument,
                variables,
                options,
            ) as Promise<ExportCsvQuery>;
        },
        ExportBatchCSV(
            variables: ExportBatchCsvQueryVariables,
            options?: C,
        ): Promise<ExportBatchCsvQuery> {
            return requester<ExportBatchCsvQuery, ExportBatchCsvQueryVariables>(
                ExportBatchCsvDocument,
                variables,
                options,
            ) as Promise<ExportBatchCsvQuery>;
        },
        GetDealObjectives(
            variables: GetDealObjectivesQueryVariables,
            options?: C,
        ): Promise<GetDealObjectivesQuery> {
            return requester<
                GetDealObjectivesQuery,
                GetDealObjectivesQueryVariables
            >(
                GetDealObjectivesDocument,
                variables,
                options,
            ) as Promise<GetDealObjectivesQuery>;
        },
        GetDealStageUpdateDate(
            variables: GetDealStageUpdateDateQueryVariables,
            options?: C,
        ): Promise<GetDealStageUpdateDateQuery> {
            return requester<
                GetDealStageUpdateDateQuery,
                GetDealStageUpdateDateQueryVariables
            >(
                GetDealStageUpdateDateDocument,
                variables,
                options,
            ) as Promise<GetDealStageUpdateDateQuery>;
        },
        GetDealContacts(
            variables: GetDealContactsQueryVariables,
            options?: C,
        ): Promise<GetDealContactsQuery> {
            return requester<
                GetDealContactsQuery,
                GetDealContactsQueryVariables
            >(
                GetDealContactsDocument,
                variables,
                options,
            ) as Promise<GetDealContactsQuery>;
        },
        CreateCompany(
            variables: CreateCompanyMutationVariables,
            options?: C,
        ): Promise<CreateCompanyMutation> {
            return requester<
                CreateCompanyMutation,
                CreateCompanyMutationVariables
            >(
                CreateCompanyDocument,
                variables,
                options,
            ) as Promise<CreateCompanyMutation>;
        },
        UpdateCompany(
            variables: UpdateCompanyMutationVariables,
            options?: C,
        ): Promise<UpdateCompanyMutation> {
            return requester<
                UpdateCompanyMutation,
                UpdateCompanyMutationVariables
            >(
                UpdateCompanyDocument,
                variables,
                options,
            ) as Promise<UpdateCompanyMutation>;
        },
        UpdateCompanyNote(
            variables: UpdateCompanyNoteMutationVariables,
            options?: C,
        ): Promise<UpdateCompanyNoteMutation> {
            return requester<
                UpdateCompanyNoteMutation,
                UpdateCompanyNoteMutationVariables
            >(
                UpdateCompanyNoteDocument,
                variables,
                options,
            ) as Promise<UpdateCompanyNoteMutation>;
        },
        UpdateCompanyRole(
            variables: UpdateCompanyRoleMutationVariables,
            options?: C,
        ): Promise<UpdateCompanyRoleMutation> {
            return requester<
                UpdateCompanyRoleMutation,
                UpdateCompanyRoleMutationVariables
            >(
                UpdateCompanyRoleDocument,
                variables,
                options,
            ) as Promise<UpdateCompanyRoleMutation>;
        },
        DeleteCompany(
            variables: DeleteCompanyMutationVariables,
            options?: C,
        ): Promise<DeleteCompanyMutation> {
            return requester<
                DeleteCompanyMutation,
                DeleteCompanyMutationVariables
            >(
                DeleteCompanyDocument,
                variables,
                options,
            ) as Promise<DeleteCompanyMutation>;
        },
        exportEmail(
            variables: ExportEmailMutationVariables,
            options?: C,
        ): Promise<ExportEmailMutation> {
            return requester<ExportEmailMutation, ExportEmailMutationVariables>(
                ExportEmailDocument,
                variables,
                options,
            ) as Promise<ExportEmailMutation>;
        },
        CreateContact(
            variables: CreateContactMutationVariables,
            options?: C,
        ): Promise<CreateContactMutation> {
            return requester<
                CreateContactMutation,
                CreateContactMutationVariables
            >(
                CreateContactDocument,
                variables,
                options,
            ) as Promise<CreateContactMutation>;
        },
        UpdateContact(
            variables: UpdateContactMutationVariables,
            options?: C,
        ): Promise<UpdateContactMutation> {
            return requester<
                UpdateContactMutation,
                UpdateContactMutationVariables
            >(
                UpdateContactDocument,
                variables,
                options,
            ) as Promise<UpdateContactMutation>;
        },
        UpdateContactNote(
            variables: UpdateContactNoteMutationVariables,
            options?: C,
        ): Promise<UpdateContactNoteMutation> {
            return requester<
                UpdateContactNoteMutation,
                UpdateContactNoteMutationVariables
            >(
                UpdateContactNoteDocument,
                variables,
                options,
            ) as Promise<UpdateContactNoteMutation>;
        },
        UpdateContactRole(
            variables: UpdateContactRoleMutationVariables,
            options?: C,
        ): Promise<UpdateContactRoleMutation> {
            return requester<
                UpdateContactRoleMutation,
                UpdateContactRoleMutationVariables
            >(
                UpdateContactRoleDocument,
                variables,
                options,
            ) as Promise<UpdateContactRoleMutation>;
        },
        DeleteContact(
            variables: DeleteContactMutationVariables,
            options?: C,
        ): Promise<DeleteContactMutation> {
            return requester<
                DeleteContactMutation,
                DeleteContactMutationVariables
            >(
                DeleteContactDocument,
                variables,
                options,
            ) as Promise<DeleteContactMutation>;
        },
        AllPipelinesQuery(
            variables?: AllPipelinesQueryQueryVariables,
            options?: C,
        ): Promise<AllPipelinesQueryQuery> {
            return requester<
                AllPipelinesQueryQuery,
                AllPipelinesQueryQueryVariables
            >(
                AllPipelinesQueryDocument,
                variables,
                options,
            ) as Promise<AllPipelinesQueryQuery>;
        },
        BulkMove(
            variables: BulkMoveMutationVariables,
            options?: C,
        ): Promise<BulkMoveMutation> {
            return requester<BulkMoveMutation, BulkMoveMutationVariables>(
                BulkMoveDocument,
                variables,
                options,
            ) as Promise<BulkMoveMutation>;
        },
        BulkUpdateDealCustomParameters(
            variables: BulkUpdateDealCustomParametersMutationVariables,
            options?: C,
        ): Promise<BulkUpdateDealCustomParametersMutation> {
            return requester<
                BulkUpdateDealCustomParametersMutation,
                BulkUpdateDealCustomParametersMutationVariables
            >(
                BulkUpdateDealCustomParametersDocument,
                variables,
                options,
            ) as Promise<BulkUpdateDealCustomParametersMutation>;
        },
        BulkUpdateDealAttributes(
            variables: BulkUpdateDealAttributesMutationVariables,
            options?: C,
        ): Promise<BulkUpdateDealAttributesMutation> {
            return requester<
                BulkUpdateDealAttributesMutation,
                BulkUpdateDealAttributesMutationVariables
            >(
                BulkUpdateDealAttributesDocument,
                variables,
                options,
            ) as Promise<BulkUpdateDealAttributesMutation>;
        },
        GetDealsCount(
            variables?: GetDealsCountQueryVariables,
            options?: C,
        ): Promise<GetDealsCountQuery> {
            return requester<GetDealsCountQuery, GetDealsCountQueryVariables>(
                GetDealsCountDocument,
                variables,
                options,
            ) as Promise<GetDealsCountQuery>;
        },
        GetAllDealsCollection(
            variables: GetAllDealsCollectionQueryVariables,
            options?: C,
        ): Promise<GetAllDealsCollectionQuery> {
            return requester<
                GetAllDealsCollectionQuery,
                GetAllDealsCollectionQueryVariables
            >(
                GetAllDealsCollectionDocument,
                variables,
                options,
            ) as Promise<GetAllDealsCollectionQuery>;
        },
        GetDealCustomParameters(
            variables: GetDealCustomParametersQueryVariables,
            options?: C,
        ): Promise<GetDealCustomParametersQuery> {
            return requester<
                GetDealCustomParametersQuery,
                GetDealCustomParametersQueryVariables
            >(
                GetDealCustomParametersDocument,
                variables,
                options,
            ) as Promise<GetDealCustomParametersQuery>;
        },
        UpdateDealCustomParameters(
            variables: UpdateDealCustomParametersMutationVariables,
            options?: C,
        ): Promise<UpdateDealCustomParametersMutation> {
            return requester<
                UpdateDealCustomParametersMutation,
                UpdateDealCustomParametersMutationVariables
            >(
                UpdateDealCustomParametersDocument,
                variables,
                options,
            ) as Promise<UpdateDealCustomParametersMutation>;
        },
        GetAllIntegrations(
            variables?: GetAllIntegrationsQueryVariables,
            options?: C,
        ): Promise<GetAllIntegrationsQuery> {
            return requester<
                GetAllIntegrationsQuery,
                GetAllIntegrationsQueryVariables
            >(
                GetAllIntegrationsDocument,
                variables,
                options,
            ) as Promise<GetAllIntegrationsQuery>;
        },
        GetDealComments(
            variables: GetDealCommentsQueryVariables,
            options?: C,
        ): Promise<GetDealCommentsQuery> {
            return requester<
                GetDealCommentsQuery,
                GetDealCommentsQueryVariables
            >(
                GetDealCommentsDocument,
                variables,
                options,
            ) as Promise<GetDealCommentsQuery>;
        },
        AddDealComment(
            variables: AddDealCommentMutationVariables,
            options?: C,
        ): Promise<AddDealCommentMutation> {
            return requester<
                AddDealCommentMutation,
                AddDealCommentMutationVariables
            >(
                AddDealCommentDocument,
                variables,
                options,
            ) as Promise<AddDealCommentMutation>;
        },
        UpdateComment(
            variables: UpdateCommentMutationVariables,
            options?: C,
        ): Promise<UpdateCommentMutation> {
            return requester<
                UpdateCommentMutation,
                UpdateCommentMutationVariables
            >(
                UpdateCommentDocument,
                variables,
                options,
            ) as Promise<UpdateCommentMutation>;
        },
        DeleteComment(
            variables: DeleteCommentMutationVariables,
            options?: C,
        ): Promise<DeleteCommentMutation> {
            return requester<
                DeleteCommentMutation,
                DeleteCommentMutationVariables
            >(
                DeleteCommentDocument,
                variables,
                options,
            ) as Promise<DeleteCommentMutation>;
        },
        ExportToCrm(
            variables: ExportToCrmMutationVariables,
            options?: C,
        ): Promise<ExportToCrmMutation> {
            return requester<ExportToCrmMutation, ExportToCrmMutationVariables>(
                ExportToCrmDocument,
                variables,
                options,
            ) as Promise<ExportToCrmMutation>;
        },
        GetCrmState(
            variables: GetCrmStateQueryVariables,
            options?: C,
        ): Promise<GetCrmStateQuery> {
            return requester<GetCrmStateQuery, GetCrmStateQueryVariables>(
                GetCrmStateDocument,
                variables,
                options,
            ) as Promise<GetCrmStateQuery>;
        },
        GetMyHubSpotToken(
            variables?: GetMyHubSpotTokenQueryVariables,
            options?: C,
        ): Promise<GetMyHubSpotTokenQuery> {
            return requester<
                GetMyHubSpotTokenQuery,
                GetMyHubSpotTokenQueryVariables
            >(
                GetMyHubSpotTokenDocument,
                variables,
                options,
            ) as Promise<GetMyHubSpotTokenQuery>;
        },
        UpdatePerson(
            variables: UpdatePersonMutationVariables,
            options?: C,
        ): Promise<UpdatePersonMutation> {
            return requester<
                UpdatePersonMutation,
                UpdatePersonMutationVariables
            >(
                UpdatePersonDocument,
                variables,
                options,
            ) as Promise<UpdatePersonMutation>;
        },
        UpdateRelevantCompany(
            variables: UpdateRelevantCompanyMutationVariables,
            options?: C,
        ): Promise<UpdateRelevantCompanyMutation> {
            return requester<
                UpdateRelevantCompanyMutation,
                UpdateRelevantCompanyMutationVariables
            >(
                UpdateRelevantCompanyDocument,
                variables,
                options,
            ) as Promise<UpdateRelevantCompanyMutation>;
        },
        GetAccountConfiguration(
            variables?: GetAccountConfigurationQueryVariables,
            options?: C,
        ): Promise<GetAccountConfigurationQuery> {
            return requester<
                GetAccountConfigurationQuery,
                GetAccountConfigurationQueryVariables
            >(
                GetAccountConfigurationDocument,
                variables,
                options,
            ) as Promise<GetAccountConfigurationQuery>;
        },
        GetDealLocationData(
            variables: GetDealLocationDataQueryVariables,
            options?: C,
        ): Promise<GetDealLocationDataQuery> {
            return requester<
                GetDealLocationDataQuery,
                GetDealLocationDataQueryVariables
            >(
                GetDealLocationDataDocument,
                variables,
                options,
            ) as Promise<GetDealLocationDataQuery>;
        },
        GetGoogleEnrichmentData(
            variables: GetGoogleEnrichmentDataQueryVariables,
            options?: C,
        ): Promise<GetGoogleEnrichmentDataQuery> {
            return requester<
                GetGoogleEnrichmentDataQuery,
                GetGoogleEnrichmentDataQueryVariables
            >(
                GetGoogleEnrichmentDataDocument,
                variables,
                options,
            ) as Promise<GetGoogleEnrichmentDataQuery>;
        },
        CreateContactsFromExtractedContacts(
            variables: CreateContactsFromExtractedContactsMutationVariables,
            options?: C,
        ): Promise<CreateContactsFromExtractedContactsMutation> {
            return requester<
                CreateContactsFromExtractedContactsMutation,
                CreateContactsFromExtractedContactsMutationVariables
            >(
                CreateContactsFromExtractedContactsDocument,
                variables,
                options,
            ) as Promise<CreateContactsFromExtractedContactsMutation>;
        },
        CreateReminder(
            variables: CreateReminderMutationVariables,
            options?: C,
        ): Promise<CreateReminderMutation> {
            return requester<
                CreateReminderMutation,
                CreateReminderMutationVariables
            >(
                CreateReminderDocument,
                variables,
                options,
            ) as Promise<CreateReminderMutation>;
        },
        UpdateReminder(
            variables: UpdateReminderMutationVariables,
            options?: C,
        ): Promise<UpdateReminderMutation> {
            return requester<
                UpdateReminderMutation,
                UpdateReminderMutationVariables
            >(
                UpdateReminderDocument,
                variables,
                options,
            ) as Promise<UpdateReminderMutation>;
        },
        GetMyRemindersAndMentions(
            variables?: GetMyRemindersAndMentionsQueryVariables,
            options?: C,
        ): Promise<GetMyRemindersAndMentionsQuery> {
            return requester<
                GetMyRemindersAndMentionsQuery,
                GetMyRemindersAndMentionsQueryVariables
            >(
                GetMyRemindersAndMentionsDocument,
                variables,
                options,
            ) as Promise<GetMyRemindersAndMentionsQuery>;
        },
        DismissReminder(
            variables: DismissReminderMutationVariables,
            options?: C,
        ): Promise<DismissReminderMutation> {
            return requester<
                DismissReminderMutation,
                DismissReminderMutationVariables
            >(
                DismissReminderDocument,
                variables,
                options,
            ) as Promise<DismissReminderMutation>;
        },
        MarkMentionAsRead(
            variables: MarkMentionAsReadMutationVariables,
            options?: C,
        ): Promise<MarkMentionAsReadMutation> {
            return requester<
                MarkMentionAsReadMutation,
                MarkMentionAsReadMutationVariables
            >(
                MarkMentionAsReadDocument,
                variables,
                options,
            ) as Promise<MarkMentionAsReadMutation>;
        },
        DismissMention(
            variables: DismissMentionMutationVariables,
            options?: C,
        ): Promise<DismissMentionMutation> {
            return requester<
                DismissMentionMutation,
                DismissMentionMutationVariables
            >(
                DismissMentionDocument,
                variables,
                options,
            ) as Promise<DismissMentionMutation>;
        },
        GetCompanyTypes(
            variables?: GetCompanyTypesQueryVariables,
            options?: C,
        ): Promise<GetCompanyTypesQuery> {
            return requester<
                GetCompanyTypesQuery,
                GetCompanyTypesQueryVariables
            >(
                GetCompanyTypesDocument,
                variables,
                options,
            ) as Promise<GetCompanyTypesQuery>;
        },
        GetDealAlert(
            variables: GetDealAlertQueryVariables,
            options?: C,
        ): Promise<GetDealAlertQuery> {
            return requester<GetDealAlertQuery, GetDealAlertQueryVariables>(
                GetDealAlertDocument,
                variables,
                options,
            ) as Promise<GetDealAlertQuery>;
        },
        GetDynamicFilterMetrics(
            variables: GetDynamicFilterMetricsQueryVariables,
            options?: C,
        ): Promise<GetDynamicFilterMetricsQuery> {
            return requester<
                GetDynamicFilterMetricsQuery,
                GetDynamicFilterMetricsQueryVariables
            >(
                GetDynamicFilterMetricsDocument,
                variables,
                options,
            ) as Promise<GetDynamicFilterMetricsQuery>;
        },
        GetDealsCollectionByPipeline(
            variables: GetDealsCollectionByPipelineQueryVariables,
            options?: C,
        ): Promise<GetDealsCollectionByPipelineQuery> {
            return requester<
                GetDealsCollectionByPipelineQuery,
                GetDealsCollectionByPipelineQueryVariables
            >(
                GetDealsCollectionByPipelineDocument,
                variables,
                options,
            ) as Promise<GetDealsCollectionByPipelineQuery>;
        },
        AskQuestion(
            variables: AskQuestionQueryVariables,
            options?: C,
        ): Promise<AskQuestionQuery> {
            return requester<AskQuestionQuery, AskQuestionQueryVariables>(
                AskQuestionDocument,
                variables,
                options,
            ) as Promise<AskQuestionQuery>;
        },
        AskQuestionRealtime(
            variables: AskQuestionRealtimeSubscriptionVariables,
            options?: C,
        ): AsyncIterable<AskQuestionRealtimeSubscription> {
            return requester<
                AskQuestionRealtimeSubscription,
                AskQuestionRealtimeSubscriptionVariables
            >(
                AskQuestionRealtimeDocument,
                variables,
                options,
            ) as AsyncIterable<AskQuestionRealtimeSubscription>;
        },
        LiveUserNotifications(
            variables?: LiveUserNotificationsSubscriptionVariables,
            options?: C,
        ): AsyncIterable<LiveUserNotificationsSubscription> {
            return requester<
                LiveUserNotificationsSubscription,
                LiveUserNotificationsSubscriptionVariables
            >(
                LiveUserNotificationsDocument,
                variables,
                options,
            ) as AsyncIterable<LiveUserNotificationsSubscription>;
        },
        GenerateValuePropEmail(
            variables: GenerateValuePropEmailQueryVariables,
            options?: C,
        ): Promise<GenerateValuePropEmailQuery> {
            return requester<
                GenerateValuePropEmailQuery,
                GenerateValuePropEmailQueryVariables
            >(
                GenerateValuePropEmailDocument,
                variables,
                options,
            ) as Promise<GenerateValuePropEmailQuery>;
        },
        GenerateValuePropEmailFollowUp(
            variables: GenerateValuePropEmailFollowUpQueryVariables,
            options?: C,
        ): Promise<GenerateValuePropEmailFollowUpQuery> {
            return requester<
                GenerateValuePropEmailFollowUpQuery,
                GenerateValuePropEmailFollowUpQueryVariables
            >(
                GenerateValuePropEmailFollowUpDocument,
                variables,
                options,
            ) as Promise<GenerateValuePropEmailFollowUpQuery>;
        },
        GenerateValuePropCallScript(
            variables: GenerateValuePropCallScriptQueryVariables,
            options?: C,
        ): Promise<GenerateValuePropCallScriptQuery> {
            return requester<
                GenerateValuePropCallScriptQuery,
                GenerateValuePropCallScriptQueryVariables
            >(
                GenerateValuePropCallScriptDocument,
                variables,
                options,
            ) as Promise<GenerateValuePropCallScriptQuery>;
        },
        GenerateValuePropEmailStream(
            variables: GenerateValuePropEmailStreamSubscriptionVariables,
            options?: C,
        ): AsyncIterable<GenerateValuePropEmailStreamSubscription> {
            return requester<
                GenerateValuePropEmailStreamSubscription,
                GenerateValuePropEmailStreamSubscriptionVariables
            >(
                GenerateValuePropEmailStreamDocument,
                variables,
                options,
            ) as AsyncIterable<GenerateValuePropEmailStreamSubscription>;
        },
        GenerateValuePropEmailFollowUpStream(
            variables: GenerateValuePropEmailFollowUpStreamSubscriptionVariables,
            options?: C,
        ): AsyncIterable<GenerateValuePropEmailFollowUpStreamSubscription> {
            return requester<
                GenerateValuePropEmailFollowUpStreamSubscription,
                GenerateValuePropEmailFollowUpStreamSubscriptionVariables
            >(
                GenerateValuePropEmailFollowUpStreamDocument,
                variables,
                options,
            ) as AsyncIterable<GenerateValuePropEmailFollowUpStreamSubscription>;
        },
        GenerateValuePropCallScriptStream(
            variables: GenerateValuePropCallScriptStreamSubscriptionVariables,
            options?: C,
        ): AsyncIterable<GenerateValuePropCallScriptStreamSubscription> {
            return requester<
                GenerateValuePropCallScriptStreamSubscription,
                GenerateValuePropCallScriptStreamSubscriptionVariables
            >(
                GenerateValuePropCallScriptStreamDocument,
                variables,
                options,
            ) as AsyncIterable<GenerateValuePropCallScriptStreamSubscription>;
        },
        GetDomainEnrichmentResult(
            variables: GetDomainEnrichmentResultQueryVariables,
            options?: C,
        ): Promise<GetDomainEnrichmentResultQuery> {
            return requester<
                GetDomainEnrichmentResultQuery,
                GetDomainEnrichmentResultQueryVariables
            >(
                GetDomainEnrichmentResultDocument,
                variables,
                options,
            ) as Promise<GetDomainEnrichmentResultQuery>;
        },
        GetContactEnrichmentResult(
            variables: GetContactEnrichmentResultQueryVariables,
            options?: C,
        ): Promise<GetContactEnrichmentResultQuery> {
            return requester<
                GetContactEnrichmentResultQuery,
                GetContactEnrichmentResultQueryVariables
            >(
                GetContactEnrichmentResultDocument,
                variables,
                options,
            ) as Promise<GetContactEnrichmentResultQuery>;
        },
        SetAchievementsOptIn(
            variables: SetAchievementsOptInMutationVariables,
            options?: C,
        ): Promise<SetAchievementsOptInMutation> {
            return requester<
                SetAchievementsOptInMutation,
                SetAchievementsOptInMutationVariables
            >(
                SetAchievementsOptInDocument,
                variables,
                options,
            ) as Promise<SetAchievementsOptInMutation>;
        },
        GetUserAchievements(
            variables?: GetUserAchievementsQueryVariables,
            options?: C,
        ): Promise<GetUserAchievementsQuery> {
            return requester<
                GetUserAchievementsQuery,
                GetUserAchievementsQueryVariables
            >(
                GetUserAchievementsDocument,
                variables,
                options,
            ) as Promise<GetUserAchievementsQuery>;
        },
        UpdateAchievementState(
            variables: UpdateAchievementStateMutationVariables,
            options?: C,
        ): Promise<UpdateAchievementStateMutation> {
            return requester<
                UpdateAchievementStateMutation,
                UpdateAchievementStateMutationVariables
            >(
                UpdateAchievementStateDocument,
                variables,
                options,
            ) as Promise<UpdateAchievementStateMutation>;
        },
        GetValueMilestones(
            variables?: GetValueMilestonesQueryVariables,
            options?: C,
        ): Promise<GetValueMilestonesQuery> {
            return requester<
                GetValueMilestonesQuery,
                GetValueMilestonesQueryVariables
            >(
                GetValueMilestonesDocument,
                variables,
                options,
            ) as Promise<GetValueMilestonesQuery>;
        },
        GetTeamsAchievements(
            variables?: GetTeamsAchievementsQueryVariables,
            options?: C,
        ): Promise<GetTeamsAchievementsQuery> {
            return requester<
                GetTeamsAchievementsQuery,
                GetTeamsAchievementsQueryVariables
            >(
                GetTeamsAchievementsDocument,
                variables,
                options,
            ) as Promise<GetTeamsAchievementsQuery>;
        },
        GetPipelineStatistics(
            variables: GetPipelineStatisticsQueryVariables,
            options?: C,
        ): Promise<GetPipelineStatisticsQuery> {
            return requester<
                GetPipelineStatisticsQuery,
                GetPipelineStatisticsQueryVariables
            >(
                GetPipelineStatisticsDocument,
                variables,
                options,
            ) as Promise<GetPipelineStatisticsQuery>;
        },
        GetCustomFieldsForDeals(
            variables: GetCustomFieldsForDealsQueryVariables,
            options?: C,
        ): Promise<GetCustomFieldsForDealsQuery> {
            return requester<
                GetCustomFieldsForDealsQuery,
                GetCustomFieldsForDealsQueryVariables
            >(
                GetCustomFieldsForDealsDocument,
                variables,
                options,
            ) as Promise<GetCustomFieldsForDealsQuery>;
        },
        GetRemindersForDeals(
            variables: GetRemindersForDealsQueryVariables,
            options?: C,
        ): Promise<GetRemindersForDealsQuery> {
            return requester<
                GetRemindersForDealsQuery,
                GetRemindersForDealsQueryVariables
            >(
                GetRemindersForDealsDocument,
                variables,
                options,
            ) as Promise<GetRemindersForDealsQuery>;
        },
        GetCommentsForDeals(
            variables: GetCommentsForDealsQueryVariables,
            options?: C,
        ): Promise<GetCommentsForDealsQuery> {
            return requester<
                GetCommentsForDealsQuery,
                GetCommentsForDealsQueryVariables
            >(
                GetCommentsForDealsDocument,
                variables,
                options,
            ) as Promise<GetCommentsForDealsQuery>;
        },
        DeleteOutreachBlock(
            variables: DeleteOutreachBlockMutationVariables,
            options?: C,
        ): Promise<DeleteOutreachBlockMutation> {
            return requester<
                DeleteOutreachBlockMutation,
                DeleteOutreachBlockMutationVariables
            >(
                DeleteOutreachBlockDocument,
                variables,
                options,
            ) as Promise<DeleteOutreachBlockMutation>;
        },
        CreateOutreachBlock(
            variables: CreateOutreachBlockMutationVariables,
            options?: C,
        ): Promise<CreateOutreachBlockMutation> {
            return requester<
                CreateOutreachBlockMutation,
                CreateOutreachBlockMutationVariables
            >(
                CreateOutreachBlockDocument,
                variables,
                options,
            ) as Promise<CreateOutreachBlockMutation>;
        },
        UpdateOutreachBlock(
            variables: UpdateOutreachBlockMutationVariables,
            options?: C,
        ): Promise<UpdateOutreachBlockMutation> {
            return requester<
                UpdateOutreachBlockMutation,
                UpdateOutreachBlockMutationVariables
            >(
                UpdateOutreachBlockDocument,
                variables,
                options,
            ) as Promise<UpdateOutreachBlockMutation>;
        },
        Translate(
            variables: TranslateQueryVariables,
            options?: C,
        ): Promise<TranslateQuery> {
            return requester<TranslateQuery, TranslateQueryVariables>(
                TranslateDocument,
                variables,
                options,
            ) as Promise<TranslateQuery>;
        },
        CreateDealActivity(
            variables: CreateDealActivityMutationVariables,
            options?: C,
        ): Promise<CreateDealActivityMutation> {
            return requester<
                CreateDealActivityMutation,
                CreateDealActivityMutationVariables
            >(
                CreateDealActivityDocument,
                variables,
                options,
            ) as Promise<CreateDealActivityMutation>;
        },
        UpdateDealActivity(
            variables: UpdateDealActivityMutationVariables,
            options?: C,
        ): Promise<UpdateDealActivityMutation> {
            return requester<
                UpdateDealActivityMutation,
                UpdateDealActivityMutationVariables
            >(
                UpdateDealActivityDocument,
                variables,
                options,
            ) as Promise<UpdateDealActivityMutation>;
        },
        DeleteDealActivity(
            variables: DeleteDealActivityMutationVariables,
            options?: C,
        ): Promise<DeleteDealActivityMutation> {
            return requester<
                DeleteDealActivityMutation,
                DeleteDealActivityMutationVariables
            >(
                DeleteDealActivityDocument,
                variables,
                options,
            ) as Promise<DeleteDealActivityMutation>;
        },
        OmniSearch(
            variables: OmniSearchQueryVariables,
            options?: C,
        ): Promise<OmniSearchQuery> {
            return requester<OmniSearchQuery, OmniSearchQueryVariables>(
                OmniSearchDocument,
                variables,
                options,
            ) as Promise<OmniSearchQuery>;
        },
        GetContactExtractionResult(
            variables: GetContactExtractionResultQueryVariables,
            options?: C,
        ): Promise<GetContactExtractionResultQuery> {
            return requester<
                GetContactExtractionResultQuery,
                GetContactExtractionResultQueryVariables
            >(
                GetContactExtractionResultDocument,
                variables,
                options,
            ) as Promise<GetContactExtractionResultQuery>;
        },
        RunContactExtractionForDeal(
            variables: RunContactExtractionForDealMutationVariables,
            options?: C,
        ): Promise<RunContactExtractionForDealMutation> {
            return requester<
                RunContactExtractionForDealMutation,
                RunContactExtractionForDealMutationVariables
            >(
                RunContactExtractionForDealDocument,
                variables,
                options,
            ) as Promise<RunContactExtractionForDealMutation>;
        },
        GetActivityLogByDeal(
            variables: GetActivityLogByDealQueryVariables,
            options?: C,
        ): Promise<GetActivityLogByDealQuery> {
            return requester<
                GetActivityLogByDealQuery,
                GetActivityLogByDealQueryVariables
            >(
                GetActivityLogByDealDocument,
                variables,
                options,
            ) as Promise<GetActivityLogByDealQuery>;
        },
        RunAutoEnrichmentForCompany(
            variables: RunAutoEnrichmentForCompanyMutationVariables,
            options?: C,
        ): Promise<RunAutoEnrichmentForCompanyMutation> {
            return requester<
                RunAutoEnrichmentForCompanyMutation,
                RunAutoEnrichmentForCompanyMutationVariables
            >(
                RunAutoEnrichmentForCompanyDocument,
                variables,
                options,
            ) as Promise<RunAutoEnrichmentForCompanyMutation>;
        },
        RemoveEnrichmentItemFromCompany(
            variables: RemoveEnrichmentItemFromCompanyMutationVariables,
            options?: C,
        ): Promise<RemoveEnrichmentItemFromCompanyMutation> {
            return requester<
                RemoveEnrichmentItemFromCompanyMutation,
                RemoveEnrichmentItemFromCompanyMutationVariables
            >(
                RemoveEnrichmentItemFromCompanyDocument,
                variables,
                options,
            ) as Promise<RemoveEnrichmentItemFromCompanyMutation>;
        },
        UpdateCompanyWithEnrichment(
            variables: UpdateCompanyWithEnrichmentMutationVariables,
            options?: C,
        ): Promise<UpdateCompanyWithEnrichmentMutation> {
            return requester<
                UpdateCompanyWithEnrichmentMutation,
                UpdateCompanyWithEnrichmentMutationVariables
            >(
                UpdateCompanyWithEnrichmentDocument,
                variables,
                options,
            ) as Promise<UpdateCompanyWithEnrichmentMutation>;
        },
        FindContactsForCompany(
            variables: FindContactsForCompanyQueryVariables,
            options?: C,
        ): Promise<FindContactsForCompanyQuery> {
            return requester<
                FindContactsForCompanyQuery,
                FindContactsForCompanyQueryVariables
            >(
                FindContactsForCompanyDocument,
                variables,
                options,
            ) as Promise<FindContactsForCompanyQuery>;
        },
        GetLinkedinProfileContactInfo(
            variables: GetLinkedinProfileContactInfoQueryVariables,
            options?: C,
        ): Promise<GetLinkedinProfileContactInfoQuery> {
            return requester<
                GetLinkedinProfileContactInfoQuery,
                GetLinkedinProfileContactInfoQueryVariables
            >(
                GetLinkedinProfileContactInfoDocument,
                variables,
                options,
            ) as Promise<GetLinkedinProfileContactInfoQuery>;
        },
        GetDealContentMetadata(
            variables: GetDealContentMetadataQueryVariables,
            options?: C,
        ): Promise<GetDealContentMetadataQuery> {
            return requester<
                GetDealContentMetadataQuery,
                GetDealContentMetadataQueryVariables
            >(
                GetDealContentMetadataDocument,
                variables,
                options,
            ) as Promise<GetDealContentMetadataQuery>;
        },
        GetDealContentAttachmentDownloadUrl(
            variables: GetDealContentAttachmentDownloadUrlQueryVariables,
            options?: C,
        ): Promise<GetDealContentAttachmentDownloadUrlQuery> {
            return requester<
                GetDealContentAttachmentDownloadUrlQuery,
                GetDealContentAttachmentDownloadUrlQueryVariables
            >(
                GetDealContentAttachmentDownloadUrlDocument,
                variables,
                options,
            ) as Promise<GetDealContentAttachmentDownloadUrlQuery>;
        },
        DeleteDealContent(
            variables: DeleteDealContentMutationVariables,
            options?: C,
        ): Promise<DeleteDealContentMutation> {
            return requester<
                DeleteDealContentMutation,
                DeleteDealContentMutationVariables
            >(
                DeleteDealContentDocument,
                variables,
                options,
            ) as Promise<DeleteDealContentMutation>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
