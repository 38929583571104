import { TDealAttachment } from '../deal-attachments.model';
import { IDealAttachmentsStore } from './deal-attachments.store.interface';

import { makeAutoObservable } from 'mobx';

export class DealAttachmentsStore implements IDealAttachmentsStore {
    attachments: TDealAttachment[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    get attachmentList(): TDealAttachment[] {
        return this.attachments;
    }

    setAttachmentList(attachmentList: TDealAttachment[]): void {
        this.attachments = attachmentList;
    }
}
